import React from 'react';

export default function CrossIcon({
  fill = 'none',
  stroke = '#A7ACB0',
  className = '',
  onClick = () => {},
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="🔐-1-Login-&amp;-Onboarding"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="1-Forgotten-Password"
          transform="translate(-325.000000, -302.000000)"
          stroke={stroke}
          strokeWidth="2"
        >
          <g id="Icons-/-Close" transform="translate(326.000000, 303.000000)">
            <g id="cross">
              <path
                d="M8,7.95094105 L15.2909785,0.659962503 L8,7.95094105 L0.709021454,0.659962503 L8,7.95094105 Z M8,7.95094105 L15.767845,15.718786 L8,7.95094105 L0.232155016,15.718786 L8,7.95094105 Z"
                id="Line-2"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
