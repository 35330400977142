import update from 'immutability-helper';
import { GLOBAL_ALERT_ADD_TO_LIST, GLOBAL_ALERT_REMOVE_FROM_LIST } from '../constants/actionTypes';

const initialState = {};
const alertInitialState = {
  title: '',
  message: '',
  messageStyle: {},
  errors: false,
  success: false,
  id: null,
  confirmBtnText: 'Ok',
  addBtn: '',
};

const globalAlerts = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ALERT_ADD_TO_LIST: {
      for (const alertId in state) {
        if (state[alertId].message === action.data.message) {
          return state
        }
      }
      return update(state, {
        $merge: {
          [action.data.id]: {
            ...alertInitialState,
            ...action.data,
          },
        },
      });
    }
    case GLOBAL_ALERT_REMOVE_FROM_LIST: {
      return update(state, {
        $unset: [action.id],
      });
    }
    default:
      return state;
  }
};

export default globalAlerts;
