import {
  FETCH_FEATURE_FLAGS_REQUEST,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_FAIL,
} from '../constants/actionTypes';

const fetchFeatureFlagsRequest = () => ({
  type: FETCH_FEATURE_FLAGS_REQUEST,
});

const fetchFeatureFlagsSuccess = (payload) => ({
  type: FETCH_FEATURE_FLAGS_SUCCESS,
  payload
});

const fetchFeatureFlagsFail = () => ({
  type: FETCH_FEATURE_FLAGS_FAIL,
});

export { fetchFeatureFlagsRequest, fetchFeatureFlagsSuccess, fetchFeatureFlagsFail };
