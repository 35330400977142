import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ChallengeBadgeCount } from '../index';

function ChallengePostCard({ challengeItem, from }) {
  const shouldShowFeedItems = from === 'feed';

  return (
    <>
      <div className="challenges__item-content challenges__border mt-3">
        <div className="challenges__item-content-left">
        {shouldShowFeedItems ? (
          <div className="challenges__item-tag">
            Challenge
          </div>
        ) : null}
          <h6 className={`single-line-text ${challengeItem.complete ? 'complete' : ''}`}>
            {challengeItem.name}
          </h6>
          <p>
            {challengeItem.complete
              ? 'Completed'
              : `by ${moment(challengeItem.end_date).format('Do MMM YYYY')}`}
          </p>
          {shouldShowFeedItems ? (
            <Link to={`/engage/${challengeItem.id}`}>
              <div className="challenges__item-button">
                View More Details
              </div>
            </Link>
          ) : null}
        </div>
        <ChallengeBadgeCount value={challengeItem.points} />

      </div>
    </>
  );
}

ChallengePostCard.propTypes = {
  challengeItem: PropTypes.object.isRequired,
};

export default ChallengePostCard;
