export const extractTags = (form, userTags) => {
  let tags = Object.entries(form)
    .filter(([key]) => key.includes('tag'))
    .map(([key, formValue]) => {
      const tagId = key.slice(4);
      const userTag = userTags.find(tag => tag.id.toString() === tagId);
      const result = { tagId, value: '' };
      if (userTag) {
        if (userTag.type === 1) result.value = formValue.value;
        else if (userTag.type === 2) result.value = formValue.value ? userTag.options[0].id : null;
      }
      return result;
    });
  tags = tags.filter((tagItem) => tagItem.value);
  return tags;
};
