import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_REQUEST_WITH_PASSWORD,
  SIGN_IN_SUCCEEDED_WITH_PASSWORD,
  SIGN_IN_REQUEST_WITH_MAGIC_LINK,
  SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK,
  LOGOUT,
  USER_LOCKED,
  LOG_APP_SESSION_REQUEST,
} from '../constants/actionTypes';

const signInRequest = payload => ({
  type: SIGN_IN_REQUEST,
  payload,
});

const signInRequestWithPassword = payload => ({
  type: SIGN_IN_REQUEST_WITH_PASSWORD,
  payload,
});

const signInRequestMagicLink = payload => ({
  type: SIGN_IN_REQUEST_WITH_MAGIC_LINK,
  payload,
});

const userLocked = data => ({
  type: USER_LOCKED,
  data,
});

const signInSucceeded = data => ({
  type: SIGN_IN_SUCCEEDED,
  data,
});
const signInSucceededWithPassword = data => ({
  type: SIGN_IN_SUCCEEDED_WITH_PASSWORD,
  data,
});
const signInSucceededWithMagicLink = data => ({
  type: SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK,
  data,
});

const logout = () => ({
  type: LOGOUT,
});

const logAppSession = () => ({
  type: LOG_APP_SESSION_REQUEST,
});

export {
  signInRequest,
  signInSucceeded,
  signInRequestWithPassword,
  signInSucceededWithPassword,
  signInRequestMagicLink,
  signInSucceededWithMagicLink,
  logout,
  userLocked,
  logAppSession
};
