import React from 'react';

const TaskLine = ({ isFirst, isLast, is_completed }) => (
  <div className='line-container'>
    {!isFirst ? <div className='line filled-line'></div> : <div className='line'></div>}
    {is_completed ? (
      <div className='outer-circle'>
        <div className='inner-circle circle-filled'></div>
      </div>
    ) : (
      <div className='inner-circle'></div>
    )}
    {!isLast ? <div className='line filled-line'></div> : <div className='line'></div>}
  </div>
);

export default TaskLine;
