import React from 'react';

export default function PollIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="26px"
      height="23px"
      viewBox="0 0 26 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="🚨-8-Notifications"
        stroke="none"
        strokeWidth="1"
        fill={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="8-Notifications"
          transform="translate(-372.000000, -707.000000)"
          stroke={stroke}
          strokeWidth="2"
        >
          <g id="Group" transform="translate(349.000000, 684.000000)">
            <g id="Icons-/-Polls" transform="translate(21.000000, 20.000000)">
              <rect
                id="Rectangle"
                x="3.57142857"
                y="4.28571429"
                width="22.8571429"
                height="5"
                rx="2.5"
              ></rect>
              <rect
                id="Rectangle"
                x="3.57142857"
                y="12.1428571"
                width="11.4285714"
                height="5"
                rx="2.5"
              ></rect>
              <rect id="Rectangle" x="3.57142857" y="20" width="15" height="5" rx="2.5"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
