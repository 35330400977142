import React from 'react';
import Right from '../../../../assets/images/right.svg';
import Cross from '../../../../assets/images/cross.svg';

const LearningTestItem = ({ questions, length }) => {
  const handleMultipleChoise = question => {
    const correctAnswers = question.answers.filter(answer => answer.correct);
    const userAnswers = question.answers.filter(answer => answer.user_choise && answer.correct);

    if (correctAnswers.length > 1) {
      return (
        <p>
          {' '}
          <strong>
            {userAnswers.length} out of {correctAnswers.length}
          </strong>{' '}
          correct options selected:
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      {questions
        .filter(question => question.answers !== null)
        .map((question, index) => {
          return (
            <div key={index}>
              <div className="divider" />
              <div className="single-item">
                <div>0{index + 1}.</div>
                <h3 className="title-item">
                  <strong>{question.question}</strong>
                </h3>
                {handleMultipleChoise(question)}
                {question.answers.map(answer => {
                  let style = ['d-flex answer-item'];
                  let circleStyle = [
                    'result-circle d-flex ' +
                      'user-incorrect-circle ' +
                      ' mr-2 justify-content-center',
                  ];
                  let showCorrect = false;
                  let showWrong = false;

                  if (answer.correct && answer.user_choise) {
                    style.push('user-correct');
                    circleStyle.push('user-correct-circle');
                    showCorrect = true;
                  }

                  if (answer.correct && !answer.user_choise) {
                    style.push('correct');
                    circleStyle.push('correct-circle');
                    showCorrect = true;
                  }

                  if (!answer.correct && answer.user_choise) {
                    style.push('user-incorrect');
                    circleStyle.push('user-incorrect-circle');
                    showWrong = true;
                  }

                  return (
                    <div className={style.join(' ')} key={answer.title.toString()}>
                      {showCorrect && <img src={Right} alt="" />}
                      {showWrong && (
                        <span className={circleStyle.join(' ')}>
                          <img src={Cross} alt="" />
                        </span>
                      )}
                      <span className={showCorrect ? 'ml-2' : ''}>{answer.title}</span>
                    </div>
                  );
                })}
              </div>
              {index + 1 !== length().length && <div className="divider" />}
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default LearningTestItem;
