import moment from 'moment';

export function dateDifferenceShorted(target, now = new Date()) {
  const duration = moment.duration(moment(now).diff(target));
  const isPast = duration.asMilliseconds() > 0;

  const years = Math.abs(Math.floor(duration.asYears()));
  const months = Math.abs(Math.floor(duration.asMonths()));
  const days = Math.abs(Math.floor(duration.asDays()));
  const hours = Math.abs(Math.floor(duration.asHours()));
  const minutes = Math.abs(Math.floor(duration.asMinutes()));
  const seconds = Math.abs(Math.floor(duration.asSeconds()));

  if (years > 0) return `${years}y ${isPast ? 'ago' : 'left'}`;
  if (months > 0) return `${months}m ${isPast ? 'ago' : 'left'}`;
  if (days > 0) return `${days}d ${isPast ? 'ago' : 'left'}`;
  if (hours > 0) return `${hours}h ${isPast ? 'ago' : 'left'}`;
  if (minutes > 0) return `${minutes}min${minutes === 1 ? '' : 's'} ${isPast ? 'ago' : 'left'}`;
  if (seconds > 0) return `${seconds}s ${isPast ? 'ago' : 'left'}`;

  return '';
}

export function dateDifference(target, now = new Date(), allValues) {
  let resultString = '';
  const duration = moment.duration(moment(now).diff(target));
  if (duration.asMilliseconds() > 0) return 'no time left';

  const years = Math.abs(Math.floor(duration.asYears()));
  const months = Math.abs(Math.floor(duration.asMonths()));
  const days = Math.abs(Math.floor(duration.asDays()));
  const hours = Math.abs(Math.floor(duration.asHours()));
  const minutes = Math.abs(Math.floor(duration.asMinutes()));
  const seconds = Math.abs(Math.floor(duration.asSeconds()));

  if (years > 0) resultString += `${years} year${years === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  if (months > 0) resultString += `${months} month${months === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  if (days > 0) resultString += `${days} day${days === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  if (hours > 0) resultString += `${hours} hour${hours === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  if (minutes > 0) resultString += `${minutes} minute${minutes === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  if (seconds > 0) resultString += `${seconds} second${seconds === 1 ? '' : 's'} `;
  if (!allValues && resultString) return `${resultString}left`;

  return `${resultString}left`;
}

export function timeLeft(target, now = new Date()) {
  let resultString = '';
  const duration = moment.duration(moment(target).diff(now));
  if (duration.asMilliseconds() <= 0) return false;

  let addedValues = 0;
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (months > 0) {
    resultString += `${months} month${months === 1 ? '' : 's'} `;
    addedValues++;
  }
  if (addedValues === 2) return `${resultString}left`;

  if (days > 0) {
    resultString += `${days} day${days === 1 ? '' : 's'} `;
    addedValues++;
  }
  if (addedValues === 2) return `${resultString}left`;

  if (hours > 0) {
    resultString += `${hours} hour${hours === 1 ? '' : 's'} `;
    addedValues++;
  }
  if (addedValues === 2) return `${resultString}left`;

  if (minutes > 0) {
    resultString += `${minutes} minute${minutes === 1 ? '' : 's'} `;
    addedValues++;
  }
  if (addedValues === 2) return `${resultString}left`;

  if (seconds > 0) {
    resultString += `${seconds} second${seconds === 1 ? '' : 's'} `;
    addedValues++;
  }
  if (addedValues === 2) return `${resultString}left`;

  return `${resultString}left`;
}

export function getYoutubeIDByURL(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[7].length == 11) {
    return match[7]
  } else {
    return null;
  }
}

export function getThumbnailURIByYoutubeURI(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[7].length == 11) {
    return `https://img.youtube.com/vi/${match[7]}/0.jpg`;
  } else {
    return null;
  }
}