import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';

class SignInWithMagicLink extends Component {
  componentDidMount() {
    this.request();
  }

  request() {
    const token = this.props.match.params.token;
    // const clippedToken = decodeURIComponent(token);
    // const decodedBase64 = atob(clippedToken);
    const decodedJWT = jwt_decode(token);

    this.props.signInWithMagicLink({
      username: decodedJWT.email,
      token: token,
      device_key: decodedJWT.device_key,
    });
  }

  render() {
    return (
      <div className="magic-lick-preloader">
        <div id="main">
          <span id="title">Taking you to Engage4</span>
          <div className="lds-ellipsis" />
        </div>
      </div>
    );
  }
}
export default SignInWithMagicLink;
