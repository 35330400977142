/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { GroupsSearchResults, PeopleSearchResults, SearchInput } from '../../components';
import { searchGroupsRequest, searchPeopleRequest } from '../../actionCreators/search';
import { logFirebaseEvent } from '../../firebase/analytics';


import './styles.scss';
import GridContainer from '../../components/global/GridContainer/GridContainer';

function SearchOrganisations (props) {
  const [searchPerformed, toggleSearchPerformed] = useState(false);

  useEffect(() => {
    props.searchPeopleRequest({ value: '' });
    props.searchGroupsRequest('', props.token.key);
    logFirebaseEvent({
      event: 'search',
      action: 'org',
    });
  }, []);

  const handleSearchSubmit = value => {
    toggleSearchPerformed(true);
    props.searchPeopleRequest({ value });
    props.searchGroupsRequest(value, props.token.key);
  };

  const filterItems = (data, type) => {
    return data.filter(item => (type === 'user' ? item.first_name || item.last_name : item.name));
  };

  if (false) {
    return (
      <div className='search-organisation'>
        <div className='search-wrapper'>
          <div className='row'>
            <div className=' justify-content-center align-self-center w-100'>
              <div className='col-md-6 offset-md-3'>
                <h2 className='font-weight-bold page-title'>Search Organisation</h2>

                <div className='search-inner-container'>
                  <SearchInput submit={handleSearchSubmit} placeholder='Search' />

                  <div className='mt-3 results-cont'>
                    {filterItems(props.searchResults.users, 'user').length > 0 && (
                      <PeopleSearchResults data={filterItems(props.searchResults.users, 'user')} />
                    )}
                    {filterItems(props.searchResults.groups, 'group').length > 0 && (
                      <GroupsSearchResults
                        data={filterItems(props.searchResults.groups, 'group')}
                      />
                    )}

                    {filterItems(props.searchResults.groups, 'group').length +
                      filterItems(props.searchResults.users, 'user').length ===
                      0 &&
                      searchPerformed && (
                        <p className='text-secondary'>Sorry, we couldn't find anything.</p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='search-organisation'>
        <GridContainer>
          <div className='search-wrapper'>
            <div className='justify-content-center align-self-center align-items-center w-100 d-flex flex-column'>
              <span className='search-label'>Search Organisation</span>
              <div className='search-inner-container'>
                <SearchInput submit={handleSearchSubmit} placeholder='Search' />
                <div className='mt-3 results-cont'>
                  {filterItems(props.searchResults.users, 'user').length > 0 && (
                    <PeopleSearchResults data={filterItems(props.searchResults.users, 'user')} />
                  )}
                  {filterItems(props.searchResults.groups, 'group').length > 0 && (
                    <GroupsSearchResults data={filterItems(props.searchResults.groups, 'group')} />
                  )}

                  {filterItems(props.searchResults.groups, 'group').length +
                    filterItems(props.searchResults.users, 'user').length ===
                    0 &&
                    searchPerformed && (
                      <p className='text-secondary'>Sorry, we couldn't find anything.</p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }
}

SearchOrganisations.propTypes = {
  token: PropTypes.object.isRequired,
  searchResults: PropTypes.object,
  searchPeopleRequest: PropTypes.func.isRequired,
  searchGroupsRequest: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currentUser: { signInData: token }, searchResults }) => ({
  token,
  searchResults,
});

const mapDispatchToProps = {
  searchPeopleRequest,
  searchGroupsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrganisations);
