import React, { PureComponent } from 'react';

import FeedPollItemOption from './components/FeedPollItemOption';
import { timeLeft } from '../../../utils/helpers';
import { logFirebaseEvent } from '../../../firebase/analytics';
import { Link } from 'react-router-dom';

class FeedPollItem extends PureComponent {
  _vote = optionId => {
    if (this.props.disableVote) return;
    logFirebaseEvent({
      event: 'poll',
      action: 'vote',
    });
    this.props.voteOnPost(optionId);
  };
  parseQuestion = questionTxt => {
    let reg = /@\[([^\]]+?)]\(id:([^\]]+?)\)/;
    const parts = [];

    let left = questionTxt || '';
    while (left !== '') {
      let matched = false;
      const match = reg.exec(left);
      if (match) {
        matched = true;
        parts.push(left.substr(0, match.index));
        parts.push(
          <Link
            className='feed-item-footer-comment-owner'
            key={match[2]}
            to={
              this.props.myGroups && this.props.myGroups.indexOf(match[2]) !== -1
                ? `/group-feed/${match[2]}`
                : `/users/${match[2]}`
            }
          >
            {match[1]}
          </Link>,
        );
        left = left.substr(match.index + match[0].length);
      } else {
        parts.push(left.substr(0));
        left = '';
      }
    }
    return parts
  }
  render() {
    const { item } = this.props;
    const totalVotes = item.post.options.reduce((sum, item) => sum + item.total_votes, 0);
    const timeLeftToVote = timeLeft(item.post.expiry_date);
    const showAsFinished = !timeLeftToVote || !!item.post.my_vote_id;

    return (
      <div className="feed-item-content">
        {this.parseQuestion(item.post.question)}

        <div className="d-flex flex-wrap feed-item-content-poll">
          {item.post.options.map(option => {
            return (
              <FeedPollItemOption
                key={`options:${option.id}`}
                text={option.option_text}
                isSelected={option.selected}
                onClick={() => this._vote(option.id)}
                showAsFinished={showAsFinished}
                votes={option.total_votes}
                percents={(option.total_votes * 100) / totalVotes}
              />
            );
          })}
        </div>

        <p className="feed-item-content-poll-total">
          {timeLeftToVote
            ? `${totalVotes} vote${totalVotes === 1 ? '' : 's'} with ${timeLeftToVote}`
            : `Poll ended with ${totalVotes} vote${totalVotes === 1 ? '' : 's'}`}
        </p>
      </div>
    );
  }
}

export default FeedPollItem;
