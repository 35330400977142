import React from 'react';
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';


const ExploreMenu = ({ onClickAway, featureFlags, taskCount, inProgressTaskCount }) => {

  const MenuItems = [
    {
      id: 'knowledgebase',
      title: 'Knowledgebase',
      subtitle: 'View company files and info',
      path: '/knowledgebase',
    },
  ];

  
 if (featureFlags.ACTIVITY) {
    MenuItems.splice(0, 0, {
      id: 'activity',
      title: 'Activity',
      subtitle: 'View your points and badges',
      path: '/activity',
    });
  }

  if (featureFlags.EMPLOYEE_ONBOARDING && taskCount && inProgressTaskCount) {
    MenuItems.splice(2, 1, {
      id: 'onboarding',
      title: 'Onboarding',
      subtitle: 'Complete assigned tasks',
      path: '/employee_onboarding',
    });
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={'explore-menu'}
      >
        {MenuItems.map(item => (
          <div className='explore-menu-item'>
            <Link
              key={item.id}
              to={item.path}
              style={{textDecoration: 'none'}}
            >
              <p className={'explore-menu-title'}>
                {item.title}
              </p>
              <p className={'explore-menu-subtitle'}>
                {item.subtitle}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </ClickAwayListener>
  );
};

export default ExploreMenu;
