import update from 'immutability-helper';
import {
  GLOBAL_PRELOADER_ADD_TO_LIST,
  GLOBAL_PRELOADER_REMOVE_FROM_LIST,
} from '../constants/actionTypes';

const initialState = {};

const globalPreloaders = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_PRELOADER_ADD_TO_LIST: {
      return update(state, {
        $merge: {
          [action.id]: action.title,
        },
      });
    }
    case GLOBAL_PRELOADER_REMOVE_FROM_LIST: {
      return update(state, {
        $unset: [action.id],
      });
    }
    default:
      return state;
  }
};

export default globalPreloaders;
