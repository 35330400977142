import React, { Component, PureComponent } from 'react';
import { Avatar, AutoComplete } from 'antd';

import TextField from '../../global/fields/TextField';
import ImageApi from '../../../api/image';
import FeedApi from '../../../api/feed';
import lodash from 'lodash';

class SearchColleague extends Component {
  render() {
    return (
      <TextField
        fk="group"
        value={this.props.value}
        onFocus={this.props.onFocus}
        label="Select colleague"
        updateFieldData={(fk, value) => this.props.onChange({ target: { value } })}
        triggerOnChange
        ignoreBlur
        showError={this.props.showError}
        validateRule="alwaysRequired"
        validateObj={{ alwaysRequired: this.props.value }}
      />
    );
  }
}

class AutoCompleteOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item.image && this.props.item.image.thumbnailUrl) {
      ImageApi.getImage(this.props.item.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    return (
      <div className="create-feed-group-option">
        <Avatar
          className="create-feed-group-option-image"
          src={this.state.imageUrl}
          size={36}
          shape="square"
        />
        {this.props.item.first_name} {this.props.item.last_name}
      </div>
    );
  }
}

class CreateFeedItemSearchColleague extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      dataSource: [],
    };

    this.input = null;

    this._debouncedSearchGroupUsers = lodash.debounce(this._searchGroupUsers, 300);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.focusSearchColleague && this.input) {
      this.input.focus();
      this.props.resetFocusSearchColleague();
    }
  }

  _onChange = newValue => {
    this.setState({ value: newValue });
  };

  _onSearch = searchValue => {
    if (!searchValue || !this.props.selectedGroupId) return this.setState({ dataSource: [] });

    this._debouncedSearchGroupUsers(this.props.selectedGroupId, searchValue);
  };

  _searchGroupUsers = (groupId, value) => {
    FeedApi.getGroupUsers(this.props.selectedGroupId, value).then(result => {
      this.setState({ dataSource: result.users.filter(user => user.id) });
    });
  };

  _onSelect = colleagueId => {
    const selectedColleague = lodash.find(this.state.dataSource, { id: colleagueId });
    this.props.onSelect(selectedColleague);
  };

  render() {
    return (
      <AutoComplete
        ref={ref => (this.input = ref)}
        className="create-feed-group-row"
        value={this.state.value}
        onChange={this._onChange}
        dataSource={this.state.dataSource.map(this.renderOption)}
        onSelect={this._onSelect}
        onSearch={this._onSearch}
        onFocus={this.props.onFocus}
      >
        <SearchColleague showError={this.props.showError} />
      </AutoComplete>
    );
  }

  renderOption(item) {
    return (
      <AutoComplete.Option key={item.id} text={`${item.first_name} ${item.last_name}`}>
        <AutoCompleteOption item={item} />
      </AutoComplete.Option>
    );
  }
}

export default CreateFeedItemSearchColleague;
