import React, { PureComponent } from 'react';
import GroupForm from '../GroupForm/GroupForm';
import SearchPeople from '../SearchPeople';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from '../global/Tabs/Tabs';
import { connect } from 'react-redux';
import {
  updateGroupRequest,
  getGroupPrivacyTypesRequest,
  verifyGroupRequest,
  addGroupMemberRequest,
} from '../../actionCreators/groupActions';

import GridContainer from '../global/GridContainer/GridContainer';

class ManageComponent extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      activeTab: 'Overview',
      showErrors: false,
      group: false,
    };
    this.searchPeople = this.props.searchPeopleRequest;
    this.token = this.props.currentUser.signInData;
    this.group = this.props.getGroup;
    this.history = this.props.history;
  }

  tabs = [{ label: 'Overview' }, { label: 'Members' }];
  componentDidMount () {}

  _activateTab = target => {
    if (this.state.activeTab === target) return;

    this.setState({ activeTab: target, showErrors: false });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  renderContent () {
    const { group } = this.props;
    return (
      <div>
        <div className='manage-group-header-back-link' onClick={this.props.history.goBack}>
          <FontAwesomeIcon
            icon={['fas', 'chevron-left']}
            className='feed-group-header-back-link-icon'
          />
          {'Go back'}
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-end flex-wrap flex-fill'>
            <div className='d-flex flex-column flex-fill'>
              <span className='manage-group-header-title pb-2'>Manage Group</span>
              <div className='d-flex flex-wrap align-items-center'>
                <>
                  <span className='manage-group-header-desc'>{group.name}</span>
                  <span className='manage-group-footer-splitter' />
                  <span className='manage-group-header-desc'>
                    {' '}
                    {group.total_users} member{group.total_users > 1 ? 's' : ''}{' '}
                  </span>
                </>
              </div>
              <div className={`create-feed-holder active`}>
                <div className='d-flex flex-wrap align-items-center'>
                  <Tabs
                    screen={'manageGroup'}
                    tabs={this.tabs}
                    onChange={this._activateTab}
                    active={!this.state.activeTab}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // render functions
  render () {
    const {
      group,
      categories,
      groupPrivacyTypes,
      searchResults,
      groupsDetailedUpdatedAt,
    } = this.props;
    return (
      <div className='manage'>
        <GridContainer>
          <div className='d-flex w-100 flex-column'>
            <div className='manage-group-header'>
              <div className='manage-group-header-content'>{this.renderContent()}</div>
            </div>
            <div className='manage-group-content manage-group-content__lower-margin'>
              {this.state.activeTab === 'Overview' ? (
                <GroupForm
                  group={group}
                  getGroup={this.props.getGroup}
                  history={this.props.history}
                  categories={categories}
                  privacyTypes={groupPrivacyTypes}
                  updatedAt={groupsDetailedUpdatedAt}
                  updateGroup={this.props.updateGroup}
                  token={this.token}
                />
              ) : (
                <SearchPeople
                  group={group}
                  addMembers={this.props.addMembersToGroup}
                  token={this.token}
                  searchResults={searchResults}
                  searchPeople={this.searchPeople}
                />
              )}
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.group,
    groupPrivacyTypes: state.group.groupPrivacyTypes,
  }),
  dispatch => ({
    updateGroup: payload => dispatch(updateGroupRequest(payload)),
    getGroupPrivacyTypes: () => dispatch(getGroupPrivacyTypesRequest()),
  }),
)(ManageComponent);
