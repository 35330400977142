import React from 'react';
import lottie from 'lottie-web';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import Portal from '../Portal'
import dailyPulseFace from '../../assets/images/dailyPulseFace';
import { logFirebaseEvent } from '../../firebase/analytics';

const marks = {
  0: 'Bad',
  10: {},
  20: {},
  30: {},
  40: {},
  50: 'Fine',
  60: {},
  70: {},
  80: {},
  90: {},
  100: 'Great',
};

class DailyPulse extends React.Component {
  state = {
    value: 50,
    moveSlider: false,
  };

  animation = null;
  totalFrames = 0;

  componentDidMount() {
    this.animation = lottie.loadAnimation({
      container: this.container,
      renderer: 'svg',
      name: 'container',
      autoplay: false,
      animationData: dailyPulseFace,
    });

    this.totalFrames = this.animation.getDuration(true);
    this.animation.setSpeed(3);
    this.animation.goToAndStop(this.totalFrames / 2, true);
  }

  handleSliderValueChange = newValue => {
    const nextFrame = (this.totalFrames / 100) * newValue;
    this.animation.playSegments(
      [this.animation.firstFrame + this.animation.currentFrame, nextFrame],
      true
    );

    this.setState({ value: newValue, moveSlider: true });
  };

  setSeen = dailyPulseItem => {
    dailyPulseItem.lastSeen = {
      year: parseInt(moment().format('YYYY')),
      day: parseInt(moment().format('DDD')),
    };

    localStorage.setItem(`dailyPulse-${this.props.userId}`, JSON.stringify(dailyPulseItem));
    this.props.hide();
  };

  handleNotNowClicked = () => {
    const dailyPulseItem = JSON.parse(localStorage.getItem(`dailyPulse-${this.props.userId}`));
    dailyPulseItem.notNowClicked = dailyPulseItem.notNowClicked + 1;

    if (dailyPulseItem.notNowClicked > 2) {
      let hideUntilDay = parseInt(moment().format('DDD')) + 7;
      let hideUntilYear = parseInt(moment().format('YYYY'));

      if (hideUntilDay > 365) {
        hideUntilYear++;
        hideUntilDay = hideUntilDay - 365;
      }

      dailyPulseItem.hideUntil = {
        year: hideUntilYear,
        day: hideUntilDay,
      };
    }

    this.props.sendPulse({ rating: -1 });
    this.props.hide();
  };

  handleDoneClicked = () => {
    if (this.state.moveSlider) {
      const dailyPulseItem = JSON.parse(localStorage.getItem(`dailyPulse-${this.props.userId}`));
      this.setSeen(dailyPulseItem);

      this.props.sendPulse({ rating: this.state.value });
      this.props.hide();

      logFirebaseEvent({
        event: 'add_pulse',
        action: this.state.value,
      });
    }
  };

  getColor = () => {
    if (this.state.value < 25) return 'red';
    if (this.state.value > 75) return 'green';

    return 'yellow';
  };

  render() {
    return (
      <Portal
        isOpen
      >
        <div className={`feed-item daily-pulse ${this.getColor()}`}>
          <h5>How are you feeling today?</h5>
          <p style={{ marginBottom: 20 }}>Adjust to tell us how you feel:</p>
          <div
            ref={el => (this.container = el)}
            className="daily-pulse__animated-face"
            style={{
              transform: `translateX(-50%) scale(1.${(this.state.value / 50)
                .toString()
                .replace('.', '')})`,
            }}
          />
          <div
            className='daily-pulse__done-button'
            onClick={this.handleDoneClicked}
            style={!this.state.moveSlider ? { backgroundColor: '#D8D8D8' } : {}}
          >
            DONE
        </div>
          <div className="daily-pulse__not-now" onClick={this.handleNotNowClicked}>
            NOT NOW
        </div>
          <Slider
            step={1}
            min={1}
            max={100}
            marks={marks}
            tooltipVisible={false}
            defaultValue={this.state.value}
            onChange={this.handleSliderValueChange}
          />
        </div>
      </Portal>
    );
  }
}

DailyPulse.propTypes = {
  hide: PropTypes.func.isRequired,
  sendPulse: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default DailyPulse;
