export const CATEGORY = {
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
};

const getCategoriesRequest = () => ({
  type: CATEGORY.GET_CATEGORIES_REQUEST,
});

const getCategoriesSuccess = data => ({
  type: CATEGORY.GET_CATEGORIES_SUCCESS,
  data,
});

export { getCategoriesRequest, getCategoriesSuccess };
