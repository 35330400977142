export default request => {
  const errorStatuses = [400, 401, 402, 404, 413, 429, 500];

  return fetch(request)
    .then(response => {
      if (response.status === 204) return {};

      if (response.url.includes('https://api-dev.engage4.com/v1/content/image')) {
        return response;
      }

      return response
        .json()
        .then(resp => {
          if (errorStatuses.includes(response.status) && resp.failure_reason !== 'LOCKED') {
            const message = resp.message || resp.detail || (resp.nonFieldErrors && resp.nonFieldErrors[0]);
            const errorObj = {
              status: response.status,
              error: resp.error,
              code: resp.code,
              ...(message ? { message } : { fieldsErrors: resp }),
            };
            return Promise.reject(errorObj);
          }

          return resp;
        })
        .catch(error => {
          if (error) throw error;

          const errorObj = {
            status: response.status,
            message: response.statusText,
          };

          throw errorObj;
        });
    })
    .catch(error => Promise.reject(error));
};
