import { GET_CUSTOM_APP_REQUEST, GET_CUSTOM_APP_SUCCESS, GET_CUSTOM_APP_FAILURE } from '../constants/actionTypes';

export const getCustomAppRequest = payload => ({
  type: GET_CUSTOM_APP_REQUEST,
  payload,
});

export const getCustomAppSuccess = payload => ({
  type: GET_CUSTOM_APP_SUCCESS,
  payload,
});

export const getCustomAppFailure = payload => ({
    type: GET_CUSTOM_APP_FAILURE,
    payload,
  });
