import {
  GET_NOTIFICATIONS_REQUEST,
  SET_NOTIFICATIONS_READ_REQUEST,
} from '../constants/actionTypes';

export const getNotificationsRequest = payload => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload,
});

export const setNotificationsReadRequest = payload => ({
  type: SET_NOTIFICATIONS_READ_REQUEST,
  payload,
});
