import React, { Component, useEffect, useState } from 'react';
import update from 'immutability-helper';
import SelectField from '../../global/fields/SelectField';
import TextField from '../../global/fields/TextField';
import ConsentModal from '../../../containers/global/ConsentModal';
import PasswordCheckList from './PasswordChecklist';

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        fields: {
          username: {
            label: 'Username',
            value: '',
            type: 'username',
            validateRule: 'username',
          },
          password: {
            label: 'Password',
            value: '',
            type: 'password',
            validateRule: 'password',
          },
          confirmpassword: {
            label: 'Repeat Password',
            value: '',
            type: 'password',
            validateRule: 'password',
          },
        },
        showValidateBlock_email: false,
        showValidateBlock_password: false,
      },
    }

    this.updateFieldType = this.updateFieldType.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
  }



  updateFieldType(fk, type) {
    this.setState(state => {
      return update(state, {
        form: {
          fields: {
            [fk]: {
              type: { $set: type }
            }
          }
        }
      })
    })
  }

  updateFieldValue(fk, value, error) {
    this.setState(state =>
      update(state, {
        form: {
          fields: {
            [fk]: {
              value: { $set: value },
              error: { $set: error },
            },
          },
        },
        showValidateBlock_email: { $set: false },
        showValidateBlock_password: { $set: false },
      }),
    );
  }

  render() {
    const { account, securityAnswer, password, confirmPassword, securityQuestionId } = this.props;
    const securityQuestions = account.securityQuestions.map(item => ({
      value: item.id,
      displayName: item.long_text,
    }));
    return (
      <div>
        <p className='field-container__account-helper-text'>
          Create a password you’ll use to access Engage4 in future:
        </p>
        <TextField
          fk='password'
          key='password'
          label={'Password'}
          type={this.state.form.fields.password.type}
          value={password}
          updateFieldData={this.props.onChangeFieldValue}
          updateFieldType={this.updateFieldType}
          triggerOnChange={true}
        />
        <TextField
          fk='confirmPassword'
          type={this.state.form.fields.confirmpassword.type}
          key='confirmpassword'
          label={'Repeat Password'}
          value={confirmPassword}
          updateFieldData={this.props.onChangeFieldValue}
          updateFieldType={this.updateFieldType}
          triggerOnChange={true}
        />
        {this.props.passwordNotMatch && (
          <div className='field-container__error-wrapper'>
            <p className='field-container__error m-0'>Passwords do not match</p>
          </div>
        )}
        <p className='field-container__account-helper-text mb-3 mt-3'>
          Your password should be at least 8 characters long and contain a number, special character
          and is case sensitive
        </p>
        <PasswordCheckList
          password={password}
          updatePasswordValidity={this.props.updatePasswordValidity}
        />
        {!this.props.hideSecurity && (
          <>
            <p className='field-container__account-helper-text mt-4'>
              Pick a security question in case you need to recover your password:
            </p>
            <SelectField
              fk='securityQuestionId'
              key='securityQuestionId'
              placeholder={'Choose security question'}
              options={securityQuestions}
              value={securityQuestionId}
              updateFieldData={this.props.onChangeFieldValue}
            />
            <TextField
              fk='securityAnswer'
              key='securityAnswer'
              type='password'
              label={'Answer'}
              value={securityAnswer}
              updateFieldData={this.props.onChangeFieldValue}
              triggerOnChange={true}
            />
          </>
        )}
        {this.props.passwordMigration && <ConsentModal />}
      </div>
    );
  }
}

export default SetPassword;
