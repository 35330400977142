import * as React from "react"

function Public(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <g
        transform="translate(-16 -402) translate(16 396) translate(0 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#91C7B5" x={0} y={0} width={40} height={40} rx={8} />
        <path
          d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25zm-4.921-7.5a1.5 1.5 0 001.455-1.864l-.75-3A1.5 1.5 0 006.329 9.75H.976a11.246 11.246 0 004.016 11.042L6 15.75h1.079zm13.906-10.5h-4.064a1.5 1.5 0 00-1.455 1.136l-.75 3a1.5 1.5 0 001.455 1.864h1.579l.791 4.75a1.5 1.5 0 001.48 1.253h1.925a11.2 11.2 0 00-.961-12V5.25z"
          transform="translate(8 8)"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
  )
}

export default Public