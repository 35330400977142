import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders, getCookieValForKey } from './helpers';

class ConsentsApi {
  static getConsents() {
    const request = new Request(
      `${apiUrl}/account/privacy`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }

  static getConsentsStatus() {
    const request = new Request(
      `${apiUrl}/account/privacy/agreed`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }

  static setConsentsAgreed(data) {
    const token = getCookieValForKey('token');
    if (token.success === false) {
      alert('An error occurred, please try again or contact support')
    }
    const request = new Request(`${apiUrl}/account/privacy/agreed`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.key}`,
      },
      body: JSON.stringify(data)
    });

    return fetchRequest(request);
  }
}

export default ConsentsApi;