import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleData } from '../../containers/Engage/helpers';
import { logFirebaseEvent } from '../../firebase/analytics';

function EngageEvents ({ myEvents, allEvents }) {
  const [viewMore, viewMoreToggle] = useState(false);

  useEffect(() => {
    logFirebaseEvent({
      event: 'events',
      action: 'load',
    });
  }, []);
  const handleTime = dataObject => {
    return handleData(dataObject);
  };
  return (
    <>
      <div className='my-events-container'>
        {allEvents.length === 0 && myEvents.length === 0 ? (
          <div className='mt-5 placeholder-text'>
            <p>There are currently no events planned.</p>
            <p>Check back later.</p>
          </div>
        ) : null}
        {myEvents.length > 0 ? (
          <div className='d-flex justify-content-between'>
            <h3 className='title'>Your Events</h3>
            <button
              onClick={() => {
                viewMoreToggle(!viewMore);
              }}
              className='single-line-no-overflow text-uppercase btn'
            >
              view all
            </button>
          </div>
        ) : null}
        {myEvents.length > 0 &&
          myEvents.slice(0, 3).map(event => {
            const eventData = handleTime(event.post.start_time);
            const eventDataEnds = handleTime(event.post.end_time);
            const sameDay = eventData.fullDay === eventDataEnds.fullDay;

            return (
              <div key={event.id} className='event d-flex'>
                <div className='event-calendar'>
                  <div className='event-container'>
                    <span className='month'>{eventData.month}</span> <br />
                    <span className='number'>{eventData.day}</span>
                  </div>
                </div>
                <div className='event-details'>
                  <Link to={`/feed/${event.post.id}?type=event`}>
                    <h3 className='title'>{event.post.title}</h3>
                  </Link>
                  <div className='time'>
                    {eventData.time}
                    {eventData.amPm} <div className='oval' /> {eventData.fullDay}
                    {event.post.end_time && (
                      <>
                        <span className='ml-1 mr-1'>-</span>
                        {eventDataEnds.time}
                        {eventDataEnds.amPm} <div className='oval' /> {eventDataEnds.fullDay}
                      </>
                    )}
                  </div>
                  <p className='location'>{event.post.location}</p>
                </div>
              </div>
            );
          })}

        {viewMore &&
          myEvents.slice(3).map(event => {
            const eventData = handleTime(event.post.start_time);
            const eventDataEnds = handleTime(event.post.end_time);
            const sameDay = eventData.fullDay === eventDataEnds.fullDay;

            return (
              <div key={event.id} className='event d-flex'>
                <div className='event-calendar'>
                  <div className='event-container'>
                    <span className='month'>{eventData.month}</span> <br />
                    <span className='number'>{eventData.day}</span>
                  </div>
                </div>
                <div className='event-details'>
                  <Link to={`/feed/${event.post.id}?type=event`}>
                    <h3 className='title'>{event.post.title}</h3>
                  </Link>
                  <div className='time'>
                    {eventData.time}
                    {eventData.amPm} <div className='oval' /> {eventData.fullDay}
                    {event.post.end_time && (
                      <>
                        <span className='ml-1 mr-1'>-</span>
                        {eventDataEnds.time}
                        {eventDataEnds.amPm} <div className='oval' /> {eventDataEnds.fullDay}
                      </>
                    )}
                  </div>
                  <p className='location'>{event.post.location}</p>
                </div>
              </div>
            );
          })}
      </div>

      <div className='my-events-container'>
        {allEvents.length > 0 && (
          <div className='d-flex justify-content-between'>
            <h3 className='title'>Upcoming Events</h3>
          </div>
        )}
        {allEvents.length > 0 &&
          allEvents.map(event => {
            const eventData = handleTime(event.post.start_time);
            const eventDataEnds = handleTime(event.post.end_time);
            const sameDay = eventData.fullDay === eventDataEnds.fullDay;
            return (
              <div key={event.id} className='event d-flex'>
                <div className='event-calendar'>
                  <div className='event-container'>
                    <span className='month'>{eventData.month}</span> <br />
                    <span className='number'>{eventData.day}</span>
                  </div>
                </div>
                <div className='event-details'>
                  <Link to={`/feed/${event.post.id}?type=event`}>
                    <h3 className='title'>{event.post.title}</h3>
                  </Link>
                  <div className='time'>
                    {eventData.time}
                    {eventData.amPm} <div className='oval' /> {eventData.fullDay}
                    {event.post.end_time && (
                      <>
                        <span className='ml-1 mr-1'>-</span>
                        {eventDataEnds.time}
                        {eventDataEnds.amPm} <div className='oval' /> {eventDataEnds.fullDay}
                      </>
                    )}
                  </div>
                  <p className='location'>{event.post.location}</p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default EngageEvents;
