import React, { useEffect, useState } from 'react';
import ProgressBar from '../../components/global/ProgressBar';
import BackLabel from './backLabel';
import { getUserMicroLearningQuestions } from '../../actionCreators/userProfile';
import { connect } from 'react-redux';
import LearningRadio from './components/LearningRadioButton';
import LearningCheckbox from './components/LearningCheckbox';
import LearningResults from './components/LearningTestResult';
import './styles.scss';

const LearningTestContainer = props => {
  const { handleQuestions, history, match, questions } = props;
  const [step, handleStep] = useState(0);
  const [value, handleChange] = useState('');
  const [results, handleResults] = useState({});
  const [checkBoxes, handleChackBoxes] = useState([]);
  const [buttonStyles, handleStyles] = useState(['continue-btn disabled-btn']);
  const progressBarLength = questions && (100 * (step + 1)) / questions.length;
  const queryParams = new URLSearchParams(props.location.search);
  const taskId = queryParams.get('taskId');
  const firstTimeLogin = queryParams.get('firstTimeLogin') || '';
  useEffect(() => {
    handleQuestions(match.params.learningId);
  }, []);

  const handleAddResults = () => {
    if (
      (step === 0 && questions[step].type === 'radiobutton' && value === '') ||
      (questions[step].type === 'checkbox' && checkBoxes.length <= 0)
    ) {
      handleStyles(['continue-btn disabled-btn']);
      return;
    }

    if (
      (questions[step].type === 'radiobutton' && value === '') ||
      (questions[step].type === 'checkbox' && checkBoxes.length <= 0)
    ) {
      handleStyles(['continue-btn disabled-btn']);
      return;
    }

    if (questions[step + 1] !== undefined && questions[step + 1].type === 'statement') {
      handleStyles(['continue-btn']);
    } else {
      handleStyles(['continue-btn disabled-btn']);
    }

    if (questions[step].type === 'statement') {
      handleResults({ ...results, [step]: ' ' });
    }
    if (questions[step].type === 'checkbox') {
      handleResults({ ...results, [step]: checkBoxes });
    }
    if (questions[step].type === 'radiobutton') {
      if (value === '') return;
      handleResults({ ...results, [step]: value });
    }

    handleStep(step + 1);
    handleChange('');
    handleChackBoxes([]);
  };

  const handleBackStep = () => {
    const newResult = { ...results };
    delete newResult[step];

    handleStyles(['continue-btn']);

    handleResults(newResult);
    handleChange(results[step - 1]);
    handleChackBoxes(results[step - 1]);
    handleStep(step - 1);
  };

  const handleRadio = index => {
    handleStyles(['continue-btn']);
    handleChange(index);
  };

  const handleCheckBox = value => {
    if (value && value.length) {
      handleStyles(['continue-btn']);
    } else {
      handleStyles(['continue-btn disabled-btn']);
    }
    handleChackBoxes(value);
  };

  const learningProps = {
    value,
    checkBoxes,
    handleChange,
    handleChackBoxes,
    questions,
    handleRadio,
    handleCheckBox,
    step,
  };

  const resultProps = {
    results,
    history,
    progressBarLength,
  };

  if (questions && step === questions.length) {
    return (
      <LearningResults
        id={match.params.learningId}
        {...resultProps}
        taskId={taskId}
        firstTimeLogin={firstTimeLogin}
      />
    );
  }

  return (
    <React.Fragment>
      <div className='progressbar'>
        <ProgressBar
          className='m-0'
          width={progressBarLength}
          backLabel={<BackLabel text={taskId ? 'Back to Onboarding' : 'Back to Micro-learning'} />}
          onGoBackClick={() =>
            history.push(
              taskId
                ? `/employee_onboarding?firstTimeLogin=${firstTimeLogin}`
                : '/engage?tab=learning',
            )
          }
        />
      </div>
      <div className='learning-question'>

        <div className='back-btn-container'>
          {step > 0 && (
            <BackLabel
              onClick={() => {
                handleBackStep();
              }}
              text={`Back to Step 0${step}`}
            />
          )}
        </div>
        <div className='learning-question-container'>
          <p className='question-number'>0{step + 1}.</p>
          <p
            className={
              questions && questions[step].type === 'statement'
                ? 'question-description'
                : 'question-title'
            }
          >
            {questions && questions[step].question}
          </p>
          {questions && questions[step].type === 'checkbox' && (
            <p className='question-description'>Select all that apply:</p>
          )}
          <ul className='learning-question-container--list'>
            {questions && questions[step].type === 'checkbox' ? (
              <LearningCheckbox {...learningProps} />
            ) : (
              <LearningRadio {...learningProps} />
            )}
          </ul>
        </div>
        <div className='learning-question-bottom-container'>
          <button
            className={
              questions && questions[step].type === 'statement'
                ? 'continue-btn'
                : buttonStyles.join(' ')
            }
            onClick={() => {
              handleAddResults();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  state => ({
    questions: state.currentUser.learningQuestions.questions,
  }),
  dispatch => ({
    handleQuestions: id => dispatch(getUserMicroLearningQuestions(id)),
  }),
)(LearningTestContainer);
