import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class SearchApi {
  static getPeople (data) {
    const groupId = data.groupId || '';
    let requestURL = `${apiUrl}/content/user?limit=100&page=1&filter=${data.value || ''}`;
    requestURL += groupId ? `&in_group_id=${groupId}` : '';
    const request = new Request(requestURL, {
      registered: true,
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getGroups (data) {
    const request = new Request(`${apiUrl}/content/group?filter=${data.value || ''}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}

export default SearchApi;
