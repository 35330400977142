import { GET_ORG_THEME_REQUEST, GET_ORG_THEME_SUCCESS } from '../constants/actionTypes';

export const getOrgThemeRequest = payload => ({
  type: GET_ORG_THEME_REQUEST,
  payload,
});

export const getOrgThemeSuccess = payload => ({
  type: GET_ORG_THEME_SUCCESS,
  payload,
});
