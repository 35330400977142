import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tabs extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let active = props.active;
    if (!props.active && props.tabs && props.tabs[0]) {
      if (typeof props.tabs[0] === 'string') active = props.tabs[0];
      else active = props.tabs[0].label;
    }

    this.state = {
      active: active,
    };
  }

  _activateTab = target => () => {
    this.setState({ active: target });
    if (typeof this.props.onChange === 'function') this.props.onChange(target);
  };

  render() {
    const { screen } = this.props
    // if screen is ManageGroup screen then set screenName to 'manage' so that the correct styles can be applied. otherwise keep as 'tab'
    const screenName = screen ? "manage" : "tab"
    return (
      <div className={`${screenName}-holder`}>
        {this.props.tabs.map(tab => {
          let Icon = null;
          let label = '';
          if (typeof tab === 'string') label = tab;
          else {
            Icon = tab.icon;
            label = tab.label;
          }

          return (
            <div
              key={label}
              className={`${screenName}-item ${this.state.active === label ? 'selected' : ''}`}
              onClick={this._activateTab(label)}
            >
              {Icon && (
                <Icon
                  className="tab-item-icon"
                  stroke={this.state.active === label ? '#505A62' : '#FFF'}
                />
              )}
              {label}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Tabs;
