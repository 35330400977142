import React from 'react'

import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'reactstrap'



const ErrorModal = ({ isOpen, message, title, onClose }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <div>{title}</div>
      </ModalHeader>
      <ModalBody>
        <div>{message}</div>
      </ModalBody>
      <ModalFooter>
        <div style={{ flex: 1 }} />
        <Button color='primary' onClick={onClose}>Ok</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ErrorModal