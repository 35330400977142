import React, { useEffect, useState } from 'react';
import CheckBoxRoundGreen from '../../Icons/CheckBoxRoundGreen';
import CheckBoxRound from '../../Icons/CheckBoxRound';
import { configConsumerProps } from 'antd/lib/config-provider';

const PasswordCheckList = ({ style, password, updatePasswordValidity }) => {
  const CheckList = [
    {
      isPassed: false,
      title: 'At least 8 characters',
    },
    {
      isPassed: false,
      title: 'Contains a number',
    },
    {
      isPassed: false,
      title: 'Contains a special character',
    },
  ];
  const [passwordChecks, setPasswordChecks] = useState(CheckList);

  useEffect(() => {
    let speicalCharsformat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let checkList = [...CheckList];
    checkList[0].isPassed = password.length >= 8 ? true : false;
    checkList[1].isPassed = /\d/.test(password) ? true : false;
    checkList[2].isPassed = speicalCharsformat.test(password) ? true : false;
    const isPasswordValid = checkList.filter(item => item.isPassed).length === 3 ? true : false;
    setPasswordChecks(checkList);
    updatePasswordValidity(isPasswordValid);
  }, [password]);

  return (
    <div style={style}>
      {passwordChecks.map(({ isPassed, title }) => (
        <div className='d-flex align-items-center mb-3'>
          {isPassed ? <CheckBoxRoundGreen /> : <CheckBoxRound />}
          <p className='field-container__account-helper-text ml-3'>{title}</p>
        </div>
      ))}
    </div>
  );
};

export default PasswordCheckList;
