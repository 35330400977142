import React, { Component, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { Avatar } from 'antd';
import lodash from 'lodash';

import TextField from '../global/fields/TextField';
import SelectField from '../global/fields/SelectField';
import Button from '../global/Button';
import UserProfileApi from '../../api/userProfile';
import OnBoardingScreen from './OnBoardingScreen';
import ProgressBar from '../global/ProgressBar';

import userPlaceholder from '../../assets/images/user_placeholder.svg';
import Plus from '../../assets/images/add-img.svg';
import { screenData, policyData } from './onboardingScreenData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConsentModal from '../../containers/global/ConsentModal';
import CheckboxField from '../global/fields/Checkbox';
import SplashImage from '../../assets/images/welcome.png';
import ConsentsApi from '../../api/consents';
import imgTeams from '../../assets/images/img_teams.png';
import ImageApi from '../../api/image';
import DomPurify from 'dompurify';
import { Checkbox } from 'antd';

import { extractTags } from './userTagsUtils';
import { mdiConsoleNetworkOutline } from '@mdi/js';
import AddIcon from '../../components/Icons/AddIcon';
import GridContainer from '../global/GridContainer/GridContainer';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import FeatureFlagsApi from '../../api/featureFlags';
import { CUSTOM_THEME } from '../../constants/themeConstants';

class UserProfile extends Component {
  constructor (props) {
    super(props);

    const queryParams = new URLSearchParams(props.location.search);
    const currentStep = +queryParams.get('currentStep') || 0;

    this.state = {
      form: {
        fields: {
          first_name: {
            label: 'First Name',
            value: '',
            type: 'text',
            validateRule: 'name',
          },
          last_name: {
            label: 'Last Name',
            value: '',
            type: 'text',
            validateRule: 'name',
          },
          job_title: {
            label: 'Job Title',
            value: '',
            type: 'text',
            validateRule: 'name',
          },
          phone_number: {
            label: 'Phone no. (optional)',
            value: '',
            type: 'text',
            inputType: 'number',
            validateRule: 'tel',
          },
          department_id: {
            label: 'Department',
            value: '',
            type: 'dropdown',
            validateRule: 'name',
          },
        },
        validate: false,
      },
      imageBlobUrl: '',
      imageFile: null,
      showValidateBlock: false,
      validationBlockInputs: [],
      onBoardingData: [{ header: '', body: '' },],
      userProfileData: [],
      screenIndex: currentStep,
      imageUrl: 'LOADING',
      currentAgreementAccepted: false,
      agreementsLength: null,
      firstAgreementStepIndex: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
  }

  async componentDidMount () {
    setTimeout(this.dispatchInitialActions, 0);
    let onBoardingData = [...this.state.onBoardingData];
    const featureFlagsResp = await FeatureFlagsApi.fetchFeatureFlags();
    const featureFlags = featureFlagsResp ? featureFlagsResp.feature_flags || {} : {};
    if (featureFlags['APP_ONBOARDING']) {
      onBoardingData = [...onBoardingData, ...screenData, ...policyData];
    } else {
      onBoardingData = [...onBoardingData, ...policyData];
    }
    this.props.getEmployeeOnboardingTasks();
    UserProfileApi.fetchOnBoardingData({ token: this.props.token.key }).then(resp => {
      const newOnBoardingData = lodash.cloneDeep(onBoardingData);

      if (resp && resp.screenData && resp.screenData[0]) {
        if (resp.screenData[0].image && resp.screenData[0].image.url) {
          newOnBoardingData.forEach(data => {
            data.image = resp.screenData[0].image;
            data.image_id = resp.screenData[0].image_id;
          });
        }

        newOnBoardingData[0] = resp.screenData[0];
      } else newOnBoardingData.shift();

      this.setState({ onBoardingData: newOnBoardingData });

      if (onBoardingData[0].image_id && this.state.imageUrl === 'LOADING') {
        ImageApi.getImage(onBoardingData[0].image.url)
          .then(image => {
            this.setState({ imageUrl: image });
          })
          .catch(() => {
            this.setState({ imageUrl: CUSTOM_THEME[this.props.customAppId].welcome });
          });
      } else {
        this.setState({ imageUrl: CUSTOM_THEME[this.props.customAppId].welcome });
      }
    });

    ConsentsApi.getConsents().then(resp => {
      const agreements = [];

      if (resp.data.engage4.text) {
        agreements.push({
          header: 'Privacy Policy',
          body: DomPurify.sanitize(resp.data.engage4.text),
          ctaButtonTitle: 'CONTINUE',
        });
      }

      if (resp.data.organisation && resp.data.organisation.text) {
        agreements.push({
          header: 'Organisation Terms',
          body: DomPurify.sanitize(resp.data.organisation.text),
        });
      }

      const beforeAgreements = onBoardingData.slice(0, -1);
      const afterAgreements = onBoardingData.slice(-1);

      this.setState({
        onBoardingData: [...beforeAgreements, ...agreements, ...afterAgreements],
        agreementsLength: agreements.length,
        firstAgreementStepIndex: beforeAgreements.length,
      });
    });
  }

  componentWillReceiveProps() {
    const formFields = Object.assign({}, this.state.form.fields);
    const newFormFields = { ...formFields };

    this.setState(state =>
      update(state, {
        form: {
          fields: { $set: newFormFields },
        },
        imageBlobUrl: { $set: this.state.imageBlobUrl },
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userData &&
      this.props.userData.user &&
      this.props.userData.user.first_name &&
      this.props.userData.user.last_name &&
      this.props.userData.user.job_title
    ) {
      if (this.props.showEmployeeOnboard)
        this.props.history.push('/employee_onboarding?firstTimeLogin=true');
      else this.props.history.push('/');
    }
    if (prevProps.userTags.length !== this.props.userTags.length) {
      this.setupUserTagFields(this.props.userTags);
    }
  }

  setupUserTagFields = userTags => {
    const {
      form,
      form: { fields },
    } = this.state;

    const userTagFields = userTags.map(tag => {
      const id = `tag-${tag.id}`;

      if (tag.type === 1) {
        const selected = tag.options.find(option => option.is_selected) || {};
        return {
          id,
          label: tag.title,
          value: '',
          type: 'dropdown',
          validateRule: 'userTags',
        };
      } else if (tag.type === 2) {
        const [checkbox] = tag.options;
        return {
          id,
          label: checkbox.label,
          type: 'checkbox',
          validateRule: 'required',
        };
      } else return {};
    });

    const userTagObject = userTagFields.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});

    const newState = {
      form: {
        ...form,
        fields: {
          ...fields,
          ...userTagObject,
        },
      },
    };

    console.log('NEW', newState);

    this.setState(newState);
  };

  dispatchInitialActions = () => {
    this.props.getDepartments();
    this.props.getUserTags();
    this.props.fetchFeatureFlags();
  };

  updateFieldValue(fk, value, error) {
    this.setState(state =>
      update(state, {
        form: {
          fields: {
            [fk]: {
              value: { $set: value },
              error: { $set: error },
            },
          },
        },
        showValidateBlock: { $set: false },
      }),
    );
    this.checkFields();
  }

  checkFields() {
    const { fields } = this.state.form;
    // Get errors
    const validationBlockInputs = [];
    Object.values(fields).forEach(item => {
      if (item.hasOwnProperty('validateRule') && item.error) {
        validationBlockInputs.push(item.label);
      }
    });

    // Not valid
    if (validationBlockInputs.length) {
      return validationBlockInputs;
    }
  }

  onSubmit() {
    this.setState(
      state =>
        update(state, {
          form: {
            validate: { $set: true },
          },
        }),
      () => {
        const { fields } = this.state.form;
        const data = {};

        // Get errors
        const validationBlockInputs = [];
        Object.values(fields).forEach(item => {
          if (item.hasOwnProperty('validateRule') && item.error) {
            validationBlockInputs.push(item.label);
          }
        });

        // Not valid
        if (validationBlockInputs.length) {
          return this.setState({ showValidateBlock: true, validationBlockInputs });
        }

        Object.keys(fields).map(key => (data[key] = fields[key].value));
        const tags = extractTags(fields, this.props.userTags);

        this.props.createUserProfile({ ...data, tags, token: this.props.token.key });

        if (this.state.imageBlobUrl !== '') {
          this.props.postUserAvatar({ image: this.state.imageFile, token: this.props.token.key });
        }
        this.setStep(1);
      },
    );
  }

  _renderField(key) {
    const {
      type,
      placeholder,
      label,
      validateRule,
      value,
      error,
      helperText,
      inputType,
    } = this.state.form.fields[key];

    return (
      <TextField
        key={key}
        fk={key}
        type={type}
        inputType={inputType}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        value={value}
        validateRule={validateRule}
        validateObj={{ [validateRule]: value }}
        error={error}
        showError={this.state.form.validate}
        updateFieldData={this.updateFieldValue}
      />
    );
  }

  _goNext = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const currentStep = +queryParams.get('currentStep') || 1;
    const isProfile = queryParams.get('profile') || null;

    const isAnAgreementScreen =
      currentStep - 1 >= this.state.firstAgreementStepIndex &&
      currentStep - 1 < this.state.firstAgreementStepIndex + this.state.agreementsLength;

    // every agreement step requires to be accepted
    if (isAnAgreementScreen) {
      // check that the current agreement is accepted
      if (this.state.currentAgreementAccepted === false) return;
      ConsentsApi.setConsentsAgreed({
        engage4: true,
        engage4Terms: true,
        organisation: true,
      });
      // then reset for the next step
      this.setState({ currentAgreementAccepted: false });
    }

    if (isProfile) {
      return this.onSubmit();
    } else if (currentStep !== this.state.onBoardingData.length) {
      return this.setStep(currentStep + 1);
    } else {
      this.props.history.push('/');
    }
  };

  setStep = value => {
    const { location, history } = this.props;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('currentStep', value);

    if (value >= this.state.onBoardingData.length) {
      queryParams.set('profile', 'true');
    }

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.push(newLocation);
  };
  _renderSelect = ({ key, options, label }) => {
    const { validateRule, value, error } = this.state.form.fields[key];

    return (
      <SelectField
        placeholder={label}
        options={options}
        key={key}
        fk={key}
        value={value}
        validateRule={validateRule}
        validateObj={{ [validateRule]: value }}
        error={error}
        showError={this.state.form.validate}
        updateFieldData={this.updateFieldValue}
      />
    );
  };

  _renderCheckbox = key => {
    const { value, label } = this.state.form.fields[key];

    return (
      <CheckboxField
        key={key}
        fk={key}
        value={value}
        label={label}
        updateFieldData={this.updateFieldValue}
      />
    );
  };

  _renderUserTags = () => {
    const { userTags } = this.props;
    return userTags.map(({ type, id, options, title }) => {
      const key = `tag-${id}`;
      if (!this.state.form.fields[key]) return null;
      if (type === 1)
        return this._renderSelect({
          key,
          label: title,
          options: options.map(value => ({
            value: value.id,
            displayName: value.label,
          })),
        });
      if (type === 2) return this._renderCheckbox(key);
      else return null;
    });
  };

  render() {
    const { onBoardingData } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    const currentStep = (+queryParams.get('currentStep') % (this.state.onBoardingData.length + 1)) || 1;
    const showingProfileForm = queryParams.get('profile');

    const validationBlocks = this.checkFields();

    let progressBarWidth = (100 / (onBoardingData.length + 1)) * (currentStep + 1);
    if (!onBoardingData.length) progressBarWidth = 0;

    console.warn(`onboarding data length ${onBoardingData.length}`)
    console.warn(`current step ${currentStep}`)

    return (
      <div className='on-boarding w-100 overflow-auto'>
      <div className={'w-100 h-100 bg-white overflow-auto'}>
        <Container fluid style={{ maxWidth: '1440px' }}>
            <div>
              {showingProfileForm ? null : (
              <div className={'progress-bar-container'}>
                <ProgressBar
                  width={progressBarWidth}
                  backLabel={currentStep > 0 ? 'Skip Onboarding' : ''}
                    onGoBackClick={() =>
                      this.props.history.push(
                        `/onboarding?currentStep=${this.state.firstAgreementStepIndex + 1}`,
                      )
                    }
                />
              </div>
              )}
            </div>
          <Row>
            <Col className="no-padding">
            <div>
                  {currentStep - 1 === 0 ? null : (
                    <p className='back-to-form' onClick={() => this.props.history.goBack()}>
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-left']}
                        className='back-to-form-icon'
                      />
                      Back to {`Onboarding Step ${currentStep - 1}`}
                </p>
              )}
            </div>
                <div className='create-profile__left-panel'>
              {showingProfileForm ? this.renderForm() : this.renderScreens(currentStep - 1)}
                    <div className='onboarding-privacy-bottom-container'>
                  {currentStep - 1 >= this.state.firstAgreementStepIndex &&
                    currentStep - 1 <
                      this.state.firstAgreementStepIndex + this.state.agreementsLength && (
                      <div className='w-100 d-flex align-items-center justify-content-center'>
                        <div className='d-flex align-items-center justify-content-start agreements-checkbox-container'>
                      <Checkbox
                        className='onboarding-agreements-checkbox'
                        checked={this.state.currentAgreementAccepted}
                            onChange={() =>
                              this.setState({
                                currentAgreementAccepted: !this.state.currentAgreementAccepted,
                              })
                            }
                      >
                        I have read and accepted
                    </Checkbox>
                    </div>
                  </div>
                    )}

                  <div className='onboarding button-container'>
              <div>
                <Button.Primary
                  onClick={this._goNext}
                        type='large'
                  className='onboarding-next-button'
                  disabled={validationBlocks && validationBlocks.length}
                >
                        {onBoardingData[currentStep - 1].ctaButtonTitle
                          ? onBoardingData[currentStep - 1].ctaButtonTitle
                          : onBoardingData.length && currentStep === onBoardingData.length
                        ? 'FINISH'
                          : 'NEXT'}
                </Button.Primary>
              </div>
            </div>
            </div>
            </div>
            </Col>
            <Col className="no-padding">
              <div className='create-profile__right-panel'>
              {this.state.imageUrl !== 'LOADING' &&

                  (this.state.imageUrl ? (
                    <img className='create-profile__panel-img' src={this.state.imageUrl} alt='' />
                  ) : (
                    <img className='create-profile__panel-img' src={imgTeams} alt='' />
                  ))}
              </div>
            </Col>
          </Row>
        </Container>   
      </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{ marginLeft: 30, marginRight: 60}}>
        <h3 className="title">Create profile</h3>
        <div className="on-boarding">
          <input
            ref={e => (this.input = e)}
            className="d-none"
            type="file"
            accept="image/x-png,image/jpeg,image/gif"
            onChange={e => {
              this.setState({
                imageFile: e.target.files[0],
                imageBlobUrl: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />

          <div className="upload-img-holder" onClick={() => this.input.click()}>
            <Avatar size={97} src={this.state.imageBlobUrl || userPlaceholder} shape="square" />
            <AddIcon className="add-img" />
          </div>

          <p className="picture-text">Profile picture</p>

          <form
            onSubmit={e => {
              e.preventDefault();
              this.onSubmit();
            }}
            className="user-profile-form"
          >
            {this._renderField('first_name')}
            {this._renderField('last_name')}
            {this._renderField('job_title')}
            {this._renderField('phone_number')}
            {this._renderSelect({
              key: 'department_id',
              label: 'Department',
              options: this.props.departments.map(value => ({
                value: value.id,
                displayName: value.department,
              })),
            })}
            {this._renderUserTags()}
            <p className="form-text mt-4">
              If you enter your phone number it will be visible when a colleague visits your profile
            </p>
            {this.state.showValidateBlock ? (
              <>
                <div className="validate-block">
                  <p>Looks like you made some mistakes, check the following fields:</p>
                  <ul>
                    {this.state.validationBlockInputs.map(input => (
                      <li key={input}>{input}</li>
                    ))}
                  </ul>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    );
  }

  renderScreens(targetIndex) {
    const { onBoardingData } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    const currentStep = (+queryParams.get('currentStep') % (this.state.onBoardingData.length + 1)) || 1;

    const validationBlocks = this.checkFields();
    const onboardScreenToRender = this.state.onBoardingData[targetIndex] || {};

    return (
      <div className=' d-flex flex-column ' style={{ overflowY: 'scroll', paddingBottom: '84px' }}>
        <OnBoardingScreen screen={onboardScreenToRender} customAppId = {this.props.customAppId}/>
      </div>
    );
  }
}

export default UserProfile;
