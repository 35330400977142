import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import SearchInput from '../SearchInput';
import styles from './styles';
import {
  getKnowledgebasebaseCategoriesRequest,
  setKnowledgebaseFilters,
  getMoreKnowledgebaseItemsRequest,
} from '../../actionCreators/knowledgebase';
import KnowledgebaseFilters from './KnowledgebaseFilters';
import { logFirebaseEvent } from '../../firebase/analytics';
import KnowledgebaseItem from './KnowledgebaseItem';
import GridContainer from '../global/GridContainer/GridContainer';

class Knowledgebase extends Component {
  constructor (props) {
    super(props);
    this.state = {
      searchText: '',
    };
    this.scrollBlock = React.createRef();
    this.contentBlock = React.createRef();
  }

  componentDidMount () {
    this.props.fetchKnowledgebaseCategories();
    logFirebaseEvent({
      event: 'knowledgebase',
      action: 'load',
    });
  }

  search = searchText => {
    this.setState({ searchText });
    this.props.setKnowledgebaseFilters(this.props.filterGroups, searchText);
  };

  _onScroll = () => {
    const height = this.scrollBlock.current.offsetHeight;
    const scroll = this.scrollBlock.current.scrollTop;
    const contentHeight = this.contentBlock.current.offsetHeight;
    if (height + scroll >= contentHeight - 60) {
      this.props.fetchMoreKnowledgebaseItems(this.props.filterGroups, this.state.searchTxt);
    }
  };

  render () {
    return (
      <div className='knowledgebase' ref={this.scrollBlock} onScroll={this._onScroll}>
        <GridContainer>
          <div className='knowledgebase-header'>
            <p className='knowledgebase-header-title'>Knowledgebase</p>
            <p className='knowledgebase-header-subtitle-text'>
              Explore files and info from around the company
            </p>
          </div>
          <div className='knowledgebase-content' ref={this.contentBlock}>
            <SearchInput submit={this.search} placeholder='Search Knowledgebase' />
            <KnowledgebaseFilters searchTxt={this.state.searchText} />
            <div style={styles.postListContainer}>
              {this.props.knowledgebaseItems.map(item => {
                return <KnowledgebaseItem item={item} />;
              })}
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }
}

export default connect(
  state => ({
    filterGroups: state.knowledgebase.filters,
    knowledgebaseItems: state.knowledgebase.items,
  }),
  dispatch => ({
    fetchKnowledgebaseCategories: () => dispatch(getKnowledgebasebaseCategoriesRequest()),
    fetchMoreKnowledgebaseItems: (filters, search) =>
      dispatch(getMoreKnowledgebaseItemsRequest({ filters, search })),
    setKnowledgebaseFilters: (filters, search) =>
      dispatch(setKnowledgebaseFilters({ filters, search })),
  }),
)(Knowledgebase);
