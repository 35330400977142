import { put, takeLatest, call } from 'redux-saga/effects';

import { searchGroupsSuccess, searchPeopleSuccess } from '../actionCreators/search';
import { SEARCH } from '../actionCreators/search';
import { SearchApi } from '../api';
import errorHandler from '../utils/requestErrorHandler';

function* searchPeople(action) {
  try {
    let data = { users: [] };

    if (action.payload && action.payload.value) {
      data = yield call(SearchApi.getPeople, action.payload);
    }

    yield put(searchPeopleSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* searchGroups(action) {
  try {
    const data = yield call(SearchApi.getGroups, action.payload);

    yield put(searchGroupsSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchSearch() {
  yield takeLatest(SEARCH.SEARCH_PEOPLE_REQUEST, searchPeople);
  yield takeLatest(SEARCH.SEARCH_GROUPS_REQUEST, searchGroups);
}
