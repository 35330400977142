import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class EventsApi {
  static getAllEvents() {
    const request = new Request(`${apiUrl}/content/event/upcoming`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getMyEvents() {
    const request = new Request(`${apiUrl}/content/event/myEvents`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getSingleEvent({ id }) {
    const request = new Request(`${apiUrl}/content/event/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static eventGoing({ id }) {
    const request = new Request(`${apiUrl}/content/event/${id}/going`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static eventNotGoing({ id }) {
    const request = new Request(`${apiUrl}/content/event/${id}/not-going`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static async getImage(imageUrl) {
    const request = new Request(imageUrl, {
      method: 'GET',
      headers: getHeaders(),
    });

    const stream = await fetch(request);
    const blob = await stream.blob();

    return URL.createObjectURL(blob);
  }

  static getParticipants(eventId, limit = 15, page = 1) {
    const request = new Request(
      `${apiUrl}/content/event/${eventId}/participants?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }
}

export default EventsApi;
