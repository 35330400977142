const styles = {
  activeFiltersSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    flexWrap: 'wrap',
  },
  activeFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid',
    borderColor: 'var(--primary-color)',
    borderRadius: 10,
    padding: 10,
    margin: 5,
  },
  activeFiltersSectionLabel: {
    fontWeight: 'bold',
    color: 'rgb(83, 92, 99)',
  },
  filterCount: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    marginLeft: 5,
    fontSize: '10px',
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: 'var(--primary-color)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1px',
  },
  activeFilter: {
    marginRight: 6,
    display: 'inline-block',
  },
  filtersSectionContainer: {
    marginTop: 30,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  filtersButtonContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  filtersButtonLabel: {
    color: 'var(--primary-color)',
    fontWeight: 'bold',
    diplay: 'flex',
    flexDirection: 'row',
  },
  filterGroupsContainer: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 14,
  },
  filterGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  filterGroupLabel: {
    marginBottom: 10,
    fontWeight: 600,
    color: 'rgba(83, 92, 99)',
  },
  singleFilterContainer: {
    borderWidth: 10,
    border: '1px solid',
    borderColor: 'rgba(83, 92, 99, 0.2)',
    marginBottom: 5,
    borderRadius: 10,
    width: 320,
    display: 'flex',
    height: 35,
    alignItems: 'center',
    paddingLeft: 15,
    color: 'var(--primary-color)',
    fontWeight: 600,
  },
  postListContainer: {
    paddingBottom: 50,
  },
  fileContainer: {
    borderRadius: 6,
    backgroundColor: 'rgb(236, 236, 236)',
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  fileIcon: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  fileName: {
    fontWeight: 'bold',
    color: 'var(--primary-color)',
  },
  fileTypeSize: {
    color: 'rgb(83, 92, 99)',
  },
  knowledgebaseItemSubheader: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  knowledgebaseShareButton: {
    height: 34,
    width: 100,
    backgroundColor: 'var(--primary-color)',
    borderRadius: 17,
    padding: '0 15px',
    color: '#ffffff',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginLeft: 15,
  },
};

export default styles;
