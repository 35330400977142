import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/global/Button';
import './styles.css';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import Right from '../../../assets/images/right.svg';

import DomPurify from 'dompurify';

import ConsentsApi from '../../../api/consents.js';

class ConsentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currentScreen: 'intro',
      screens: [],
      screenIndex: -1,
      checked: false,
      // organisationPrivacyPolicyAccepted: false,
      // privacyPolicyAccepted: false,
      // termsAndConditionsAccepted: false,
    };
  }

  confirm = async () => {
    if (this.state.screenIndex >= this.state.screens.length - 1) {
      const response = await ConsentsApi.setConsentsAgreed({
        engage4: true,
        engage4Terms: true,
        organisation: true,
      });

      if (response.success === true) {
        this.setState({ isOpen: false });
        setTimeout(() => {
          this.setState({ screenIndex: -1 });
        }, 1000);
      }
    } else {
      // progress to next screen
      this.setState({
        screenIndex: this.state.screenIndex + 1,
        checked: false,
      });
    }
  };

  checkPrivacyPolicy = async () => {
    const consentsStatus = await ConsentsApi.getConsentsStatus();

    if (
      consentsStatus.data.engage4 !== false &&
      consentsStatus.data.engage4Terms !== false &&
      consentsStatus.data.organisation !== false
    ) {
      return false;
    }

    // get the consents values
    const consentsContent = await ConsentsApi.getConsents();

    const screens = [];

    if (consentsStatus.data.engage4 === false && consentsContent.data.engage4) {
      screens.push({
        title: 'Engage4 Privacy Policy',
        content: DomPurify.sanitize(consentsContent.data.engage4.text),
      });
    }

    if (consentsStatus.data.engage4Terms === false && consentsContent.data.engage4Terms) {
      screens.push({
        title: 'engage4 Terms',
        content: DomPurify.sanitize(consentsContent.data.engage4Terms.text),
      });
    }

    if (consentsStatus.data.organisation === false && consentsContent.data.organisation) {
      screens.push({
        title: 'Organisation Terms',
        content: DomPurify.sanitize(consentsContent.data.organisation.text),
      });
    }

    if (screens.length > 0) {
      this.setState({
        isOpen: true,
        screens,
      });
    }
  };

  componentDidMount() {
    // document.addEventListener('showConsentModal', () => this.checkPrivacyPolicy());
    this.checkPrivacyPolicy();
  }

  confirmPrivacyPolicy = () => {
    if (this.state.privacyPolicyAccepted) {
      if (this.state.organisationPrivacyPolicy) {
        this.setState({
          currentScreen: 'organisationPrivacyPolicy',
        });
      } else {
        this.setState({
          currentScreen: 'termsAndConditions',
        });
      }
    }
  };

  confirmOrganisationPrivacyPolicy = () => {
    if (this.state.organisationPrivacyPolicyAccepted) {
      this.setState({
        currentScreen: 'termsAndConditions',
      });
    }
  };

  viewPrivacyPolicy = () => {
    this.setState({
      currentScreen: 'privacyPolicy',
    });
  };

  render() {
    let currentScreen = {
      title: 'title',
      content: '<b>!</b>',
    };

    if (this.state.screenIndex > -1 && this.state.screenIndex < this.state.screens.length) {
      currentScreen = this.state.screens[this.state.screenIndex];
    }

    return (
      <Modal size="lg" style={{ maxWidth: '800px' }} isOpen={this.state.isOpen}>
        {this.state.screenIndex === -1 ? (
          <>
            <ModalBody>
              <Text>
                <h4>
                  <b>What we do with your data</b>
                </h4>
              </Text>
              <IntroContainer>
                <Text>
                  Here's the key points about the information you input to <b>Engage4:</b>
                </Text>

                <VerticalSpacer />
                <IconTextContainer>
                  <img src={Right} style={{ marginRight: 10 }} />
                  <Text>
                    Notices you post and your comments, likes, and High-5s are <b>only visible</b>{' '}
                    to colleagues who are also using the app
                  </Text>
                </IconTextContainer>
                <VerticalSpacer />
                <IconTextContainer>
                  <img src={Right} style={{ marginRight: 10 }} />
                  <Text>
                    Your individual poll, check-in pulse and micro-learning responses{' '}
                    <b>are not visible</b> to anyone but you
                  </Text>
                </IconTextContainer>
                <VerticalSpacer />
                <IconTextContainer>
                  <img src={Right} style={{ marginRight: 10 }} />
                  <Text>
                    Check-in pulse and general usage statistics are available to your organisation
                    only in <b>aggregated and anonymised</b> form
                  </Text>
                </IconTextContainer>
              </IntroContainer>
            </ModalBody>
            <ModalFooter>
              <FooterContainer>
                <Button.Primary onClick={this.confirm} color={'primary'}>
                  VIEW PRIVACY POLICY
                </Button.Primary>
              </FooterContainer>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              <Text>
                <h4><b>{currentScreen.title}</b></h4>
              </Text>
              <VerticalSpacer />
              <ConsentContainer>
                <Text dangerouslySetInnerHTML={{ __html: currentScreen.content }} >{ }
                </Text>
              </ConsentContainer>
            </ModalBody>
            <BottomCheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  checked={this.state.checked}
                  onChange={() => this.setState({ checked: !this.state.checked })}
                />
              </CheckboxContainer>
              <AcceptText>
                <div>I have read and accept the <strong>{currentScreen.title}</strong></div>
              </AcceptText>
            </BottomCheckboxContainer>
            <ModalFooter>
              <FooterContainer>
                <Button.Primary
                  onClick={this.confirm}
                  disabled={!this.state.checked}
                  color={'primary'}
                >
                  CONFIRM
                </Button.Primary>
              </FooterContainer>
            </ModalFooter>
          </>
        )}
      </Modal>
    );
  }
}

export default ConsentModal;

const IconTextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  font-size: 14px;
  flex: 1;
`;

const VerticalSpacer = styled.div`
  width: 100%;
  height: 10px;
`;

const IntroContainer = styled.div`
  font-size: 14px;
  flex-direction: row;
`;

const ConsentContainer = styled.div`
  border: solid rgb(158, 163, 168) 1px;
  border-radius: 10px;
  padding: 8px;
  height: 300px;
  overflow-y: scroll;
`;

const BottomCheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 15px 15px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  width: 32px;
  height: 24px;
`;

const AcceptText = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
