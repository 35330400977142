import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import { dateDifferenceShorted } from '../../../../utils/helpers';
import ImageApi from '../../../../api/image';

class FeedItemFooterCommentRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.author && this.props.author.image.thumbnailUrl) {
      this._getImage(this.props.author.image.thumbnailUrl);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.author &&
      (!this.props.author ||
        this.props.author.image.thumbnailUrl !== nextProps.author.image.thumbnailUrl)
    ) {
      this._getImage(nextProps.author.image.thumbnailUrl);
    }
  }

  _getImage(targetImageUrl) {
    ImageApi.getImage(targetImageUrl).then(imageUrl => {
      this.setState({ avatarUrl: imageUrl });
    });
  }

  _onLikeCommentClick = (commentId, newLikeState) => {
    this.props.onCommentLike(commentId, newLikeState);
  };

  parseComment = comment => {
    let reg = /@\[([^\]]+?)]\(id:([^\]]+?)\)/;
    const parts = [];

    let left = comment || '';
    while (left !== '') {
      let matched = false;
      const match = reg.exec(left);
      if (match) {
        matched = true;
        parts.push(left.substr(0, match.index));
        parts.push(
          <Link key={match[2]} to={`/users/${match[2]}`}>
              {match[1]}
          </Link>
        );        
        left = left.substr(match.index + match[0].length);
      } else {
        parts.push(left.substr(0));
        left = '';
      }
    }

    return parts;
  };

  render() {
    const { comment, author = {} } = this.props;
    const commentLikes = comment.likes.likes_total;
    const isLikeByMe = comment.likes.is_liked_by_me;
    const commentToRender = this.parseComment(comment.comment);
    
    return (
      <div className="feed-item-footer-comment-holder">
        <div className="d-flex">
          <Link to={`/users/${author.id}`}>
            <Avatar
              className="feed-item-footer-comments-avatar"
              shape="square"
              src={this.state.avatarUrl}
              icon="user"
              size={42}
            />
          </Link>

          <div className="d-flex w-100 feed-item-footer-comment">
            <div className="d-flex flex-column flex-grow-1">
              <Link to={`/users/${author.id}`} className="feed-item-footer-comment-owner">
                {author.first_name} {author.last_name}
              </Link>
              <span className='feed-item-footer-comment-text'>
                <Linkify
                  componentDecorator={(href, text, key) => (
                    <a href={href} key={key} target={'_blank'}>
                      {text}
                    </a>
                  )}
                >
                  {commentToRender}
                </Linkify>
              </span>
              {/* <p className='feed-item-footer-comment-text'>{commentToRender}</p> */}
            </div>

            <div className="feed-item-footer-comment-date">
              {dateDifferenceShorted(comment.created_at)}
            </div>
          </div>
        </div>

        <div className="feed-item-footer-comment-like-holder">
          <span
            className={`feed-item-footer-comment-like-button ${isLikeByMe ? 'liked' : ''}`}
            onClick={this._onLikeCommentClick.bind(this, comment.id, !isLikeByMe)}
          >
            {isLikeByMe ? 'Liked' : 'Like'}
          </span>

          {commentLikes > 0 && (
            <>
              <div className="feed-item-footer-splitter" />
              {commentLikes} Like{commentLikes > 1 ? 's' : ''}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state, props) => ({ author: state.feed.authors[props.comment.user_id] }))(
  FeedItemFooterCommentRow
);
