import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { getTasksRequest, updateTaskRequest } from '../../actionCreators/employeeOnboarding';
import OnboardingComponent from '../../components/EmployeeOnboarding';
import { logFirebaseEvent } from '../../firebase/analytics';

class EmployeeOnboarding extends React.Component {
  queryParams = new URLSearchParams(this.props.location.search);
  firstTimeLogin = this.queryParams.get('firstTimeLogin');
  componentDidMount () {
    this.props.getOnboardingTasks();
  }
  render () {
    return <OnboardingComponent {...this.props} firstTimeLogin={this.firstTimeLogin} />;
  }
}

const mapStateToProps = state => {
  return {
    tasks: state.employeeOnboard.tasks,
  };
};

const mapDispatchToProps = dispatch => ({
  getOnboardingTasks: () => dispatch(getTasksRequest()),
  updateOnboardingTask: taskId => dispatch(
    updateTaskRequest({ taskId }), 
    logFirebaseEvent({
      event: 'employee_onboarding',
      action: 'task_completed'
    })
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOnboarding);
