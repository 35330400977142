import * as React from "react"

function GroupTagSecret(props) {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Group 15"}</title>
      <path
        d="M8.533 3.2a6.253 6.253 0 015.645 3.52 6.174 6.174 0 01-1.542 1.997l.902.902a7.553 7.553 0 002.035-2.899 7.57 7.57 0 00-9.37-4.435L7.26 3.34A6.47 6.47 0 018.533 3.2zm-.684.73l1.324 1.324c.365.16.66.455.82.82l1.324 1.324c.052-.217.09-.448.09-.684A2.87 2.87 0 008.533 3.84c-.236 0-.46.032-.684.09zm-5.71-2.093l1.716 1.715A7.512 7.512 0 001.493 6.72a7.57 7.57 0 007.04 4.8c.973 0 1.908-.186 2.765-.525l2.189 2.189.902-.902L3.042.928l-.902.909zm4.8 4.8l1.671 1.67a.321.321 0 01-.077.013 1.6 1.6 0 01-1.6-1.6c0-.032.007-.051.007-.083zM4.765 4.46l1.12 1.12A2.884 2.884 0 009.666 9.37l.627.627a6.677 6.677 0 01-1.76.243A6.253 6.253 0 012.89 6.72a6.337 6.337 0 011.875-2.26z"
        fill="#505A62"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default GroupTagSecret
