import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HighFive from '../../assets/images/highFive.svg';
import UserPlaceholder from '../../assets/images/user_placeholder.svg';
import './styles.scss';

import ImageApi from '../../api/image';
import { getCollegueProfileRequest } from '../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../firebase/analytics';
import Portal from '../Portal/Portal';
import SendHighFive from './SendHighFive';


import GridContainer from '../global/GridContainer/GridContainer';

const BACK_TEXT = { '/': 'Back to Feed', '/search': 'Back to Search' };

const CollegueProfile = props => {
  const { collegueProfile, handleGetCollegue, match, history, prevRoute } = props;
  const {
    first_name,
    last_name,
    job_title,
    username,
    phone_number,
    highfives_count,
    image,
  } = collegueProfile;

  const [imageUrl, setImageUrl] = useState('');
  const [showSendHighFive, setShowSendHighFive] = useState(false);
  const highFiveBtn = ['high-five-btn'];

  // Component Did Mount
  useEffect(() => {
    const userId = match.params.userId;
    handleGetCollegue({ id: userId });
    logFirebaseEvent({
      event: 'colleague_profile',
      action: 'load',
    });
  }, [handleGetCollegue, match.params.userId]);

  // Get user avatar
  useEffect(() => {
    if (!image || !image.url) return;

    ImageApi.getImage(image.url).then(avatar => setImageUrl(avatar));
  }, [image]);

  const hideSendHighFive = () => {
    setShowSendHighFive(false);
  };

  // Show loading
  if (!first_name || !username) {
    return (
      <div className='text-center mt-5'>
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!highfives_count > 0) highFiveBtn.push('mt-3');

  return (
    <div className='collegue-profile-container'>
      <GridContainer>
        <div>
          <div className='btn-container'>
            <button onClick={() => history.goBack()} className='back-btn p-0'>
              <FontAwesomeIcon className='mr-2' icon={['fas', 'chevron-left']} />
              {BACK_TEXT[prevRoute || '/'] || 'Go back'}
            </button>
          </div>
          <div className='collegue-profile-container--upper'>
            <Avatar
              className='collegue-avatar'
              size={147}
              shape='square'
              src={imageUrl || UserPlaceholder}
            />

            <h2 className='title'>{`${first_name} ${last_name}`}</h2>
            <p className='subtitle'>{job_title}</p>
            <p className={!phone_number ? 'email pb-4' : 'email'}>
              Email:
              <a href={`mailto:${username}`} className='email--inner'>
                {username}
              </a>
            </p>

            {phone_number && phone_number.length > 0 && (
              <p className='phone'>
                Phone No:
                <span className='email--inner'>{phone_number}</span>
              </p>
            )}
          </div>

          <div className='collegue-profile-container--bottom'>
            <img src={HighFive} className='empty-high-fives' alt='' />

            {highfives_count > 0 && (
              <p className='mt-2'>
                {first_name} has received
                <span className='high-five'> {highfives_count} high fives</span>
                in the last 3 <br /> months.
              </p>
            )}

            <button className={highFiveBtn.join(' ')} onClick={() => setShowSendHighFive(true)}>
              Send High Five
            </button>
          </div>
        </div>
        <Portal
          className='collegue-profile-modal'
          hide={hideSendHighFive}
          isOpen={showSendHighFive}
        >
          <SendHighFive selectedColleague={collegueProfile} close={hideSendHighFive} />
        </Portal>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  collegueProfile: state.currentUser.collegueProfile,
  prevRoute: state.routerHistory.prevPathname,
});

const mapDispatchToProps = dispatch => ({
  handleGetCollegue: payload => dispatch(getCollegueProfileRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollegueProfile);
