import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TextBlock({ children, className }) {
  const classNames = ['text-row-block'];
  if (className) classNames.push(className);
  return <div className={classNames.join(' ')}>{children}</div>;
}

TextBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TextBlock;
