import update from 'immutability-helper';
import { GET_CUSTOM_APP_SUCCESS } from '../constants/actionTypes';
import { getCustomAppRequest } from '../actionCreators/customApp';

const initialState = {
  id: 'engage4',
  organisation_id: ''
};

const CustomApp = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOM_APP_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};

export default CustomApp;
