import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { REMOVE_GROUP_MEMBER_REQUEST, TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST } from '../constants/actionTypes';
import GroupApi from '../api/group';
import errorHandler from '../utils/requestErrorHandler';
import {
  removeGroupMemberSuccess,
  removeGroupMemberFail,
  toggleGroupMemberPermissonSuccess,
  toggleGroupMemberPermissonFail
} from '../actionCreators/manageGroupMembers';

function* removeGroupMember(action) {
    try {
      const { 
        payload: { userId, groupId }
      } = action
        
        if (userId && groupId) {
            const result = yield call(GroupApi.removeGroupMember, userId, groupId)
      
            if (result && result.success) {
                yield put(
                    removeGroupMemberSuccess({
                        userId: userId,
                        groupId: groupId
                    })
                )
            } else { 
                yield put(removeGroupMemberFail('User was not removed from group'))
            }
           
        } else { 
            yield put(removeGroupMemberFail('Cant handle type'))
        }  
    } catch (error) {
        yield errorHandler(error)
        yield put(removeGroupMemberFail(error))
    }
}

function* toggleGroupMemberPermission(action) {
    try {
      const { 
        payload: { userId, groupId }
      } = action
        
        if (userId && groupId) {
            const result = yield call(GroupApi.toggleGroupMemberPermission, userId, groupId)
      
            if (result && result.success) {
                yield put(
                    toggleGroupMemberPermissonSuccess({
                        userId: userId,
                        groupId: groupId
                    })
                )
            } else { 
                yield put(toggleGroupMemberPermissonFail('User was not removed from group'))
            }
           
        } else { 
            yield put(toggleGroupMemberPermissonFail('Cant handle type'))
        }  
    } catch (error) {
        yield errorHandler(error)
        yield put(toggleGroupMemberPermissonFail(error))
    }
}

export default function* watchManageGroups() {
    yield all([
        takeLatest(REMOVE_GROUP_MEMBER_REQUEST, removeGroupMember),
        takeLatest(TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST, toggleGroupMemberPermission),
    ])
}