import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_REQUEST_SUCCESS,
  GET_MY_EVENTS_REQUEST,
  GET_MY_EVENTS_REQUEST_SUCCESS,
  GET_SINGLE_EVENT_REQUEST,
  GET_SINGLE_EVENT_REQUEST_SUCCESS,
  EVENT_GOING_REQUEST,
  EVENT_NOT_GOING_REQUEST,
} from '../constants/actionTypes';

const getAllEvents = () => ({
  type: GET_EVENTS_REQUEST,
});

const getMyEvents = () => ({
  type: GET_MY_EVENTS_REQUEST,
});

const getSingleEvent = id => ({
  type: GET_SINGLE_EVENT_REQUEST,
  id,
});

const eventGoing = id => ({
  type: EVENT_GOING_REQUEST,
  id,
});

const eventNotGoing = id => ({
  type: EVENT_NOT_GOING_REQUEST,
  id,
});

const getAllEventsSuccess = payload => ({
  type: GET_EVENTS_REQUEST_SUCCESS,
  payload,
});

const getMyEventsSuccess = payload => ({
  type: GET_MY_EVENTS_REQUEST_SUCCESS,
  payload,
});

const getSingleEventSuccess = payload => ({
  type: GET_SINGLE_EVENT_REQUEST_SUCCESS,
  payload,
});

export {
  getAllEvents,
  getMyEvents,
  getAllEventsSuccess,
  getMyEventsSuccess,
  getSingleEvent,
  getSingleEventSuccess,
  eventGoing,
  eventNotGoing,
};
