import React from 'react';

export default function HighFiveIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="32x"
      height="32px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="high_five"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path
          d="M23.374465,18.0071855 L23.374465,8.01449823 C23.374465,6.46155535 24.5347312,5.20311999 25.9661313,5.20311999 C27.3984664,5.20311999 28.5587325,6.46155535 28.5587325,8.01449823 L28.5587325,19.1543624 L28.5587325,12.4872729 C28.5587325,10.93433 29.7189987,9.67495973 31.1503988,9.67495973 C32.5817989,9.67495973 33.743,10.93433 33.743,12.4872729 L33.743,25.0239442 C33.0904087,30.7925521 29.5684726,37.705532 21.3493752,37.705532 C17.8928852,37.6139074 13.9128263,35.6187358 11.4342883,31.5751006 L5.18698891,20.8007998 C5.06638106,20.5184465 5,20.206175 5,19.8789444 C5,18.5690871 6.062097,17.5069901 7.3719543,17.5069901 C8.19003077,17.5069901 8.91180796,17.9211706 9.33814267,18.5522581 L13.0274338,22.8221499 L13.0274338,9.1831789 C13.0274338,7.62836613 14.1877,6.36993078 15.620035,6.36993078 C17.0514351,6.36993078 18.2117013,7.62836613 18.2117013,9.1831789 L18.1901976,18.0071855 L18.1901976,4.81231318 C18.1901976,3.2593703 19.3504637,2 20.7827988,2 C22.2141989,2 23.374465,3.2593703 23.374465,4.81231318 L23.374465,18.0071855 Z"
          id="Stroke-11"
          stroke={stroke}
          strokeWidth="2"
        ></path>
      </g>
    </svg>
  );
}
