export const RESET_STORE_TO_INIT_STATE = 'RESET_STORE_TO_INIT_STATE';

export const GLOBAL_PRELOADER_ADD_TO_LIST = 'GLOBAL_PRELOADER_ADD_TO_LIST';
export const GLOBAL_PRELOADER_REMOVE_FROM_LIST = 'GLOBAL_PRELOADER_REMOVE_FROM_LIST';

export const GLOBAL_ALERT_ADD_TO_LIST = 'GLOBAL_ALERT_ADD_TO_LIST';
export const GLOBAL_ALERT_REMOVE_FROM_LIST = 'GLOBAL_ALERT_REMOVE_FROM_LIST';

export const FORM_ERROR_ADD_TO_LIST = 'FORM_ERROR_ADD_TO_LIST';
export const FORM_ERROR_REMOVE_FROM_LIST = 'FORM_ERROR_REMOVE_FROM_LIST';

// AUTH
export const USER_LOCKED = 'USER_LOCKED';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_REQUEST_WITH_PASSWORD = 'SIGN_IN_REQUEST_WITH_PASSWORD';
export const SIGN_IN_REQUEST_WITH_MAGIC_LINK = 'SIGN_IN_REQUEST_WITH_MAGIC_LINK';
export const SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK = 'SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK';
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED';
export const SIGN_IN_SUCCEEDED_WITH_PASSWORD = 'SIGN_IN_SUCCEEDED_WITH_PASSWORD';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SET_PULSE_REPLIED = 'SET_PULSE_REPLIED';
export const LOGOUT = 'LOGOUT';
export const LOG_APP_SESSION_REQUEST = 'LOG_APP_SESSION_REQUEST';
export const LOG_APP_SESSION_SUCCESS = 'LOG_APP_SESSION_SUCCESS';

//USER PROFILE

export const USER_PROFILE_CREATE_REQUEST = 'USER_PROFILE_CREATE_REQUEST';
export const USER_PROFILE_CREATE_SUCCEEDED = 'USER_PROFILE_CREATE_SUCCEEDED';
export const USER_AVATAR_CREATE_REQUEST = 'USER_AVATAR_CREATE_REQUEST';
export const SET_COLLEGUE_PROFILE = 'SET_COLLEGUE_PROFILE';
export const GET_COLLEGUE_PROFILE_REQUEST = 'GET_COLLEGUE_PROFILE_REQUEST';
export const GET_COLLEGUE_PROFILE_SUCCESS = 'GET_COLLEGUE_PROFILE_SUCCESS';
export const GET_COLLEGUE_AVATAR_REQUEST = 'GET_COLLEGUE_AVATAR_REQUEST';
export const GET_COLLEGUE_AVATAR_SUCCESS = 'GET_COLLEGUE_AVATAR_SUCCESS';

export const GET_USER_AVATAR_REQUEST = 'GET_USER_AVATAR_REQUEST';
export const GET_USER_AVATAR_SUCCESS = 'GET_USER_AVATAR_SUCCESS';
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_REQUEST_SUCCESS = 'GET_USER_REQUEST_SUCCESS';

export const SEND_USER_PULSE_REQUEST = 'SEND_USER_PULSE_REQUEST';

// FEED
export const SET_FEED_LOADING = 'SET_FEED_LOADING';
export const SET_FEED_DETAILS_LOADING = 'SET_FEED_DETAILS_LOADING';

export const RESET_FEED_PAGE = 'RESET_FEED_PAGE';
export const RESET_SINGlE_FEED_PAGE = 'RESET_SINGlE_FEED_PAGE';
export const RESET_FEED_AFTER_REFRESH = 'RESET_FEED_AFTER_REFRESH';
export const RESET_FEED_ITEMS = 'RESET_FEED_ITEMS';

export const GET_FEED_REQUEST = 'GET_FEED_REQUEST';
export const GET_FEED_REQUEST_SUCCESS = 'GET_FEED_REQUEST_SUCCESS';
export const GET_FEED_REQUEST_FAILURE = 'GET_FEED_REQUEST_FAILURE';

export const GET_SINGLE_FEED_REQUEST = 'GET_SINGLE_FEED_REQUEST';
export const GET_SINGLE_FEED_REQUEST_SUCCESS = 'GET_SINGLE_FEED_REQUEST_SUCCESS';
export const GET_SINGLE_FEED_REQUEST_FAILURE = 'GET_SINGLE_FEED_REQUEST_FAILURE';

export const GET_FEED_ITEM_DETAILS_REQUEST = 'GET_FEED_ITEM_DETAILS_REQUEST';
export const GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS = 'GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS';
export const GET_FEED_ITEM_DETAILS_REQUEST_FAILURE = 'GET_FEED_ITEM_DETAILS_REQUEST_FAILURE';

export const GET_HIGHFIVE_OPTIONS_REQUEST = 'GET_HIGHFIVE_OPTIONS_REQUEST';
export const GET_HIGHFIVE_OPTIONS_REQUEST_SUCCESS = 'GET_HIGHFIVE_OPTIONS_REQUEST_SUCCESS';
export const GET_HIGHFIVE_OPTIONS_REQUEST_FAILURE = 'GET_HIGHFIVE_OPTIONS_REQUEST_FAILURE';

export const TOGGLE_COMMENT_LIKE_REQUEST = 'TOGGLE_COMMENT_LIKE_REQUEST';
export const SET_PIN_RETRY = 'SET_PIN_RETRY';

// ACTIVITIES
export const GET_USER_ACTIVITY_POINTS_REQUEST = 'GET_USER_ACTIVITY_POINTS_REQUEST';
export const GET_USER_ACTIVITY_POINTS_SUCCESS = 'GET_USER_ACTIVITY_POINTS_SUCCESS';
export const GET_USER_BADGES_REQUEST = 'GET_USER_BADGES_REQUEST';
export const GET_USER_BADGES_REQUEST_SUCCESS = 'GET_USER_BADGES_REQUEST_SUCCESS';

export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST';
export const POST_VOTE_REQUEST = 'POST_VOTE_REQUEST';
export const TOGGLE_LIKE_REQUEST = 'TOGGLE_LIKE_REQUEST';

export const CREATE_NOTICE_REQUEST = 'CREATE_NOTICE_REQUEST';
export const CREATE_POLL_REQUEST = 'CREATE_POLL_REQUEST';
export const CREATE_HIGHFIVE_REQUEST = 'CREATE_HIGHFIVE_REQUEST';
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const REPORT_POST_REQUEST = 'REPORT_POST_REQUEST';
export const PIN_POST_REQUEST = 'PIN_POST_REQUEST';
export const REPORT_POST_REQUEST_SUCCESS = 'REPORT_POST_REQUEST_SUCCESS';
export const RESET_REPORT_POST_REQUEST_SUCCESS = 'RESET_REPORT_POST_REQUEST_SUCCESS';
export const POPULATE_POST_REPORT_TYPES_REQUEST = 'POPULATE_POST_REPORT_REQUEST';
export const POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS = 'POPULATE_POST_REPORT_REQUEST_SUCCESS';

export const GET_MICRO_LEARNING_REQUEST = 'GET_MICRO_LEARNING_REQUEST';
export const GET_MICRO_LEARNING_REQUEST_SUCCESS = 'GET_MICRO_LEARNING_REQUEST_SUCCESS';
export const GET_MICRO_LEARNING_QUESTIONS_REQUEST = 'GET_MICRO_LEARNING_QUESTIONS_REQUEST';
export const GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS =
  'GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS';
export const GET_MICRO_LEARNING_ANSWERS_REQUEST = 'GET_MICRO_LEARNING_ANSWERS_REQUEST';
export const GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS =
  'GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS';

// GROUP
export const SET_GROUP_LOADING = 'SET_GROUP_LOADING';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_REQUEST_SUCCESS = 'GET_GROUPS_REQUEST_SUCCESS';
export const GET_GROUPS_REQUEST_FAILURE = 'GET_GROUPS_REQUEST_FAILURE';

export const GET_MY_GROUPS_REQUEST = 'GET_MY_GROUPS_REQUEST';
export const GET_MY_GROUPS_REQUEST_SUCCESS = 'GET_MY_GROUPS_REQUEST_SUCCESS';
export const GET_MY_GROUPS_REQUEST_FAILURE = 'GET_MY_GROUPS_REQUEST_FAILURE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_REQUEST_SUCCESS = 'GET_GROUP_REQUEST_SUCCESS';
export const TOGGLE_JOIN_GROUP_REQUEST = 'TOGGLE_JOIN_GROUP_REQUEST';

export const GET_GROUP_PRIVACY_TYPES_REQUEST = 'GET_GROUP_PRIVACY_TYPES_REQUEST';
export const GET_GROUP_PRIVACY_TYPES_SUCCESS = 'GET_GROUP_PRIVACY_TYPES_SUCCESS';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const POST_NEW_MEMBER_REQUEST = 'POST_NEW_MEMBER_REQUEST';
export const POST_NEW_MEMBER_SUCCESS = 'POST_NEW_MEMBER_SUCCESS';

export const ADD_GROUP_MEMBER_REQUEST = 'ADD_NEW_MEMBER_REQUEST';
export const ADD_GROUP_MEMBER_SUCCESS = 'ADD_NEW_MEMBER_SUCCESS';

// EVENTS
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_REQUEST_SUCCESS = 'GET_EVENTS_REQUEST_SUCCESS';
export const GET_MY_EVENTS_REQUEST = 'GET_MY_EVENTS_REQUEST';
export const GET_MY_EVENTS_REQUEST_SUCCESS = 'GET_MY_EVENTS_REQUEST_SUCCESS';
export const GET_SINGLE_EVENT_REQUEST = 'GET_SINGLE_EVENT_REQUEST';
export const GET_SINGLE_EVENT_REQUEST_SUCCESS = 'GET_SINGLE_EVENT_REQUEST_SUCCESS';
export const EVENT_GOING_REQUEST = 'EVENT_GOING_REQUEST';
export const EVENT_NOT_GOING_REQUEST = 'EVENT_NOT_GOING_REQUEST';

export const SET_GROUPS_FROM_FEED_REQUEST = 'SET_GROUPS_FROM_FEED_REQUEST';

// NOTIFICATIONS
export const SET_NOTIFICATION_LOADING = 'SET_NOTIFICATION_LOADING';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_NOTIFICATIONS_REQUEST_SUCCESS';

export const SET_NOTIFICATIONS_READ_REQUEST = 'SET_NOTIFICATIONS_READ_REQUEST';
export const SET_NOTIFICATIONS_ITEMS = 'SET_NOTIFICATIONS_ITEMS';

// USERS FOR ATTENDANTS AND LIKES ETC...

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_MORE_USERS_REQUEST = 'GET_MORE_USERS_REQUEST';
export const GET_MORE_USERS_SUCCESS = 'GET_MORE_USERS_SUCCESS';
export const GET_MORE_USERS_FAIL = 'GET_MORE_USERS_FAIL';

// FOR DEPARTMENTS

export const GET_DEPARTMENTS_START = 'GET_DEPARTMENTS_START';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';

// USER TAGS

export const GET_USER_TAGS_START = 'GET_USER_TAGS_START';
export const GET_USER_TAGS_SUCCESS = 'GET_USER_TAGS_SUCCESS';
export const GET_USER_TAGS_FAIL = 'GET_USER_TAGS_FAIL';

// VERIFY GROUP

export const VERIFY_GROUP_REQUEST = 'VERIFY_GROUP_REQUEST';
export const VERIFY_GROUP_SUCCESS = 'VERIFY_GROUP_SUCCESS';
export const SHOW_CREATE_GROUP_MODAL = 'SHOW_CREATE_GROUP_MODAL';

//KNOWLEDGAEBASE

export const SET_KNOWLEDGEBASE_LOADING = 'SET_KNOWLEDGEBASE_LOADING';

export const GET_KNOWLEDGEBASE_CATEGORIES_REQUEST = 'GET_KNOWLEDGEBASE_CATEGORIES_REQUEST';
export const GET_KNOWLEDGEBASE_CATEGORIES_SUCCESS = 'GET_KNOWLEDGEBASE_CATEGORIES_SUCCESS';
export const GET_KNOWLEDGEBASE_CATEGORIES_FAIL = 'GET_KNOWLEDGEBASE_CATEGORIES_REQUEST';

export const GET_KNOWLEDGEBASE_ITEMS_REQUEST = 'GET_KNOWLEDGEBASE_ITEMS_REQUEST';
export const GET_KNOWLEDGEBASE_ITEMS_SUCCESS = 'GET_KNOWLEDGEBASE_ITEMS_SUCCESS';
export const GET_KNOWLEDGEBASE_ITEMS_FAIL = 'GET_KNOWLEDGEBASE_ITEMS_FAIL';

export const GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST = 'GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST';
export const GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS = 'GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS';
export const GET_MORE_KNOWLEDGEBASE_ITEMS_FAIL = 'GET_MORE_KNOWLEDGEBASE_ITEMS_FAIL';

export const SET_KNOWLEDGEBASE_FILTERS = 'SET_KNOWLEDGEBASE_FILTERS';

//account

export const GET_SECURITY_QUESTIONS_REQUEST = 'GET_SECURITY_QUESTIONS_REQUEST';
export const GET_SECURITY_QUESTIONS_SUCCESS = 'GET_SECURITY_QUESTIONS_SUCCESS';
export const GET_USER_SECURITY_REQUEST = 'GET_USER_SECURITY_REQUEST';
export const GET_USER_SECURITY_SUCCESS = 'GET_USER_SECURITY_SUCCESS';
export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const VALIDATE_DOB_REQUEST = 'VALIDATE_DOB_REQUEST';
export const VALIDATE_DOB_SUCCESS = 'VALIDATE_DOB_SUCCESS';
export const VALIDATE_SECURITY_REQUEST = 'VALIDATE_SECURITY_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_SECURITY_REQUEST = 'CHANGE_SECURITY_REQUEST';
export const GET_PASSWORD_MIGRATION_REQUEST = 'GET_PASSWORD_MIGRATION_REQUEST';
export const POST_PASSWORD_MIGRATION_REQUEST = 'POST_PASSWORD_MIGRATION_REQUEST';
export const CHANGE_PASSWORD_OR_SECURITY_FAILURE = 'CHANGE_PASSWORD_OR_SECURITY_FAILURE';

//More Menu navigaiton
export const NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN = 'NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN';
export const NAVIGATE_TO_CHANGE_SECURITY = 'NAVIGATE_TO_CHANGE_SECURITY';
export const NAVIGATE_TO_CHANGE_PASSWORD = 'NAVIGATE_TO_CHANGE_PASSWORD';
export const NAVIGATE_TO_REVOKE_PRIVACY = 'NAVIGATE_TO_REVOKE_PRIVACY';
export const NAVIGATE_TO_REVOKE_ORG_TERMS = 'NAVIGATE_TO_REVOKE_ORG_TERMS';
export const NAVIGATE_TO_EMPLOYEE_ONBOARDING = 'NAVIGATE_TO_EMPLOYEE_ONBOARDING';

//Feature Flags
export const FETCH_FEATURE_FLAGS_REQUEST = 'FETCH_FEATURE_FLAGS_REQUEST';
export const FETCH_FEATURE_FLAGS_SUCCESS = 'FETCH_FEATURE_FLAGS_SUCCESS';
export const FETCH_FEATURE_FLAGS_FAIL = 'FETCH_FEATURE_FLAGS_FAIL';

// Manage Group Members

export const REMOVE_GROUP_MEMBER_REQUEST = 'REMOVE_GROUP_MEMBER_REQUEST';
export const REMOVE_GROUP_MEMBER_SUCCESS = 'REMOVE_GROUP_MEMBER_SUCCESS';
export const REMOVE_GROUP_MEMBER_FAIL = 'REMOVE_GROUP_MEMBER_FAIL';
export const TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST = 'TOGGLE_GROUP_MEMBER_PERMISSION_REQUEST';
export const TOGGLE_GROUP_MEMBER_PERMISSON_SUCCESS = 'TOGGLE_GROUP_MEMBER_PERMISSION_SUCCESS';
export const TOGGLE_GROUP_MEMBER_PERMISSON_FAIL = 'TOGGLE_GROUP_MEMBER_PERMISSION_FAIL';

// Employee  Onboarding

export const GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST = 'GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST';
export const GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS = 'GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS';
export const UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST = 'UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST';
export const UPDATE_EMPLOYEE_ONBOARDING_TASKS_SUCCESS = 'UPDATE_EMPLOYEE_ONBOARDING_TASKS_SUCCESS';

//Branding

export const GET_ORG_THEME_REQUEST = 'GET_ORG_THEME_REQUEST';
export const GET_ORG_THEME_SUCCESS = 'GET_ORG_THEME_SUCCESS';
export const GET_ORG_THEME_FAILURE = 'GET_ORG_THEME_FAILURE';

//Custom App
//Branding

export const GET_CUSTOM_APP_REQUEST = 'GET_CUSTOM_APP_REQUEST';
export const GET_CUSTOM_APP_SUCCESS = 'GET_CUSTOM_APP_SUCCESS';
export const GET_CUSTOM_APP_FAILURE = 'GET_CUSTOM_APP_FAILURE';

export const UPDATE_KNOWLEDEBASE_VIEWS_REQUEST = 'UPDATE_KNOWLEDEBASE_VIEWS_REQUEST';
export const UPDATE_KNOWLEDEBASE_VIEWS_SUCCESS = 'UPDATE_KNOWLEDEBASE_VIEWS_SUCCESS';
export const UPDATE_KNOWLEDEBASE_VIEWS_FAILURE = 'UPDATE_KNOWLEDEBASE_VIEWS_FAILURE';
