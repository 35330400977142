export const SEARCH = {
  SEARCH_PEOPLE_REQUEST: 'SEARCH_PEOPLE_REQUEST',
  SEARCH_PEOPLE_SUCCESS: 'SEARCH_PEOPLE_SUCCESS',
  SEARCH_GROUPS_REQUEST: 'SEARCH_GROUPS_REQUEST',
  SEARCH_GROUPS_SUCCESS: 'SEARCH_GROUPS_SUCCESS',
};

const searchPeopleRequest = payload => ({
  type: SEARCH.SEARCH_PEOPLE_REQUEST,
  payload,
});

const searchPeopleSuccess = data => ({
  type: SEARCH.SEARCH_PEOPLE_SUCCESS,
  data,
});

const searchGroupsRequest = (value, token) => ({
  type: SEARCH.SEARCH_GROUPS_REQUEST,
  payload: {
    value,
    token,
  },
});

const searchGroupsSuccess = data => ({
  type: SEARCH.SEARCH_GROUPS_SUCCESS,
  data,
});

export { searchPeopleRequest, searchPeopleSuccess, searchGroupsRequest, searchGroupsSuccess };
