import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoutes from './PublicRoute';
import SignIn from '../containers/Auth/SignIn';
import SignInWithMagicLink from '../containers/Auth/SignInWithMagicLink';
import UserProfile from '../containers/OnBoarding/UserProfile';
import CollegueProfile from '../components/CollegueProfile';
import EditProfile from '../containers/EditProfile';
import Engage from '../containers/Engage';
import Activity from '../containers/Activity';
import LearningTestContainer from '../containers/LearningTestContainer';
import Feed from '../components/Feed/Feed';
import Header from '../containers/global/Header';
import Knowledgebase from '../components/Knowledgebase/Knowledgebase';
import KnowledgebaseDetails from '../components/Knowledgebase/KnowledgebaseDetails';
import { SearchOrganisations, SingleChallenge, UserListModal } from '../containers';
import Notifications from '../components/Notifications/Notifications';
import ConsentModal from '../containers/global/ConsentModal';
import Account from '../containers/Account';
import PrivacyAndTerms from '../containers/PrivacyAndTerms';
import ManageGroups from '../containers/ManageGroup';
import EmployeeOnboarding from '../containers/EmployeeOnboarding';

const AuthorizedRoutes = () => {
  return (
    <>
      <Header />
      <div className="wrapper-content">
        <Route
          path="/"
          render={props => {
            if (props.location.pathname.indexOf('onboarding') === -1) {
              return <ConsentModal />;
            } else {
              return null;
            }
          }}
        />
        <Switch>
          <Route path="/onboarding" component={UserProfile} />
          <Route path="/existing-account" component={Account} />
          <Route path="/privacy-terms" component={PrivacyAndTerms} />
          <Route path="/privacy-policy" component={PrivacyAndTerms} />
          <Route path="/set" component={UserProfile} />
          <Route exact path="/edit" component={EditProfile} />
          <Route path="/search-organisations" component={SearchOrganisations} />
          <Route path="/users/:userId" component={CollegueProfile} />
          <Route path="/search" component={SearchOrganisations} />
          <Route path="/activity" component={Activity} />
          <Route path="/engage/:engageId" component={SingleChallenge} />
          <Route path="/engage" component={Engage} />
          <Route path="/explore" component={Activity} />
          <Route path="/knowledgebase-item/:id" component={KnowledgebaseDetails} />
          <Route path="/knowledgebase" component={Knowledgebase} />
          <Route path="/learning/:learningId" component={LearningTestContainer} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/feed/:postId" render={props => <Feed key="feedSingle" {...props} />} />
          <Route path="/employee_onboarding" component={EmployeeOnboarding} />
          <Route
            path="/group-feed/:groupId"
            render={props => <Feed key={`feedGroup:${props.match.params.groupId}`} {...props} />}
          />
          <Route path="/manage-group/:groupId" component={ManageGroups} />
          <Route path="/" render={props => <Feed key="feedAll" {...props} />} />
        </Switch>
        <Switch>
          <Route path="/feed/:id" render={props => <UserListModal {...props} />} />
          <Route path="/group-feed/:id" render={props => <UserListModal {...props} />} />
          <Route path="/engage/:id" render={props => <UserListModal {...props} />} />
          <Route path="/" render={props => <UserListModal {...props} />} />
        </Switch>
      </div>
    </>
  );
};

const routes = () => {
  return (
    <>
      <Switch>
        <PublicRoutes exact path="/auth" component={SignIn} />
        <PublicRoutes path="/account" component={Account} />
        <PublicRoutes path="/token_auth/:token" component={SignInWithMagicLink} />
        <PrivateRoute path="/" component={AuthorizedRoutes} />
      </Switch>
    </>
  );
};

const mainRoutes = (
  <Switch>
    <Route component={routes} />
  </Switch>
);

export default mainRoutes;
