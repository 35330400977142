import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class DepartmentsApi {
  static getDepartments() {
    const request = new Request(`${apiUrl}/content/department`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}

export default DepartmentsApi;
