import React from 'react';

export default function EventIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="18px"
      height="20px"
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M14,0.375 C14.5128358,0.375 14.9355072,0.76104019 14.9932723,1.25837887 L15,1.375 L15,2 C16.6568542,2 18,3.34314575 18,5 L18,17 C18,18.6568542 16.6568542,20 15,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,5 C0,3.34314575 1.34314575,2 3,2 L3,1.375 C3,0.82271525 3.44771525,0.375 4,0.375 C4.51283584,0.375 4.93550716,0.76104019 4.99327227,1.25837887 L5,1.375 L5,2 L13,2 L13,1.375 C13,0.82271525 13.4477153,0.375 14,0.375 Z M16,8.931 L2,8.931 L2,17 C2,17.5522847 2.44771525,18 3,18 L15,18 C15.5522847,18 16,17.5522847 16,17 L16,8.931 Z M15,4 C14.9291155,4.49232939 14.5089614,4.87255859 14,4.87255859 C13.4910386,4.87255859 13.0708845,4.49232939 13.0080993,4.00043264 L4.99190067,4.00043264 C4.9291155,4.49232939 4.50896139,4.87255859 4,4.87255859 C3.49103861,4.87255859 3.0708845,4.49232939 3.00809933,4.00043264 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,6.931 L16,6.931 L16,5 C16,4.44771525 15.5522847,4 15,4 Z"
          id="path-123"
        ></path>
      </defs>
      <g id="✏️-10-Creating-Posts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Create-Event-Filled" transform="translate(-738.000000, -772.000000)">
          <g id="calendar" transform="translate(735.000000, 770.000000)">
            <g id="Colours-/-dark-grey" transform="translate(3.000000, 2.000000)">
              <mask id="mask-234" fill="white">
                <use xlinkHref="#path-123"></use>
              </mask>
              <use id="Line1" fill="#505A62" fillRule="nonzero" xlinkHref="#path-123"></use>
              <g id="Colours-/-dark-grey-Colours-/-blue" mask="url(#mask-234)" fill={stroke}>
                <g transform="translate(-3.000000, -2.000000)" id="Rectangle">
                  <rect x="0" y="0" width="24" height="24"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
