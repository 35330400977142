import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProgressBar from '../global/ProgressBar';
import moment from 'moment';
import { ChallengeBadgeCount } from '../index';

function ChallengeListItem({ challenge }) {
  function getProgress(challenge) {
    return (100 * (challenge.counter || 0)) / (challenge.target_count || 1);
  }

  return (
    <Link to={`/engage/${challenge.id}`} className="challenges__item">
      <div className="challenges__item-content">
        <div className="challenges__item-content-left">
          <h6 className={`single-line-text ${challenge.complete ? 'complete' : ''}`}>
            {challenge.name}
          </h6>
          <p>
            {challenge.complete
              ? 'Completed'
              : `by ${moment(challenge.end_date).format('Do MMM YYYY')}`}
          </p>
        </div>
        <ChallengeBadgeCount value={challenge.points} />
      </div>
      {challenge.joined && !challenge.complete && (
        <div className="challenges__item-progress-bar">
          <ProgressBar width={getProgress(challenge)} hideLabels />
        </div>
      )}
    </Link>
  );
}

ChallengeListItem.propTypes = {
  challenge: PropTypes.object.isRequired,
};

export default ChallengeListItem;
