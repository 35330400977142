import { put, takeLatest, call } from 'redux-saga/effects';

import { getCategoriesSuccess } from '../actionCreators/category';
import { CATEGORY } from '../actionCreators/category';
import { CategoryApi } from '../api';
import errorHandler from '../utils/requestErrorHandler';

function* getCategories() {
  try {
    const data = yield call(CategoryApi.getCategories);

    yield put(getCategoriesSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchCategory() {
  yield takeLatest(CATEGORY.GET_CATEGORIES_REQUEST, getCategories);
}
