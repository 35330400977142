import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import { logout } from '../actionCreators/auth';
import { removeCookie } from '../api/helpers';

import { addAlertToAlertsList } from '../actionCreators/globalAlerts';
import {
  addPreloaderToGlobalPreloadersList,
  removePreloaderToGlobalPreloadersList,
} from '../actionCreators/globalPreloaders';
import {
  getSecurityQuestionsRequest,
  getSecurityQuestionsSuccess,
  getUserSecuritySuccess,
  resetPasswordSuccess,
} from '../actionCreators/account';
import {
  GET_SECURITY_QUESTIONS_REQUEST,
  VALIDATE_DOB_REQUEST,
  SET_PASSWORD_REQUEST,
  VALIDATE_SECURITY_REQUEST,
  RESET_PASSWORD_REQUEST,
  GET_PASSWORD_MIGRATION_REQUEST,
  POST_PASSWORD_MIGRATION_REQUEST,
  NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_SECURITY_REQUEST,
  GET_USER_SECURITY_REQUEST,
  CHANGE_PASSWORD_OR_SECURITY_FAILURE,
} from '../constants/actionTypes';
import { signInSucceededWithPassword, userLocked } from '../actionCreators/auth';
import AccountApi from '../api/account';
import errorHandler from '../utils/requestErrorHandler';
import { getOrgThemeRequest } from '../actionCreators/orgTheme';

function * validateDOB (action) {
  const preloaderId = Date.now();
  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  const alertMsg = {
    title: 'Incorrect Date of birth',
    message:
      'The information you have entered does not match the system please try again or contact your administrator',
  };
  try {
    const data = yield call(AccountApi.validateDOB, action.payload);
    if (data.success) {
      yield put(getSecurityQuestionsRequest());
    } else {
      if (data.locked_until) {
        yield put(push('/auth'));
        yield put(
          userLocked({
            locked: true,
            lockedUntil: data.locked_until,
          }),
        );
      } else yield put(addAlertToAlertsList(alertMsg));
    }
  } catch (error) {
    let message = 'Something went wrong.';
    if (error && error.fieldsErrors && error.fieldsErrors.message)
      message = error.fieldsErrors.message;
    yield put(
      addAlertToAlertsList({
        ...alertMsg,
        message,
      }),
    );
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * getSecurityQuestions (action) {
  try {
    const data = yield call(AccountApi.getSecurityQuestions, action.payload);
    yield put(getSecurityQuestionsSuccess({ ...data, setPassword: true }));
  } catch (err) {
    yield errorHandler(err);
  }
}

function * setPassword (action) {
  const preloaderId = Date.now();
  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  const alertMsg = {
    title: 'Incorrect Date of birth',
    message:
      'The information you have entered does not match the system please try again or contact your administrator',
  };
  try {
    const data = yield call(AccountApi.setPassword, action.payload);
    if (data.success) {
      const decodedJWT = jwt_decode(data.key);
      if (!decodedJWT.first_name || !decodedJWT.last_name || !decodedJWT.job_title) {
        yield put(push('/onboarding'));
      } else yield put(push('/'));
      document.dispatchEvent(new Event('showConsentModal'));
      yield put(signInSucceededWithPassword(data));
      yield put(getOrgThemeRequest())
    } else {
      if (data.locked_until) {
        yield put(push('/auth'));
        yield put(
          userLocked({
            locked: true,
            lockedUntil: data.locked_until,
          }),
        );
      } else yield put(addAlertToAlertsList(alertMsg));
    }
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * validateSecurity (action) {
  const preloaderId = Date.now();
  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  const alertMsg = {
    title: 'Incorrect security setails',
    message:
      'The information you have entered is not correct please try again. Please note you have 3 attempts before your account will be locked',
    // messageStyle: { color: '#505A62' },
    // confirmBtnText: 'OKAY',
  };
  const { username, securityQuestionId, securityAnswer } = action.payload;
  try {
    const data = yield call(AccountApi.validateSecurity, action.payload);
    if (data.success) {
      yield put(
        push(
          `/account?username=${username}&question_id=${securityQuestionId}&answer=${securityAnswer}`,
        ),
      );
    } else {
      if (data.locked_until) {
        yield put(push('/auth'));
        yield put(
          userLocked({
            locked: true,
            lockedUntil: data.locked_until,
          }),
        );
      } else {
        yield put(addAlertToAlertsList(alertMsg));
      }
    }
  } catch (error) {
    let message = 'Something went wrong.';
    if (error && error.fieldsErrors && error.fieldsErrors.message)
      message = error.fieldsErrors.message;
    yield put(
      addAlertToAlertsList({
        ...alertMsg,
        message,
      }),
    );
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * resetPassword (action) {
  const preloaderId = Date.now();
  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  const alertMsg = {
    title: 'Incorrect security setails',
    message:
      'The information you have entered is not correct please try again. Please note you have 3 attempts before your account will be locked',
    // messageStyle: { color: '#505A62' },
    // confirmBtnText: 'OKAY',
  };
  try {
    const data = yield call(AccountApi.resetPassword, action.payload);
    if (data.success) {
      yield put(resetPasswordSuccess());
      yield put(push('/auth'));
    } else {
      if (data.locked_until) {
        yield put(push('/auth'));
        yield put(
          userLocked({
            locked: true,
            lockedUntil: data.locked_until,
          }),
        );
      } else yield put(addAlertToAlertsList(alertMsg));
    }
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * getPasswordMigrationStatus (action) {
  const preloaderId = Date.now();
  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  const alertMsg = {
    title: 'Login Update',
    message:
      'We’ve made some changes to how you login to make it easier and more secure. Please review our new terms, privacy policy and add some new details to continue using Engage4.',
    addBtn: 'REVIEW & ADD DETAILS',
    passwordMigration: true,
    messageStyle: {
      textAlign: 'center',
    },
  };
  try {
    const data = yield call(AccountApi.getPasswordMigrationStatus, action.payload);
    if (data.success) {
      if (!data.is_password_migrated) {
        yield put(getSecurityQuestionsRequest());
        yield put(addAlertToAlertsList(alertMsg));
      }
    }
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * navigateToPasswordMigration (action) {
  try {
    yield put(push('/existing-account?password-migration=true'));
  } catch (error) {
    yield errorHandler(error);
  }
}

function * migrateToPassword (action) {
  try {
    const data = yield call(AccountApi.migrateToPassword, action.payload);
    if (data.success) {
      yield put(push('/feed'));
    } else {
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function * onChangePasswordOrSecurityFailure (action) {
  try {
    const { data, alertMsg } = action.payload;
    const preloaderId = Date.now();
    yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
    if (data.locked_until) {
      yield put(logout());
      yield put(
        userLocked({
          locked: true,
          lockedUntil: data.locked_until,
        }),
      );
      yield put(push('/auth'));
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      removeCookie('token');
    } else {
      yield put(addAlertToAlertsList(alertMsg));
    }
  } catch (err) {
    yield errorHandler(err);
  }
}

function * changePassword (action) {
  const alertMsg = {
    title: 'Incorrect password',
    message:
      'The information you have entered is not correct please try again. Please note you have 3 attempts before your account will be locked',
  };
  try {
    const data = yield call(AccountApi.changePassword, action.payload);
    if (data.success) {
      yield put(push('/feed'));
    } else {
      yield put({
        type: 'CHANGE_PASSWORD_OR_SECURITY_FAILURE',
        payload: {
          data,
          alertMsg,
        },
      });
    }
  } catch (error) {
    yield put(addAlertToAlertsList(alertMsg));
  }
}

function * getUserSecurityDetails (action) {
  try {
    const data = yield call(AccountApi.getUserSecurityDetails);
    yield put(getUserSecuritySuccess(data));
  } catch (err) {
    yield errorHandler(err);
  }
}

function * changeSecurity (action) {
  const alertMsg = {
    title: 'Incorrect security',
    message:
      'The information you have entered is not correct please try again. Please note you have 3 attempts before your account will be locked',
  };
  try {
    const data = yield call(AccountApi.changeSecurity, { ...action.payload });

    if (data.success) {
      yield put(push('/feed'));
    } else {
      yield put({
        type: 'CHANGE_PASSWORD_OR_SECURITY_FAILURE',
        payload: {
          data,
          alertMsg,
          
        },
      });
    }
  } catch (error) {
    yield put(addAlertToAlertsList(alertMsg));
  }
}

export default function * watchAuth () {
  yield takeLatest(VALIDATE_DOB_REQUEST, validateDOB);
  yield takeLatest(GET_SECURITY_QUESTIONS_REQUEST, getSecurityQuestions);
  yield takeLatest(SET_PASSWORD_REQUEST, setPassword);
  yield takeLatest(VALIDATE_SECURITY_REQUEST, validateSecurity);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(GET_PASSWORD_MIGRATION_REQUEST, getPasswordMigrationStatus);
  yield takeLatest(POST_PASSWORD_MIGRATION_REQUEST, migrateToPassword);
  yield takeLatest(NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN, navigateToPasswordMigration);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(CHANGE_SECURITY_REQUEST, changeSecurity);
  yield takeLatest(GET_USER_SECURITY_REQUEST, getUserSecurityDetails);
  yield takeLatest(CHANGE_PASSWORD_OR_SECURITY_FAILURE, onChangePasswordOrSecurityFailure);
}
