import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class ChallengeApi {
  static getChallenges(data) {
    const request = new Request(`${apiUrl}/content/challenges`, {
      method: 'GET',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    });

    return fetchRequest(request);
  }

  static getChallenge(data) {
    const request = new Request(`${apiUrl}/content/challenges/${data.id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static updateChallenge(data) {
    const formData = {
      id: data.id,
      ...(data.count > -1 && { count: data.count }),
    };

    const request = new Request(`${apiUrl}/content/challenges`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(formData),
    });

    return fetchRequest(request);
  }

  static updateChallengeJoined(data) {
    const formData = {
      id: data.id,
      action: data.action,
    };

    const request = new Request(`${apiUrl}/content/challenges`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(formData),
    });

    return fetchRequest(request);
  }

  static getParticipants(challengeId, limit = 15, page = 1) {
    const request = new Request(
      `${apiUrl}/content/challenges/${challengeId}/participants?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }
}

export default ChallengeApi;
