import React, { PureComponent } from 'react';
import ImageApi from '../../api/image';
import './knowledgebase.scss';

export default class KnowledgebaseItem extends PureComponent {
  constructor (props) {
    super(props);
    this.state = { imageUrl: '' };
  }
  componentDidMount () {
    if (this.props.image && this.props.image.url) this.fetchImageUrl();
  }
  fetchImageUrl = async () => {
    ImageApi.getImage(this.props.image.url).then(imageUrl => {
      this.setState({ imageUrl });
    });
  };

  render () {
    return (
      <img
        className='knowledgebase-item-content-image'
        src={this.state.imageUrl}
        width='100%'
        alt='post'
      />
    );
  }
}
