import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';

function create() {
  let instance;
  let configured = false;

  const setup = config => {
    if (config && config.autoClear) {
      Reactotron.clear && Reactotron.clear();
    }

    return Reactotron.configure({
      name: 'Engage4',
      host: 'localhost',
    })
      .use(reactotronRedux())
      .use(sagaPlugin())
      .connect();
  };

  const log = (message, ...args) => {
    Reactotron.display({
      name: 'LOG',
      preview: JSON.stringify(message),
      value: { message, args },
    });
  };

  const warn = (message, ...args) => {
    Reactotron.display({
      name: 'WARN',
      preview: JSON.stringify(message),
      value: { message, args },
      important: true,
    });
  };

  const error = (message, ...args) => {
    Reactotron.display({
      name: 'ERROR',
      preview: JSON.stringify(message),
      value: { message, args },
      important: true,
    });
  };

  const info = (message, ...args) => {
    Reactotron.display({
      name: 'INFO',
      preview: JSON.stringify(message),
      value: { message, args },
    });
  };

  const overrideConsole = config => {
    const included = type => config && config.include && config.include.includes(type);
    if (included('info')) console.info = info;
    if (included('log')) console.log = log;
    if (included('warn')) console.warn = warn;
    if (included('error')) console.error = error;
  };

  return function config(config) {
    if (!configured) {
      instance = setup(config);
      overrideConsole(config);
    }
    return instance.createEnhancer && instance.createEnhancer();
  };
}

export default create();
