import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './styles';
import './knowledgebase.scss';
import { useParams, useLocation, Link } from 'react-router-dom';
import KnowledgebaseItem from './KnowledgebaseItem';
import CreateFeedItem from '../Feed/CreateFeedItem/CreateFeedItem';
import { updateTaskRequest } from '../../actionCreators/employeeOnboarding';
import { logFirebaseEvent } from '../../firebase/analytics';

import GridContainer from '../global/GridContainer/GridContainer';
import { updateKnowledgebaseViewsRequest } from '../../actionCreators/knowledgebase';

const KnowledgebaseDetails = props => {
  const { id } = useParams();
  const data = useLocation();
  let item = null;
  let taskId = null;
  let firstTimeLogin = '';
  if (data && data.state) {
    item = data.state.item;
    taskId = data.state.taskId;
    firstTimeLogin = data.state.firstTimeLogin;
  }

  const [showShareModal, togglShowShareModal] = useState(false);

  const onToggleCreateFeed = isActive => {
    togglShowShareModal(isActive);
  };

  useEffect(() => {
    props.updateKnowledgebaseViews(item.id);
  }, []);

  return (
    <div className='knowledgebase'>
      <GridContainer>
        <div className='knowledgebase-header'>
          <p className='knowledgebase-header-text page-title'>Knowledgebase</p>
        </div>
        <div className='knowledgebase-content'>
          <div style={styles.knowledgebaseItemSubheader}>
            <Link
              to={
                !taskId ? '/knowledgebase' : `/employee_onboarding?firstTimeLogin=${firstTimeLogin}`
              }
              className='single-challenge__back-text'
            >
              &#x276E; {taskId ? 'Back to Onboarding' : 'Back to Knowledgebase'}
            </Link>
            {item && !taskId && (
              <div
                style={styles.knowledgebaseShareButton}
                onClick={() => togglShowShareModal(true)}
              >
                Share
              </div>
            )}
          </div>
          {showShareModal ? (
            <CreateFeedItem
              onToggleActive={onToggleCreateFeed}
              action={'share'}
              title={'Share knowledgebase'}
              isFocused={true}
              shouldShowAttachedMedia={false}
              knowledgebaseItem={item}
            />
          ) : null}
          {item && (
            <div style={styles.postListContainer}>
              <KnowledgebaseItem
                viewOnly
                item={item}
                updateTask={props.updateOnboardingTask}
                updateKnowledgebaseViews={props.updateKnowledgebaseViews}
                taskId={taskId}
              />
            </div>
          )}
        </div>
      </GridContainer>
    </div>
  );
};

export default connect(null, dispatch => ({
  updateOnboardingTask: taskId =>
    dispatch(
      updateTaskRequest({ taskId }),
      logFirebaseEvent({
        event: 'employee_onboarding',
        action: 'task_completed',
      }),
    ),
  updateKnowledgebaseViews: itemId => dispatch(updateKnowledgebaseViewsRequest(itemId)),
}))(KnowledgebaseDetails);
