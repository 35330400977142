import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import SidebarGroupBlock from './SidebarGroupBlock';
import SidebarEventBlock from './SidebarEventBlock';
import { CreateGroupModal, Portal, SearchGroupsModal, VerifyGroupModal } from '../../index';

import lodash from 'lodash'
import {
  getMyGroupsRequest,
  verifyGroupRequest,
  updateGroupRequest,
  verifyGroupSuccess,
  showCreateGroupModal,
} from '../../../actionCreators/groupActions';
import { getMyEvents, getAllEvents } from '../../../actionCreators/events';
import { getCategoriesRequest } from '../../../actionCreators/category';
import { getGroupPrivacyTypesRequest } from '../../../actionCreators/groupActions';
import { searchGroupsRequest, searchPeopleRequest } from '../../../actionCreators/search';
import { createGroupRequest } from '../../../actionCreators/groupActions';
import { logFirebaseEvent } from '../../../firebase/analytics';
import Icon from '@mdi/react'
import { mdiDotsHorizontalCircle } from '@mdi/js'
import { ClickAwayListener } from '@material-ui/core'

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSearchGroupsModal: false,
      showGroupsMenu: false,
      myGroupsOnly: false
    };
  }

  componentDidMount() {
    this.props.getMyGroups();
    this.props.handleMyEvents();
    this.props.getAllEvents();
    this.props.getCategories();
    this.props.getGroupPrivacyTypes();
    this.props.searchGroups('', this.props.currentUser.signInData)
  }

  refreshEvents = () => {
    this.props.handleMyEvents();
    this.props.getAllEvents();
  }

  hideCreateGroupModal = () => {
    this.props.showCreateGroupModal({ flag: false });
    this.refreshEvents()
  }
  showCreateGroupModal = () => this.props.showCreateGroupModal({
    flag: true,
    myGroupsOnly: false
  });

  showSearchGroupsModal = () => this.setState({
    showSearchGroupsModal: true,
    myGroupsOnly: false
  })
  hideSearchGroupsModal = () => {
    this.setState({ showSearchGroupsModal: false })
    this.refreshEvents()
  }

  onClickCreateNewGroup = () => this.props.createGroup(this.props.verifyGroupPayload.createGroupRequest);

  clearGroupsMarkers = () => this.props.getMyGroups();

  toggleGroupsMenu = () => this.setState({
    showGroupsMenu: !this.state.showGroupsMenu
  })

  viewMyGroups = () => {
    this.setState({
      showSearchGroupsModal: true,
      myGroupsOnly: true
    })
    this.refreshEvents()
  }

  shouldShowClearActivity = () => {
    let shouldShow = false;
    const { myGroups } = this.props;
    myGroups.length && myGroups.forEach((group) => {
      if (group.newPostActivity > 0) shouldShow = true;
    });

    return shouldShow;
  }

  onClickDontMind = () => {
    this.props.dismissVerifyGroup();
    this.hideCreateGroupModal();
  };

  onClickBackToCreateGroup = () => {
    this.props.dismissVerifyGroup();
    this.props.showCreateGroupModal({ flag: true });
    this.refreshEvents()
  }

  render() {
    const myActualGroups = this.props.searchResults.groups.filter(group => {
      return lodash.find(this.props.myGroups, { id: group.id })
    })

    let groupsToRender = this.props.myGroups;
    const { verifyGroupPayload, showVerifyGroupModal } = this.props;
    const showClearActivity = this.shouldShowClearActivity();
    const rootClassName = 'sidebar-group-block';

    if (this.props.hasGroupFeedId)
      groupsToRender = groupsToRender.filter(group => group.id !== this.props.hasGroupFeedId);
    groupsToRender = groupsToRender.slice(0, 6);
    const canCreateGroup = this.props.isAdmin
      ? true
      : this.props.featureFlags.CREATE_GROUP_NORMAL_USER;
    return (
      <div className='sidebar'>
        <div className='sidebar-block'>
          <div className='sidebar-block-header'>
            <span className='sidebar-block-title'>Groups</span>

            <div onClick={this.toggleGroupsMenu}>
              <Icon path={mdiDotsHorizontalCircle}
                size={1.5}
                horizontal
                vertical
                color="#535b64"
              />
            </div>

            {this.state.showGroupsMenu && (
              <ClickAwayListener onClickAway={this.toggleGroupsMenu}>
                <div className={'sidebar-block-groups-menu'}>
                  {canCreateGroup ? (
                    <p
                      onClick={this.showCreateGroupModal}
                      className={'sidebar-block-groups-menu-button'}
                    >
                      Create Group
                    </p>
                  ) : null}
                    <p
                      onClick={this.showSearchGroupsModal}
                      className={'sidebar-block-groups-menu-button'}
                    >
                      Find Groups
                  </p>
                    <p
                      onClick={this.viewMyGroups}
                      className={'sidebar-block-groups-menu-button'}
                    >
                      View All
                  </p>
                  </div>
                </ClickAwayListener>
              )
            }

          </div>

          <div className='sidebar-block-content'>
            {groupsToRender.slice(0, 5).map(group => {
              return (
                <SidebarGroupBlock
                  key={group.id}
                  title={group.name}
                  imageUrl={group.image.url}
                  id={group.id}
                  newPostActivity={group.newPostActivity}
                />
              );
            })}
          </div>

          <Portal
            hide={this.hideCreateGroupModal}
            isOpen={this.props.showNewGroupModal}
            className='create-group-modal-holder'
          >
            <CreateGroupModal
              hide={this.hideCreateGroupModal}
              categories={this.props.categories}
              searchResults={this.props.searchResults}
              searchPeople={this.props.searchPeople}
              token={this.props.currentUser.signInData}
              verifyGroup={this.props.verifyGroup}
              updateGroup={this.props.updateGroup}
              groupPrivacyTypes={this.props.groupPrivacyTypes}
            />
          </Portal>

          <Portal isOpen={showVerifyGroupModal} className='create-group-modal-holder'>
            <VerifyGroupModal
              hide={this.onClickDontMind}
              token={this.props.currentUser.signInData}
              createGroup={this.onClickCreateNewGroup}
              recommendedGroups={verifyGroupPayload.recommendedGroups}
              errorMessage={verifyGroupPayload.errorMessage}
              onClickBackToCreateGroup={this.onClickBackToCreateGroup}
            />
          </Portal>

          <Portal
            topPosition={true}
            hide={() => {
              this.setState({ showSearchGroupsModal: false })
              this.setState({ myGroupsOnly: false })
            }}
            isOpen={this.state.showSearchGroupsModal}
          >
            <SearchGroupsModal
              hide={() => this.setState({ showSearchGroupsModal: false })}
              searchGroups={this.props.searchGroups}
              searchResults={this.props.searchResults}
              token={this.props.currentUser.signInData}
              myGroupsOnly={this.state.myGroupsOnly}
              myGroups={this.props.myGroups}
            />
          </Portal>
        </div>

        <div className='sidebar-block'>
          <div className='sidebar-block-header'>
            <span className='sidebar-block-title'>Events</span>
          </div>

          <div className='sidebar-block-content'>
            {[
              ...this.props.myEvents,
              ...this.props.allEvents
            ].sort((a, b) => {
              if (a.post.start_time > b.post.start_time) {
                return 1
              }

              if (a.post.start_time < b.post.start_time) {
                return -1
              }

              return 0
            }).slice(0, 6).map(event => {
              return (
                <SidebarEventBlock
                  key={event.postable_id}
                  id={event.postable_id}
                  title={event.post.title}
                  startTime={event.post.start_time}
                  imageUrl={event.post.image.url}
                />
              );
            })}
          </div>
        </div>
      </div >
    );
  }
}

export default connect(
  state => ({
    ...state.group,
    events: state.events,
    myEvents: state.events.myEvents,
    allEvents: state.events.allEvents,
    myGroups: state.group.myGroups,
    categories: state.categories,
    searchResults: state.searchResults,
    currentUser: state.currentUser,
    verifyGroupPayload: state.group.verifyGroup,
    showVerifyGroupModal: state.group.verifyGroup.recommendedGroups.length > 0 ? true : false,
    showNewGroupModal: state.group.showCreateGroupModal,
    groupPrivacyTypes: state.group.groupPrivacyTypes,
    featureFlags: state.featureFlags || {},
    isAdmin: state.currentUser.userData && state.currentUser.userData.is_admin ? true : false,
  }),
  dispatch => ({
    getMyGroups: () => dispatch(getMyGroupsRequest()),
    getCategories: () => dispatch(getCategoriesRequest()),
    searchPeople: payload => dispatch(searchPeopleRequest(payload)),
    searchGroups: (value, token) => dispatch(searchGroupsRequest(value, token)),
    createGroup: payload => dispatch(createGroupRequest(payload)),
    verifyGroup: payload => dispatch(verifyGroupRequest(payload)),
    handleMyEvents: () => dispatch(getMyEvents()),
    getAllEvents: () => dispatch(getAllEvents()),
    updateGroup: payload => dispatch(updateGroupRequest(payload)),
    showCreateGroupModal: payload => dispatch(showCreateGroupModal(payload)),
    getGroupPrivacyTypes: () => dispatch(getGroupPrivacyTypesRequest()),
    dismissVerifyGroup: () =>
      dispatch(
        verifyGroupSuccess({
          recommendedGroups: [],
          createGroupRequest: {},
          errorMessage: '',
        }),
      ),
  }),
)(Sidebar);
