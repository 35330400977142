import React, { Component, PureComponent } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Button from '../global/Button';
import CrossIcon from '../Icons/CrossIcon';
import FeedApi from '../../api/feed';

class Alert extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
    this.replaceToShop = this.replaceToShop.bind(this);
  }

  toggle () {
    this.props.removeFromStack();
    this.props.isTokenExpired && this.props.onTokenExpired();
    this.setState({ modal: !this.state.modal }, () => {
      if (typeof this.props.successCb === 'function') this.props.successCb();
      this.timerId && clearTimeout(this.timerId);
    });
  }

  replaceToShop = () => {
    if (this.props.link && this.props.link.length > 0) {
      const URL = this.props.link[0].link;
      window.open(URL, '_blank');
    }
    if (this.props.passwordMigration) {
      this.props.navigateToPasswordMigrationScren();
    }
    this.toggle();
  };

  uploadVideoToCloudflare = async ({
    notice,
    createNotice,
    closeNotice,
    signal,
    onFailToCreateNotice,
  }) => {
    try {
      const data = { ...notice };
      const videoUrlResp = (await FeedApi.getNoticeVideoUploadURL()) || {};
      const uploadURL = videoUrlResp.data ? videoUrlResp.data.uploadURL : '';
      if (!uploadURL) throw new Error(videoUrlResp.statusText);
      const videoInfo = {
        uploadURL,
        file: data.video,
      };
      const uploadStatus = await FeedApi.uploadNoticeVideoToCloudFlare(videoInfo, signal);
      if (uploadStatus) {
        if (uploadStatus.ok) {
          data.video_cloudflare_id = videoUrlResp.data.uid;
          delete data.video;
          this.timerId = setTimeout(() => {
            createNotice(data);
            closeNotice();
            this.toggle();
          }, 10000);
        } else {
          this.toggle();
          onFailToCreateNotice('Failed to upload');
        }
      }
    } catch (err) {
      this.toggle();
      onFailToCreateNotice('Failed to upload');
    }
  };

  render () {
    if (!this.state.modal) return null;
    if (this.props.isVideoAlert) this.uploadVideoToCloudflare(this.props.noticeParams);
    const {
      title,
      message,
      messageStyle = {},
      error = false,
      confirmBtnText = 'Ok',
      removeFromStack,
      addBtn,
      revokeBtnTxt,
      revokeBtnAction,
    } = this.props;

    // Error Button
    let errButton = null;
    if (error)
      errButton = <Button.Secondary onClick={this.toggle}>{confirmBtnText}</Button.Secondary>;
    else if (!addBtn) {
      errButton = (
        <Button.SecondaryOutline onClick={this.toggle}>{confirmBtnText}</Button.SecondaryOutline>
      );
    }

    let revokeBtn = null;
    if (revokeBtnTxt)
      revokeBtn = (
        <Button.Primary
          onClick={() => {
            this.toggle();
            revokeBtnAction();
          }}
        >
          {revokeBtnTxt}
        </Button.Primary>
      );

    // Add button
    let addButton = null;
    if (addBtn) addButton = <Button.Primary onClick={this.replaceToShop}>{addBtn}</Button.Primary>;

    // Close button
    const close = <CrossIcon className='modal-close' onClick={this.toggle} />;

    return (
      <Modal
        isOpen={true}
        toggle={this.toggle}
        onClosed={removeFromStack}
        className='modal-dialog-centered'
      >
        {this.props.passwordMigration ? (
          <h4 style={{ textAlign: 'center', paddingTop: 16, paddingBottom: 10, fontWeight: 600 }}>
            {title}
          </h4>
        ) : (
          title && (
            <ModalHeader
              className={`message-title ${error ? 'error' : ''}`}
              toggle={this.toggle}
              close={close}
            >
              {title}
            </ModalHeader>
          )
        )}

        <ModalBody className='message-body'>
          <div className='d-flex flex-column align-items-center'>
            <p className='modal-text' style={messageStyle}>
              {message}
            </p>
            {this.props.isVideoAlert ? (
              <Loader type='ThreeDots' color='#00BFFF' height={50} width={50} />
            ) : null}
          </div>
        </ModalBody>

        <ModalFooter className='message-footer'>
          {errButton}
          {addButton}
          {revokeBtn}
        </ModalFooter>
      </Modal>
    );
  }
}

class GlobalAlerts extends Component {
  render () {
    return (
      <div>
        {Object.values(this.props.globalAlerts).map(alertData => (
          <Alert
            key={alertData.id}
            removeFromStack={() => this.props.removeAlertFromAlertsList(alertData.id)}
            {...alertData}
            navigateToPasswordMigrationScren={this.props.navigateToPasswordMigrationScren}
            onTokenExpired={this.props.onTokenExpired}
            {...this.props}
          />
        ))}
      </div>
    );
  }
}

export default GlobalAlerts;
