import React from 'react';
import PropTypes from 'prop-types';

function RoundedSwitch({ checked = false, updateChecked, textOff, textOn }) {
  const activeStylesContainer = ['switch'];
  const activeStylesSpan = ['text on'];

  if (checked) {
    activeStylesSpan.push('black-color');
    activeStylesContainer.push('white-background');
  }

  return (
    <>
      <label className={activeStylesContainer.join(' ')}>
        <input
          type="checkbox"
          checked={checked}
          onChange={event => updateChecked(event.target.checked)}
        />
        <span className="slider round" />

        {typeof textOff !== 'undefined' && (
          <span className={`text off ${checked ? '' : 'active'}`}>{textOff}</span>
        )}
        {typeof textOn !== 'undefined' && (
          <span className={`${activeStylesSpan.join(' ')} ${checked ? 'active' : ''}`}>
            {textOn}
          </span>
        )}
      </label>
    </>
  );
}

RoundedSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  updateChecked: PropTypes.func.isRequired,
  textOff: PropTypes.string,
  textOn: PropTypes.string,
};

export default RoundedSwitch;
