import React from 'react';

export default function EngageIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="⋯-9-More"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="9-More"
          transform="translate(-98.000000, -736.000000)"
          stroke="#505A62"
          strokeWidth="2"
        >
          <g id="Nav" transform="translate(0.000000, 729.000000)">
            <g id="Icons-/-Engage" transform="translate(99.000000, 8.000000)">
              <g id="engage">
                <polygon
                  id="Stroke-1"
                  points="14.0082552 7.91622759 15.9310828 12.5516759 20.2759103 12.5516759 16.4138414 15.4482276 18.3448759 20.2758138 14.0082552 16.8631931 9.65522069 20.2758138 11.5862552 15.4482276 7.72418621 12.5516759 12.0690138 12.5516759"
                ></polygon>
                <polygon
                  id="Stroke-2"
                  points="25.3023931 16.2480621 28.0000483 13.9998552 25.3023931 11.752131 26.9341172 8.64268276 23.5818414 7.5979931 23.8994966 4.10088966 20.4019103 4.41854483 19.3572207 1.06578621 16.2482552 2.6979931 14.0000483 -0.000144827586 11.7518414 2.6979931 8.6423931 1.06578621 7.59818621 4.41854483 4.1006 4.10088966 4.41825517 7.5979931 1.06597931 8.64268276 2.69770345 11.752131 4.82758621e-05 13.9998552 2.69770345 16.2480621 1.06597931 19.3575103 4.41825517 20.4022 4.1006 23.8997862 7.59818621 23.5816483 8.6423931 26.9344069 11.7518414 25.3022 14.0000483 27.9998552 16.2482552 25.3022 19.3572207 26.9344069 20.4019103 23.5816483 23.8994966 23.8997862 23.5818414 20.4022 26.9341172 19.3575103"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
