import React from 'react';

export default function ExploreIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="Nav" transform="translate(-320.000000, -7.000000)" stroke={stroke} stroke-width="2">
          <g id="explore_nav_icon" transform="translate(321.000000, 8.000000)">
            <path
              d="M14,27.125 C21.2487373,27.125 27.125,21.2487373 27.125,14 C27.125,6.75126266 21.2487373,0.875 14,0.875 C6.75126266,0.875 0.875,6.75126266 0.875,14 C0.875,21.2487373 6.75126266,27.125 14,27.125 Z M19.4226667,8.57733333 C19.1881531,8.34283023 18.8412172,8.2610654 18.5266667,8.36616667 L10.906,10.906 L8.36616667,18.5266667 C8.26108393,18.8412142 8.34285889,19.1881368 8.57736107,19.4226389 C8.81186324,19.6571411 9.15878579,19.7389161 9.47333333,19.6338333 L17.094,17.094 L19.6338333,9.47333333 C19.7389346,9.15878277 19.6571698,8.81184693 19.4226667,8.57733333 Z M14,0.875 L14,3.5 M23.2808333,4.71916667 L21.4246667,6.57533333 M27.125,14 L24.5,14 M23.2808333,23.2808333 L21.4246667,21.4246667 M14,27.125 L14,24.5 M4.71916667,23.2808333 L6.57533333,21.4246667 M0.875,14 L3.5,14 M4.71916667,4.71916667 L6.57533333,6.57533333 M14,13.5625 C14.2416246,13.5625 14.4375,13.7583754 14.4375,14 M13.5625,14 C13.5625,13.7583754 13.7583754,13.5625 14,13.5625 M14,14.4375 C13.7583754,14.4375 13.5625,14.2416246 13.5625,14 M14.4375,14 C14.4375,14.2416246 14.2416246,14.4375 14,14.4375"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
