import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { Skeleton } from 'antd';

import NotificationRow from './NotificationRow';
import {
  getNotificationsRequest,
  setNotificationsReadRequest,
} from '../../actionCreators/notificationActions';
import { postNewMemberRequest } from '../../actionCreators/groupActions';
import { getUserBadgesRequest } from '../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../firebase/analytics';
import GridContainer from '../global/GridContainer/GridContainer';

const LIMIT = 15;

class Notifications extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showing: LIMIT,
    };
  }

  componentDidMount () {
    this.props.getUserBadges();
    this.props.getNotifications({ page: 1 });
    logFirebaseEvent({
      event: 'app_notifications',
      action: 'load',
    });
  }

  componentWillUnmount () {
    this._markAllAsRead(false);
  }

  _markAllAsRead = skipFetch => {
    const ids = [];
    this.props.items.forEach(item => {
      if (!item.is_read) {
        item.meta &&
          !item.meta.length &&
          logFirebaseEvent({
            event: 'points_goal',
          });
        item.notification.indexOf('badge') !== -1 &&
          logFirebaseEvent({
            event: 'badge',
          });
        ids.push(item.id);
      }
    });

    this.props.setNotificationsRead({ ids, skipFetch });
  };

  _prepNotifications (items) {
    const read = [];
    const notRead = [];

    for (let i = 0; i < this.state.showing; i++) {
      if (!items[i]) continue;
      const copy = { ...items[i] };

      // Find proper badge type
      for (const meta of items[i].meta || []) {
        if (meta.data.type !== 'badges') continue;

        const badge = lodash.find(this.props.badges, { id: +meta.data.id });
        if (badge) copy.badge = badge;
        break;
      }

      // Split items on read and unread
      if (items[i].is_read) read.push(copy);
      else notRead.push(copy);
    }

    return [read, notRead];
  }

  _getMoreNotifications = () => {
    this.setState(state => {
      if (this.state.showing >= this.props.items.length) this.props.getNotifications();

      return {
        showing: state.showing + LIMIT,
      };
    });
  };

  render () {
    const [read, notRead] = this._prepNotifications(this.props.items);

    const showViewMore =
      !this.props.loading &&
      (this.state.showing < this.props.items.length ||
        this.props.currentPage < this.props.totalPages);

    return (
      <div className='notifications'>
        <GridContainer hideNotifications={true}>
          <p className='notifications-header-text'>Notifications</p>
          <div className='notifications-content'>
            {this.renderLoading()}
            {this.renderNotRead(notRead)}
            {this.renderRead(read)}

            {showViewMore && (
              <div
                className='notifications-button align-self-center'
                onClick={this._getMoreNotifications}
              >
                VIEW MORE
              </div>
            )}
          </div>
        </GridContainer>
      </div>
    );
  }

  renderLoading () {
    if (!this.props.loading) return null;

    return (
      <div className='notifications-holder'>
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
      </div>
    );
  }

  renderNotRead (items) {
    if (!items.length) return null;

    return (
      <>
        <div
          className='notifications-button align-self-end'
          onClick={() => {
            logFirebaseEvent({
              event: 'app_notifications',
              action: 'mark_all_read',
            });
            this._markAllAsRead(false);
          }}
        >
          MARK ALL AS READ
        </div>

        <span className='notifications-title'>
          Unread
          <span className='notifications-title-badge'>{this.props.unreadCount}</span>
        </span>

        <div className='notifications-holder'>
          {items.map(notification => {
            return (
              <NotificationRow
                token={this.props.token}
                acceptNewMember={this.props.acceptNewMember}
                key={`notread:${notification.id}`}
                notification={notification}
              />
            );
          })}
        </div>
      </>
    );
  }

  renderRead (items) {
    if (!items.length) return null;

    return (
      <>
        <span className='notifications-title mt-4'>Older</span>

        <div className='notifications-holder'>
          {items.map(notification => {
            return (
              <NotificationRow
                token={this.props.token}
                acceptNewMember={this.props.acceptNewMember}
                key={`read:${notification.id}`}
                notification={notification}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    ...state.notification,
    badges: state.currentUser.badges.userBadges || [],
    token: state.currentUser.signInData.key,
  }),
  dispatch => ({
    getNotifications: payload => dispatch(getNotificationsRequest(payload)),
    setNotificationsRead: payload => dispatch(setNotificationsReadRequest(payload)),
    getUserBadges: () => dispatch(getUserBadgesRequest()),
    acceptNewMember: payload => dispatch(postNewMemberRequest(payload)),
  }),
)(Notifications);
