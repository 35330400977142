import React from 'react';
import './styles.scss';
import EditUserProfile from '../../containers/OnBoarding/EditUserProfile';
import Button from '../../components/global/Button';
import { connect } from 'react-redux';
import { logout } from '../../actionCreators/auth';
import { logFirebaseEvent } from '../../firebase/analytics';
import { clearStorageOnLogout } from '../../api/helpers';
import { Link } from 'react-router-dom';

class EditProfile extends React.Component {
  componentDidMount() {
    logFirebaseEvent({
      event: 'profile',
      action: 'load',
    });
  }
  handleSignOut = () => {
    clearStorageOnLogout();
    this.props.handleLogout();
    window.location.reload();
  };

  render() {
    return (
      <div className="edit-profile">

        <div className='edit-profile-title-container'>
          <p className="edit-profile-title">My Account</p>
        </div>

        <div className='edit-profile-block-container'>
          <div className={'flex-one'} />
          <div className={'edit-profile-inner-container'}>
            <EditUserProfile location={this.props.history.location} />
          </div>
          <div className={'edit-profile-sidemenu-container'}>

            <div>
              <p className={'edit-profile-sidemenu-label'}>Security</p>

              <Link to={'/existing-account?change-password=true'}>
                <p className={'edit-profile-sidemenu-button'}>
                  Change password
                </p>
              </Link>

              <Link to={'/existing-account?change-security=true'}>
                <p className={'edit-profile-sidemenu-button'}>
                  Change security section
              </p>
              </Link>

              <Link onClick={this.handleSignOut}>
                <p className={'edit-profile-sidemenu-button'}>
                  Sign Out
                </p>
              </Link>
            </div>

            <div className={'flex-one'} />

            <div>
              <p className={'edit-profile-sidemenu-label'}>
                Legal
              </p>
              <Link to={'/privacy-policy'}>
                <p className={'edit-profile-sidemenu-button'}>
                  Privacy Policy
                </p>
              </Link>
              <Link to={'/privacy-terms'}>
                <p className={'edit-profile-sidemenu-button'}>
                  Terms and conditions
                </p>
              </Link>
            </div>

          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout()),
});

export default connect(
  null,
  mapDispatchToProps
)(EditProfile);
