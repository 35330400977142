import React from 'react';

function HeaderText({ text, learningTitle }) {
  return (
    <div>
      <p className={learningTitle ? 'title-learning' : 'tittle'}>{text}</p>
    </div>
  );
}

export default HeaderText;
