import {
  GET_MY_EVENTS_REQUEST_SUCCESS,
  GET_EVENTS_REQUEST_SUCCESS,
  GET_SINGLE_EVENT_REQUEST_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  allEvents: [],
  myEvents: [],
  singleEvent: {},
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST_SUCCESS:
      return {
        ...state,
        allEvents: action.payload.upcomingEvents,
      };
    case GET_MY_EVENTS_REQUEST_SUCCESS:
      return {
        ...state,
        myEvents: action.payload.myEvents,
      };
    case GET_SINGLE_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        singleEvent: action.payload.event,
      };
    default:
      return state;
  }
};

export default categories;
