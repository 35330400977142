import React from 'react';

export default function EngageActiveIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="30px"
      height="30px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="💫-4-Engage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="4-Engage-Events" transform="translate(-99.000000, -816.000000)" fill={fill}>
          <g id="Nav" transform="translate(0.000000, 808.000000)">
            <g id="Icons-/-Engage" transform="translate(99.000000, 8.000000)">
              <g id="engage_active">
                <path
                  d="M25.3023931,16.2480621 L26.9341172,19.3575103 L23.5818414,20.4022 L23.8994966,23.8997862 L20.4019103,23.5816483 L19.3572207,26.9344069 L16.2482552,25.3022 L14.0000483,27.9998552 L11.7518414,25.3022 L8.6423931,26.9344069 L7.59818621,23.5816483 L4.1006,23.8997862 L4.41825517,20.4022 L1.06597931,19.3575103 L2.69770345,16.2480621 L4.82758621e-05,13.9998552 L2.69770345,11.752131 L1.06597931,8.64268276 L4.41825517,7.5979931 L4.1006,4.10088966 L7.59818621,4.41854483 L8.6423931,1.06578621 L11.7518414,2.6979931 L14.0000483,-0.000144827586 L16.2482552,2.6979931 L19.3572207,1.06578621 L20.4019103,4.41854483 L23.8994966,4.10088966 L23.5818414,7.5979931 L26.9341172,8.64268276 L25.3023931,11.752131 L28.0000483,13.9998552 L25.3023931,16.2480621 Z M14.0082552,7.91622759 L12.0690138,12.5516759 L7.72418621,12.5516759 L11.5862552,15.4482276 L9.65522069,20.2758138 L14.0082552,16.8631931 L18.3448759,20.2758138 L16.4138414,15.4482276 L20.2759103,12.5516759 L15.9310828,12.5516759 L14.0082552,7.91622759 Z"
                  id="Stroke-3"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
