import React from 'react';
import Checkbox from '../../Checkbox';
import './style.scss';

function CheckboxField({ value, label, fk, updateFieldData }) {
  const handleOnChange = () => updateFieldData && updateFieldData(fk, value ? !value : true, null);

  return (
    <div className="field-container">
      <label className="engage-checkbox-wrapper">
        <span className="engage-checkbox-label">{label}</span>
        <Checkbox.Controlled onChange={handleOnChange} checked={value} />
      </label>
    </div>
  );
}

export default CheckboxField;
