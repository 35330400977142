import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import Reactotron from 'reactotron-react-js';

import reactotronConfig from './reactotron/config';
import setupReactotron from './reactotron/setup';
import createRootReducer from './reducers';
import rootSaga from './sagas';

const configureStore = (initialState = {}, history) => {
  const __DEV__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const reactotronEnhancer = __DEV__ && setupReactotron(reactotronConfig);
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: reactotronEnhancer && Reactotron.createSagaMonitor(),
  });

  // Combine all middlewares into single enhancer
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  // Compose all enhancer into single function
  const enhancers = [middlewareEnhancer];
  if (reactotronEnhancer) enhancers.push(reactotronEnhancer);
  const composeFunction = process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;
  const composedEnhancers = composeFunction(...enhancers);

  // Create store with preloaded state and enhancers
  const store = createStore(createRootReducer(history), initialState, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
