import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import './styles.scss';
import { handleData } from '../../helpers';
import { getSingleEvent, eventGoing, eventNotGoing } from '../../../../actionCreators/events';
import { logFirebaseEvent } from '../../../../firebase/analytics';

import { dateDifferenceShorted } from '../../../../utils/helpers';
import ImageApi from '../../../../api/image';
import Participant from './Participant';
import ShowAllParticipantsModal from '../../../../components/ShowAllEventParticipantsModal';
import { Portal, RoundedSwitch } from '../../../../components';
import UserPlaceholder from '../../../../assets/images/user_placeholder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deletePostRequest,
  reportPostRequest,
  resetPostRequestSuccess,
} from '../../../../actionCreators/feedActions';
import ErrorModal from '../../../../components/ErrorModal';

import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Dropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import Linkify from 'react-linkify';

class EventItem extends Component {
  constructor (props) {
    super(props);

    this.state = {
      imageUrl: '',
      groupTitle: '',
      userAvatar: '',
      showAllParticipants: false,
      menuOpen: false,
      confirmModalOpen: false,
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: '',
      selectedReportingReason: null,
      reportPostModalOpen: false,
      reportPostDescription: '',
    };
  }

  componentDidMount () {
    // Get event image
    try {
      if (this.props.eventItem.post.image.url) {
        ImageApi.getImage(this.props.eventItem.post.image.url).then(imageUrl => {
          this.setState({ imageUrl: imageUrl });
        });
      }
    } catch (err) {}

    // Get author avatar
    try {
      if (this.props.author.image.thumbnailUrl) {
        ImageApi.getImage(this.props.author.image.thumbnailUrl).then(imageUrl => {
          this.setState({ userAvatar: imageUrl });
        });
      }
    } catch (err) {}
  }

  handleEvent = type => {
    if (type) {
      this.props.eventGoing(this.props.postId);
      logFirebaseEvent({
        event: 'events',
        action: 'going',
      });
    } else {
      this.props.eventNotGoing(this.props.postId);
      logFirebaseEvent({
        event: 'events',
        action: 'not_going',
      });
    }
  };

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  toggleDeleteConfirmModal = () => {
    this.toggleMenu();
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen });
  };

  deletePost = async () => {
    logFirebaseEvent({
      event:'delete_post'
    });
    await this.props.deletePost({ postId: this.props.postableId });
    this.props.history.goBack();
  };

  toggleReportPostModal = () => {
    this.resetPostReportModal();
    this.setState({ reportPostModalOpen: !this.state.reportPostModalOpen });
  };

  resetPostReportModal = () => {
    this.setState({
      reportPostModalOpen: false,
      selectedReportingReason: null,
      reportPostDescription: '',
    });
  };

  reportPost = async () => {
    // validating
    if (!this.state.selectedReportingReason) return;

    this.toggleReportPostModal();
    logFirebaseEvent({
      event:'report_post'
    })
    await this.props.reportPost({
      postId: this.props.postableId,
      reason_type: this.state.selectedReportingReason,
      reason_text: this.state.reportPostDescription,
    });
  };

  resetErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: '',
    });
  };

  notifyAuthenticationError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Authentication Error',
      errorModalMessage: 'It seems you are not authenticated. Please login again.',
    });
  };

  notifyPermissionError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Permission Error',
      errorModalMessage: 'It seems you have not the right permissions for this group or operation',
    });
  };

  notifyPostNotFound = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Post not found',
      errorModalMessage: 'It seems this post does not exist anymore. Please refresh your page.',
    });
  };

  notifyGenericError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Error',
      errorModalMessage: 'An error occurred. Please try again or contact Engage4 support.',
    });
  };
  // Render functions
  render () {
    const { eventItem, author, group } = this.props;
    const event = eventItem.post;

    const sameDay = event.end_time && moment(event.start_time).isSame(event.end_time, 'day');
    const startDate = moment(event.start_time);
    const endDate = moment(event.end_time);

    const currentTime = new Date();
    const endTime = event && new Date(event.end_time);
    const isPassed = event && event.end_time !== null ? currentTime - endTime : -1;

    return (
      <div className='feed event-single-main'>
        <Modal isOpen={this.state.confirmModalOpen} centered>
          <ModalHeader>
            <div>Confirm</div>
          </ModalHeader>
          <ModalBody>
            <div>Do you want to delete it?</div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.toggleDeleteConfirmModal}>
              Cancel
            </Button>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={() => this.deletePost()}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.reportPostModalOpen}
          centered
          style={{ maxWidth: '600px', width: '100%' }}
        >
          <ModalHeader toggle={this.toggleReportPostModal}>
            <div>Report Post</div>
          </ModalHeader>
          <ModalBody>
            <UncontrolledDropdown>
              <DropdownToggle
                data-toggle='dropdown'
                outline
                style={{ width: '100%', textAlign: 'left' }}
                color={'secondary'}
              >
                <div>
                  {this.state.selectedReportingReason
                    ? this.props.postReportTypes.find(
                        postReportType => postReportType.id === this.state.selectedReportingReason,
                      ).report_type_text
                    : 'Select reason'}
                </div>
              </DropdownToggle>
              <DropdownMenu>
                {this.props.postReportTypes &&
                  this.props.postReportTypes.map(postReportType => {
                    return (
                      <DropdownItem
                        onClick={() =>
                          this.setState({ selectedReportingReason: postReportType.id })
                        }
                      >
                        {postReportType.report_type_text}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </UncontrolledDropdown>
            <div style={{ width: '100%', height: 20 }} />
            <Input
              type='textarea'
              name='text'
              id='description'
              placeholder={'Description (optional)'}
              maxLength={240}
              onChange={e => this.setState({ reportPostDescription: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={this.reportPost}>
              SUBMIT REPORT
            </Button>
          </ModalFooter>
        </Modal>
        <ErrorModal
          isOpen={this.state.isErrorModalOpen}
          title={this.state.errorModalTitle}
          message={this.state.errorModalMessage}
          onClose={this.resetErrorModal}
        />
        <div className='event-single-container'>
          <div className='main-event-container'>
            {event.image_id && (
              <div className='img-title'>
                <img
                  className='img-title event-bottom-cover'
                  src={this.state.imageUrl}
                  alt='event'
                />
              </div>
            )}
            <div className='event-avatar-section d-flex justify-content-between'>
              <div className='d-flex flex-fill justify-content-between'>
                <div className='d-flex'>
                  {this.state.userAvatar ? (
                    <img className='event-bottom-cover' src={this.state.userAvatar} alt='' />
                  ) : (
                    <img className='event-bottom-cover' src={UserPlaceholder} alt='' />
                  )}
                  <h3 className='title'>
                    <Link to={`/users/${author.id}`} className='title__link'>
                      {author.first_name} {author.last_name}
                    </Link>

                    <span className='font-weight-light ml-1 mr-1'>in</span>

                    <Link to={`/group-feed/${group.id}`} className='title__link'>
                      {group.name}
                    </Link>

                    <br />
                    <span className='subtitle'>
                      {event && dateDifferenceShorted(event.created_at)}
                    </span>
                  </h3>
                </div>
                <div>
                  <Dropdown isOpen={this.state.menuOpen} direction='left' toggle={this.toggleMenu}>
                    <DropdownToggle
                      tag='span'
                      data-toggle='dropdown'
                      aria-expanded={this.state.menuOpen}
                    >
                      <FontAwesomeIcon
                        className='feed-item-header-collapse'
                        icon={['fas', 'chevron-down']}
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      {(this.props.currentUser.userData.is_admin ||
                        !!group.is_moderator ||
                        this.props.currentUser.userData.id === author.id) && (
                        <DropdownItem onClick={this.toggleDeleteConfirmModal}>Delete</DropdownItem>
                      )}
                      <DropdownItem onClick={this.toggleReportPostModal}>Report Post</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className='event-single-calendar d-flex'>
              <div className='calendar'>
                <div className='event-container'>
                  <span className='month'>{event && handleData(event.start_time).month}</span>{' '}
                  <br />
                  <span className='number'>{event && handleData(event.start_time).day}</span>
                </div>
              </div>
              <h3 className='event-single-title'>{event && event.title}</h3>
            </div>

            <div className='event-single-information'>
              <div className='date d-flex align-items-center'>
                {startDate.format('HH:mma')}
                {!sameDay && (
                  <>
                    <div className='oval' />
                    {startDate.format('dddd')}
                  </>
                )}

                {event.end_time && (
                  <>
                    {' - '}
                    {endDate.format('HH:mma')}
                    <div className='oval' />
                    {endDate.format('dddd')}
                  </>
                )}
              </div>

              <p className='location'>{event && event.location}</p>
              <p className='description'>
                <Linkify
                  componentDecorator={(href, text, key) => (
                    <a href={href} key={key} target={'_blank'}>
                      {text}
                    </a>
                  )}
                >
                  {event && event.description}
                </Linkify>
              </p>
            </div>
            {/* <div className="event-single-points">
              <div className="amount">25</div>
              <p className="title">Athletic Event</p>
            </div> */}
            {event && event.participants < 1 ? null : (
              <div className='event-single-attending d-flex align-items-center justify-content-end'>
                <Link
                  to={{ search: '?type=event&listType=event&showUsers=true' }}
                  className='subtitle'
                >
                  {event && event.participants.length} Attending
                </Link>
                {event &&
                  event.participants.slice(0, 3).map(participant => {
                    return (
                      <Participant key={participant.user_id} participant={participant.user_id} />
                    );
                  })}
                {event && event.participants.length > 3 && (
                  <div
                    onClick={() => {
                      this.setState({ showAllParticipants: true });
                    }}
                    className='rest-users cursor-pointer'
                  >
                    +{event && event.participants.length - 3}
                  </div>
                )}
                <Portal
                  hide={() => this.setState({ showAllParticipants: false })}
                  isOpen={this.state.showAllParticipants}
                >
                  <ShowAllParticipantsModal
                    participants={event.participants}
                    hide={() => this.setState({ showAllParticipants: false })}
                    title={`${event.participants.length} Attending`}
                  />
                </Portal>
              </div>
            )}
            <div className='divider' />
            {isPassed < 0 ? (
              <div className='event-visiting-container d-flex justify-content-end'>
                <RoundedSwitch
                  updateChecked={this.handleEvent}
                  checked={event.is_going}
                  textOff="Can't go"
                  textOn='Going'
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    postReportTypes: state.feed.postReportTypes,
    reportPostRequestSuccessful: state.feed.reportPostRequestSuccessful,
    currentUser: state.currentUser,
  }),
  dispatch => ({
    handleEvent: payload => dispatch(getSingleEvent(payload)),
    eventGoing: payload => dispatch(eventGoing(payload)),
    eventNotGoing: payload => dispatch(eventNotGoing(payload)),
    deletePost: payload => dispatch(deletePostRequest(payload)),
    reportPost: payload => dispatch(reportPostRequest(payload)),
    resetPostRequestSuccess: () => dispatch(resetPostRequestSuccess()),
  }),
)(EventItem);
