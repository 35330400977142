import React, { PureComponent } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Avatar, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import lodash from 'lodash';
import {
  showCreateGroupModal,
} from '../../actionCreators/groupActions';

import ImageApi from '../../api/image';
import { Portal } from '../index';
import CrossIcon from '../Icons/CrossIcon';
import GroupPlaceholder from '../../assets/images/group_placeholder.svg';
import { logFirebaseEvent } from "../../firebase/analytics";

const BACK_TEXT = {
  '/': 'Back to organization feed',
  '/search': 'Back to Search',
};

class FeedGroupHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
      showConfirmLeave: false,
      showCantLeave: false,
      showLastModerator: false
    };
  }

  componentDidMount() {
    if (this.props.group && this.props.group.image && this.props.group.image.url) {
      this.getImage(this.props.group.image.url);
    }
    logFirebaseEvent({
      event: 'group',
      action: 'feed'
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.group && nextProps.group.image && nextProps.group.image.url
    ) {
      this.getImage(nextProps.group.image.url);
    }
  }

  getImage(imageUrl) {
    ImageApi.getImage(imageUrl).then(imageUrl => {
      this.setState({ imageUrl: imageUrl });
    });
  }

  _onToggleJoinClick = () => {
    if ( this.props.group.is_join && this.props.group.is_moderator && this.props.group.moderator_count == 1){
      return this.setState({ showLastModerator: true})
    }
    if (this.props.group.is_join && this.props.group.is_org_level)
      return this.setState({ showCantLeave: true });
    if (this.props.group.is_join) return this.setState({ showConfirmLeave: true });

    this._toggleJoinGroup();
  };

  _toggleJoinGroup = () => {
    logFirebaseEvent({
      event: 'group',
      action: !this.props.group.is_join ? 'Join' : 'leave'
    })
    this.props.toggleJoinGroup({ groupId: this.props.group.id, join: !this.props.group.is_join });
    this._closeModal();
  };

  _closeModal = () => {
    this.setState({ showConfirmLeave: false, showCantLeave: false, showLastModerator: false });
  };

  render() {
    const { group } = this.props;
    return (
      <>
        <div className="feed-group-header">
          <div className="feed-group-header-content">{this.renderContent()}</div>

          <Portal hide={this._closeModal} isOpen={this.state.showConfirmLeave}>
            <div className="modal-content">
              <div className="modal-header bg-white">
                <h5 className="modal-title text-center w-100">Are you sure?</h5>

                <div className="close" onClick={this._closeModal}>
                  <CrossIcon className="modal-close" />
                </div>
              </div>

              <div className="feed-group-header-modal-body">
                <div className="feed-group-header-modal-button" onClick={this._toggleJoinGroup}>
                  Yes
              </div>
                <div className="feed-group-header-modal-button" onClick={this._closeModal}>
                  No
              </div>
              </div>
            </div>
          </Portal>

          <Portal hide={this._closeModal} isOpen={this.state.showCantLeave} minWidth="400px">
            <div className="modal-content">
              <div className="modal-header bg-white">
                <div className="d-flex flex-column align-items-center w-100">
                  <h5 className="modal-title text-center w-100">Sorry</h5>
                  <h6 className="modal-sub-title text-center w-100">
                    You can't leave organization level group
                </h6>
                </div>

                <div className="close" onClick={this._closeModal}>
                  <CrossIcon className="modal-close" />
                </div>
              </div>

              <div className="feed-group-header-modal-body justify-content-center">
                <div className="feed-group-header-modal-button" onClick={this._closeModal}>
                  Okay
                </div>
              </div>
            </div>
          </Portal>

          <Portal hide={this._closeModal} isOpen={this.state.showLastModerator} minWidth="400px">
            <div className="modal-content">
              <div className="modal-header bg-white">
                <div className="d-flex flex-column align-items-center w-100">
                  <h5 className="modal-title text-center w-100">Sorry</h5>
                  <h6 className="modal-sub-title text-center w-100">
                    A group must have at least one moderator. You cannot leave before assigning another moderator.
                </h6>
                </div>

                <div className="close" onClick={this._closeModal}>
                  <CrossIcon className="modal-close" />
                </div>
              </div>

              <div className="feed-group-header-modal-body justify-content-center">
                <div className="feed-group-header-modal-button" onClick={this._closeModal}>
                  Okay
                </div>
              </div>
            </div>
          </Portal>
        </div>
      </>
    );
  }

  renderContent() {
    const { group, categories, prevRoute } = this.props;

    if (!group) return <Skeleton className="feed-group-header-skeleton" />;

    const category = lodash.find(categories, { id: group.category_id }) || {};

    return (
      <>
        <div className="feed-group-header-back-link" onClick={this.props.history.goBack}>
          <FontAwesomeIcon
            icon={['fas', 'chevron-left']}
            className="feed-group-header-back-link-icon"
          />
          {BACK_TEXT[prevRoute] || 'Go back'}
        </div>

        <div className="d-flex align-items-center mb-2">
          {this.state.imageUrl ? (
            <Avatar
              shape="square"
              className="feed-group-header-image"
              src={this.state.imageUrl}
              size={100}
            />
          ) : (
              <div className="feed-group-header-image feed-group-header-image__default">
                <img src={GroupPlaceholder} width="80" alt="" />
              </div>
            )}

          <div className="d-flex justify-content-end flex-wrap flex-fill">
            <div className="d-flex flex-column flex-fill">
              <span className="feed-group-header-title">{group.name}</span>

              <div className="d-flex flex-wrap align-items-center">
                {!group.is_org_level && category.category_name && (
                  <>
                    <span className="feed-group-header-category">{category.category_name}</span>
                    <span className="feed-item-footer-splitter grey" />
                  </>
                )}

                <Link
                  to={{ search: '?listType=group&showUsers=true' }}
                  className="feed-group-header-amount"
                >
                  {group.total_users} member{group.total_users > 1 ? 's' : ''}
                </Link>
              </div>
            </div>

            {group.is_requested ? (
              <div className="feed-group-header-buttons-container">
                <div className={`feed-group-header-button disabled-b`}>{'Request sent'}</div>
              </div>
            ) : (
              <div className="feed-group-header-buttons-container">
                <div
                  className={`feed-group-header-button ${
                    group.is_mandatory || (group.is_join && group.is_org_level) ? 'disabled-b' : ''
                  }`}
                  onClick={!group.is_mandatory ? this._onToggleJoinClick : null}
                >
                  { group.is_join ? 'Leave group' : 'Join group'}
                </div>
                {group.is_moderator && (
                  <NavLink
                    key={`/manage-group/${group.id}`}
                    to={`/manage-group/${group.id}`}
                    className={`feed-group-header-button`}
                  >
                    {'Manage group'}
                  </NavLink>
                )}
              </div>
            )}
          </div>
        </div>
        <span className="feed-group-header-desc">{group.description}</span>
      </>
    );
  }
}

export default connect(
  state => ({
    categories: state.categories,
    // groupDetails: state.group.getGroupRequest,
    prevRoute: state.routerHistory.prevPathname,
    showNewGroupModal: state.group.showCreateGroupModal,
  }),
  dispatch => ({
    // groupDetails: payload => dispatch(getGroupRequest(payload)),
    showCreateGroupModal: payload => dispatch(showCreateGroupModal(payload)),
  })
)(FeedGroupHeader);
