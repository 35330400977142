import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { removeAlertFromAlertsList } from '../../actionCreators/globalAlerts';
import GlobalAlertsComponent from '../../components/global/GlobalAlerts';
import { navigateToPasswordMigrationScren } from '../../actionCreators/account';
import { clearStorageOnLogout } from '../../api/helpers';
import { logout } from '../../actionCreators/auth';

const GlobalAlerts = props => <GlobalAlertsComponent {...props} />;

const mapStateToProps = state => ({
  globalAlerts: state.globalAlerts,
});

const mapDispatchToProps = dispatch => ({
  removeAlertFromAlertsList: id => dispatch(removeAlertFromAlertsList(id)),
  navigateToPasswordMigrationScren: () => dispatch(navigateToPasswordMigrationScren()),
  onTokenExpired: () => {
    clearStorageOnLogout();
    dispatch(logout());
    push('/auth');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlerts);
