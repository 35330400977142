import React from 'react';
import { connect } from 'react-redux';
import GlobalPreloaderComponent from '../../components/global/GlobalPreloaders';

const GlobalPreloaders = props => <GlobalPreloaderComponent {...props} />;

const mapStateToProps = state => ({
  globalPreloaders: state.globalPreloaders,
});

export default connect(mapStateToProps)(GlobalPreloaders);
