import React from 'react';
import './styles.scss';
import CrossIcon from '../Icons/CrossIcon';
import Participant from './SingleParticipant';

function PeopleSearchResults(props) {
  const { title, hide, participants } = props;

  return (
    <div className="container mt-2 main-container">
      <div className="row upper-container">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={hide}>
          <CrossIcon className="modal-close" />
        </button>
      </div>
      <div className="row d-flex justify-content-between">
        {participants.map(participant => {
          return <Participant key={participant.user_id} participant={participant.user_id} />;
        })}
      </div>
    </div>
  );
}

export default PeopleSearchResults;
