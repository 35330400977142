import {
  GET_GROUP_REQUEST,
  GET_GROUPS_REQUEST,
  GET_MY_GROUPS_REQUEST,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  TOGGLE_JOIN_GROUP_REQUEST,
  VERIFY_GROUP_REQUEST,
  VERIFY_GROUP_SUCCESS,
  SHOW_CREATE_GROUP_MODAL,
  GET_GROUP_PRIVACY_TYPES_REQUEST,
  GET_GROUP_PRIVACY_TYPES_SUCCESS,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  ADD_GROUP_MEMBER_REQUEST,
  ADD_GROUP_MEMBER_SUCCESS,
  POST_NEW_MEMBER_REQUEST,
  POST_NEW_MEMBER_SUCCESS,
} from '../constants/actionTypes';

export const getGroups = () => ({
  type: GET_GROUPS_REQUEST,
});

export const getMyGroupsRequest = () => ({
  type: GET_MY_GROUPS_REQUEST,
});

export const createGroupRequest = payload => ({
  type: CREATE_GROUP_REQUEST,
  payload,
});

export const verifyGroupRequest = payload => ({
  type: VERIFY_GROUP_REQUEST,
  payload,
});

export const createGroupSuccess = data => ({
  type: CREATE_GROUP_SUCCESS,
  data,
});

export const verifyGroupSuccess = payload => ({
  type: VERIFY_GROUP_SUCCESS,
  payload,
});

export const showCreateGroupModal = payload => ({
  type: SHOW_CREATE_GROUP_MODAL,
  payload,
});

export const getGroupRequest = payload => ({
  type: GET_GROUP_REQUEST,
  payload,
});

export const toggleJoinGroupRequest = payload => ({
  type: TOGGLE_JOIN_GROUP_REQUEST,
  payload,
});

export const getGroupPrivacyTypesRequest = () => ({
  type: GET_GROUP_PRIVACY_TYPES_REQUEST,
});

export const getGroupPrivacyTypesSuccess = payload => ({
  type: GET_GROUP_PRIVACY_TYPES_SUCCESS,
  payload,
});

export const updateGroupRequest = payload => ({
  type: UPDATE_GROUP_REQUEST,
  payload,
});

export const updateGroupSuccess = payload => ({
  type: UPDATE_GROUP_SUCCESS,
  payload,
});

export const addGroupMemberRequest = payload => ({
  type: ADD_GROUP_MEMBER_REQUEST,
  payload
})

export const addGroupMemberSuccess = payload => ({
  type: ADD_GROUP_MEMBER_SUCCESS,
  payload
})

export const postNewMemberRequest = payload => ({
  type: POST_NEW_MEMBER_REQUEST,
  payload,
});

export const postNewMemberSuccess = payload => ({
  type: POST_NEW_MEMBER_SUCCESS,
  payload,
});
