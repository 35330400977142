import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../../Feed/Sidebar/Sidebar';
import NotificationsSidebar from '../../Feed/NotificationsSidebar/NotificationsSidebar';

//HOC for react-bootstrap container so that maxWidth only has to be set once

export default class GridContainer extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <Container fluid style={{ maxWidth: '1440px' }}>
        <Row>
          <Col xs={2} sm={2} md={3} lg={3} xl={3}>
            <Sidebar />
          </Col>
          <Col xs={8} sm={8} md={6} lg={6} xl={6}>
            {this.props.children}
          </Col>
          <Col xs={2} sm={2} md={3} lg={3} xl={3}>
            {!this.props.hideNotifications && <NotificationsSidebar />}
          </Col>
        </Row>
      </Container>
    );
  }
}
