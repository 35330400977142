export const handleData = dataObject => {
  const data = new Date(dataObject).toDateString();
  const hoursData = new Date(dataObject).toString();
  const hoursPmAm = new Date(dataObject);
  const fullDay = new Date(dataObject).toLocaleString('en-us', { weekday: 'long' });

  const amPm = hoursPmAm.getHours() >= 12 ? 'pm' : 'am';
  const day = data.slice(8, 10);
  const month = data.slice(4, 7).toUpperCase();
  const time = hoursData.slice(16, 21);

  return {
    amPm,
    day,
    month,
    time,
    fullDay,
  };
};
