import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import validate from '../../../utils/validate';
import './style.scss';

function CustomSelect({
  options,
  defaultValue,
  value,
  placeholder,
  onChange,
  className,
  iconClassName,
  ...otherProps
}) {
  const selectRef = useRef();
  const [active, setActive] = useState(false);
  const [trackedValue, setTrackedValue] = useState(value && defaultValue);

  const arrowClassName = `engage-select-icon ${iconClassName}`;
  const labelClassName = `engage-select-label ${active || trackedValue ? 'active' : ''}`;
  const selectClassName = `engage-select ${className}`;

  const icon = <FontAwesomeIcon className={arrowClassName} icon={['fas', 'chevron-down']} />;

  const handleChange = value => {
    setTrackedValue(value);
    onChange && onChange(value);
  };

  const handleClickLabel = () => {
    if (!active) {
      selectRef.current.focus();
    }
  };

  useEffect(() => {
    setTrackedValue(value);
  }, [value]);

  return (
    <div className="field-container">
      <div className="engage-select-wrapper">
        <span className={labelClassName} onClick={handleClickLabel}>
          {placeholder}
        </span>
        <Select
          ref={selectRef}
          className={selectClassName}
          suffixIcon={icon}
          showAction={['focus', 'click']}
          onDropdownVisibleChange={setActive}
          onChange={handleChange}
          defaultValue={defaultValue}
          value={trackedValue}
          {...otherProps}
        >
          {options.map(({ value, displayName, ...otherProps }) => (
            <Select.Option value={value} {...otherProps}>
              <div className="engage-select-dropdown-item">{displayName}</div>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default function SelectField(props) {
  const {
    error,
    value,
    validateObj = {},
    validateRule,
    updateFieldData,
    fk,
    triggerOnChange,
    inputType,
    showError,
    ...otherProps
  } = props;

  const [formValue, setFormValue] = useState(value);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    let initialError;
    if (!error) {
      initialError = getErrorMessage(validateObj);
    }
    updateFieldData(fk, value, initialError);
    setErrorText(initialError);
  }, []);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  const getErrorMessage = obj => {
    const errors = validate(obj);
    return errors ? errors[0] : null;
  };

  const handleChange = value => {
    const obj = { [validateRule]: value };
    const errorText = getErrorMessage(obj);
    setFormValue(value);
    setErrorText(errorText);
    updateFieldData(fk, value, errorText);
  };

  const hasError = showError && errorText;

  const errorClassName = `${hasError ? 'has-error' : ''}`;

  return (
    <div>
      <CustomSelect
        className={errorClassName}
        iconClassName={errorClassName}
        value={formValue}
        onChange={handleChange}
        {...otherProps}
      />
      {hasError && (
        <div className="field-container__error-wrapper">
          <p className="field-container__error m-0">{errorText}</p>
        </div>
      )}
    </div>
  );
}

CustomSelect.defaultProps = {
  options: [],
};
