import React from 'react';
import { connect } from 'react-redux';

import {} from '../../actionCreators/auth';
import HeaderComponent from '../../components/global/Header/Header';

const Header = props => <HeaderComponent {...props} />;

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  customAppId : state.customApp.id
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
