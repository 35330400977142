import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackLabel = ({ text, onClick }) => (
  <React.Fragment>
    <div onClick={onClick}>
      <FontAwesomeIcon className="mr-2" icon={['fas', 'chevron-left']} />
      {text}
    </div>
  </React.Fragment>
);

export default BackLabel;
