import React, { PureComponent } from 'react';
import { Avatar } from 'antd';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { Link } from 'react-router-dom';

import { dateDifferenceShorted } from '../../../utils/helpers';
import ImageApi from '../../../api/image';
import highfiveBg from '../../../assets/images/desktop_high-five.png';
import UserPlaceholder from '../../../assets/images/user_placeholder.svg';
import HighFive from '../../../assets/images/highFive.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedApi from '../../../api/feed';
import ErrorModal from '../../ErrorModal';

import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
} from 'reactstrap';

import {
  deletePostRequest,
  reportPostRequest,
  pinPostRequest,
  setPinRetry,
} from '../../../actionCreators/feedActions';
import { logFirebaseEvent } from '../../../firebase/analytics';

class FeedHighFiveItem extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      authorImageUrl: '',
      recipientImageUrl: '',
      menuOpen: false,
      confirmModalOpen: false,
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: '',
      selectedReportingReason: null,
      reportPostModalOpen: false,
      reportPostDescription: '',
    };
  }

  componentDidMount () {
    if (this.props.author.image.thumbnailUrl) {
      ImageApi.getImage(this.props.author.image.thumbnailUrl).then(imageUrl => {
        this.setState({ authorImageUrl: imageUrl });
      });
    }

    if (this.props.recipient.image.thumbnailUrl) {
      ImageApi.getImage(this.props.recipient.image.thumbnailUrl).then(imageUrl => {
        this.setState({ recipientImageUrl: imageUrl });
      });
    }
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  toggleDeleteConfirmModal = () => {
    this.toggleMenu();
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen });
  };

  deletePost = async () => {
    logFirebaseEvent({
      event: 'delete_post',
    });
    await this.props.deletePost({ postId: this.props.item.id });
  };

  pinPost = async (replace = false) => {
    logFirebaseEvent({
      event: 'pin_post',
    });

    const { setPinRetryRequest, pinRetry, item, pinPost } = this.props;
    const { id, group_id, is_pinned, groupId } = item;
    console.log('item', item);

    await pinPost({
      postId: pinRetry.id || id,
      pinned_post: pinRetry.is_pinned || !is_pinned,
      groupId: pinRetry.group_id || group_id,
      replacePost: replace,
    });

    replace && setPinRetryRequest({});
  };

  resetErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: '',
    });
  };

  notifyAuthenticationError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Authentication Error',
      errorModalMessage: 'It seems you are not authenticated. Please login again.',
    });
  };

  notifyPermissionError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Permission Error',
      errorModalMessage: 'It seems you have not the right permissions for this group or operation',
    });
  };

  notifyPostNotFound = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Post not found',
      errorModalMessage: 'It seems this post does not exist anymore. Please refresh your page.',
    });
  };

  notifyGenericError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Error',
      errorModalMessage: 'An error occurred. Please try again or contact Engage4 support.',
    });
  };

  toggleReportPostModal = () => {
    this.resetPostReportModal();
    this.setState({ reportPostModalOpen: !this.state.reportPostModalOpen });
  };

  resetPostReportModal = () => {
    this.setState({
      reportPostModalOpen: false,
      selectedReportingReason: null,
      reportPostDescription: '',
    });
  };

  reportPost = async () => {
    // validating
    if (!this.state.selectedReportingReason) return;

    this.toggleReportPostModal();
    logFirebaseEvent({
      event: 'report_post',
    });
    await this.props.reportPost({
      postId: this.props.item.id,
      reason_type: this.state.selectedReportingReason,
      reason_text: this.state.reportPostDescription,
    });
  };

  render () {
    const { item, author, recipient, group = {}, pinRetry, currentUser, orgGroup } = this.props;

    const authorName = `${author.first_name} ${author.last_name.charAt(0).toUpperCase()}.`;
    const recipientName = `${recipient.first_name} ${recipient.last_name.charAt(0).toUpperCase()}.`;

    return (
      <>
        <Modal isOpen={this.state.confirmModalOpen} centered>
          <ModalHeader>
            <div>Confirm</div>
          </ModalHeader>
          <ModalBody>
            <div>Do you want to delete it?</div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.toggleDeleteConfirmModal}>
              Cancel
            </Button>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={() => this.deletePost()}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={pinRetry && pinRetry.postId} centered>
          <ModalHeader>
            <div>Maximum pinned posts</div>
          </ModalHeader>
          <ModalBody>
            <div>
              It looks like you already have 3 pinned posts, would you like us to unpin the oldest
              item and pin this one?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={() => this.props.setPinRetryRequest({})}>
              No
            </Button>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={() => this.pinPost(true)}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.reportPostModalOpen}
          centered
          style={{ maxWidth: '600px', width: '100%' }}
        >
          <ModalHeader toggle={this.toggleReportPostModal}>
            <div>Report Post</div>
          </ModalHeader>
          <ModalBody>
            <UncontrolledDropdown>
              <DropdownToggle
                data-toggle='dropdown'
                outline
                style={{ width: '100%', textAlign: 'left' }}
                color={'secondary'}
              >
                <div>
                  {this.state.selectedReportingReason
                    ? this.props.postReportTypes.find(
                        postReportType => postReportType.id === this.state.selectedReportingReason,
                      ).report_type_text
                    : 'Select reason'}
                </div>
              </DropdownToggle>
              <DropdownMenu>
                {this.props.postReportTypes &&
                  this.props.postReportTypes.map(postReportType => {
                    return (
                      <DropdownItem
                        onClick={() =>
                          this.setState({ selectedReportingReason: postReportType.id })
                        }
                      >
                        {postReportType.report_type_text}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </UncontrolledDropdown>
            <div style={{ width: '100%', height: 20 }} />
            <Input
              type='textarea'
              name='text'
              id='description'
              placeholder={'Description (optional)'}
              maxLength={240}
              onChange={e => this.setState({ reportPostDescription: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={this.reportPost}>
              SUBMIT REPORT
            </Button>
          </ModalFooter>
        </Modal>

        <ErrorModal
          isOpen={this.state.isErrorModalOpen}
          title={this.state.errorModalTitle}
          message={this.state.errorModalMessage}
          onClose={this.resetErrorModal}
        />

        <div className='feed-item-highfive-header'>
          <img className='feed-item-highfive-bg-image' src={highfiveBg} alt='' />

          <div className='feed-item-highfive-header-text'>
            <Link to={`/group-feed/${group.id}`}>{group.name}</Link>
            <span className='feed-item-highfive-header-text-small'>
              {dateDifferenceShorted(item.created_at)}
            </span>
          </div>

          <div style={styles.menuDropdown}>
            <Dropdown isOpen={this.state.menuOpen} direction='left' toggle={this.toggleMenu}>
              <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={this.state.menuOpen}>
                <FontAwesomeIcon
                  className='feed-item-header-collapse'
                  icon={['fas', 'chevron-down']}
                />
              </DropdownToggle>
              <DropdownMenu>
                {(group.is_moderator || currentUser.userData.is_admin) && (
                  <DropdownItem onClick={this.toggleDeleteConfirmModal}>Delete</DropdownItem>
                )}
                <DropdownItem onClick={() => this.pinPost()}>
                  {this.props.item.is_pinned
                    ? 'Unpin Post'
                    : currentUser.userData.is_admin && orgGroup && orgGroup.id === group.id
                    ? 'Pin Post to Group and Main Feed'
                    : 'Pin Post to Group'}
                </DropdownItem>
                <DropdownItem onClick={this.toggleReportPostModal}>Report Post</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <Link to={`/users/${author.id}`}>
            <Avatar
              className='feed-item-highfive-header-image left-image'
              shape='square'
              src={this.state.authorImageUrl || UserPlaceholder}
              icon='user'
              size={90}
            />
          </Link>

          <Link to={`/users/${recipient.id}`}>
            <Avatar
              className='feed-item-highfive-header-image right-image'
              shape='square'
              src={this.state.recipientImageUrl || UserPlaceholder}
              icon='user'
              size={90}
            />
          </Link>

          <img className='feed-item-highfive-hands-image' src={HighFive} alt='' />
        </div>

        <div className='feed-item-content'>
          <div className='feed-item-highfive-user-info'>
            <Link to={`/users/${author.id}`} className='feed-item-highfive-user'>
              {authorName}
            </Link>
            to
            <Link to={`/users/${recipient.id}`} className='feed-item-highfive-user'>
              {recipientName}
            </Link>
          </div>

          <p className='feed-item-highfive-title'>{item.post.highfive_type_text}</p>
          <p className='feed-item-highfive-text'>{item.post.reason}</p>
        </div>
      </>
    );
  }
}

export default connect(
  (state, props) => ({
    group: lodash.find(state.group.myGroups, { id: props.item.group_id }),
    orgGroup: lodash.find(state.group.myGroups, { is_org_level: 1 }),
    postReportTypes: state.feed.postReportTypes,
    currentUser: state.currentUser,
    pinRetry: state.feed.pinRetry,
  }),
  dispatch => ({
    deletePost: payload => dispatch(deletePostRequest(payload)),
    reportPost: payload => dispatch(reportPostRequest(payload)),
    pinPost: payload => dispatch(pinPostRequest(payload)),
    setPinRetryRequest: payload => dispatch(setPinRetry(payload)),
  }),
)(FeedHighFiveItem);

const styles = {
  menuDropdown: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    padding: 16,
    width: '100%',
    justifyContent: 'flex-end',
  },
};
