import React, { useEffect } from 'react';
import { GroupsSearchResults, SearchInput } from '../index';
import find from 'lodash/find'

function SearchGroupsModal(props) {
  const { hide, searchGroups, token, searchResults, myGroupsOnly, myGroups } = props;

  useEffect(() => {
    handleSearchPeople('');
  }, []);

  function handleSearchPeople(value) {
    searchGroups(value, token.key);
  }

  const groups = (myGroupsOnly === false) ? (
    props.searchResults.groups
  ) : (
    props.searchResults.groups.filter(group => {
      if (find(myGroups, { id: group ? group.id : '' })) {
        return true
      } else {
        return false
      }
    })
  )

  return (
    <div className='find-groups modal-content modal-lg' style={{ width: '650px' }}>
      <div className='modal-header bg-white pb-0'>
        <h5 className='modal-title-search font-weight-semibold'>{myGroupsOnly ? 'My groups' : 'Find groups'}</h5>
        <button type='button' className='close' onClick={hide}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body pt-0 find-groups-modal-body'>
        <SearchInput submit={handleSearchPeople} />

        <div className='overflow-auto max-vh-60'>
          {searchResults.groups.length > 0 && (
            <GroupsSearchResults
              showHeading={true}
              data={groups}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchGroupsModal;
