import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import moment from 'moment';
import { getHeaders, setCookieValForKey } from './helpers';

export default class AccountApi {
  static validateDOB (data) {
    const requestBody = {
      date_of_birth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
      username: data.username,
    };
    const request = new Request(`${apiUrl}/content/account/verify_date_of_birth`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    return fetchRequest(request);
  }
  static validateSecurity (data) {
    const requestBody = {
      username: data.username,
      security_question_id: data.securityQuestionId,
      security_answer: data.securityAnswer,
    };
    const request = new Request(`${apiUrl}/content/account/validate_security_details`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    return fetchRequest(request);
  }
  static resetPassword (data) {
    const requestBody = {
      username: data.username,
      security_question_id: parseInt(data.securityQuestionId),
      security_answer: data.securityAnswer,
      password: data.password,
    };
    const request = new Request(`${apiUrl}/content/account/reset_password`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    return fetchRequest(request).then(data => {
      if (data) 
      setCookieValForKey('token',data);
      return data;
    });
  }
  static getSecurityQuestions () {
    const request = new Request(`${apiUrl}/account/security_questions`, {
      method: 'GET',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
    });
    return fetchRequest(request);
  }

  static setPassword (data) {
    const requestBody = {
      date_of_birth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
      username: data.username,
      security_question_id: data.securityQuestionId,
      security_answer: data.securityAnswer,
      password: data.password,
    };
    const request = new Request(`${apiUrl}/content/account/create_password`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    return fetchRequest(request).then(data => {
      if (data) 
      setCookieValForKey('token',data);
      return data;
    });
  }
  static getPasswordMigrationStatus () {
    const request = new Request(`${apiUrl}/content/account/password_migration`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
  
  static migrateToPassword (data) {
    const requestBody = {
      date_of_birth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
      security_question_id: parseInt(data.securityQuestionId),
      security_answer: data.securityAnswer,
      password: data.password,
    };
    const request = new Request(`${apiUrl}/content/account/password_migration`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestBody),
    });

    return fetchRequest(request);
  }
  static changePassword (data) {
    const { oldPassword, password, username } = data;
    const requestBody = {
      old_password: oldPassword,
      password,
      username,
    };
    const request = new Request(`${apiUrl}/content/account/change_password`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(requestBody),
    });

    return fetchRequest(request);
  }

  static getUserSecurityDetails () {
    const request = new Request(`${apiUrl}/content/account/security_details`, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }

  static changeSecurity (data) {
    const { username, securityAnswer, securityQuestionId, newSecurityAnswer, newSecurityQuestionId } = data;
    const requestBody = {
      security_answer: securityAnswer,
      security_question_id: securityQuestionId,
      new_security_question_id: newSecurityQuestionId,
      new_security_answer: newSecurityAnswer,
      username
    };
    const request = new Request(`${apiUrl}/content/account/change_security`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(requestBody),
    });

    return fetchRequest(request);
  }
}
