import React from 'react';
import { connect } from 'react-redux';

import AccountComponent from '../../components/Account';
import {
  setPasswordRequest,
  validateDOBRequest,
  resetPasswordRequest,
  migrateToPasswordRequest,
  changePasswordRequest,
  changeSecurityRequest
} from '../../actionCreators/account';

const Account = props => <AccountComponent {...props} />;

const mapStateToProps = state => ({
  account: state.account,
  currentUser: state.currentUser,
  customAppId: state.customApp.id
});

const mapDispatchToProps = dispatch => ({
  setPassword: payload => dispatch(setPasswordRequest(payload)),
  changePassword: payload => dispatch(changePasswordRequest(payload)),
  validateDOB: payload => dispatch(validateDOBRequest(payload)),
  resetPassword: payload => dispatch(resetPasswordRequest(payload)),
  migrateToPassword: payload => dispatch(migrateToPasswordRequest(payload)),
  changeSecurity: payload => dispatch(changeSecurityRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
