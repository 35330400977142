import React, { Component } from 'react';
import moment from 'moment';

import TextField from '../../global/fields/TextField';
import AddIcon from '../../Icons/AddIcon';
import CrossWithBgIcon from '../../Icons/CrossWitBgIcon';
import EventIcon from '../../Icons/EventIcon';
import TimeIcon from '../../Icons/TimeIcon';
import DatepickerField from '../../global/fields/DatepickerField';
import GroupPlaceholder from '../../../assets/images/group_placeholder.svg';
import Button from '../../global/Button';

class CreateFeedItemEvent extends Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      title: {
        value: '',
        error: null,
      },
      location: {
        value: '',
        error: null,
      },
      description: {
        value: '',
        error: null,
      },
      startDate: {
        value: '',
        error: null,
      },
      startTime: {
        value: '',
        error: '',
      },
      endDate: {
        value: '',
        error: '',
      },
      endTime: {
        value: '',
        error: '',
      },
      imageFile: null,
      imageBlobUrl: '',
      isEndTimeAdded: false,
    };
  }

  _onManageAttachmentClick = () => {
    this.fileInputRef.current.click();
  };

  _onFileSelect = event => {
    this.setState({
      imageFile: event.target.files[0],
      imageBlobUrl: URL.createObjectURL(event.target.files[0]),
    });
  };

  _toggleEndTime = () => {
    this.setState({ isEndTimeAdded: !this.state.isEndTimeAdded });
  };

  _submit = () => {
    if (typeof this.props.onSubmit !== 'function') return;

    const {
      title,
      description,
      location,
      startDate,
      startTime,
      isEndTimeAdded,
      endDate,
      endTime,
      imageFile,
    } = this.state;

    const startD = moment(startDate.value);
    const startT = new Date(startTime.value);
    startD.hours(startT.getHours());
    startD.minutes(startT.getMinutes());

    const data = {
      title,
      description,
      location,
      start_time: {
        value: startD.toDate().toUTCString(),
        error: startDate.error || startTime.error,
      },
    };

    if (imageFile) data.image = imageFile;

    if (isEndTimeAdded) {
      const endD = moment(endDate.value);
      const endT = new Date(endTime.value);
      endD.hours(endT.getHours());
      endD.minutes(endT.getMinutes());

      data.end_time = { value: endD.toDate().toUTCString(), error: endDate.error || endTime.error };
    }

    this.props.onSubmit('Event', data);
  };

  _shouldShowButton = () => {
    if (typeof this.props.checkFields !== 'function') return;

    const {
      title,
      description,
      location,
      startDate,
      startTime,
      isEndTimeAdded,
      endDate,
      endTime,
      imageFile,
    } = this.state;

    const startD = moment(startDate.value);
    const startT = new Date(startTime.value);
    startD.hours(startT.getHours());
    startD.minutes(startT.getMinutes());

    const data = {
      title,
      description,
      location,
      start_time: {
        value: startD.toDate().toUTCString(),
        error: startDate.error || startTime.error,
      },
    };

    if (imageFile) data.image = imageFile;

    if (isEndTimeAdded) {
      const endD = moment(endDate.value);
      const endT = new Date(endTime.value);
      endD.hours(endT.getHours());
      endD.minutes(endT.getMinutes());

      data.end_time = { value: endD.toDate().toUTCString(), error: endDate.error || endTime.error };
    }

    return this.props.checkFields('Event', data);
  };

  render() {
    const { startDate, startTime } = this.state;
    const showButton = this._shouldShowButton();
    return (
      <div className="create-feed-extra-block create-feed-event-content">
        <div className="create-feed-event-image-holder">
          {this.state.imageBlobUrl ? (
            <img
              src={this.state.imageBlobUrl}
              className="create-feed-event-image"
              alt="attachment"
            />
          ) : (
            <img
              src={GroupPlaceholder}
              className="create-feed-event-image-placeholder"
              alt="placeholder"
              width="88"
            />
          )}

          <input
            type="file"
            style={{ display: 'none' }}
            ref={this.fileInputRef}
            onChange={this._onFileSelect}
          />
        </div>

        <div
          className="create-feed-add-text align-self-center"
          onClick={this._onManageAttachmentClick}
        >
          <AddIcon className="create-feed-add-icon" />
          {this.state.imageBlobUrl ? 'REPLACE IMAGE' : 'ADD AN IMAGE'}
        </div>

        <TextField
          fk="title"
          value={this.state.title.value}
          updateFieldData={(fk, value, error) => this.setState({ title: { value, error } })}
          label="Event name"
          validateObj={{ requiredMax128: this.state.title.value }}
          showError={this.props.showErrors}
          triggerOnChange
        />

        <TextField
          fk="location"
          value={this.state.location.value}
          updateFieldData={(fk, value, error) => this.setState({ location: { value, error } })}
          label="Location"
          validateObj={{ required: this.state.location.value }}
          showError={this.props.showErrors}
          triggerOnChange
        />

        <TextField
          fk="description"
          value={this.state.description.value}
          updateFieldData={(fk, value, error) => this.setState({ description: { value, error } })}
          fieldTag="textarea"
          rows="3"
          label="Description"
          validateObj={{ requiredMax2048: this.state.description.value }}
          showError={this.props.showErrors}
          triggerOnChange
        />

        <div className="create-feed-time-select">
          <DatepickerField
            value={startDate.value}
            updateFieldData={(fk, value, error) => this.setState({ startDate: { value, error } })}
            label="Start Date"
            icon={EventIcon}
            optionalParams={{ dateFormat: 'dd MMM yy' }}
            validateObj={{ dateIsFuture: startDate.value }}
            showError={this.props.showErrors}
            error={startDate.error}
          />

          <DatepickerField
            value={startTime.value}
            updateFieldData={(fk, value, error) => this.setState({ startTime: { value, error } })}
            label="Start Time"
            icon={TimeIcon}
            optionalParams={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeCaption: 'Time',
              dateFormat: 'HH:mm',
              timeFormat: 'HH:mm',
            }}
            validateObj={{
              timeIsFuture: moment(startDate.value)
                .set({ hour: moment(startTime.value).hour(), minute: moment(startTime).minute() })
                .toISOString(),
            }}
            showError={this.props.showErrors}
            error={startTime.error}
          />
        </div>

        {this.renderEndTime()}

        <div className="create-feed-post-button">
          <Button.Primary onClick={this._submit} type="submit" disabled={!showButton}>
            POST
          </Button.Primary>
        </div>
        <div className="create-feed-empty-space" />
      </div>
    );
  }

  renderEndTime() {
    const { isEndTimeAdded, endDate, endTime, startDate, startTime } = this.state;

    if (!isEndTimeAdded) {
      return (
        <div className="create-feed-add-text align-self-end" onClick={this._toggleEndTime}>
          <AddIcon className="create-feed-add-icon" />
          ADD AN OPTIONAL END TIME
        </div>
      );
    }

    return (
      <div className="create-feed-time-select-extra">
        <div className="create-feed-time-select">
          <DatepickerField
            value={endDate.value}
            updateFieldData={(fk, value, error) => this.setState({ endDate: { value, error } })}
            label="End Date"
            icon={EventIcon}
            optionalParams={{ dateFormat: 'dd MMM yy' }}
            validateObj={{ dateIsLater: endDate.value, startDate: startDate.value }}
            showError={this.props.showErrors}
            error={endDate.error}
          />

          <DatepickerField
            value={endTime.value}
            updateFieldData={(fk, value, error) => this.setState({ endTime: { value, error } })}
            label="End Time"
            icon={TimeIcon}
            optionalParams={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeCaption: 'Time',
              dateFormat: 'HH:mm',
              timeFormat: 'HH:mm',
            }}
            validateObj={{
              dateIsLater: moment(endDate.value)
                .set({ hour: moment(endTime.value).hour(), minute: moment(endTime).minute() })
                .toISOString(),
              startDate: moment(startDate.value)
                .set({ hour: moment(startTime.value).hour(), minute: moment(startTime).minute() })
                .toISOString(),
            }}
            showError={this.props.showErrors}
            error={endTime.error}
          />
        </div>

        <div className="create-feed-time-cancel" onClick={this._toggleEndTime}>
          <CrossWithBgIcon />
        </div>
      </div>
    );
  }
}

export default CreateFeedItemEvent;
