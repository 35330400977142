import React from 'react';

function ProgressBar({
  startLabel = 'Start',
  endLabel = 'Finish',
  width,
  backLabel,
  onGoBackClick,
  hideLabels = false,
  className,
}) {
  let backEl = null;
  if (backLabel) {
    backEl = (
      <div className="skip-link" onClick={onGoBackClick}>
        {backLabel}
      </div>
    );
  }

  const hideBoxShadow = hideLabels ? { boxShadow: 'none' } : {};

  return (
    <div className={`row progress-bar-holder ${className}`} style={hideBoxShadow}>
      {backEl}

      <div
        className={`col-md-${hideLabels ? '12' : '6'} ${!hideLabels ? 'col-xl-4 offset-xl-3' : ''
          } d-flex`}
      >
        {!hideLabels && <span className="mr-3">{startLabel}</span>}

        <div className="w-100 d-flex justify-content-center flex-column">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${width}%` }}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>

        {!hideLabels && <span className="ml-3">{endLabel}</span>}
      </div>
    </div>
  );
}

export default ProgressBar;
