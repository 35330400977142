import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import ImageApi from '../../../api/image';
import moment from 'moment';

class FeedEventItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item.post.image.url) {
      ImageApi.getImage(this.props.item.post.image.url).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    const { item, link } = this.props;
    const startDate = moment(item.post.start_time);

    return (
      <div className="feed-item-content">
        <div className="feed-item-event-block d-flex">
          {this.props.item.post.image.url && (
            <div className="feed-item-event-image-holder">
              {this.state.imageUrl && (
                <img className="feed-item-event-image" src={this.state.imageUrl} alt="event" />
              )}
            </div>
          )}

          <div className="feed-item-event-date-holder">
            <div className="feed-item-event-date">
              <span className="feed-item-event-date-month">
                {startDate.format('MMM').toUpperCase()}
              </span>
              <span className="feed-item-event-date-day">{startDate.format('DD')}</span>
            </div>
          </div>

          <div className="feed-item-event-info-holder">
            <div className="d-flex flex-column flex-grow-1">
              <Link to={link} className="feed-item-event-info-title">
                {item.post.title}
              </Link>

              {this.renderTime(item.post.start_time, item.post.end_time)}
              <span className="feed-item-event-info-place">{item.post.location}</span>
            </div>

            <Link to={link} className="feed-item-event-info-button">
              View Event
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderTime(startTime, endTime) {
    const startDate = moment(startTime);
    const endDate = moment(endTime);
    const sameDay = endTime && startDate.isSame(endDate, 'day');

    const elements = [];
    elements.push(startDate.format('HH:mma'));
    if (!sameDay) {
      elements.push(<div key="splitter1" className="feed-item-footer-splitter" />);
      elements.push(startDate.format('dddd'));
    }
    if (endTime) {
      elements.push(endDate.format(' - HH:mma'));
      elements.push(<div key="splitter2" className="feed-item-footer-splitter" />);
      elements.push(endDate.format('dddd'));
    }

    return <div className="feed-item-event-info-time">{elements}</div>;
  }
}

export default FeedEventItem;
