import update from 'immutability-helper';
import { CHALLENGE } from '../actionCreators/challenge';

const initialState = {
  challengesList: [],
};

const challenge = (state = initialState, action) => {
  switch (action.type) {
    case CHALLENGE.GET_CHALLENGES_SUCCESS:
      return update(state, {
        challengesList: {
          $set: action.payload.challenges,
        },
      });
    case CHALLENGE.GET_CHALLENGE_SUCCESS:
      return update(state, {
        singleChallenge: {
          $set: action.payload.challenge,
        },
      });
    case CHALLENGE.UPDATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        singleChallenge: {
          ...state.singleChallenge,
          ...action.payload,
        },
      };
    case CHALLENGE.CLEAR_SINGLE_CHALLENGE:
      return {
        ...state,
        singleChallenge: {},
      };
    default:
      return state;
  }
};

export default challenge;
