import {
  put,
  takeLatest,
  call,
  // select
} from 'redux-saga/effects';

import {
  addPreloaderToGlobalPreloadersList,
  removePreloaderToGlobalPreloadersList,
} from '../actionCreators/globalPreloaders';
import {
  createUserProfileSucceeded,
  getCollegueProfileSuccess,
  getCollegueAvatarSuccess,
  getUserAvatarSuccess,
  updateUserProfileSuccess,
  getUserPointsSuccess,
  getUserBadgesSuccess,
  getUserSuccess,
  getUserMicroLearningSuccess,
  getUserMicroLearningQuestionsSuccess,
  getUserMicroLearningAnswersSuccess,
} from '../actionCreators/userProfile';
import {
  USER_PROFILE_CREATE_REQUEST,
  USER_AVATAR_CREATE_REQUEST,
  GET_COLLEGUE_PROFILE_REQUEST,
  GET_COLLEGUE_AVATAR_REQUEST,
  GET_USER_AVATAR_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  GET_USER_BADGES_REQUEST,
  GET_USER_ACTIVITY_POINTS_REQUEST,
  GET_USER_REQUEST,
  SEND_USER_PULSE_REQUEST,
  GET_MICRO_LEARNING_REQUEST,
  GET_MICRO_LEARNING_QUESTIONS_REQUEST,
  GET_MICRO_LEARNING_ANSWERS_REQUEST,
  SET_PULSE_REPLIED,
} from '../constants/actionTypes';
import UserProfileApi from '../api/userProfile';

import errorHandler from '../utils/requestErrorHandler';
import ImageApi from '../api/image';
import { addAlertToAlertsList } from '../actionCreators/globalAlerts';

function* createUserProfileRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const { ...fieldsData } = action.payload;

    const data = yield call(UserProfileApi.createUserProfile, fieldsData);

    yield put(createUserProfileSucceeded(data));
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}
function* postUserAvatarRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const { ...image } = action.payload;

    const data = yield call(UserProfileApi.postUserImage, image);

    localStorage.setItem('imageId', data.image_id);

    yield put(createUserProfileSucceeded(data));
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getCollegueProfileRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchCollegueProfile, action.payload);

    yield put(getCollegueProfileSuccess(data));
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getCollegueAvatarRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.getCollegueAvatar, action.payload);

    const img = yield data.blob();
    const objectURL = URL.createObjectURL(img);

    yield put(getCollegueAvatarSuccess(objectURL));
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserAvatarRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(ImageApi.getImage.bind(ImageApi), action.payload);

    yield put(getUserAvatarSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* updateUserProfileRequest(action) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.updateUser, action.payload);

    localStorage.setItem('user', JSON.stringify(data));

    yield put(updateUserProfileSuccess(data));
    yield put(
      addAlertToAlertsList({
        title: 'Edit profile',
        message: 'Your details have been changed!',
        messageStyle: { color: '#505A62', textAlign: 'left', width: '100%' },
        confirmBtnText: 'OKAY',
      }),
    );
    yield put({ type: GET_USER_REQUEST });
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserActivityPointsRequest() {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchUserActivityPoints);

    yield put(getUserPointsSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserBadgesRequest() {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchUserBadges);

    yield put(getUserBadgesSuccess(data));
  } catch (error) {
    console.log(error);
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserProfileRequest() {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.getUser);

    localStorage.setItem('user', JSON.stringify(data));

    yield put(getUserSuccess(data.user));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* sendUserPulseRequest(action) {
  try {
    yield call(UserProfileApi.sendPulseRating, action.payload);
    yield put({ type: SET_PULSE_REPLIED });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getUserLearningRequest() {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchMicroLearning);

    yield put(getUserMicroLearningSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserLearningQuestionsRequest(id) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchMicroLearningQuestions, id);

    yield put(getUserMicroLearningQuestionsSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function* getUserLearningAnswersRequest(payload) {
  const preloaderId = Date.now();

  yield put(
    addPreloaderToGlobalPreloadersList({
      id: preloaderId,
    }),
  );

  try {
    const data = yield call(UserProfileApi.fetchMicroLearningAnswers, payload);

    yield put(getUserMicroLearningAnswersSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

export default function* watchAuth() {
  yield takeLatest(USER_PROFILE_CREATE_REQUEST, createUserProfileRequest);
  yield takeLatest(USER_AVATAR_CREATE_REQUEST, postUserAvatarRequest);
  yield takeLatest(GET_COLLEGUE_PROFILE_REQUEST, getCollegueProfileRequest);
  yield takeLatest(GET_COLLEGUE_AVATAR_REQUEST, getCollegueAvatarRequest);
  yield takeLatest(GET_USER_AVATAR_REQUEST, getUserAvatarRequest);
  yield takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserProfileRequest);
  yield takeLatest(GET_USER_ACTIVITY_POINTS_REQUEST, getUserActivityPointsRequest);
  yield takeLatest(GET_USER_BADGES_REQUEST, getUserBadgesRequest);
  yield takeLatest(GET_USER_REQUEST, getUserProfileRequest);
  yield takeLatest(SEND_USER_PULSE_REQUEST, sendUserPulseRequest);
  yield takeLatest(GET_MICRO_LEARNING_REQUEST, getUserLearningRequest);
  yield takeLatest(GET_MICRO_LEARNING_QUESTIONS_REQUEST, getUserLearningQuestionsRequest);
  yield takeLatest(GET_MICRO_LEARNING_ANSWERS_REQUEST, getUserLearningAnswersRequest);
}
