import { call, put, takeLatest, all } from 'redux-saga/effects';

import errorHandler from '../utils/requestErrorHandler';
import { FeatureFlagsApi } from '../api';
import { FETCH_FEATURE_FLAGS_REQUEST } from '../constants/actionTypes';
import { fetchFeatureFlagsFail, fetchFeatureFlagsSuccess } from '../actionCreators/featureFlags';

function * fetchFeatureFlags () {
  try {
    const result = yield call(FeatureFlagsApi.fetchFeatureFlags);
    yield put(fetchFeatureFlagsSuccess(result.feature_flags || {}));
  } catch (error) {
    yield put(fetchFeatureFlagsFail(error));
    //yield errorHandler(error);
  }
}

export default function * fetchFeatureFlagsRoot () {
  yield all([takeLatest(FETCH_FEATURE_FLAGS_REQUEST, fetchFeatureFlags)]);
}
