import React from 'react';
import { Checkbox } from 'antd';

const LearningCheckbox = ({ checkBoxes, questions, step, handleCheckBox }) => {
  return (
    <Checkbox.Group value={checkBoxes} onChange={handleCheckBox}>
      {questions[step].answers !== null
        ? questions[step].answers.map((question, index) => {
            return (
              <li
                key={index}
                className="list-item-checkbox d-flex flex-row-reverse justify-content-between position-relative"
              >
                <Checkbox value={index}></Checkbox>
                <p className="m-0 mr-auto flex-1">{question.title}</p>
              </li>
            );
          })
        : null}
    </Checkbox.Group>
  );
};

export default LearningCheckbox;
