import React, { useEffect, useState } from 'react';
import GreyTick from '../../../assets/images/grey_tick.svg';
import GreenTick from '../../../assets/images/green_tick.svg';
import { Link } from 'react-router-dom';
import ImageApi from '../../../api/image';
import Portal from '../../Portal';
import MicroLearningModal from '../../../components/MicroLearningDetailsModal';
import TaskLine from './TaskLine';
import { logFirebaseEvent } from '../../../firebase/analytics';

const OnboardingTaskItem = props => {
  const { title, type, entity = {}, id, is_completed, firstTimeLogin = ''} = props;
  const entityId = entity.id;
  let entityTitle = 'Mirco-Learning';
  let taskLink = `/learning/${entityId}`;
  let taskState = { item: entity, taskId: id, firstTimeLogin };
  if (type === 'knowledgebase') {
    taskLink = `/knowledgebase-item/${entityId}`;
    entityTitle = 'Knowledgebase';
  } else if (type === 'challenges') {
    taskLink = `/engage/${entityId}?tab=challenges`;
    entityTitle = 'Challenge';
  }

  const [isLearningModal, setIsLearningModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      if (entity.image && entity.image.url) {
        ImageApi.getImage(entity.image.url).then(url => {
          setImageUrl(url);
        });
      }
    };
    fetchData();
  }, []);

  const renderTaskFooter = () => (
    <>
      {!is_completed ? (
        type === 'learning' ? (
          <div onClick={() => setIsLearningModal(true)}>
            <span className='start-button'>Start</span>
          </div>
        ) : type === 'challenges' || type === 'knowledgebase' ? (
          <Link
            to={{
              pathname: taskLink,
              state: taskState,
            }}
          >
            <span className='start-button'>Start</span>
          </Link>
        ) : (
          <div onClick={() => {
            props.updateTask(id)
            }}>
            <span className='start-button'>Mark as done</span>
          </div>
        )
      ) : null}
    </>
  );
  return (
    <div className='task-item-container'>
      <TaskLine {...props} />
      <div className='d-flex w-100 p-2'>
        <div className='task-item-content'>
          <div className='d-flex flex-column w-100'>
            <p className='mb-1'>{title}</p>
            {entityId && (
              <span className='entity-title'>
                {`${entityTitle}: `}
                <span className='entity-desc'>{entity.title}</span>
              </span>
            )}
            {renderTaskFooter()}
          </div>
          <img src={is_completed ? GreenTick : GreyTick} />
        </div>
      </div>
      <Portal hide={() => setIsLearningModal(!isLearningModal)} isOpen={isLearningModal}>
        <MicroLearningModal
          taskId={id}
          learningItem={entity}
          firstTimeLogin={firstTimeLogin}
          learningAvatar={imageUrl}
          hide={() => setIsLearningModal(false)}
        />
      </Portal>
    </div>
  );
};

export default OnboardingTaskItem;
