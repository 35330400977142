import React, { PureComponent } from 'react';
import Linkify from 'react-linkify';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import ImageApi from '../../../api/image';
import Portal from '../../Portal';
import CrossIcon from '../../Icons/CrossIcon';
import { Stream } from '@cloudflare/stream-react';
import jwtDecode from 'jwt-decode';
import FeedApi from '../../../api/feed';
import { Link } from 'react-router-dom';
import KnowledgebaseItem from '../../Knowledgebase/KnowledgebaseItem';
import { ChallengePostCard} from '../../../components';

class FeedNoticeItem extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      isImageLoading: !!props.item.post.image.url,
      imageUrl: '',
      showImageFullscreen: false,
      videoToken: this.props.item.post.video_id ? this.props.item.post.video.token : '',
      newVideoToken: '',
    };
  }

  componentDidMount () {
    if (this.props.item.post.image.url) {
      ImageApi.getImage(this.props.item.post.image.url).then(imageUrl => {
        this.setState({ isImageLoading: false, imageUrl });
      });
    }
  }

  onClickPlayVideo = async () => {
    try {
      const videoId = this.props.item.post.video_id;
      const currentTime = Date.now() / 1000;
      const videoJWTToken = jwtDecode(this.state.videoToken);
      if (videoJWTToken.exp - currentTime < 0) {
        const streamUrlResp = (await FeedApi.getVideoStreamURL(videoId)) || {};
        const newToken = streamUrlResp.video ? streamUrlResp.video.token : '';
        if (newToken)
          this.setState({
            newVideoToken: newToken,
          });
      }
    } catch (err) {}
  };
  _toggleShowImage = () => {
    this.setState({ showImageFullscreen: !this.state.showImageFullscreen });
  };

  parseNotice = noticeTxt => {
    let reg = /@\[([^\]]+?)]\(id:([^\]]+?)\)/;
    const parts = [];
    let left = noticeTxt || '';
    while (left !== '') {
      let matched = false;
      const match = reg.exec(left);
      if (match) {
        matched = true;
        parts.push(left.substr(0, match.index));
        parts.push(
          <Link
            className='feed-item-footer-comment-owner'
            key={match[2]}
            to={
              this.props.myGroups && this.props.myGroups.indexOf(match[2]) !== -1
                ? `/group-feed/${match[2]}`
                : `/users/${match[2]}`
            }
          >
            {match[1]}
          </Link>,
        );
        left = left.substr(match.index + match[0].length);
      } else {
        parts.push(left.substr(0));
        left = '';
      }
    }

    return parts;
  };
  render () {
    const noticePost = this.props.item.post;

    const { notice, image, video_id, video, challenge_id, challenge, knowledgebase_item } = noticePost;
    const parsedNoticeText = this.parseNotice(notice);

    return (
      <div className='feed-item-content'>
        <span className='feed-item-content-text'>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target={'_blank'}>
                {text}
              </a>
            )}
          >
            {parsedNoticeText}
          </Linkify>
        </span>

        {challenge_id ? <ChallengePostCard challengeItem={challenge} from={'feed'}/> : null}

        {this.state.isImageLoading && <div className='feed-item-content-image-loader' />}
        {this.state.imageUrl && (
          <div className='feed-item-content-image-holder' onClick={this._toggleShowImage}>
            <img className='feed-item-content-image' src={this.state.imageUrl} alt='post' />
          </div>
        )}

        {(this.state.videoToken || this.state.newVideoToken) && (
          <Stream
            src={this.state.newVideoToken ? this.state.newVideoToken : this.state.videoToken}
            onPlay={this.onClickPlayVideo}
            controls
            autoplay={this.state.newVideoToken ? true : false}
            preload
            height='300px'
            width='100%'
          ></Stream>
        )}

        {knowledgebase_item && (
          <KnowledgebaseItem viewOnly item={knowledgebase_item} />
        )}

        {this.renderUrlMeta()}

        {this.state.showImageFullscreen && (
          <Lightbox
            mainSrc={this.state.imageUrl}
            onCloseRequest={() => this.setState({ showImageFullscreen: false })}
          />
        )}
      </div>
    );
  }

  renderUrlMeta () {
    if (!this.props.item.post.meta_url) return null;

    return (
      <a
        href={this.props.item.post.meta_url}
        target='_blank'
        rel='noopener noreferrer'
        className='feed-item-content-url-holder'
      >
        {this.props.item.post.meta_image && (
          <img
            className='feed-item-content-url-image'
            src={this.props.item.post.meta_image}
            alt=''
          />
        )}
        <p className='feed-item-content-url-title'>{this.props.item.post.meta_title}</p>
        <p className='feed-item-content-url-link'>{this.props.item.post.meta_hostname}</p>
      </a>
    );
  }
}

export default FeedNoticeItem;
