import React, { Component } from 'react';
import lodash from 'lodash';
import { connect } from 'react-redux';

import CreateFeedItemSearchGroup from '../Feed/CreateFeedItem/CreateFeedItemSearchGroup';
import CreateFeedItemSelectedGroup from '../Feed/CreateFeedItem/CreateFeedItemSelectedGroup';
import CreateFeedItemHighFive from '../Feed/CreateFeedItem/CreateFeedItemHighFive';

import GroupApi from '../../api/group';
import { createHighFiveRequest } from '../../actionCreators/feedActions';

class SendHighFive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroupId: '',
      showErrors: '',
      groups: [],
    };
  }

  componentDidMount() {
    GroupApi.getMyAndColleagueGroups(this.props.userId, this.props.selectedColleague.id).then(
      groups => {
        this.setState({ groups: groups.groups || [] });
      }
    );
  }

  onSelect = selectedGroupId => {
    this.setState({ selectedGroupId: selectedGroupId });
  };

  onSubmit = (type, data) => {
    if (!this.state.selectedGroupId) return this.setState({ showErrors: true });
    if (!data.recipient_id || !data.highfive_type_id || (data.reason && data.reason.error)) {
      return this.setState({ showErrors: true });
    }

    if (data.reason) data.reason = data.reason.value;
    data.group_id = this.state.selectedGroupId;
    this.props.createHighFive(data);

    return this.props.close();
  };

  render() {
    const { selectedGroupId, showErrors } = this.state;

    let groupRowEl = null;
    if (!selectedGroupId) {
      groupRowEl = (
        <CreateFeedItemSearchGroup
          onSelect={this.onSelect}
          groups={this.state.groups}
          showError={showErrors}
        />
      );
    } else {
      const selectedGroup = lodash.find(this.state.groups, { id: selectedGroupId });
      if (selectedGroup) {
        groupRowEl = (
          <CreateFeedItemSelectedGroup
            group={selectedGroup}
            onCancel={() => this.setState({ selectedGroupId: '' })}
          />
        );
      }
    }

    return (
      <div className="create-feed feed">
        <div className="create-feed-holder w-100">
          <div className="create-feed-content">
            {groupRowEl}

            <CreateFeedItemHighFive
              onSubmit={this.onSubmit}
              selectedGroupId={selectedGroupId}
              showErrors={showErrors}
              selectedColleague={this.props.selectedColleague}
              disallowEditColleague
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({ userId: state.currentUser.userData.id }),
  dispatch => ({
    createHighFive: payload => dispatch(createHighFiveRequest(payload)),
  })
)(SendHighFive);
