import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// ANTD styles
import 'antd/es/form/style/css';
import 'antd/es/skeleton/style/css';
import 'antd/es/avatar/style/css';
import 'antd/es/auto-complete/style/css';
import 'antd/es/select/style/css';
import 'antd/es/slider/style/css';
import 'antd/es/progress/style/css';
import 'antd/es/mention/style/css';
import 'antd/es/checkbox/style/css';
import 'antd/es/radio/style/css';
import './assets/styles/index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

// Configure font-awesome
library.add(fas, far);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
