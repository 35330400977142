import { put, takeLatest, call } from 'redux-saga/effects';
import { getOrgThemeSuccess } from '../actionCreators/orgTheme';
import { OrgThemeApi } from '../api';
import { GET_ORG_THEME_REQUEST } from '../constants/actionTypes';
import { APP_PRIMARY_COLOR } from '../theme/constants';

function * getOrgTheme () {
  var bodyStyles = document.body.style;
  try {
    let result = yield call(OrgThemeApi.getOrgTheme);
    const orgPrimaryColor = result.theme ? result.theme.PRIMARY_COLOR : APP_PRIMARY_COLOR;
    bodyStyles.setProperty('--primary-color', orgPrimaryColor);
    localStorage.setItem('primary-color',orgPrimaryColor)
    yield put(getOrgThemeSuccess(orgPrimaryColor));
  } catch (error) {
    bodyStyles.setProperty('--primary-color', APP_PRIMARY_COLOR);
  }
}

export default function * getOrgThemeRoot () {
  yield takeLatest(GET_ORG_THEME_REQUEST, getOrgTheme);
}
