import React from 'react';

export default function FeedPollItemOption({
  text,
  showAsFinished,
  onClick,
  votes,
  percents,
  isSelected,
}) {
  if (showAsFinished) {
    return (
      <div className="feed-poll-item-option-finished">
        <div className="feed-poll-item-option-progress">
          <div className="feed-poll-item-option-progress-bg" style={{ width: `${percents}%` }} />
          <span className={`feed-poll-item-option-progress-text ${isSelected ? 'voted' : ''}`}>
            {text}
          </span>
        </div>

        <span className="feed-poll-item-option-progress-value">{votes}</span>
      </div>
    );
  }

  return (
    <div className="feed-poll-item-option" onClick={onClick}>
      <div className="feed-poll-item-option-circle" />

      {text}
    </div>
  );
}
