import React from 'react';
import Participant from './Participant';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

const ParticipantList = ({ participants, total, className, ...otherProps }) => {
  const renderUsers = () =>
    participants.map(participant => {
      return <Participant key={participant.user_id} {...participant} />;
    });

  const renderRestUsers = () =>
    total > 3 && <div className='rest-users cursor-pointer'>+{total - 3}</div>;

  return (
    <div
      className={`event-single-attending d-flex align-items-center justify-content-end ${className}`}
      {...otherProps}
    >
      <Link
        to={{ search: '?listType=challenge&showUsers=true' }}
        style={{ textDecoration: 'none', color: 'var(--primary-color' }}
        className='subtitle'
      >
        {total} Attending
      </Link>
      {renderUsers()}
      {renderRestUsers()}
    </div>
  );
};

ParticipantList.propTypes = {
  participants: PropTypes.array,
};

ParticipantList.defaultProps = {
  participants: [],
};

export default ParticipantList;
