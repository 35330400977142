import React, { Component } from 'react';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextField from '../../global/fields/TextField';
import AddIcon from '../../Icons/AddIcon';
import CrossWithBgIcon from '../../Icons/CrossWitBgIcon';
import Button from '../../global/Button';
import { InputWithMentions } from '../../../containers';

class CreateFeedItemPoll extends Component {
  constructor (props) {
    super(props);

    this.state = {
      text: {
        value: '',
        error: '',
      },
      isTextareaFocused: false,
      options: [
        { value: '', error: null },
        { value: '', error: null },
      ],
      length: undefined,
      selectActive: false,
    };
  }

  _submit = () => {
    if (typeof this.props.onSubmit !== 'function') return;

    this.props.onSubmit('Poll', {
      question: this.state.text,
      options: this.state.options,
      duration: this.state.length,
    });
  };

  _shouldShowButton = () => {
    if (typeof this.props.checkFields !== 'function') return;

    return this.props.checkFields('Poll', {
      question: this.state.text,
      options: this.state.options,
      duration: this.state.length,
    });
  };

  _updateOption = (index, value, error) => {
    const options = [...this.state.options];
    options[index] = { value, error };

    this.setState({ options: options });
  };

  _addOption = () => {
    if (this.state.options.length === 5) return;

    const options = [...this.state.options];
    options.push('');

    this.setState({ options: options });
  };

  _removeOption (index) {
    const options = [...this.state.options];
    options.splice(index, 1);

    this.setState({ options: options });
  }

  _onLengthChange = newValue => {
    this.setState({ length: newValue });
    this.select.blur();
  };

  render () {
    const showButton = this._shouldShowButton();
    return (
      <div className='create-feed-extra-block'>
        <div
        //className={`create-feed-textarea-holder ${this.state.isTextareaFocused ? 'focused' : ''}`}
        >
          {/* <TextField
            fk="text"
            value={this.state.text.value}
            updateFieldData={(fk, value, error) => this.setState({ text: { value, error } })}
            fieldTag="textarea"
            rows="3"
            label="Poll description"
            className="custom-textarea"
            onFocus={() => this.setState({ isTextareaFocused: true })}
            onBlur={() => this.setState({ isTextareaFocused: false })}
            validateRule='requiredMax350'
            validateObj={{ requiredMax350: this.state.text.value }}
            showError={true}
            error={this.state.text.error}
            triggerOnChange={true}
          /> */}

          <InputWithMentions
            isAdmin={this.props.isAdmin}
            maxLength={350}
            label='Poll description'
            isPostCreation={true}
            onChange={changedTxt => this.setState({ text: { value: changedTxt } })}
            selectedGroup={this.props.selectedGroup}
          />

          <div className='create-feed-options-holder'>
            {this.state.options.map((option, index) => {
              let validationRule = index > 1 ? 'notEmptyPollOption' : 'requiredPollOption';
              let showError = this.props.showErrors;
              if (option.value && option.value.length > 40) {
                showError = true;
              }
              return (
                <div className='create-feed-option' key={`option:${index}`}>
                  <TextField
                    fk={index}
                    value={option.value}
                    updateFieldData={this._updateOption}
                    label={`Option ${String.fromCharCode(65 + index)}`}
                    validateRule={validationRule}
                    validateObj={{ [validationRule]: option.value }}
                    showError={showError}
                    triggerOnChange={true}
                  />

                  {index > 1 && (
                    <div
                      className='create-feed-option-remove'
                      onClick={() => this._removeOption(index)}
                    >
                      <CrossWithBgIcon />
                    </div>
                  )}
                </div>
              );
            })}

            {this.state.options.length < 5 && (
              <div className='create-feed-add-text' onClick={this._addOption}>
                <AddIcon className='create-feed-add-icon' />
                ADD ANOTHER OPTION
              </div>
            )}
          </div>
        </div>

        <div className='create-feed-select-holder'>
          <span
            className={`create-feed-select-label ${
              this.state.selectActive || this.state.length ? 'active' : ''
            }`}
            onClick={() => this.select.focus()}
          >
            Poll length
          </span>

          <Select
            ref={ref => (this.select = ref)}
            value={this.state.length}
            onChange={this._onLengthChange}
            className='create-feed-select'
            showArrow={false}
            dropdownClassName='create-feed-select-dropdown'
            defaultActiveFirstOption={false}
            open={this.state.selectActive}
            onFocus={() => this.setState({ selectActive: true })}
            onBlur={() => this.setState({ selectActive: false })}
          >
            {this.renderOptions()}
          </Select>

          <FontAwesomeIcon className='create-feed-select-icon' icon={['fas', 'chevron-down']} />

          {this.props.showErrors && !this.state.length && (
            <div className='create-feed-select-error'>This field is required</div>
          )}
        </div>

        <div className='create-feed-post-button'>
          <Button.Primary onClick={this._submit} type='submit' disabled={!showButton}>
            POST
          </Button.Primary>
        </div>
        <div className='create-feed-empty-space' />
      </div>
    );
  }

  renderOptions () {
    const options = [];

    for (let i = 1; i <= 30; i++) {
      options.push(
        <Select.Option key={`lengthDur:${i}`} value={i}>
          <div className='create-feed-select-dropdown-option'>
            {i} day{i !== 1 ? 's' : ''}
          </div>
        </Select.Option>,
      );
    }

    return options;
  }
}

export default CreateFeedItemPoll;
