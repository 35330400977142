import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class FeatureFlagsApi {
    static fetchFeatureFlags() {
      const request = new Request(`${apiUrl}/config/feature_flags`, {
        method: 'GET',
        headers: getHeaders(),
      });
      return fetchRequest(request);
    }
  }
  
  export default FeatureFlagsApi;