import { createMatchSelector } from 'connected-react-router';

const matchSelector = createMatchSelector({ path: ['/group-feed/:groupId', '/feed/:postId'] });

export function parsePath(state) {
  const match = matchSelector(state);
  const queryParams = new URLSearchParams(state.router.location.search);

  return {
    params: match ? match.params : {},
    queryParams: queryParams,
  };
}

export function getFeedPagination(state) {
  return {
    currentPage: state.feed.currentPage,
    totalPages: state.feed.totalPages,
  };
}

export function getKnowledgebasePagination(state) {
  return {
    currentPage: state.knowledgebase.currentPage,
    totalPages: state.knowledgebase.totalPages,
  };
}