import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class OrgApi {
    static fetchOrgName() {
      const request = new Request(`${apiUrl}/content/org_name`, {
        method: 'GET',
        headers: getHeaders(),
      });
      return fetchRequest(request);
    }
  }
  
  export default OrgApi;