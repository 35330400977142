import firebase from 'firebase/app';
import 'firebase/analytics';
import OrgApi from '../api/org';
import { CUSTOM_THEME } from '../constants/themeConstants';
import firebaseConfig from './firebaseConfig';

export const initializeFirebase = (appId, env) => {
  try {
    const firebaseAppConfig =
      appId === 'engage4' ? firebaseConfig : CUSTOM_THEME[appId].firebaseConfig[env];
    firebase.initializeApp(firebaseAppConfig);
  } catch (err) {
    console.log(JSON.stringify(err));
  }
};

export const logFirebaseEvent = ({ event, action = '', attachment = '' }) => {
  try {
    if (action) {
      firebase.analytics().logEvent(event, {
        action,
        attachment,
      });
    } else {
      firebase.analytics().logEvent(event);
    }
  } catch (err) {
    console.log(JSON.stringify(err));
  }
};

export const logFirebaseUserProperty = async () => {
  try {
    const orgResp = await OrgApi.fetchOrgName();
    if (orgResp && orgResp.org_name) {
      firebase.analytics().setUserProperties('Org_Name', orgResp.org_name);
    }
  } catch (err) {
    console.log(JSON.stringify(err));
  }
};
