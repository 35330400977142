import React from 'react';

export default function AddIcon({ fill = 'none', stroke = 'var(--primary-color)', className = '' }) {
  return (
    <svg
      className={className}
      width="22px"
      height="23px"
      viewBox="0 0 22 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="✏️-10-Creating-Posts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Create-Event-Filled" transform="translate(-17.000000, -745.000000)">
          <g id="Cell-/-Nested" transform="translate(0.000000, 735.000000)">
            <g id="Left-detail-/-placeholder-text-Left-detail-/-Add">
              <g id="Icons-/-add-add" transform="translate(16.000000, 10.000000)">
                <g
                  id="Wrong"
                  transform="translate(12.146447, 11.439340) rotate(-315.000000) translate(-12.146447, -11.439340) translate(0.646447, -0.060660)"
                  fill={stroke}
                >
                  <g id="Right" transform="translate(0.500000, 0.707107)">
                    <circle id="Oval" cx="11" cy="11" r="11"></circle>
                  </g>
                </g>
                <path
                  d="M12,6 L12,18"
                  id="Line-2"
                  stroke="#FFFFFF"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M12,6 L12,18"
                  id="Line-2"
                  stroke="#FFFFFF"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
