import React from 'react';

export default function TimeIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M10,0 C15.5220354,0 20,4.47743135 20,10 C20,15.5227772 15.5221373,20 10,20 C4.47598634,20 0,15.5236938 0,10 C0,4.47651469 4.47608818,0 10,0 Z M10,2 C5.58070034,2 2,5.58104153 2,10 C2,14.4191563 5.58058783,18 10,18 C14.4176318,18 18,14.4181437 18,10 C18,5.58205417 14.4175192,2 10,2 Z M9.68965517,4.69579655 C10.2419399,4.69579655 10.6896552,5.1435118 10.6896552,5.69579655 L10.6896552,5.69579655 L10.6896552,9.56521006 L14.9865469,13.572849 C15.3904285,13.9495426 15.4124687,14.5823239 15.0357751,14.9862055 C14.6590815,15.3900871 14.0263002,15.4121274 13.6224186,15.0354337 L13.6224186,15.0354337 L9.00759104,10.7312613 C8.80480187,10.5421233 8.68965517,10.2772714 8.68965517,9.99996897 L8.68965517,9.99996897 L8.68965517,5.69579655 C8.68965517,5.1435118 9.13737042,4.69579655 9.68965517,4.69579655 Z"
          id="path-11"
        ></path>
      </defs>
      <g id="✏️-10-Creating-Posts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Create-Event" transform="translate(-1020.000000, -773.000000)">
          <g id="calendar-time" transform="translate(1018.000000, 771.000000)">
            <g id="baseline-access_time-24px" transform="translate(2.000000, 2.000000)">
              <g id="Colours-/-dark-grey">
                <mask id="mask-22" fill="white">
                  <use xlinkHref="#path-11"></use>
                </mask>
                <use id="Stroke-3" fill="none" fillRule="nonzero" xlinkHref="#path-11"></use>
                <g id="Colours-/-dark-grey-Colours-/-blue" mask="url(#mask-22)" fill={stroke}>
                  <g transform="translate(-2.000000, -2.000000)" id="Rectangle">
                    <rect x="0" y="0" width="24" height="24"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
