import cookie from 'react-cookies';
import { logFirebaseEvent } from '../firebase/analytics';

export function clearStorageOnLogout () {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  removeCookie('token');
  logFirebaseEvent({
    event: 'logout',
  });
}


export function getHeaders (extraHeaders, removeContentType = false) {
  const token = getCookieValForKey('token');

  const headers = {
    'X-Application-Platform': 'web',
    'X-Application-Version': '1.0.0',
    'Content-Type': 'application/json',
  };

  if (token.key) headers['Authorization'] = `Bearer ${token.key}`;
  if (removeContentType) delete headers['Content-Type'];

  return { ...headers, ...extraHeaders };
}

export function setCookieValForKey (key, value, options) {
  const __DEV__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  cookie.save(key, value, { sameSite: true, secure: __DEV__ ? false : true});
}

export function getCookieValForKey (key) {
  try {
    if (key === 'token') {
      const token = cookie.load('token') || '';
      if (!token) {
        const storageToken = localStorage.getItem('token');
        if (storageToken) {
          setCookieValForKey('token', JSON.parse(storageToken));
        }
      }
      localStorage.removeItem('token')
    }
    return cookie.load(key) || {};
  } catch (err) {
    console.log(JSON.stringify(err));
  }
}

export function removeCookie () {
  cookie.remove('token');
}
