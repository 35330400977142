import {
  GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
  GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS,
  UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
  UPDATE_EMPLOYEE_ONBOARDING_TASKS_SUCCESS
} from '../constants/actionTypes';

const getTasksRequest = () => ({
  type: GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
});

const getTasksSuccess = data => ({
  type: GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS,
  payload: data,
});

const updateTaskRequest = data => ({
  type: UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
  payload: data,
});

const updateTaskSuccess = data => ({
  type: UPDATE_EMPLOYEE_ONBOARDING_TASKS_SUCCESS,
  payload: data,
});

export { getTasksRequest, getTasksSuccess, updateTaskRequest, updateTaskSuccess };
