import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import HeaderText from './HeaderText';
import HeaderLinks from './HeaderLinks';

import { CUSTOM_THEME } from '../../../constants/themeConstants'

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { location } = this.props;
    const showOnBoardingText = location.pathname === '/onboarding' || location.pathname === '/account';
    const showLearningText = location.pathname.includes('/learning');
    const activeProfile = location.pathname === '/edit' ? null : 'active';

    let headerContent = null;
    if (showOnBoardingText) headerContent = <HeaderText text="Welcome to Engage4!" />;
    else if (showLearningText)
      headerContent = (
        <HeaderText
          learningTitle={true}
          text={[
            <strong key={1} className="mr-1">
              Micro-learning:
            </strong>,
            'Test your knowledge of workplace wellbeing',
          ]}
        />
      );
    else headerContent = <HeaderLinks activeProfile={activeProfile} pathname={location.pathname} />;

    return (
      <header className="header">
        <div className="container-fluid d-flex h-100 align-items-center">
          <div className="logo-holder">
            <NavLink to="/" className="text-center w-100">
              <img width="175" height="40" className="logo-img" src={CUSTOM_THEME[this.props.customAppId].logo} alt="logo" />
            </NavLink>
          </div>

          {headerContent}
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
