import React, { Component } from 'react';
import { Avatar } from 'antd';

import ImageApi from '../../api/image';
import UserPlaceholder from '../../assets/images/user_placeholder.svg';

class CreateGroupSearchRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item && this.props.item.image && this.props.item.image.thumbnailUrl) {
      ImageApi.getImage(this.props.item.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    const { item, handlePersonSelected } = this.props;

    return (
      <div
        className="create-group__invite-colleagues-row"
        key={item.id}
        onClick={() => handlePersonSelected(item)}
      >
        <Avatar
          src={this.state.imageUrl || UserPlaceholder}
          size={36}
          icon="user"
          shape="square"
          className="create-group__invite-colleagues-avatar"
        />
        {item.first_name || ''} {item.last_name || ''}
      </div>
    );
  }
}

export default CreateGroupSearchRow;
