import lodash from 'lodash';

import {
  GET_FEED_REQUEST_SUCCESS,
  GET_SINGLE_FEED_REQUEST_SUCCESS,
  SET_FEED_LOADING,
  RESET_FEED_PAGE,
  RESET_SINGlE_FEED_PAGE,
  GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS,
  SET_FEED_DETAILS_LOADING,
  RESET_FEED_AFTER_REFRESH,
  GET_HIGHFIVE_OPTIONS_REQUEST_SUCCESS,
  RESET_FEED_ITEMS,
  POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS,
  REPORT_POST_REQUEST_SUCCESS,
  RESET_REPORT_POST_REQUEST_SUCCESS,
  SET_PIN_RETRY
} from '../constants/actionTypes';

const initialState = {
  items: [],
  singleItem: null,
  authors: {},
  currentPage: 0,
  totalPages: Infinity,
  loading: false,
  detailsLoading: false,
  highfiveOptions: [],
  postReportTypes: [],
  reportPostRequestSuccessful: null,
  pinRetry: {},
};

const feed = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEED_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_FEED_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading: action.payload,
      };

    case GET_FEED_REQUEST_SUCCESS: {
      const items = [...state.items, ...action.payload.posts];

      return {
        ...state,
        items: items,
        authors: { ...state.authors, ...lodash.keyBy(action.payload.users, 'id') },
        currentPage: action.payload.currentPage,
        totalPages: action.payload.pages,
      };
    }

    case RESET_FEED_AFTER_REFRESH:
      return {
        ...state,
        items: action.payload.posts,
        authors: { ...state.authors, ...lodash.keyBy(action.payload.users, 'id') },
        currentPage: 1,
      };

    case GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS: {
      const index = lodash.findIndex(state.items, { postable_id: action.payload.post.postable_id });
      const items = [...state.items];
      if (index !== -1) items[index] = action.payload.post;

      return {
        ...state,
        items: items,
        authors: { ...state.authors, ...lodash.keyBy(action.payload.authors, 'id') },
      };
    }

    case RESET_FEED_PAGE:
      return {
        ...state,
        items: [],
        currentPage: 0,
        totalPages: Infinity,
        loading: true,
      };

    case GET_SINGLE_FEED_REQUEST_SUCCESS:
      return {
        ...state,
        singleItem: action.payload.post,
        authors: { ...state.authors, ...lodash.keyBy(action.payload.authors, 'id') },
      };

    case RESET_SINGlE_FEED_PAGE:
      return {
        ...state,
        singleItem: null,
      };

    case GET_HIGHFIVE_OPTIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        highfiveOptions: action.payload,
      };
    }

    case RESET_FEED_ITEMS: {
      return {
        ...state,
        items: [],
      };
    }

    case POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS: {
      return {
        ...state,
        postReportTypes: action.payload,
      }
    }

    case REPORT_POST_REQUEST_SUCCESS: {
      return {
        ...state,
        reportPostRequestSuccessful: true
      }
    }

    case RESET_REPORT_POST_REQUEST_SUCCESS: {
      return {
        ...state,
        reportPostRequestSuccessful: null
      }
    }

    case SET_PIN_RETRY:
      return {
        ...state,
        pinRetry: action.payload,
      };

    default:
      return state;
  }
};

export default feed;
