import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logFirebaseEvent } from '../../firebase/analytics';
import ImageApi from '../../api/image';
import {
    verifyGroupRequest,
  } from '../../actionCreators/groupActions';

import GroupPlaceholder from '../../assets/images/group_placeholder.svg';
import TextField from '../global/fields/TextField';
import AddIcon from '../Icons/AddIcon';
import Public from '../../components/Icons/Public';
import Private from '../../components/Icons/Private';
import Secret from '../../components/Icons/Secret';

function GroupForm(props) {
    const { group, categories, groupPrivacyTypes, updateGroup, history, groupsDetailedUpdatedAt, getGroup, token, errorMessage, verifyGroup } = props;

    useEffect(() => {
        if (groupInitialized === false && group.id) {
            //update state for all & set groupInitilized as true
            setCategory(group && group.category_id ? group.category_id : '')
            setGroupPrivacy(group && group.group_privacy_type_id ? group.group_privacy_type_id : '')
            setImageBlobUrl(group && group.image ? group.image.url : '')
            setName({ value: group && group.name ? group.name : '', error: '' })
            setImageFile(group && group.image ? group.image.url : '')
            setDescription({ value: group && group.description ? group.description : '', error: '' })
            setSelectActive(false)
            setSelectGroupPrivacyActive(false)
            setShowErrors(false)
            setGroupInitialized(true)
        } else {
            if (group && group.image) {
                ImageApi.getImage(group.image.url).then(imageUrl => {
                    setImageBlobUrl(imageUrl);
                });
            }
        }
    }, [group])

    const [groupInitialized, setGroupInitialized] = useState(group && group.id ? true : false)
    const [category, setCategory] = useState(group && group.category_id ? group.category_id : '');
    const [groupPrivacy, setGroupPrivacy] = useState(group && group.group_privacy_type_id ? group.group_privacy_type_id : '');
    const [imageBlobUrl, setImageBlobUrl] = useState(group && group.image ? group.image.url : '');
    const [name, setName] = useState({ value: group && group.name ? group.name : '', error: '' });
    const [imageFile, setImageFile] = useState(null);
    const [description, setDescription] = useState({ value: group && group.description ? group.description : '', error: '' });
    const [selectActive, setSelectActive] = useState(false);
    const [selectGroupPrivacyActive, setSelectGroupPrivacyActive] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    let selectRef = null;
    let groupPrivacyRef = null;

    function handleSubmitForm() {
        if (name.error || description.error || !category || !groupPrivacy) return setShowErrors(true);

        const payload = {
            groupId: group ? group.id : null,
            name: name.value,
            description: description.value,
            categoryId: category,
            imageFile,
            token: token.key,
            groupPrivacyTypId: groupPrivacy
        };

        if (group) {
            updateGroup(payload);
            logFirebaseEvent({
                event: 'group',
                action: 'update',
            });
        }
    }
    function handleDiscard() {
        getGroup()
        history.goBack()
    }


    return (

        <div className='modal-content overflow-scroll create-group' style={{ maxWidth: '500px' }}>
            <div className='modal-header bg-white'>
                <h5 className='modal-title'>Manage Group</h5>
            </div>

            <div className='modal-body overflow-auto d-flex flex-column'>
                <input
                    id='avatarImage'
                    className='d-none'
                    type='file'
                    accept='image/x-png,image/jpeg,image/gif'
                    onChange={e => {
                        setImageBlobUrl(URL.createObjectURL(e.target.files[0]));
                        setImageFile(e.target.files[0]);
                    }}
                />

                <div
                    className='create-group__image-holder'
                    onClick={() => document.getElementById('avatarImage').click()}
                >
                    {imageBlobUrl ? (
                        <img className='create-group__image' src={imageBlobUrl} alt='avatar' />
                    ) : (
                            <img className='create-group__image_default' src={GroupPlaceholder} alt='avatar' />
                        )}
                </div>

                <div
                    className='create-group__add-button'
                    onClick={() => document.getElementById('avatarImage').click()}
                >
                    <AddIcon className='create-group__add-button-icon' />
                    {imageBlobUrl ? 'REPLACE IMAGE' : 'ADD AN IMAGE'}
                </div>

                <form>
                    <div className='form-group create-feed'>
                        {errorMessage && <span className='create-feed-error-message'>{errorMessage}</span>}
                        <TextField
                            fk='name'
                            type='text'
                            label='Group name'
                            value={name.value}
                            validateObj={{ requiredMax128: name.value }}
                            updateFieldData={(fk, value, error) => setName({ value, error })}
                            showError={showErrors}
                            triggerOnChange
                        />
                        <TextField
                            fk='description'
                            fieldTag='textarea'
                            type='text'
                            label='Description'
                            value={description.value}
                            validateObj={{ requiredMax255: description.value }}
                            updateFieldData={(fk, value, error) => setDescription({ value, error })}
                            showError={showErrors}
                            triggerOnChange
                        />

                        <div className='create-feed-select-holder'>
                            <span
                                className={`create-feed-select-label ${selectActive || category ? 'active' : ''}`}
                                onClick={() => selectRef.focus()}
                            >
                                Select category
                      </span>

                            <Select
                                ref={ref => (selectRef = ref)}
                                value={category}
                                onChange={value => {
                                    setCategory(value);
                                    selectRef.blur();
                                }}
                                className='create-feed-select'
                                showArrow={false}
                                dropdownClassName='create-feed-select-dropdown'
                                defaultActiveFirstOption={false}
                                open={selectActive}
                                onFocus={() => setSelectActive(true)}
                                onBlur={() => setSelectActive(false)}
                            >
                                {categories.length > 0 &&
                                    categories.map(category => (
                                        <Select.Option
                                            key={category.id + category['category_name']}
                                            value={category.id}
                                        >
                                            <div className='create-feed-select-dropdown-option'>
                                                {category['category_name']}
                                            </div>
                                        </Select.Option>
                                    ))}
                            </Select>

                            <FontAwesomeIcon className='create-feed-select-icon' icon={['fas', 'chevron-down']} />

                            {showErrors && !category && (
                                <div className='create-feed-select-error'>This field is required</div>
                            )}

                        </div>

                        <div className='create-feed-select-holder pb-4'>
                            <span
                                className={`create-feed-select-label ${selectGroupPrivacyActive || groupPrivacy ? 'active' : ''}`}
                                onClick={() => groupPrivacyRef.focus()}
                            >
                                Group privacy
                        </span>
                            <Select
                                ref={ref => (groupPrivacyRef = ref)}
                                value={groupPrivacy}
                                onChange={value => {
                                    setGroupPrivacy(value);
                                    groupPrivacyRef.blur();
                                }}
                                className='create-feed-select'
                                showArrow={false}
                                optionLabelProp="label"
                                defaultActiveFirstOption={false}
                                open={selectGroupPrivacyActive}
                                onFocus={() => setSelectGroupPrivacyActive(true)}
                                onBlur={() => setSelectGroupPrivacyActive(false)}
                            >
                                {groupPrivacyTypes.length > 0 &&
                                    groupPrivacyTypes.map(groupPrivacy => (
                                        <Select.Option
                                            key={groupPrivacy.id}
                                            value={groupPrivacy.id}
                                            label={groupPrivacy.name}
                                        >
                                            <div className="create-feed-select-group-container">
                                                {groupPrivacy.id === 'PUBLIC' && <Public />}
                                                {groupPrivacy.id === 'PRIVATE' && <Private />}
                                                {groupPrivacy.id === 'SECRET' && <Secret />}

                                                <div className="create-feed-select-group-innerCcontainer">
                                                    <div className='create-feed-select-group-dropdown-title'>
                                                        {groupPrivacy.name}
                                                    </div>
                                                    <div className='create-feed-select-group-dropdown-description'>
                                                        {groupPrivacy.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </Select.Option>
                                    ))}
                            </Select>

                            {showErrors && !groupPrivacy && (
                                <div className='create-feed-select-error'>This field is required</div>
                            )}

                            <FontAwesomeIcon className='create-feed-select-icon' icon={['fas', 'chevron-down']} />

                        </div>
                        <div className="manage-divider" />
                        <div className="d-flex flex-row justify-content-between pt-4">
                            <div
                                className={`manage-people__discard`}
                                onClick={handleDiscard}
                            >
                                DISCARD CHANGES
                            </div>
                            <div
                                className={`manage-people__save ml-auto`}
                                onClick={handleSubmitForm}
                            >
                                SAVE CHANGES
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default connect(
    state => ({
        ...state.group,
        categories: state.categories,
        currentUser: state.currentUser,
        groupPrivacyTypes: state.group.groupPrivacyTypes,
        errorMessage: state.group.verifyGroup.errorMessage,
    }),
    dispatch => ({
        verifyGroup: payload => dispatch(verifyGroupRequest(payload)),
    }),
)(GroupForm);

