import React, { useEffect, useState } from 'react';

const FeedGroupManagementItem = (props) => {
  const [usersString, setUsersString] = useState([]);
  const { item, users } = props;
  const { user_ids, member_counter } = item.post;

  useEffect(() => {
    if (member_counter > 1) {
      let strings = [];
      user_ids.forEach((user) => {
        strings.push(`${users[user].first_name} ${users[user].last_name}`);
      })
      setUsersString(strings.join(', '));
    } else {
      setUsersString(`${users[user_ids[0]].first_name} ${users[user_ids[0]].last_name}`)
    }
  }, []);

  return (
    <div className='feed-group-management-item-container'>
      <div className='feed-group-management-side-line' />
      <div className='feed-group-management-text'>
        {usersString} has joined the group
      </div>
      <div className='feed-group-management-side-line' />
    </div>
  )
}

export default FeedGroupManagementItem;
