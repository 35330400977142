import React from 'react';

export default function NoticeIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="26px"
      height="24px"
      viewBox="0 0 26 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="-🎯-7-Activity"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="7-Activity"
          transform="translate(-630.000000, -906.000000)"
          stroke={stroke}
          strokeWidth="2"
        >
          <g id="Badge" transform="translate(620.000000, 896.000000)">
            <g id="Group-9">
              <g id="Icons-/-Athletic" transform="translate(8.000000, 8.000000)">
                <g id="Group-9" transform="translate(3.571429, 3.571429)">
                  <path
                    d="M23.0158724,9.55404762 C23.0158724,14.7393651 17.8638884,18.9429365 11.5079362,18.9429365 C10.0896823,18.9429365 8.79444421,18.6369841 7.53968234,18.2540476 L0.863095215,21.4286508 L3.57142848,16.2699206 C1.4412698,14.5655556 0,12.1806349 0,9.55404762 C0,4.36873016 5.15198399,7.93650802e-05 11.5079362,7.93650802e-05 C17.8638884,7.93650802e-05 23.0158724,4.36873016 23.0158724,9.55404762 Z"
                    id="Stroke-1"
                  ></path>
                  <path
                    d="M7.00488091,6.03174627 L13.5092463,6.03174627"
                    id="Stroke-3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M7.00488091,9.20634891 L16.5116269,9.20634891"
                    id="Stroke-5"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M7.00488091,12.3809522 L16.5116269,12.3809522"
                    id="Stroke-7"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
