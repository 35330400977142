import { call, put, takeLatest, all } from 'redux-saga/effects';

import errorHandler from '../utils/requestErrorHandler';
import { DepartmentsApi } from '../api';
import { GET_DEPARTMENTS_START } from '../constants/actionTypes';
import { getDepartmentsSuccess } from '../actionCreators/departmentActions';

function* getDepartmentWorker() {
  try {
    const result = yield call(DepartmentsApi.getDepartments);
    yield put(getDepartmentsSuccess(result.departments));
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchDepartmentsRoot() {
  yield all([takeLatest(GET_DEPARTMENTS_START, getDepartmentWorker)]);
}
