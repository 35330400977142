import React from 'react';
import { connect } from 'react-redux';

import { signInRequestMagicLink } from '../../../actionCreators/auth';
import SignInComponentWithMagicLink from '../../../components/Auth/SignInWithMagicLink';

const SignInWithMagicLink = props => <SignInComponentWithMagicLink {...props} />;
const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
  signInWithMagicLink: payload => dispatch(signInRequestMagicLink(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInWithMagicLink);
