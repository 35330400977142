import React, { useState, useEffect } from 'react';

import imgTeams from '../../assets/images/img_teams.png';
import ImageApi from '../../api/image';

function OnBoardingScreen({ screen }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!screen.image_id || imageUrl) return;

    ImageApi.getImage(screen.image.url).then(image => {
      setImageUrl(image);
    });
  }, [screen.image]);

  let content = null;

  if (typeof screen.header !== 'string') {
    content = screen.header.map((header, index) => {
      return (
        <React.Fragment key={`onboarding:${index}`}>
          <p className="steps__header">
            <div
              dangerouslySetInnerHTML={{
                __html: header
              }}
            />
          </p>
          <p className="steps__text mb-0">
            <div
              dangerouslySetInnerHTML={{
                __html: screen.body[index]
              }}
            />
          </p>
        </React.Fragment>
      );
    });
  } else {
    content = (
      <React.Fragment>
        <p className="steps__header">
          <div
            dangerouslySetInnerHTML={{
              __html: screen.header
            }}
          />
        </p>
        <p className="steps__text mb-0">
          <div
            dangerouslySetInnerHTML={{
              __html: screen.body
            }}
          />
        </p>
      </React.Fragment>
    );
  }

  return (
    <div className="steps__holder">
      {content}
    </div>
  );
}

export default OnBoardingScreen;
