import {
  USER_PROFILE_CREATE_REQUEST,
  USER_PROFILE_CREATE_SUCCEEDED,
  USER_AVATAR_CREATE_REQUEST,
  SET_COLLEGUE_PROFILE,
  GET_COLLEGUE_PROFILE_REQUEST,
  GET_COLLEGUE_PROFILE_SUCCESS,
  GET_COLLEGUE_AVATAR_REQUEST,
  GET_COLLEGUE_AVATAR_SUCCESS,
  GET_USER_AVATAR_REQUEST,
  GET_USER_AVATAR_SUCCESS,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  GET_USER_ACTIVITY_POINTS_REQUEST,
  GET_USER_ACTIVITY_POINTS_SUCCESS,
  GET_USER_BADGES_REQUEST,
  GET_USER_BADGES_REQUEST_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_REQUEST_SUCCESS,
  SEND_USER_PULSE_REQUEST,
  GET_MICRO_LEARNING_REQUEST,
  GET_MICRO_LEARNING_REQUEST_SUCCESS,
  GET_MICRO_LEARNING_QUESTIONS_REQUEST,
  GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS,
  GET_MICRO_LEARNING_ANSWERS_REQUEST,
  GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS,
} from '../constants/actionTypes';

const createUserProfileRequest = payload => ({
  type: USER_PROFILE_CREATE_REQUEST,
  payload,
});

const createUserProfileSucceeded = data => ({
  type: USER_PROFILE_CREATE_SUCCEEDED,
  data,
});

const postUserAvatarRequest = payload => ({
  type: USER_AVATAR_CREATE_REQUEST,
  payload,
});

const setCollegueProfile = payload => ({
  type: SET_COLLEGUE_PROFILE,
  payload,
});

const getCollegueProfileRequest = payload => ({
  type: GET_COLLEGUE_PROFILE_REQUEST,
  payload,
});

const getCollegueProfileSuccess = payload => ({
  type: GET_COLLEGUE_PROFILE_SUCCESS,
  payload,
});

const getCollegueAvatarRequest = payload => ({
  type: GET_COLLEGUE_AVATAR_REQUEST,
  payload,
});

const getCollegueAvatarSuccess = payload => ({
  type: GET_COLLEGUE_AVATAR_SUCCESS,
  payload,
});

const getUserAvatarRequest = payload => ({
  type: GET_USER_AVATAR_REQUEST,
  payload,
});

const getUserAvatarSuccess = payload => ({
  type: GET_USER_AVATAR_SUCCESS,
  payload,
});

const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});

const getUserSuccess = payload => ({
  type: GET_USER_REQUEST_SUCCESS,
  payload,
});

const updateUserProfileRequest = payload => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
});

const updateUserProfileSuccess = payload => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

const getUserPointsRequest = () => ({
  type: GET_USER_ACTIVITY_POINTS_REQUEST,
});

const getUserPointsSuccess = payload => ({
  type: GET_USER_ACTIVITY_POINTS_SUCCESS,
  payload,
});

const getUserBadgesRequest = () => ({
  type: GET_USER_BADGES_REQUEST,
});

const getUserBadgesSuccess = payload => ({
  type: GET_USER_BADGES_REQUEST_SUCCESS,
  payload,
});

const sendUserPulseRequest = payload => ({
  type: SEND_USER_PULSE_REQUEST,
  payload,
});

const getUserMicroLearning = () => ({
  type: GET_MICRO_LEARNING_REQUEST,
});

const getUserMicroLearningSuccess = ({ learning }) => ({
  type: GET_MICRO_LEARNING_REQUEST_SUCCESS,
  learning,
});

const getUserMicroLearningQuestions = id => ({
  type: GET_MICRO_LEARNING_QUESTIONS_REQUEST,
  id,
});

const getUserMicroLearningQuestionsSuccess = payload => ({
  type: GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS,
  payload,
});

const getUserMicroLearningAnswers = payload => ({
  type: GET_MICRO_LEARNING_ANSWERS_REQUEST,
  payload,
});

const getUserMicroLearningAnswersSuccess = payload => ({
  type: GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS,
  payload,
});

export {
  createUserProfileRequest,
  createUserProfileSucceeded,
  postUserAvatarRequest,
  getUserMicroLearning,
  getUserMicroLearningSuccess,
  setCollegueProfile,
  getCollegueProfileRequest,
  getCollegueProfileSuccess,
  getCollegueAvatarRequest,
  getCollegueAvatarSuccess,
  getUserAvatarRequest,
  getUserAvatarSuccess,
  updateUserProfileRequest,
  updateUserProfileSuccess,
  getUserBadgesRequest,
  getUserBadgesSuccess,
  getUserPointsRequest,
  getUserPointsSuccess,
  getUserRequest,
  getUserSuccess,
  sendUserPulseRequest,
  getUserMicroLearningQuestions,
  getUserMicroLearningQuestionsSuccess,
  getUserMicroLearningAnswers,
  getUserMicroLearningAnswersSuccess,
};
