import React, { Component } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';

import { dateDifferenceShorted } from '../../../../utils/helpers';
import ImageApi from '../../../../api/image';
import UserPlaceholder from '../../../../assets/images/user_placeholder.svg';
import { connect } from 'react-redux';

import ErrorModal from '../../../ErrorModal';

import {
  deletePostRequest,
  reportPostRequest,
  pinPostRequest,
  setPinRetry,
  resetPostRequestSuccess,
} from '../../../../actionCreators/feedActions';

import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Dropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'
import { logFirebaseEvent } from '../../../../firebase/analytics';

class FeedItemHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
      menuOpen: false,
      confirmModalOpen: false,
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: '',
      selectedReportingReason: null,
      reportPostModalOpen: false,
      reportPostDescription: '',
    };
  }

  componentDidMount() {
    if (!this.props.author.image || !this.props.author.image.thumbnailUrl) return;

    ImageApi.getImage(this.props.author.image.thumbnailUrl).then(imageUrl => {
      this.setState({ imageUrl });
    });
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  toggleDeleteConfirmModal = () => {
    this.toggleMenu()
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen })
  }

  resetErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
      errorModalTitle: '',
      errorModalMessage: ''
    })
  }

  notifyAuthenticationError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Authentication Error',
      errorModalMessage: 'It seems you are not authenticated. Please login again.'
    })
  }

  notifyPermissionError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Permission Error',
      errorModalMessage: 'It seems you have not the right permissions for this group or operation'
    })
  }

  notifyPostNotFound = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Post not found',
      errorModalMessage: 'It seems this post does not exist anymore. Please refresh your page.'
    })
  }

  notifyGenericError = () => {
    this.setState({
      isErrorModalOpen: true,
      errorModalTitle: 'Error',
      errorModalMessage: 'An error occurred. Please try again or contact Engage4 support.'
    })
  }

  deletePost = async () => {
    logFirebaseEvent({
      event:'delete_post'
    })
    await this.props.deletePost({ postId: this.props.postId });
    this.props.hasPostSingleId && this.props.history.goBack();
  }

  pinPost = async (replace = false) => {
    logFirebaseEvent({
      event:'pin_post'
    });

    const { postId, isPinned, groupId, pinPost, setPinRetryRequest, pinRetry } = this.props;
    
    await pinPost({ 
      postId: pinRetry.postId || postId,
      pinned_post: pinRetry.isPinned || !isPinned,
      groupId: pinRetry.groupId || groupId,
      replacePost: replace,
    });

    replace && setPinRetryRequest({});
  }

  toggleReportPostModal = () => {
    this.resetPostReportModal()
    this.setState({ reportPostModalOpen: !this.state.reportPostModalOpen })
  }

  resetPostReportModal = () => {
    this.setState({
      reportPostModalOpen: false,
      selectedReportingReason: null,
      reportPostDescription: ''
    })
  }

  reportPost = async () => {
    // validating
    if (!this.state.selectedReportingReason) return

    this.toggleReportPostModal()
    logFirebaseEvent({
      event:'report_post'
    })
    await this.props.reportPost({
      postId: this.props.postId,
      reason_type: this.state.selectedReportingReason,
      reason_text: this.state.reportPostDescription
    })
  }

  render() {
    const {
      author,
      createdAt,
      link,
      group = {},
      hasGroupFeedId,
      currentUser,
      isNewGroupItem,
      pinRetry,
      groupId,
      orgGroup
    } = this.props;
    let groupName = null;

    if (!hasGroupFeedId) {
      groupName = (
        <>
          <span className="feed-item-header-normal-text">{isNewGroupItem ?' created a new group ':' in ' }</span>
          <Link to={`/group-feed/${group.id}`} className="feed-item-header-link">
            {group.name}
          </Link>
        </>
      );
    }

    return (
      <div className={`feed-item-header d-flex align-items-center ${isNewGroupItem ? 'pl-0':''}`}>

        <Modal isOpen={this.state.confirmModalOpen} centered>
          <ModalHeader>
            <div>Confirm</div>
          </ModalHeader>
          <ModalBody>
            <div>Do you want to delete it?</div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.toggleDeleteConfirmModal}>Cancel</Button>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={() => this.deletePost()}>Confirm</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={pinRetry && pinRetry.postId} centered>
          <ModalHeader>
            <div>Maximum pinned posts</div>
          </ModalHeader>
          <ModalBody>
            <div>It looks like you already have 3 pinned posts, would you like us to unpin the oldest item and pin this one?</div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={() => this.props.setPinRetryRequest({})}>No</Button>
            <div style={{ flex: 1 }} />
            <Button color='primary' onClick={() => this.pinPost(true)}>Yes</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.reportPostModalOpen} centered style={{ maxWidth: '600px', width: '100%' }}>
          <ModalHeader toggle={this.toggleReportPostModal}>
            <div>Report Post</div>
          </ModalHeader>
          <ModalBody>

            <UncontrolledDropdown >
              <DropdownToggle data-toggle='dropdown' outline style={{ width: '100%', textAlign: 'left' }} color={'secondary'}>
                <div>{this.state.selectedReportingReason ? this.props.postReportTypes.find(postReportType => postReportType.id === this.state.selectedReportingReason).report_type_text : 'Select reason'}</div>
              </DropdownToggle>
              <DropdownMenu>
                {
                  this.props.postReportTypes &&
                  this.props.postReportTypes.map(postReportType => {
                    return (
                      <DropdownItem key={postReportType.id} 
                        onClick={() => this.setState({ selectedReportingReason: postReportType.id })}>
                          {postReportType.report_type_text}
                      </DropdownItem>
                    )
                  })
                }
              </DropdownMenu>
            </UncontrolledDropdown>
            <div style={{ width: '100%', height: 20 }} />
            <Input type="textarea" name="text" id="description" placeholder={'Description (optional)'} maxLength={240} onChange={(e) => this.setState({ reportPostDescription: e.target.value })} />
          </ModalBody>
          <ModalFooter>
            <div style={{ flex: 1 }} />
            <Button className="modal-button" onClick={this.reportPost}>SUBMIT REPORT</Button>
          </ModalFooter>
        </Modal>

        <ErrorModal
          isOpen={this.state.isErrorModalOpen}
          title={this.state.errorModalTitle}
          message={this.state.errorModalMessage}
          onClose={this.resetErrorModal}
        />

        {!isNewGroupItem ? (
        <Avatar
          shape="square"
          className="feed-item-header-image"
          src={this.state.imageUrl || UserPlaceholder}
          icon="user"
          size={42}
        />
        ) : null}

        <div className="feed-item-header-content d-flex flex-column flex-fill">
          <div className="d-flex justify-content-between">
            <p className="feed-item-header-text">
              <Link className="feed-item-header-text" to={`/users/${author.id}`}>
                {author.first_name} {author.last_name}
              </Link>

              {groupName}
            </p>

            {!isNewGroupItem ? (
            <div>
              <Dropdown isOpen={this.state.menuOpen} direction='left' toggle={this.toggleMenu}>
                <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={this.state.menuOpen}>
                  <FontAwesomeIcon
                    className="feed-item-header-collapse"
                    icon={['fas', 'chevron-down']}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {
                    (currentUser.userData.is_admin || !!group.is_moderator || currentUser.userData.id === author.id) &&
                    <DropdownItem onClick={this.toggleDeleteConfirmModal}>Delete</DropdownItem>
                  }
                  {
                    ((group.is_moderator) || currentUser.userData.is_admin) &&
                    <DropdownItem onClick={() => this.pinPost()}>
                      {this.props.isPinned ? 'Unpin Post' 
                        : currentUser.userData.is_admin && orgGroup.id === groupId ? 'Pin Post to Group and Main Feed' 
                        : 'Pin Post to Group'} 
                    </DropdownItem>
                 }
                 
                  <DropdownItem onClick={this.toggleReportPostModal}>Report Post</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            ) : null}
          </div>

          {!isNewGroupItem ? (
            <Link to={link} className='feed-item-header-time'>
            {dateDifferenceShorted(createdAt)}
          </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    group: lodash.find(state.group.myGroups, { id: props.groupId }),
    orgGroup: lodash.find(state.group.myGroups, { is_org_level: 1 }) || {},
    postReportTypes: state.feed.postReportTypes,
    reportPostRequestSuccessful: state.feed.reportPostRequestSuccessful,
    currentUser: state.currentUser,
    pinRetry: state.feed.pinRetry
  }),
  dispatch => ({
    deletePost: payload => dispatch(deletePostRequest(payload)),
    reportPost: payload => dispatch(reportPostRequest(payload)),
    pinPost: payload => dispatch(pinPostRequest(payload)),
    setPinRetryRequest: payload => dispatch(setPinRetry(payload)),
    resetPostRequestSuccess: () => dispatch(resetPostRequestSuccess())
  })
)(FeedItemHeader);