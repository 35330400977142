import { put, takeLatest, call } from 'redux-saga/effects';

import {
  getMyEventsSuccess,
  getAllEventsSuccess,
  getSingleEventSuccess,
} from '../actionCreators/events';
import {
  GET_MY_EVENTS_REQUEST,
  GET_EVENTS_REQUEST,
  GET_SINGLE_EVENT_REQUEST,
  EVENT_NOT_GOING_REQUEST,
  EVENT_GOING_REQUEST,
  GET_SINGLE_FEED_REQUEST,
} from '../constants/actionTypes';
import EventsApi from '../api/events';
import errorHandler from '../utils/requestErrorHandler';

function* getAllEventsRequest() {
  try {
    const data = yield call(EventsApi.getAllEvents);

    yield put(getAllEventsSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getMyEventsRequest() {
  try {
    const data = yield call(EventsApi.getMyEvents);

    yield put(getMyEventsSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getEventRequest(action) {
  try {
    const data = yield call(EventsApi.getSingleEvent, action);

    yield put(getSingleEventSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* eventGoingRequest(action) {
  try {
    yield call(EventsApi.eventGoing, action);

    yield put({ type: GET_SINGLE_FEED_REQUEST });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* eventNotGoingRequest(action) {
  try {
    yield call(EventsApi.eventNotGoing, action);

    yield put({ type: GET_SINGLE_FEED_REQUEST });
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchCategory() {
  yield takeLatest(GET_EVENTS_REQUEST, getAllEventsRequest);
  yield takeLatest(GET_MY_EVENTS_REQUEST, getMyEventsRequest);
  yield takeLatest(GET_SINGLE_EVENT_REQUEST, getEventRequest);
  yield takeLatest(EVENT_GOING_REQUEST, eventGoingRequest);
  yield takeLatest(EVENT_NOT_GOING_REQUEST, eventNotGoingRequest);
}
