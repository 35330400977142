import validatejs from 'validate.js';
import moment from 'moment';

validatejs.extend(validatejs.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  },
});

const validation = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    email: {
      message: '^Please enter a valid email',
    },
  },
  username: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
  },
  confirmPassword: {
    equality: 'password',
  },
  required: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
  },
  url: {
    url: true,
  },

  name: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
  },
  tel: {
    presence: {
      allowEmpty: true,
    },
    format: {
      pattern: /^.{0,16}/,
      flags: 'g',
      message: '^Too many characters entered - maximum is 14',
    },
  },

  notEmptyPollOption: {
    presence: {
      allowEmpty: false,
      message: "^This field can't be empty",
    },
    length: {
      minimum: 1,
      message: '^At least 3 characters are required',
    },
  },
  requiredPollOption: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    length: {
      maximum: 40,
      message: '^Too many characters entered - maximum is 40',
    },
  },

  requiredMax2048: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    length: {
      maximum: 2048,
      message: '^Too many characters entered - maximum is 2048',
    },
  },
  requiredMax2500: {
    length: {
      maximum: 2500,
      message: '^Too many characters entered - maximum is 2500',
    },
  },
  requiredMax128: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    length: {
      maximum: 128,
      message: '^Too many characters entered - maximum is 128',
    },
  },
  requiredMax255: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    length: {
      maximum: 255,
      message: '^Too many characters entered - maximum is 255',
    },
  },
  max2048: {
    length: {
      maximum: 2048,
      message: '^Too many characters entered - maximum is 2048',
    },
  },
  max256: {
    length: {
      maximum: 256,
      message: '^The Message may not be greater than 256 characters.',
    },
  },
  requiredMax140: {
    length: {
      maximum: 140,
      message: '^Too many characters entered - maximum is 140',
    },
  },
  requiredMax350: {
    length: {
      maximum: 350,
      message: '^Too many characters entered - maximum is 350',
    },
  },
  alwaysRequired: {
    length: {
      minimum: 5,
      maximum: 4,
      message: '^This field is required',
    },
  },

  requiredDate: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    datetime: {
      dateOnly: false,
      message: '^This field is required!',
    },
  },
  dateIsFuture: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    equality: {
      attribute: 'startDate',
      message: "^Start date can't be in the past",
      comparator: function (v1) {
        return (
          new Date(v1).getTime() >=
          moment()
            .startOf('day')
            .toDate()
            .getTime()
        );
      },
    },
  },
  timeIsFuture: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    equality: {
      attribute: 'startDate',
      message: "^Start time can't be in the past",
      comparator: function (v1) {
        return new Date(v1).getTime() >= new Date().getTime();
      },
    },
  },
  dateIsLater: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    equality: {
      attribute: 'startDate',
      message: "^End date can't be before start date",
      comparator: function (v1, v2) {
        return new Date(v1).getTime() >= new Date(v2).getTime();
      },
    },
  },
  requiredMax64: {
    presence: {
      allowEmpty: false,
      message: '^This field is required',
    },
    length: {
      maximum: 64,
      message: '^Too many characters entered - maximum is 64',
    },
  },
  userTags: {
    presence: {
      allowEmpty: true,
      message: '^This field is required',
    },
  },
};

export default function validate (obj) {
  const formValues = obj;
  const constraints = {};

  Object.keys(obj).map(key => (constraints[key] = validation[key]));

  const result = validatejs(formValues, constraints);

  if (result) return Object.values(result)[0];

  return null;
}
