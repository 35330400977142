import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';

import { addAlertToAlertsList } from '../actionCreators/globalAlerts';
import {
  addPreloaderToGlobalPreloadersList,
  removePreloaderToGlobalPreloadersList,
} from '../actionCreators/globalPreloaders';
import {
  signInSucceeded,
  signInSucceededWithPassword,
  signInSucceededWithMagicLink,
  userLocked,
  logAppSession,
} from '../actionCreators/auth';
import {
  SIGN_IN_REQUEST,
  SIGN_IN_REQUEST_WITH_MAGIC_LINK,
  SIGN_IN_REQUEST_WITH_PASSWORD,
  LOG_APP_SESSION_REQUEST,
  LOG_APP_SESSION_SUCCESS,
} from '../constants/actionTypes';

import AuthApi from '../api/auth';
import { logFirebaseUserProperty } from '../firebase/analytics';
import { getOrgThemeRequest } from '../actionCreators/orgTheme';

function * signInRequest (action) {
  const preloaderId = Date.now();

  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));
  
  try {
    const data = yield call(AuthApi.getToken, action.payload);
    if (data.failure_reason === 'LOCKED') {
      return yield put(
        userLocked({
          locked: true,
          lockedUntil: data.locked_until,
        }),
      );
    }
    if (data.auth_type !== 'PASSWORD') {
      yield put(
        addAlertToAlertsList({
          title: 'Magic link sent',
          message: 'Please check your email, magic link has been sent.',
          messageStyle: { color: '#505A62' },
          confirmBtnText: 'OKAY',
        }),
      );
    } else {
      if (!data.is_registered) {
        yield put(push(`/account?username=${action.payload.username}`));
      }
    }
    yield put(signInSucceeded(data));
  } catch (error) {
    yield put(
      addAlertToAlertsList({
        title: 'Error',
        message: 'Incorrect credentials',
      }),
    );
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * signInRequestWithPassword (action) {
  const preloaderId = Date.now();

  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));

  try {
    const data = yield call(AuthApi.authenticationWithPassword, action.payload);
    if (data.failure_reason === 'LOCKED') {
      return yield put(
        userLocked({
          locked: true,
          lockedUntil: data.locked_until,
        }),
      );
    }
    const decodedJWT = jwt_decode(data.key);

    if (!decodedJWT.first_name || !decodedJWT.last_name || !decodedJWT.job_title) {
      yield put(push('/onboarding'));
    } else yield put(push('/'));
    logFirebaseUserProperty()
    document.dispatchEvent(new Event('showConsentModal'));
    yield put(logAppSession());
    yield put(getOrgThemeRequest())
    yield put(signInSucceededWithPassword(data));
  } catch (error) {
    let message = 'Something went wrong.';
    if (error && error.fieldsErrors && error.fieldsErrors.message)
      message = error.fieldsErrors.message;
    if (message.indexOf('Incorrect username or password') !== -1) {
      message =
        'The information you have entered is not correct, please try again. Please note you have 3 attempts before your account will be locked.';
    }
    yield put(
      addAlertToAlertsList({
        title: 'Error',
        message: 'Incorrect credentials',
      }),
    );
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * signInRequestWithMagicLink (action) {
  const preloaderId = Date.now();

  yield put(addPreloaderToGlobalPreloadersList({ id: preloaderId }));

  try {
    const data = yield call(AuthApi.authenticationWithMagicLink, action.payload);

    yield put(signInSucceededWithMagicLink(data));
    const decodedJWT = jwt_decode(data.key);

    if (!decodedJWT.first_name || !decodedJWT.last_name || !decodedJWT.job_title) {
      yield put(logAppSession());
      yield put(getOrgThemeRequest())
      yield put(push('/onboarding'));
    } else yield put(push('/'));
  } catch (error) {
    let message = 'Something went wrong.';
    if (error && error.fieldsErrors && error.fieldsErrors.message)
      message = error.fieldsErrors.message;

    yield put(addAlertToAlertsList({ title: 'Error', message: 'Incorrect credentials' }));
  } finally {
    yield put(removePreloaderToGlobalPreloadersList(preloaderId));
  }
}

function * logAppSessionSaga (action) {
  try {
    const data = yield call(AuthApi.logAppSession);
    yield put({ type: LOG_APP_SESSION_SUCCESS });
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

export default function * watchAuth () {
  yield takeLatest(SIGN_IN_REQUEST, signInRequest);
  yield takeLatest(SIGN_IN_REQUEST_WITH_PASSWORD, signInRequestWithPassword);
  yield takeLatest(SIGN_IN_REQUEST_WITH_MAGIC_LINK, signInRequestWithMagicLink);
  yield takeLatest(LOG_APP_SESSION_REQUEST, logAppSessionSaga);
}
