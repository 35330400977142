import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Portal, ThumbnailImage } from '../../components';
import { getMoreUsersRequest, getUsersRequest } from '../../actionCreators/users';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CrossIcon from '../../components/Icons/CrossIcon';
import { Skeleton } from 'antd';
import './styles.scss';
import ScrollView from '../../components/ScrollView';
import { logFirebaseEvent } from '../../firebase/analytics';

const TEXT_LOOKUP_BY_TYPE = {
  group: 'Members',
  event: 'Attending',
  challenge: 'Attending',
  like: 'Liked',
};

const SkeletalAvatar = () => (
  <div className="col-lg-6 mb-3 px-2 remove-highlight-link">
    <div className="card d-flex flex-row p-3 align-items-center overflow-hidden line-item">
      <Skeleton
        avatar={{ shape: 'square', style: { borderRadius: 8 } }}
        paragraph={{ rows: 0 }}
        className="placeholder"
        active
      />
    </div>
  </div>
);

const UserListModal = ({
  users,
  loadingMore,
  lastPage,
  location,
  match,
  history,
  getUsers,
  getMoreUsers,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const { id: paramId } = match.params;
  const type = searchParams.get('listType');
  const showUsers = searchParams.get('showUsers');
  const queryId = searchParams.get('id');
  const id = queryId || paramId;

  useEffect(() => {
    if (showUsers) {
      logFirebaseEvent({
        event:'view_members',
        action:type
      });
      getUsers({ type, id });
    }
  }, [getUsers, id, showUsers, type]);

  const loadMore = () => {
    if (!lastPage) {
      getMoreUsers({ type, id });
    }
  };

  const renderUsers = () => (
    <ScrollView onEndReached={loadMore} style={{ height: 500, maxHeight: 500 }}>
      <div className="container mt-2">
        <div className="row d-flex justify-content-between">
          {users
            .filter(item => item.first_name || item.last_name)
            .map(item => (
              <Link
                to={`/users/${item.id}`}
                className="col-lg-6 mb-3 px-2 remove-highlight-link"
                key={item.id}
              >
                <div className="card d-flex flex-row p-3 align-items-center overflow-hidden line-item">
                  <ThumbnailImage sourceUrl={item.image.thumbnailUrl} type="person" />

                  <div className="truncate-1-lines card-block pl-2 pt-1">
                    <h6 className="card-title mb-0 single-line-text font-weight-semibold">{`${item.first_name ||
                      ''} ${item.last_name || ''}`}</h6>
                    <p className="card-text">{item.job_title}</p>
                  </div>
                </div>
              </Link>
            ))}
          {loadingMore && <SkeletalAvatar />}
        </div>
      </div>
    </ScrollView>
  );

  const renderSkeletalUsers = () => (
    <div className="overflow-auto" style={{ height: 500, maxHeight: 500 }}>
      <div className="container mt-2">
        <div className="row d-flex justify-content-between">
          {Array(16)
            .fill(0)
            .map(item => (
              <SkeletalAvatar />
            ))}
        </div>
      </div>
    </div>
  );

  return (
    <Portal isOpen={showUsers} hide={history.goBack}>
      <div className="container like-search-wrapper">
        <div className="row">
          <div className="justify-content-center align-self-center w-100">
            <div className="col-lg-8 col-md-8 m-auto search-container">
              <div className="container-with-shadow bg-white search-inner-container rounded-lg overflow-auto px-2 py-0">
                <div className="d-flex flex-row align-items-center justify-content-between mt-2 mx-2">
                  <h4 className="font-weight-semibold mt-2">People</h4>
                  <button className="btn p-0" onClick={history.goBack}>
                    <CrossIcon />
                  </button>
                </div>
                {users.length === 0 ? renderSkeletalUsers() : renderUsers()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

const mapStateToProps = state => ({
  users: state.users.users,
  loadingMore: state.users.state === 'PENDING' && state.users.users.length > 0,
  lastPage: state.users.pagination.page >= state.users.pagination.pages,
});

const dispatchProps = {
  getUsers: getUsersRequest,
  getMoreUsers: getMoreUsersRequest,
};

UserListModal.propTypes = {
  users: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, dispatchProps)(UserListModal);
