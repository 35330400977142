import { all } from 'redux-saga/effects';

import auth from './auth';
import category from './category';
import challenge from './challenge';
import search from './search';
import userProfile from './userProfile';
import feed from './feedSagas';
import group from './groupSagas';
import events from './events';
import notifications from './notificationSagas';
import users from './users';
import departments from './departments';
import userTags from './userTags';
import knowledgebase from './knowledgebaseSagas';
import account from './account';
import featureFlags from './featureFlags';
import manageGroupMembers from './manageGroupMembers';
import employeeOnboarding from './employeeOnboarding';
import orgTheme from './orgTheme';
import customApps from './customApps';


export default function * rootSaga () {
  yield all([
    auth(),
    category(),
    challenge(),
    group(),
    search(),
    userProfile(),
    feed(),
    events(),
    notifications(),
    users(),
    departments(),
    userTags(),
    knowledgebase(),
    account(),
    featureFlags(),
    manageGroupMembers(),
    employeeOnboarding(),
    orgTheme(),
    customApps()
  ]);
}
