import React from 'react'

const FeedItemTag = ({ title }) => {
    return (
        <div className="feed-item-tag">
            {title}
        </div>
    )
}

export default FeedItemTag;