import React from 'react';
import CrossIcon from '../Icons/CrossIcon';
import { Link } from 'react-router-dom';
import './styles.scss';

const MicroLearningModal = ({ learningAvatar, hide, learningItem, taskId, firstTimeLogin }) => {
  return (
    <React.Fragment>
      <div className='container mt-2 learning-modal-container'>
        <div className='row '>
          <div className='w-100'>
            {learningItem.image_id && (
              <div className='img-upper-container'>
                <img className='avatar-img' src={learningAvatar} alt='' />
                <button type='button' className='close' onClick={hide}>
                  <CrossIcon stroke='var(--primary-color)' className='modal-close' />
                </button>
              </div>
            )}
            <div className='description-container w-100'>
              <h5 className={!learningItem.image_id ? 'title mt-3' : 'title'}>
                {learningItem.name}
              </h5>
              <p className='description'>{learningItem.description}</p>
              <p className='time'>{`Time to complete: ${learningItem.time_to_complete} ${
                learningItem.time_to_complete > 1 ? 'mins' : 'min'
              }`}</p>
              <div className='d-flex align-items-center mb-4'>
                <div className='learning-item--points'>{learningItem.points}</div>
                <p className='m-0 ml-2 modal-subtitle'>Micro-learning</p>
              </div>
            </div>
            <div className='bottom-container'>
              <Link to={taskId ? `/learning/${learningItem.id}?taskId=${taskId}&firstTimeLogin=${firstTimeLogin}` : `/learning/${learningItem.id}`}>
                <button className='text-uppercase start-btn'>start learning</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MicroLearningModal;
