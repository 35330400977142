import React from 'react';
import GroupTagPrivate from '../../../Icons/GroupTagPrivate';
import GroupTagSecret from '../../../Icons/GroupTagSecret';

export default function FeedGroupPrivacy({ groupPrivacyTypeId = '' }) {
  if (groupPrivacyTypeId === 'PUBLIC') return null;

  const privacyTitle = `${groupPrivacyTypeId.charAt(0).toUpperCase()}${groupPrivacyTypeId.slice(1).toLowerCase()} group`;

  return (
    <>
      <div className="feed-item-group-privacy-InnerContainer">
        {groupPrivacyTypeId === 'PRIVATE' ? <GroupTagPrivate /> : <GroupTagSecret />}
        <p className="feed-item-group-privacy">{privacyTitle}</p>
      </div>
      <div className="feed-item-group-privacy-line" />
    </>
  );
}
