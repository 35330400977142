import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextField from '../../global/fields/TextField';
import AddIcon from '../../Icons/AddIcon';
import CrossWithBgIcon from '../../Icons/CrossWitBgIcon';
import Button from '../../global/Button';
import { addAlertToAlertsList } from '../../../actionCreators/globalAlerts';
import Resizer from 'react-image-file-resizer';
import { InputWithMentions } from '../../../containers';
import KnowledgebaseItem from '../../Knowledgebase/KnowledgebaseItem';
import { ChallengePostCard} from '../../../components';

class CreateFeedItemNotice extends Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      text: {
        value: '',
        error: null,
      },
      mediaFile: null,
      imageBlobUrl: '',
      videoName: '',
      isTextareaFocused: false,
      mediaType: '',
      videoBlobUrl: '',
    };
  }

  _onManageAttachmentClick = () => {
    this.fileInputRef.current.click();
  };

  _onFileSelect = event => {
    try {
      const mediaFile = event.target.files[0];
      const fileBlobUrl = URL.createObjectURL(mediaFile);
      const mediaType = mediaFile.type.indexOf('video') !== -1 ? 'video' : 'image';

      if (mediaType === 'video') {
        if (mediaFile.size > 200 * 1000 * 1000) {
          //Cloudflare limit is 200 MB for direct user uploads
          this.props.addAlertToAlerts({
            title: 'Message',
            message: `Your video exceeds the maximum size of 200MB, please select another video`,
            confirmBtnText: 'Okay',
          });
          return;
        }
        this.setState({
          mediaFile,
          mediaType,
          videoBlobUrl: fileBlobUrl,
          imageBlobUrl: '',
          videoName: mediaFile.name,
        });
      } else {
        Resizer.imageFileResizer(
          mediaFile,
          1500,
          1500,
          'JPEG',
          90,
          0,
          resizedImageBlob => {
            var resizedImage = new File([resizedImageBlob], mediaFile.name, {
              type: mediaFile.type,
              lastModified: Date.now(),
            });
            this.setState({
              mediaFile:resizedImage,
              mediaType,
              videoBlobUrl: '',
              imageBlobUrl: fileBlobUrl,
              videoName: '',
            });
          },
          'blob',
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  _checkForVideoFileDuration = videoEvent => {
    if (videoEvent.currentTarget.duration > 1800) {
      this.props.addAlertToAlerts({
        title: 'Message',
        message: `Your video exceeds the maximum length of 30 minutes, please select another video`,
        confirmBtnText:'Okay'
      });
      this._removeFile();
    }
  };
  _removeFile = () => {
    this.setState({
      mediaType: '',
      mediaFile: null,
      imageBlobUrl: '',
      videoName: '',
      videoBlobUrl: '',
    });
  };

  _submit = () => {
    if (typeof this.props.onSubmit !== 'function') return;
    const { mediaType, mediaFile } = this.state;
    const { onSubmit, challengeItem, knowledgebaseItem} = this.props;
    const data = {
      notice: this.state.text,
    };

    if (challengeItem){
      data['challenge_id'] = challengeItem.id;
    }

    if (knowledgebaseItem){
      data['knowledgebase_item_id'] = knowledgebaseItem.id;
    }

    if (mediaFile) {
      data[mediaType] = mediaFile;
    }
    onSubmit('Notice', data);
  };

  _shouldShowButton = () => {
    if (typeof this.props.checkFields !== 'function') return;
    const data = {
      notice: this.state.text,
    };
    if (this.state.mediaFile) data.image = this.state.mediaFile;
    return this.props.checkFields('Notice', data);
  };

  render () {
    const {shouldShowAttachedMedia = true, challengeItem, knowledgebaseItem} = this.props;
    const showButton = this._shouldShowButton();
    const {
      isTextareaFocused,
      text,
      imageBlobUrl,
      mediaType,
      videoName,
      videoBlobUrl,
      mediaFile,
    } = this.state;
    return (
      <div className='create-feed-extra-block'>
        <div
        // className={`create-feed-textarea-holder ${this.state.isTextareaFocused ? 'focused' : ''}`}
        >
          {/* <TextField
            fk="text"
            value={this.state.text.value}
            updateFieldData={(fk, value, error) => this.setState({ text: { value, error } })}
            triggerOnChange={true}
            fieldTag="textarea"
            rows="3"
            label="New post"
            className="custom-textarea"
            onFocus={() => this.setState({ isTextareaFocused: true })}
            onBlur={() => this.setState({ isTextareaFocused: false })}
            validateRule='requiredMax2500'
            validateObj={{ requiredMax2500: this.state.text.value }}
            showError={true}
            error={this.state.text.error}
            triggerOnChange={true}
          /> */}

          <InputWithMentions
            isAdmin={this.props.isAdmin}
            maxLength={2500}
            label='New post'
            isPostCreation={true}
            onChange={changedTxt => this.setState({ text: { value: changedTxt } })}
            selectedGroup={this.props.selectedGroup}
          />
          {(imageBlobUrl || videoName) && (
            <div className='create-feed-notice-image-holder'>
              {mediaType === 'image' ? (
                <img src={imageBlobUrl} className='create-feed-notice-image' alt='attachment' />
              ) : (
                <video
                  key={videoBlobUrl}
                  width='100%'
                  controls
                  onDurationChange={this._checkForVideoFileDuration}
                  src={videoBlobUrl}
                >
                  <source src={videoBlobUrl} />
                  Your browser does not support HTML video.
                </video>
              )}
              <div className={`create-feed-notice-remove`} onClick={this._removeFile}>
                <CrossWithBgIcon className='create-feed-notice-image-remove-icon' />
              </div>
            </div>
          )}
          {challengeItem ? <ChallengePostCard challengeItem={challengeItem} /> : null}
        </div>
        {shouldShowAttachedMedia ? (
          <div className='create-feed-add-text' onClick={this._onManageAttachmentClick}>
          <AddIcon className='create-feed-add-icon' />
            {imageBlobUrl || videoName
              ? imageBlobUrl
              ? 'REPLACE IMAGE'
              : 'REPLACE VIDEO'
              : 'ATTACH MEDIA'}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={this.fileInputRef}
              onChange={this._onFileSelect}
            />
          </div>
        ) : null}
        {knowledgebaseItem && (
          <KnowledgebaseItem viewOnly item={knowledgebaseItem} />
        )}
        <div className="create-feed-post-button">
          <Button.Primary onClick={this._submit} type="submit" disabled={!showButton}>
            POST
          </Button.Primary>
        </div>
        <div className="create-feed-empty-space" />
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  addAlertToAlerts: payload => dispatch(addAlertToAlertsList(payload)),
}))(CreateFeedItemNotice);
