import React from 'react';

export default function SocialIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="27px"
      height="28px"
      viewBox="0 0 27 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="🚨-8-Notifications"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="8-Notifications"
          transform="translate(-22.000000, -857.000000)"
          stroke={stroke}
          strokeWidth="2"
        >
          <g id="Icons-/-Polls-social" transform="translate(21.000000, 856.000000)">
            <g id="Group-17" transform="translate(2.857143, 2.857143)">
              <path
                d="M19.0345558,19.2206192 L20.8918526,19.2206192 C21.8115175,19.2206192 22.5632109,18.4680902 22.5632109,17.5492611 L22.5632109,13.3708657 C22.5632109,12.4512009 21.8115175,11.6995076 20.8918526,11.6995076 L18.1984588,11.6995076"
                id="Stroke-1"
              ></path>
              <path
                d="M21.7276565,22.5633352 C21.7276565,23.4821644 20.9755453,24.2346934 20.0562983,24.2346934 L4.17839546,24.2346934 C3.25914845,24.2346934 2.50703726,23.4821644 2.50703726,22.5633352 C2.50703726,21.6436704 3.25914845,20.891977 4.17839546,20.891977 L20.0562983,20.891977 C20.9755453,20.891977 21.7276565,21.6436704 21.7276565,22.5633352 Z"
                id="Stroke-3"
                strokeLinecap="round"
              ></path>
              <path
                d="M5.48660934,13.1713472 L4.17835372,20.8921862"
                id="Stroke-5"
                strokeLinecap="round"
              ></path>
              <path
                d="M18.1303092,11.0874564 L19.2204526,20.8920615"
                id="Stroke-7"
                strokeLinecap="round"
              ></path>
              <path
                d="M14.9696037,13.6981174 L15.4601473,18.3850237"
                id="Stroke-9"
                strokeLinecap="round"
              ></path>
              <path
                d="M11.6995073,13.7887056 L11.6995073,18.3849406"
                id="Stroke-11"
                strokeLinecap="round"
              ></path>
              <path
                d="M8.64731515,13.9763982 L8.35691666,18.3850234"
                id="Stroke-13"
                strokeLinecap="round"
              ></path>
              <path
                d="M19.2206196,2.50703726 C18.4221282,2.50703726 17.7673736,2.9566326 17.1314218,3.34271634 C16.1866866,1.42149012 14.6243845,0 12.1173471,0 C9.6103098,0 7.62891462,1.42149012 6.6854329,3.34271634 C6.04989894,2.9566326 5.39347299,2.50703726 4.59623512,2.50703726 C2.08919778,2.50703726 0,4.17839543 0,6.68543268 C0,9.19246994 2.31190627,11.2302734 5.01407468,10.8638281 C4.63551204,12.5122051 5.91619028,14.2245116 7.5938161,14.2245116 C9.05040479,14.2245116 10.1652007,12.9358944 10.0281494,11.2816677 C12.0738918,11.8674787 14.1902492,11.4579959 15.8779031,9.61030948 C16.6483993,10.4493313 17.9921713,11.2816677 19.2206196,11.2816677 C21.7276569,11.2816677 23.3990152,9.19246994 23.3990152,6.68543268 C23.3990152,4.17839543 21.7276569,2.50703726 19.2206196,2.50703726 Z"
                id="Stroke-15"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
