import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clearSingleChallenge,
  getChallengeRequest,
  updateChallengeJoinedRequest,
  updateChallengeRequest,
} from '../../actionCreators/challenge';
import { Link, useLocation } from 'react-router-dom';
import ProgressBar from '../../components/global/ProgressBar';
import CreateFeedItem from '../../components/Feed/CreateFeedItem/CreateFeedItem';
import { ChallengeBadgeCount, Portal, RoundedSwitch, UpdateChallenge } from '../../components';
import moment from 'moment';
import ParticipantList from '../../components/ParticipantList';
import { logFirebaseEvent } from '../../firebase/analytics';

import './styles.scss';

function SingleChallenge ({
  match,
  challenge = {},
  getChallenge,
  updateChallenge,
  updateChallengeJoined,
  clearChallenge,
}) {
  const [showUpdate, toggleShowUpdate] = useState(false);
  const [isTotalChoose, handleTotal] = useState(false);
  const [showShareModal, togglShowShareModal] = useState(false);

  useEffect(() => {
    getChallenge({ id: match.params.engageId });

    // Clear single challenge on unmount
    return () => clearChallenge();
  }, [clearChallenge, getChallenge, match.params.engageId]);

  useEffect(() => {
    logFirebaseEvent({
      event: 'challenges',
      action: 'detail',
    });
  }, []);

  const getWidth = () =>
    challenge.complete ? 100 : (100 * (challenge.counter || 0)) / (challenge.target_count || 1);

  function updateJoinedStatus (value) {
    updateChallengeJoined({ id: challenge.id, action: value ? 'join' : 'leave' });
    logFirebaseEvent({
      event: 'challenges',
      action: value ? 'join' : 'leave',
    });
  }

  function taskCompleted() {
    logFirebaseEvent({
      event: 'employee_onboarding',
      action: 'task_completed'
    })
  }

  function handleTotalChoosen () {
    updateChallenge({ id: challenge.id, count: challenge.target_count });
    handleTotal(!isTotalChoose);
  }

  function handleModal () {
    if (challenge.target_count === 1) {
      handleTotal(true);
    } else {
      toggleShowUpdate(true);
    }
  }

  function onToggleCreateFeed (isActive) {
    togglShowShareModal(isActive);
  }

  const data = useLocation();
  let taskId = null;
  let firstTimeLogin = '';
  if (data && data.state) {
    taskId = data.state.taskId;
    firstTimeLogin = data.state.firstTimeLogin;
  }

  return (
    <div className='single-challenge'>
      {showShareModal ? (
        <CreateFeedItem
          onToggleActive={onToggleCreateFeed}
          action={'share'}
          title={'Share challenge'}
          isFocused={true}
          shouldShowAttachedMedia={false}
          challengeItem={challenge}
        />
      ) : null}
      <div className='row justify-content-between align-self-center m-0'>
        <Link
          to={
            taskId
              ? `/employee_onboarding?firstTimeLogin=${firstTimeLogin}`
              : '/engage?tab=challenge'
          }
          className='single-challenge__back-text'
        >
          &#x276E; {taskId ? 'Back to Onboarding' : 'Back to Challenges'}
        </Link>

        <div
          className='single-challenge__button'
          onClick={() => onToggleCreateFeed(!showShareModal)}
        >
          Share
        </div>
      </div>

      <div className='single-challenge__item'>
        <div className='single-challenge__item-content'>
          <h5 className='single-line-text'>{challenge.name}</h5>
          <span>
            {challenge.complete
              ? 'Completed'
              : `To be completed by ${moment(challenge.end_date).format('Do MMM YYYY')}`}
          </span>
          <p>{challenge.description || ''}</p>

          <div className='single-challenge__item-content-badge'>
            <ChallengeBadgeCount value={challenge.points} />{' '}
            {/* <span className="badge">Athletic Challenge</span> */}
          </div>
        </div>

        <div className='single-challenge__progress-bar-container'>
          <div className='single-challenge__progress-bar'>
            {!!challenge.joined && <ProgressBar hideLabels width={getWidth()} />}
          </div>

          {!challenge.complete && !!challenge.joined && (
            <div className='single-challenge__progress-bar-update'>
              <div
                className={`single-challenge__progress-bar-update-button ${
                  !challenge.joined ? 'disabled' : ''
                }`}
                onClick={() => (challenge.joined ? handleModal() : null)}
              >
                Update
              </div>
            </div>
          )}
        </div>
        <ParticipantList
          style={{ borderTop: `${!!challenge.joined ? 1 : 0}px solid #ced0d2` }}
          participants={challenge.participants_last_3}
          total={challenge.participants_total}
        />
        <div className='single-challenge__toggle-status'>
          {challenge.complete ? (
            <h6>Well Done! You've completed this Challenge and your points are banked!</h6>
          ) : (
            <RoundedSwitch
              updateChecked={updateJoinedStatus}
              checked={!!challenge.joined}
              textOff="I'm not in"
              textOn="I'm in"
            />
          )}
        </div>
      </div>

      <Portal
        hide={() => toggleShowUpdate(false)}
        isOpen={showUpdate}
        minWidth={350}
        className='update-challenge-portal-container'
      >
        <UpdateChallenge
          hide={() => toggleShowUpdate(false)}
          updateValue={updateChallenge}
          value={challenge.counter || 0}
          id={challenge.id}
          handleTotal={handleTotal}
          isTotalChoose={isTotalChoose}
          total={challenge.target_count}
        />
      </Portal>
      <Portal
        className='update-challenge-portal-container'
        hide={() => handleTotal(false)}
        isOpen={isTotalChoose}
        minWidth={350}
      >
        <div className='total-container'>
          <h4 className='title'>Challenge update</h4>
          <p className='subtitle'>Have you completed all the tasks in this challenge?</p>
          <div className='d-flex'>
            <button
              onClick={() => {
                handleTotal(false);
              }}
              className='btn-not'
            >
              NOT YET
            </button>
            <button
              onClick={() => {
                handleTotalChoosen();
                if(taskId){
                  taskCompleted();
                }
              }}
              className='ml-2 btn-yes'
            >
              YES
            </button>
          </div>
        </div>
      </Portal>
    </div>
  );
}

const mapStateToProps = ({ challenge: { singleChallenge: challenge } }) => ({ challenge });

const mapDispatchToProps = {
  getChallenge: getChallengeRequest,
  clearChallenge: clearSingleChallenge,
  updateChallenge: updateChallengeRequest,
  updateChallengeJoined: updateChallengeJoinedRequest,
};

SingleChallenge.propTypes = {
  match: PropTypes.object.isRequired,
  challenge: PropTypes.object,
  getChallenge: PropTypes.func.isRequired,
  updateChallenge: PropTypes.func.isRequired,
  updateChallengeJoined: PropTypes.func.isRequired,
  clearChallenge: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleChallenge);
