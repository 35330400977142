import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class UserProfileApi {
  static createUserProfile(data) {
    const request = new Request(`${apiUrl}/content/user/me`, {
      method: 'PATCH',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data),
    });

    return fetchRequest(request);
  }

  static postUserImage(data) {
    const formData = new FormData();
    formData.append('image', data.image);
    const request = new Request(`${apiUrl}/content/me/image`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        Authorization: `Bearer ${data.token}`,
      },
      body: formData,
    });
    return fetchRequest(request);
  }

  static updateUser(data) {
    const formatedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      job_title: data.job_title,
      phone_number: data.phone_number,
      tags: data.tags,
      department_id: data.department_id
    };

    const request = new Request(`${apiUrl}/content/user/me`, {
      method: 'PATCH',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(formatedData),
    });
    return fetchRequest(request);
  }

  static getUser() {
    const request = new Request(`${apiUrl}/content/user/me`, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }

  static fetchOnBoardingData(data) {
    const request = new Request(`${apiUrl}/content/onboarding`, {
      method: 'GET',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    });

    return fetchRequest(request);
  }

  static fetchCollegueProfile(data) {
    const request = new Request(`${apiUrl}/content/user/${data.id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getCollegueAvatar(data) {
    const request = new Request(`${apiUrl}/content/image/${data.id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static fetchUserProfileData(data) {
    const request = new Request(`${apiUrl}/content/user/me`, {
      method: 'GET',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    });

    return fetchRequest(request);
  }

  static fetchUserAvatar(data) {
    const request = new Request(`${apiUrl}/content/image/${data.id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static fetchUserActivityPoints() {
    const request = new Request(`${apiUrl}/content/user/monthPointsFull`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static fetchUserBadges() {
    const request = new Request(`${apiUrl}/content/user/badges`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static sendPulseRating(data) {
    const searchParams = new URLSearchParams();
    searchParams.set('rating', data.rating);

    const request = new Request(`${apiUrl}/content/pulse`, {
      method: 'POST',
      headers: getHeaders({}, true),
      body: searchParams,
    });

    return fetchRequest(request);
  }

  static fetchMicroLearning() {
    const request = new Request(`${apiUrl}/content/learning`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static fetchMicroLearningQuestions({ id }) {
    const request = new Request(`${apiUrl}/content/learning/${id}/questions`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static fetchMicroLearningAnswers({ payload }) {
    const request = new Request(`${apiUrl}/content/learning/${payload.id}/answer`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({ answers: [...payload.answers] }),
    });

    return fetchRequest(request);
  }
}
