import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class OrgThemeApi {
  static getOrgTheme (page) {
    const request = new Request(`${apiUrl}/content/org_theme`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}
