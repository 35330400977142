import {
  GLOBAL_PRELOADER_ADD_TO_LIST,
  GLOBAL_PRELOADER_REMOVE_FROM_LIST,
} from '../constants/actionTypes';

const addPreloaderToGlobalPreloadersList = ({ id, title = '' }) => ({
  type: GLOBAL_PRELOADER_ADD_TO_LIST,
  id,
  title,
});

const removePreloaderToGlobalPreloadersList = id => ({
  type: GLOBAL_PRELOADER_REMOVE_FROM_LIST,
  id,
});

export { addPreloaderToGlobalPreloadersList, removePreloaderToGlobalPreloadersList };
