import React, { Component } from 'react';

import Button from '../global/Button';
import Header from '../global/Header/Header';
import ConsentsApi from '../../api/consents';
import { clearStorageOnLogout } from '../../api/helpers';
import { withRouter } from "react-router";

class PrivacyAndTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consents: {},
    };
  }

  async componentDidMount() {
    const consentsData = await ConsentsApi.getConsents();
    this.setState({
      consents: consentsData ? consentsData.data : {},
    });
  }

  revokeConsent = async () => {
    this.props.addAlertToAlertsList({
      title: 'Confirm?',
      message:
        'Are you sure you want to revoke consent? By doing this you will be signed out of the app and no longer be able to access any of the content',
      messageStyle: { color: '#505A62' },
      confirmBtnText: 'NO',
      revokeBtnTxt: 'YES',
      revokeBtnAction: async () => {
        let { isPrivacy, isOrgTerms } = this.props.account;
        isPrivacy = this.props.location.pathname === '/privacy-policy';
        isOrgTerms = this.props.location.pathname === '/privacy-terms';
        const response = await ConsentsApi.setConsentsAgreed({
          engage4: isPrivacy ? false : true,
          engage4Terms: true,
          organisation: isOrgTerms ? false : true,
        });
        clearStorageOnLogout();
        this.props.handleLogout();
        window.location.reload();
      }
    });

  };

  render() {
    const isPrivacy = this.props.account.isPrivacy || this.props.location.pathname === '/privacy-policy';
    const isOrgTerms = this.props.account.isOrgTerms;
    const { consents } = this.state;
    const privacyText = consents.engage4 ? consents.engage4.text : '';
    const orgTermsText = consents.organisation ? consents.organisation.text : '';
    return (
      <>
        <div className='container-fluid on-boarding overflow-auto'>
          <div>
            <div
              className='form-holder edit-profile-container col-md-6 offset-md-3 col-xl-4 offset-xl-4'
              style={{ marginTop: '36px' }}
            >
              <h3 className='title m-0 mb-2'>
                {isPrivacy ? 'Privacy policy' : 'Terms and conditions'}
              </h3>
              <div
                style={{ fontSize: 14, flex: 1 }}
                dangerouslySetInnerHTML={{
                  __html: isPrivacy ? privacyText : orgTermsText,
                }}
              ></div>
            </div>
            <div
              className='col-md-6 offset-md-3 col-xl-4 of
            fset-xl-4 button-holder'
            >
              <div className='col-3 offset-8 p-0'>
                <Button.PrimaryOutline
                  style={{ width: 180, color: '#AF365F', borderColor: '#AF365F' }}
                  onClick={this.revokeConsent}
                  type='submit'
                >
                  REVOKE CONSENT
                </Button.PrimaryOutline>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PrivacyAndTerms);
