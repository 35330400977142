import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class NotificationApi {
  static getNotifications(page) {
    const request = new Request(`${apiUrl}/content/notification?page=${page}&limit=15`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static setNotificationsRead(ids) {
    const request = new Request(`${apiUrl}/content/notification/markAsRead`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ notification_ids: ids }),
    });

    return fetchRequest(request);
  }
}
