import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import validate from '../../../utils/validate';
import 'react-datepicker/dist/react-datepicker.css';

class Input extends Component {
  constructor() {
    super();

    this.state = {
      isFocused: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { fk, value, updateFieldData, validateObj } = this.props;
    let { error } = this.props;

    if (!error) {
      error = this.getErrorMessage(validateObj);
    }

    updateFieldData(fk, value, error);
    this.setState({ error, value });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  componentDidUpdate(prevProps) {
    // if new value then validate via validate.js
    if (prevProps.value !== this.props.value) {
      this.onChange(this.props.value);
    }
  }

  getErrorMessage(obj = {}) {
    const errors = validate(obj);

    return errors ? errors[0] : null;
  }

  onChange(value) {
    const { validateObj = {}, validateRule, fk, updateFieldData } = this.props;
    const isoString = value ? new Date(value).toISOString() : null;

    validateObj[validateRule] = isoString;
    const error = this.getErrorMessage(validateObj);

    updateFieldData(fk, isoString, error);
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  onBlur() {
    this.setState({ isFocused: false });
  }

  render() {
    const {
      error,
      label,
      value,
      helperText,
      showError,
      placeholder,
      floatingLabelFixed = false,
      optionalParams,
      icon: Icon,
    } = this.props;
    const isInputActive = this.state.isFocused || value || floatingLabelFixed;
    const errorText = showError && error;
    const placeholderText = isInputActive ? placeholder : '';
    const dateVal = value ? new Date(value) : null;

    return (
      <div
        className={`
          field-container
          ${isInputActive ? 'active' : ''}
          ${errorText ? 'has-error' : ''}
        `}
      >
        <div className='right-component'>{Icon && <Icon stroke='var(--primary-color)' />}</div>
        <DatePicker
          selected={dateVal}
          onChange={this.onChange}
          onInputClick={this.onFocus}
          onBlur={this.onBlur}
          placeholderText={placeholderText}
          showYearDropdown={true}
          {...optionalParams}
        />
        {label && <label className="field-label">{label}</label>}

        {errorText && (
          <div className="field-container__error-wrapper">
            <p className="field-container__error m-0">{errorText}</p>
          </div>
        )}

        {helperText && !value && !errorText && (
          <p className="field-container__helper-text">{helperText}</p>
        )}
      </div>
    );
  }
}

export default Input;
