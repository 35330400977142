import React, { Component } from 'react';
import TextField from '../../global/fields/TextField';
import PasswordCheckList from './PasswordChecklist';

class ChangePassword extends Component {
  render () {
    const { passwordNotMatch, oldPassword, password, confirmPassword } = this.props;
    return (
      <div>
        <p className='field-container__account-helper-text'>Your current password:</p>
        <TextField
          fk='oldPassword'
          key='oldPassword'
          label={'Old Password'}
          type='password'
          value={oldPassword}
          updateFieldData={this.props.onChangeFieldValue}
          triggerOnChange={true}
        />
        <p style={{ marginTop: 16 }} className='field-container__account-helper-text'>
          Enter your new password:
        </p>
        <TextField
          fk='password'
          key='password'
          label={'Password'}
          type='password'
          value={password}
          updateFieldData={this.props.onChangeFieldValue}
          triggerOnChange={true}
        />
        <TextField
          fk='confirmPassword'
          type='password'
          key='confirmPassword'
          label={'Repeat Password'}
          value={confirmPassword}
          updateFieldData={this.props.onChangeFieldValue}
          triggerOnChange={true}
        />
        {passwordNotMatch && (
          <div className='field-container__error-wrapper'>
            <p className='field-container__error m-0'>Passwords do not match</p>
          </div>
        )}
        <PasswordCheckList
          style={{ marginTop: 16 }}
          password={password}
          updatePasswordValidity={this.props.updatePasswordValidity}
        />
      </div>
    );
  }
}

export default ChangePassword;
