import React from 'react';

export default function CheckIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <rect width="24" height="24" x="0.5" y="0.5" fill="var(--primary-color)" stroke='var(--primary-color)' rx="8" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth="2"
          d="M17.75 8.758L9.49 16.467a.437.437 0 01-.297.118.437.437 0 01-.31-.132l-2.756-2.75a.439.439 0 11.62-.621l2.455 2.459 7.953-7.425a.437.437 0 01.594.642h0z"
        />
      </g>
    </svg>
  );
}
