import React, { Component } from 'react';
import update from 'immutability-helper';

import TextField from '../global/fields/TextField';
import Button from '../global/Button';
import SelectField from '../global/fields/SelectField';

import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { CUSTOM_THEME } from '../../constants/themeConstants'

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        fields: {
          username: {
            label: 'Username',
            value: '',
            type: 'username',
            validateRule: 'username',
          },
          password: {
            label: 'Password',
            value: '',
            type: 'password',
            validateRule: 'password',
          },
          answer: {
            label: 'Answer',
            value: '',
            type: 'password',
            validateRule: 'password',
          },
          securityQuestionId: {
            label: 'Question',
            value: null,
            type: 'securityQuestionId',
            validateRule: 'required',
          },
        },
        validate: false,
      },
      signInData: {},
      securityQuestions: [],
      showValidateBlock_email: false,
      showValidateBlock_password: false,
      showForgotPassword: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
    this.updateFieldType = this.updateFieldType.bind(this);
  }

  componentDidMount(){
    this.setState({
      customAppId: 'vidatec'
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state =>
      update(state, {
        signInData: { $set: nextProps.currentUser.signInData },
        showValidateBlock_email: { $set: false },
        form: {
          validate: { $set: false },
        },
      }),
    );
  }

  // function that is passed to TextField for toggling reveal.
  updateFieldType(fk, type) {
    this.setState(state => {
      return update(state, {
        form: {
          fields: {
            [fk]: {
              type: { $set: type }
            }
          }
        }
      })
    })
  }

  updateFieldValue(fk, value, error) {
    this.setState(state =>
      update(state, {
        form: {
          fields: {
            [fk]: {
              value: { $set: value },
              error: { $set: error },
            },
          },
        },
        showValidateBlock_email: { $set: false },
        showValidateBlock_password: { $set: false },
      }),
    );
  }


  checkFields() {
    const { fields } = this.state.form;
    // Get errors
    const validationBlockInputs = [];
    Object.values(fields).forEach(item => {
      if (item.hasOwnProperty('validateRule') && item.error) {
        validationBlockInputs.push(item.label);
      }
    });

    // Not valid
    if (validationBlockInputs.length) {
      return validationBlockInputs;
    }
  }

  onClickCancelResetPwd = () => {
    this.updateFieldValue('securityQuestionId', '');
    this.updateFieldValue('answer', '');
    this.setState({
      showForgotPassword: false,
    });
  };
  onSubmit() {
    this.setState(
      state => update(state, { form: { validate: { $set: true } } }),
      () => {
        const { fields } = this.state.form;

        const data = {};

        let fieldsValid = Object.values(fields).every(item => {
          let errorText;
          const reg = /^[a-zA-Z0-9_]*$/;
          if (item.type === 'username') {
            if (item.value) {
              if (!isEmail(item.value) && !reg.test(item.value)) {
                errorText = 'Invalid username';
              }
            }
          }
          return (
            !item.hasOwnProperty('validateRule') ||
            (item.hasOwnProperty('validateRule') && (!item.error && !errorText))
          );
        });

        if (!fieldsValid) {
          if (this.state.signInData.auth_type !== 'PASSWORD') {
            this.setState({ showValidateBlock_email: true });
          } else {
            this.setState({ showValidateBlock_password: true });
          }
          return false;
        }

        Object.keys(fields).map(key => (data[key] = fields[key].value));

        Object.assign(data, { device_key: 1 });
        Object.assign(data, { 'organisation_id' : this.props.organisationId});

        if (this.state.signInData.auth_type !== 'PASSWORD') {
          delete data.password;
          this.props.signIn(data);
        } else {
          Object.assign(data, { token: this.state.signInData.token });
          this.props.signInWithPassword(data);
        }
      },
    );
  }

  _renderField(key) {
    const {
      type,
      placeholder,
      label,
      validateRule,
      value,
      error,
      helperText,
    } = this.state.form.fields[key];

    return (
      <TextField
        key={key}
        fk={key}
        type={type}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        value={value}
        validateRule={validateRule}
        validateObj={{ [validateRule]: value }}
        error={error}
        showError={this.state.form.validate}
        updateFieldData={this.updateFieldValue}
        updateFieldType={this.updateFieldType}
        triggerOnChange
      />
    );
  }

  renderLockedMessage = () => {
    return (
      <div className='mt-3'>
        We’ve locked your account until
        <span style={{ fontWeight: '900' }}>
          {` ${moment(this.props.currentUser.lockInfo.lockedUntil).format('YYYY-MM-DD HH:mm:SS')} `}
        </span>
        after multiple failed attempts.
        <p className='mt-3'>
          If you feel this was done in error, please get in touch with your organisation admin
        </p>
      </div>
    );
  };

  renderForgotPassword = () => {
    const forgotsecurityModal = {
      title: 'Forgotten security details',
      message: 'To find out your security details, get in touch with your organisation admin.',
      confirmBtnText: 'OKAY',
    };
    const { type, label, validateRule, value, error } = this.state.form.fields[
      'securityQuestionId'
    ];
    const securityQuestions = this.props.account.securityQuestions.map(item => ({
      value: item.id,
      displayName: item.long_text,
    }));
    const validationBlocks = this.checkFields();
    return (
      <div>
        <SelectField
          fk={type}
          key={type}
          placeholder={label}
          options={securityQuestions}
          value={value}
          updateFieldData={this.updateFieldValue}
          validateRule={validateRule}
          validateObj={{ [validateRule]: value }}
          error={error}
          showError={this.state.form.validate}
        />
        {this._renderField('answer')}
        <p
          className='forgot-pass mt-4'
          onClick={() => this.props.addAlertToAlerts(forgotsecurityModal)}
        >
          I DON'T KNOW MY QUESTION AND/OR ANSWER
        </p>
        <div className='d-flex'>
          <Button.SecondaryOutline
            className='mt-3'
            type='submit'
            onClick={this.onClickCancelResetPwd}
          >
            CANCEL
          </Button.SecondaryOutline>
          <Button.Primary
            className='ml-5 mt-3'
            type='submit'
            onClick={() =>
              this.props.validateSecurity({
                securityQuestionId: this.state.form.fields.securityQuestionId.value,
                securityAnswer: this.state.form.fields.answer.value,
                username: this.state.form.fields.username.value,
              })
            }
            disabled={
              !this.state.form.fields.securityQuestionId.value ||
              !this.state.form.fields.answer.value
            }
          >
            RESET PASSWORD
          </Button.Primary>
        </div>
      </div>
    );
  };

  renderLoginForm = () => {
    const forgotUsernameModal = {
      title: 'Forgotten username',
      message: 'To find out your username, get in touch with your organisation admin.',
      confirmBtnText: 'OKAY',
    };
    const forgotPasswordModal = {
      title: 'Forgotten password',
      message: (
        <span className='m-0'>
          Security details are not set in the system to reset password, get in touch with your
          organisation admin.
        </span>
      ),
      confirmBtnText: 'OKAY',
    };
    const validationBlocks = this.checkFields();
    return (
      <form>
        {this._renderField('username')}

        <div className='clearfix' />

        {this.state.signInData.auth_type === 'PASSWORD' ? this._renderField('password') : null}

        {this.state.showValidateBlock_email && (
          <div className='validate-block mb-3'>
            <p>Looks like you made some mistakes, check the following field:</p>
            <ul>
              <li>Username</li>
            </ul>
          </div>
        )}

        {this.state.showValidateBlock_password && (
          <div className='validate-block mb-3'>
            <p>Looks like you made some mistakes, check the following fields:</p>
            <ul>
              <li>Username</li>
              <li>Password</li>
            </ul>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <p
            className='forgot-pass mt-4'
            onClick={() => this.props.addAlertToAlerts(forgotUsernameModal)}
          >
            Forgot Username?
        </p>
          <div className="form-button-login" >
            <Button.Primary
              className='mt-3'
              type='submit'
              onClick={this.onSubmit}
              disabled={validationBlocks && validationBlocks.length}
            >
              LOG IN
                </Button.Primary>
          </div>
        </div>

        {this.state.signInData.auth_type === 'PASSWORD' ? (
          <p
            className='forgot-pass mt-2'
            onClick={() => {
              if (this.state.signInData.is_security_set) {
                this.props.getSecurityQuestions();
                this.setState({
                  showForgotPassword: true,
                });
              } else {
                this.props.addAlertToAlerts(forgotPasswordModal);
              }
            }}
          >
            Forgot Password?
          </p>
        ) : null}

        <div className='col-5 offset-7 button-holder pr-0 pl-4'>
        </div>
      </form>
    );
  };

  render() {
    const isUserLocked = this.props.currentUser.lockInfo.isLocked;
    const isForgotPassword = this.state.showForgotPassword;
    const customAppId = this.props.customAppId; 
    const title = isForgotPassword
      ? 'Forgotten Password'
      : !isUserLocked
        ? 'Login'
        : 'Account Locked';
    return (
        <div className="login-outer">
      <Container fluid style={{ maxWidth: '1440px' }}>
        <Row style={{height: '100%'}}>
          <Col className="no-padding" style={{ backgroundColor: '#F4F4F4'}}>
            <div className="login-innerleft">
              <div className="login-content">
                <img src={CUSTOM_THEME[this.props.customAppId].logo} alt="Logo" className="login-logo" />
                <h3 className="login-title mt-5">Login</h3>
                <div className='form-holder'>
                  {isUserLocked
                    ? this.renderLockedMessage()
                    : isForgotPassword
                      ? this.renderForgotPassword()
                      : this.renderLoginForm()}
                </div>
              </div> 
            </div> 
          </Col>  
          <Col className="no-padding" style={{ backgroundColor: '#F4F4F4'}}>
            <div className="login-innerright">
              <img src={CUSTOM_THEME[this.props.customAppId].welcome} alt="Welcome" className="login-imageright" />
            </div>   
          </Col>
        </Row>
      </Container>

      </div>
    );
  }
}

export default SignIn;
