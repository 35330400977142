import React, { Component } from 'react';
import { Avatar } from 'antd';

import ImageApi from '../../../api/image';
import CrossWithBgIcon from '../../Icons/CrossWitBgIcon';

class CreateFeedItemSelectedColleague extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (
      this.props.colleague &&
      this.props.colleague.image &&
      this.props.colleague.image.thumbnailUrl
    ) {
      ImageApi.getImage(this.props.colleague.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    return (
      <div className="create-feed-selected-group create-feed-selected-colleague">
        <Avatar
          className="create-feed-selected-group-image"
          src={this.state.imageUrl}
          shape="square"
          size={36}
        />
        {this.props.colleague.first_name} {this.props.colleague.last_name.charAt(0).toUpperCase()}.
        {!this.props.disallowEditColleague && (
          <div onClick={this.props.onCancel}>
            <CrossWithBgIcon className="create-feed-selected-colleague-cancel" />
          </div>
        )}
      </div>
    );
  }
}

export default CreateFeedItemSelectedColleague;
