import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { GET_USERS_REQUEST, GET_MORE_USERS_REQUEST } from '../constants/actionTypes';
import GroupApi from '../api/group';

import errorHandler from '../utils/requestErrorHandler';
import {
  getMoreUsersSuccess,
  getUsersSuccess,
  getUsersFail,
  getMoreUsersFail,
} from '../actionCreators/users';
import EventsApi from '../api/events';
import ChallengeApi from '../api/challenge';
import FeedApi from '../api/feed';

const fnLookup = {
  group: GroupApi.getGroupMembers,
  event: EventsApi.getParticipants,
  challenge: ChallengeApi.getParticipants,
  likes: FeedApi.getUsersWhoLikedPost,
};

const resultPropLookup = {
  group: 'members',
  event: 'participants',
  challenge: 'participants',
  likes: 'members',
};

function* getUsers(action) {
  try {
    const {
      payload: { type, id },
    } = action;

    const fetchFn = fnLookup[type];
    const propName = resultPropLookup[type];

    if (fetchFn && propName) {
      const result = yield call(fetchFn, id);

      yield put(
        getUsersSuccess({
          users: result[propName],
          pagination: {
            total: result.total,
            pages: result.pages,
            perPages: result.per_page,
            page: 1,
          },
        }),
      );
    } else {
      yield put(getUsersFail('Cant handle type'));
    }
  } catch (error) {
    yield errorHandler(error);
    yield put(getUsersFail(error));
  }
}

function* getMoreUsers(action) {
  try {
    const {
      payload: { type, id },
    } = action;

    const { page, perPage, pages } = yield select(state => state.users.pagination);

    // last page
    if (page >= pages) return;

    const fetchFn = fnLookup[type];
    const propName = resultPropLookup[type];

    if (fetchFn && propName) {
      const result = yield call(fetchFn, id, perPage, page+1);

      yield put(
        getMoreUsersSuccess({
          users: result[propName],
          pagination: {
            total: result.total,
            pages: result.pages,
            perPages: result.per_page,
            page: page + 1,
          },
        }),
      );
    } else {
      yield put(getMoreUsersFail('Cant handle type'));
    }
  } catch (error) {
    yield errorHandler(error);
    yield put(getMoreUsersFail(error));
  }
}

export default function* watchUsers() {
  yield all([
    takeLatest(GET_USERS_REQUEST, getUsers),
    takeLatest(GET_MORE_USERS_REQUEST, getMoreUsers),
  ]);
}
