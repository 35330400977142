import { put, takeLatest, call } from 'redux-saga/effects';
import { getCustomAppSuccess } from '../actionCreators/customApp';
import { CustomAppApi } from '../api';
import { GET_CUSTOM_APP_REQUEST } from '../constants/actionTypes';
import { APP_PRIMARY_COLOR, APP_PRIMARY_FONT } from '../theme/constants';
import { initializeFirebase } from '../firebase/analytics';

function * getCustomAppDetails (action) {
  var bodyStyles = document.body.style;
  try {
    let result = yield call(CustomAppApi.getCustomAppDetails, action.payload);
    if (result.custom_app && result.custom_app.id !== 'engage4') {
      const orgPrimaryColor = result.custom_app
        ? result.custom_app.primary_color
        : APP_PRIMARY_COLOR;
      bodyStyles.setProperty('--primary-color', orgPrimaryColor);
      const orgPrimaryFont = result.custom_app
        ? result.custom_app.primary_font
        : APP_PRIMARY_FONT;
      bodyStyles.setProperty('--primary-font', orgPrimaryFont);
      localStorage.setItem('primary-font', orgPrimaryFont);
      yield put(getCustomAppSuccess(result.custom_app));
    }
    initializeFirebase(result.custom_app.id, result.custom_app.environment);
  } catch (error) {
    bodyStyles.setProperty('--primary-color', APP_PRIMARY_COLOR);
    bodyStyles.setProperty('--primary-font', APP_PRIMARY_FONT);
  }
}

export default function * getCustomAppRoot () {
  yield takeLatest(GET_CUSTOM_APP_REQUEST, getCustomAppDetails);
}
