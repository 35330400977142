import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextBlock, ThumbnailImage, GroupItem } from '../index';

function GroupsSearchResults(props) {
  const { data, showHeading } = props;

  return (
    <div className='mt-3'>
      {!showHeading && <h5 className='font-weight-semibold'>Groups</h5>}

      <div className='d-flex justify-content-between flex-wrap'>
        {data
          .filter(item => item.name)
          .map(item => (
            <Link
              className='mb-3 remove-highlight-link'
              key={item.id}
              to={`/group-feed/${item.id}`}
            >
              <div>
                <GroupItem
                  group={item}
                />
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

GroupsSearchResults.propTypes = {
  data: PropTypes.array.isRequired,
};

export default GroupsSearchResults;
