import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { logFirebaseEvent } from '../../firebase/analytics';
import GroupItem from '../GroupItem/GroupItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  verifyGroupSuccess,
} from '../../actionCreators/groupActions';

function VerifyGroupModal (props) {
  const dispatch = useDispatch();
  const { hide, createGroup, recommendedGroups, onClickBackToCreateGroup, errorMessage } = props;

  const dismissVerifyGroup = () => {
    dispatch(verifyGroupSuccess({
      recommendedGroups: [],
      createGroupRequest: {},
      errorMessage: 'Please update the group name to continue',
    }))
  }
  
  return (
    <div className='modal-content create-group' style={{ maxWidth: '500px' }}>
      <div className='d-flex flex-row flex-fill p-3 justify-content-start'>
        <div className='modal-back-link' onClick={onClickBackToCreateGroup}>
          <FontAwesomeIcon icon={['fas', 'chevron-left']} className='back-link-icon' />
          Back
        </div>
        <div className='modal-header-title'>Are you sure?</div>
      </div>
      <div className='modal-body overflow-auto d-flex flex-column'>
        <p>We've found some groups that may be similar to the one you are trying to create.</p>
        <div className='d-flex flex-column bg-white'>
          {recommendedGroups.map(item => (
            <GroupItem group={item} />
          ))}
        </div>
        <p>
          Are you sure your group is different or will cater to separate audience when compared to
          those listed above?
        </p>
        <div className='d-flex flex-row justify-content-end'>
          <div className='create-group__button create-group__nevermind' onClick={hide}>
            NEVER MIND
          </div>
          <div className='create-group__button create-group__create' onClick={errorMessage ? dismissVerifyGroup : createGroup}>
            YES, CREATE
          </div>
        </div>
        <div className='create-group__spacer' />
      </div>
    </div>
  );
}

VerifyGroupModal.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default VerifyGroupModal;
