import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import lodash from 'lodash';
import { addAlertToAlertsList } from '../actionCreators/globalAlerts';

import {
  GET_FEED_ITEM_DETAILS_REQUEST,
  GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS,
  GET_FEED_REQUEST,
  GET_FEED_REQUEST_SUCCESS,
  GET_SINGLE_FEED_REQUEST,
  GET_SINGLE_FEED_REQUEST_SUCCESS,
  SET_FEED_LOADING,
  SET_FEED_DETAILS_LOADING,
  POST_COMMENT_REQUEST,
  POST_VOTE_REQUEST,
  TOGGLE_LIKE_REQUEST,
  CREATE_NOTICE_REQUEST,
  CREATE_POLL_REQUEST,
  SET_GROUPS_FROM_FEED_REQUEST,
  CREATE_HIGHFIVE_REQUEST,
  CREATE_EVENT_REQUEST,
  TOGGLE_COMMENT_LIKE_REQUEST,
  RESET_FEED_ITEMS,
  DELETE_POST_REQUEST,
  REPORT_POST_REQUEST,
  PIN_POST_REQUEST,
  REPORT_POST_REQUEST_SUCCESS,
  POPULATE_POST_REPORT_TYPES_REQUEST,
  POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS,
  SET_PIN_RETRY,
} from '../constants/actionTypes';
import FeedApi from '../api/feed';
import GroupApi from '../api/group';
import { parsePath, getFeedPagination } from '../utils/selectors';
import errorHandler from '../utils/requestErrorHandler';

function* getFeedRequest(action) {
  yield put({ type: SET_FEED_LOADING, payload: true });
  try {
    const path = yield select(parsePath);
    const { currentPage, totalPages } = yield select(getFeedPagination);
    const groupId = path.params.groupId;

    const params = { ...action.payload };
    if (!('page' in params)) params.page = currentPage + 1;
    if (groupId) params.groupId = groupId;

    if (params.page > totalPages) return;

    const data = yield call(FeedApi.getFeed, params);
    const users = lodash.keyBy(data.users, 'id');

    // Get high five recipient
    for (const post of data.posts) {
      if (post.postable_type !== 'highfives') continue;
      if (!post.post) continue;

      const recipientId = post.post.recipient_id;
      let user = users[recipientId];
      if (!user) user = yield select(state => state.feed.authors[recipientId]);
      if (!user) {
        const userResp = yield call(FeedApi.getAuthor, { id: recipientId });
        user = userResp.user;
      }

      if (user) users[user.id] = user;
    }

    yield put({
      type: GET_FEED_REQUEST_SUCCESS,
      payload: { ...data, currentPage: params.page, users: Object.values(users) },
    });
    yield put({ type: SET_GROUPS_FROM_FEED_REQUEST, payload: data.groups });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_FEED_LOADING, payload: false });
  }
}

function* getFeedItemDetailsRequest(action) {
  yield put({ type: SET_FEED_DETAILS_LOADING, payload: true });

  try {
    const data = yield call(FeedApi.getSingleFeed, action.payload);
    const commentAuthors = [];

    if (data[action.payload.type].comments) {
      for (const comment of data[action.payload.type].comments) {
        let author = yield select(state => state.feed.authors[comment.user_id]);
        if (!author) {
          const authorResp = yield call(FeedApi.getAuthor, { id: comment.user_id });
          author = authorResp.user;
        }

        commentAuthors.push(author);
      }
    }

    yield put({
      type: GET_FEED_ITEM_DETAILS_REQUEST_SUCCESS,
      payload: {
        post: data[action.payload.type],
        authors: commentAuthors,
      },
    });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_FEED_DETAILS_LOADING, payload: false });
  }
}

function* getSingleFeedRequest() {
  yield put({ type: SET_FEED_LOADING, payload: true });

  try {
    const path = yield select(parsePath);
    const postId = path.params.postId;
    const type = path.queryParams.get('type');

    const data = yield call(FeedApi.getSingleFeed, { id: postId, type: type });
    if (!data.success && data.resource === 'group') {
      return yield put(replace(`/group-feed/${data.id}`));
    }

    // Get author
    const authorId = data[type].author_id;
    const authors = [];

    let author = yield select(state => state.feed.authors[authorId]);
    if (!author) {
      const authorResp = yield call(FeedApi.getAuthor, { id: authorId });
      author = authorResp.user;
    }
    authors.push(author);

    // Get comment authors
    if (data[type].comments) {
      for (const comment of data[type].comments) {
        let author = yield select(state => state.feed.authors[comment.user_id]);
        if (!author) {
          const authorResp = yield call(FeedApi.getAuthor, { id: comment.user_id });
          author = authorResp.user;
        }

        authors.push(author);
      }
    }

    // Get high five recipient
    if (type === 'highfive') {
      const recipientId = data[type].post.recipient_id;

      let recipient = yield select(state => state.feed.authors[recipientId]);
      if (!recipient) {
        const recipientResp = yield call(FeedApi.getAuthor, { id: recipientId });
        recipient = recipientResp.user;
      }

      authors.push(recipient);
    }

    // Get group
    let group = null;
    if (data[type].group_id) {
      group = yield select(state => state.group.groups[data[type].group_id]);
      if (!group) {
        const groupResp = yield call(GroupApi.getGroup, data[type].group_id);
        group = groupResp.group;
      }
    }

    if (group) yield put({ type: SET_GROUPS_FROM_FEED_REQUEST, payload: [group] });

    yield put({ type: GET_SINGLE_FEED_REQUEST_SUCCESS, payload: { post: data[type], authors } });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_FEED_LOADING, payload: false });
  }
}

function* postCommentRequest(action) {
  try {
    const { comment, postId, postableId, postType } = action.payload;

    // Post comment
    yield call(FeedApi.postComment, { body: { comment }, postId });
    const path = yield select(parsePath);

    // Refresh item to get new comment
    if (path.params.postId) {
      yield put({ type: GET_SINGLE_FEED_REQUEST });
    } else {
      yield put({
        type: GET_FEED_ITEM_DETAILS_REQUEST,
        payload: { type: postType, id: postableId },
      });
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function* voteOnPollRequest(action) {
  try {
    const { pollOptionId, postableId, postType } = action.payload;

    // Post vote
    yield call(FeedApi.voteOnPoll, pollOptionId);
    const path = yield select(parsePath);

    // Refresh item to get new comment
    if (path.params.postId) {
      yield put({ type: GET_SINGLE_FEED_REQUEST });
    } else {
      yield put({
        type: GET_FEED_ITEM_DETAILS_REQUEST,
        payload: { type: postType, id: postableId },
      });
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function* toggleLikeRequest(action) {
  try {
    const { postId, newLikeState, postableId, postType } = action.payload;

    // Post vote
    yield call(FeedApi.toggleLike, { postId, newLikeState });
    const path = yield select(parsePath);

    // Refresh item to get new comment
    if (path.params.postId) {
      yield put({ type: GET_SINGLE_FEED_REQUEST });
    } else {
      yield put({
        type: GET_FEED_ITEM_DETAILS_REQUEST,
        payload: { type: postType, id: postableId },
      });
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function* createNoticeRequest(action) {
  try {
    yield call(FeedApi.createNotice, action.payload);
    yield put({ type: RESET_FEED_ITEMS });
    yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* createPollRequest(action) {
  try {
    yield call(FeedApi.createPoll, action.payload);
    yield put({ type: RESET_FEED_ITEMS });
    yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* createHighFiveRequest(action) {
  try {
    yield call(FeedApi.createHighFive, action.payload);
    yield put({ type: RESET_FEED_ITEMS });
    yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* createEventRequest(action) {
  try {
    yield call(FeedApi.createEvent, action.payload);
    yield put({ type: RESET_FEED_ITEMS });
    yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* deletePostRequest(action) {
  try {
    yield call(FeedApi.deletePost, action.payload)
    yield put({ type: RESET_FEED_ITEMS })
    yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } })
  } catch (error) {
    yield errorHandler(error)
  }
}

function* reportPostRequest(action) {
  try {
    yield call(FeedApi.reportPost, action.payload)
    yield put({ type: REPORT_POST_REQUEST_SUCCESS })
  } catch (error) {
    yield errorHandler(error)
  }
}

function* pinPostRequest(action) {
  try {
      yield call(FeedApi.pinPost, action.payload);
      yield put({ type: RESET_FEED_ITEMS });
      yield put({ type: GET_FEED_REQUEST, payload: { page: 1 } });
  } catch (error) {
    if (error.error === 'PINNED_POST_LIMIT') {
      yield put({ type: SET_PIN_RETRY, payload: action.payload })
    } else {
      yield errorHandler(error)
    }

  }
}

function* populatePostReportTypesRequest() {
  try {
    const data = yield call(FeedApi.populatePostReportTypes)
    yield put({ type: POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS, payload: data.reasons || [] })

  } catch (error) {
    yield errorHandler(error)
  }
}

function* toggleCommentLikeRequest(action) {
  try {
    const { commentId, newLikeState, postableId, postType } = action.payload;

    // Post vote
    yield call(FeedApi.toggleCommentLike, { commentId, newLikeState });
    const path = yield select(parsePath);

    // Refresh item to get new comment
    if (path.params.postId) {
      yield put({ type: GET_SINGLE_FEED_REQUEST });
    } else {
      yield put({
        type: GET_FEED_ITEM_DETAILS_REQUEST,
        payload: { type: postType, id: postableId },
      });
    }
  } catch (error) { }
}


export default function* watchFeed() {
  yield takeLatest(GET_FEED_REQUEST, getFeedRequest);
  yield takeLatest(GET_FEED_ITEM_DETAILS_REQUEST, getFeedItemDetailsRequest);
  yield takeLatest(GET_SINGLE_FEED_REQUEST, getSingleFeedRequest);
  yield takeLatest(POST_COMMENT_REQUEST, postCommentRequest);
  yield takeLatest(POST_VOTE_REQUEST, voteOnPollRequest);
  yield takeLatest(TOGGLE_LIKE_REQUEST, toggleLikeRequest);
  yield takeLatest(CREATE_NOTICE_REQUEST, createNoticeRequest);
  yield takeLatest(CREATE_POLL_REQUEST, createPollRequest);
  yield takeLatest(CREATE_HIGHFIVE_REQUEST, createHighFiveRequest);
  yield takeLatest(CREATE_EVENT_REQUEST, createEventRequest);
  yield takeLatest(TOGGLE_COMMENT_LIKE_REQUEST, toggleCommentLikeRequest);
  yield takeLatest(DELETE_POST_REQUEST, deletePostRequest);
  yield takeLatest(REPORT_POST_REQUEST, reportPostRequest);
  yield takeLatest(PIN_POST_REQUEST, pinPostRequest);
  yield takeLatest(POPULATE_POST_REPORT_TYPES_REQUEST, populatePostReportTypesRequest);
}
