import React from 'react';

export default function CheckBoxRound (props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>C3C3F4FF-9674-484A-81DF-B45D16E623D7</title>
      <g
        id='🔐-1-Login-&amp;-Onboarding'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g id='1-Create-Password' transform='translate(-16.000000, -326.000000)'>
          <g id='right' transform='translate(16.000000, 326.000000)'>
            <circle id='Oval' fill='#969CA1' cx='12' cy='12' r='12'></circle>
            <path
              d='M9.52352089,16.8763756 C9.44223378,16.9554335 9.33515357,16.9996005 9.22380112,17 C9.10600864,16.999492 8.99331283,16.9500263 8.91085841,16.8626396 L6.12963521,13.9780706 C6.01783154,13.8619284 5.97416723,13.6926476 6.01509021,13.5339944 C6.05601319,13.3753412 6.17530628,13.251419 6.32803292,13.208908 C6.48075957,13.1663971 6.64371696,13.2117558 6.75552062,13.3278979 L9.2326164,15.9056953 L17.2589357,8.12193775 C17.4378548,7.94998374 17.7170866,7.96125845 17.8826172,8.14712051 C18.0481479,8.33298257 18.0372944,8.62304981 17.8583752,8.79500384 L9.52352089,16.8763756 Z'
              id='Path'
              stroke='#FFFFFF'
              stroke-width='2'
              fill='#FFFFFF'
              fill-rule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
