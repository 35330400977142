import React, { Component } from 'react';
import update from 'immutability-helper';
import TextField from '../global/fields/TextField';
import Button from '../global/Button';
import jwtDecode from 'jwt-decode';
import './styles.scss';
import { Avatar } from 'antd';

import Plus from '../../assets/images/add-img.svg';
import UserPlaceholder from '../../assets/images/user_placeholder.svg';
import SelectField from '../global/fields/SelectField';
import CheckboxField from '../global/fields/Checkbox';

import { extractTags } from './userTagsUtils';
import { logFirebaseEvent } from '../../firebase/analytics';
import AddIcon from '../../components/Icons/AddIcon';

class EditUserProfile extends Component {
  constructor(props) {
    super(props);

    const userProfileInitinal = jwtDecode(this.props.token.key);
    const userProfile = JSON.parse(localStorage.getItem('user'));

    const userName =
      userProfile === null ? userProfileInitinal.first_name : userProfile.user.first_name;
    const lastName =
      userProfile === null ? userProfileInitinal.last_name : userProfile.user.last_name;
    const phone =
      userProfile === null ? userProfileInitinal.phone_number : userProfile.user.phone_number;
    const jobTitle =
      userProfile === null ? userProfileInitinal.job_title : userProfile.user.job_title;
    const department_id =
      userProfile === null ? userProfileInitinal.department_id : userProfile.user.department_id;
    this.state = {
      form: {
        fields: {
          first_name: {
            label: 'First Name',
            value: userName,
            type: 'text',
            validateRule: 'requiredMax64',
          },
          last_name: {
            label: 'Last Name',
            value: lastName,
            type: 'text',
            validateRule: 'requiredMax64',
          },
          job_title: {
            label: 'Job Title',
            value: jobTitle,
            type: 'text',
            validateRule: 'requiredMax64',
          },
          phone_number: {
            label: 'Phone no. (optional)',
            value: phone,
            type: 'number',
          },
          department_id: {
            label: 'Department',
            value: department_id,
            type: 'dropdown',
            validateRule: 'name',
          },
        },
        validate: false,
      },
      imageBlobUrl: '',
      imageFile: null,
      showValidateBlock: false,
      validationBlockInputs: [],
      userProfileData: [],
      firstName: '',
      lastName: '',
      jobTitle: '',
      phoneNumber: '',
      avatar: '',
      saveSuccessfully: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
  }

  componentDidMount() {
    this.dispatchInitialActions();

    const userProfileInitinal = jwtDecode(this.props.token.key);
    const id = userProfileInitinal.avatar;

    this.props.handleUser({ token: this.props.token.key });

    this.setState({
      firstName: userProfileInitinal.first_name,
      lastName: userProfileInitinal.last_name,
      phoneNumber: userProfileInitinal.phone_number,
      jobTitle: userProfileInitinal.job_title,
      avatar: id,
    });
  }

  componentWillReceiveProps(nextProps) {
    const formFields = Object.assign({}, this.state.form.fields);

    if (nextProps.userData && nextProps.userData.image && nextProps.userData.image.url) {
      if (this.state.avatar !== nextProps.userData.image_id) {
        this.setState({ avatar: nextProps.userData.image_id });
        this.props.handleAvatar(nextProps.userData.image.url);
      }
    }

    const newFormFields = { ...formFields };

    this.setState(state =>
      update(state, {
        form: {
          fields: { $set: newFormFields },
        },
        imageBlobUrl: { $set: this.state.imageBlobUrl },
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userTags.length !== this.props.userTags.length) {
      this.setupUserTagFields(this.props.userTags);
    }
  }

  dispatchInitialActions = () => {
    setTimeout(() => {
      this.props.getDepartments();
      this.props.getUserTags();
    }, 0);
  };

  setupUserTagFields = userTags => {
    const {
      form,
      form: { fields },
    } = this.state;

    const userTagFields = userTags.map(tag => {
      const id = `tag-${tag.id}`;

      if (tag.type === 1) {
        const selected = tag.options.find(option => option.is_selected) || {};
        return {
          id,
          label: tag.title,
          value: selected.id,
          type: 'dropdown',
          validateRule: 'optional',
        };
      } else if (tag.type === 2) {
        const [checkbox] = tag.options;
        return {
          id,
          label: checkbox.label,
          value: checkbox.is_selected,
          type: 'checkbox',
          validateRule: 'optional',
        };
      } else return {};
    });

    const userTagObject = userTagFields.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});

    const newState = {
      form: {
        ...form,
        fields: {
          ...fields,
          ...userTagObject,
        },
      },
    };

    this.setState(newState);
  };

  handleModal = () => {
    this.setState({
      saveSuccessfully: !this.state.saveSuccessfully,
    });
  };

  updateFieldValue(fk, value, error) {
    this.setState(state =>
      update(state, {
        form: {
          fields: {
            [fk]: {
              value: { $set: value },
              error: { $set: error },
            },
          },
        },
        showValidateBlock: { $set: false },
      }),
    );
  }

  onSubmit() {
    this.setState(
      state =>
        update(state, {
          form: {
            validate: { $set: true },
          },
        }),
      () => {
        const { fields } = this.state.form;
        const data = {};

        // Get errors
        const validationBlockInputs = [];
        Object.values(fields).forEach(item => {
          if (item.hasOwnProperty('validateRule') && item.error) {
            validationBlockInputs.push(item.label);
          }
        });

        // Not valid
        if (validationBlockInputs.length) {
          return this.setState({ showValidateBlock: true, validationBlockInputs });
        }

        Object.keys(fields).map(key => (data[key] = fields[key].value));
        const tags = extractTags(fields, this.props.userTags);
        logFirebaseEvent({
          event: 'profile',
          action: 'update'
        })
        this.props.updateProfile({ ...data, tags, token: this.props.token.key });

        if (this.state.imageBlobUrl !== '') {
          this.props.postUserAvatar({ image: this.state.imageFile, token: this.props.token.key });
          this.props.updateProfile({ ...data, token: this.props.token.key });

          const imageId = JSON.parse(localStorage.getItem('imageId'));

          this.props.handleAvatar({ token: this.props.token.key, id: imageId });
        }
      },
    );
    this.handleModal();
  }

  _renderField(key, filledValue) {
    const {
      type,
      placeholder,
      label,
      validateRule,
      value,
      error,
      helperText,
    } = this.state.form.fields[key];

    return (
      <TextField
        key={key}
        fk={key}
        type={type}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        value={value}
        validateRule={validateRule}
        validateObj={{ [validateRule]: value }}
        error={error}
        showError={this.state.form.validate}
        updateFieldData={this.updateFieldValue}
      />
    );
  }

  _renderSelect = ({ key, options, label }) => {
    const { validateRule, value, error } = this.state.form.fields[key];

    return (
      <SelectField
        placeholder={label}
        options={options}
        key={key}
        fk={key}
        value={value}
        validateRule={validateRule}
        validateObj={{ [validateRule]: value }}
        error={error}
        showError={this.state.form.validate}
        updateFieldData={this.updateFieldValue}
      />
    );
  };

  _renderCheckbox = key => {
    const { value, label } = this.state.form.fields[key];
    return <CheckboxField key={key} fk={key} value={value} label={label} updateFieldData={this.updateFieldValue} />;
  };

  _renderUserTags = () => {
    const { userTags } = this.props;
    return userTags.map(({ type, id, options, title }) => {
      const key = `tag-${id}`;
      if (!this.state.form.fields[key]) return null;
      if (type === 1)
        return this._renderSelect({
          key,
          label: title,
          options: options.map(value => ({
            value: value.id,
            displayName: value.label,
          })),
        });
      if (type === 2) return this._renderCheckbox(key);
      else return null;
    });
  };

  render() {
    return (
      <div className="container-fluid on-boarding">
        <div>
          {this.renderForm(this.state)}
        </div>
      </div>
    );
  }

  renderForm({ firstName, lastName, phoneNumber, jobTitle }) {
    let image;
    image = (
      <Avatar
        className="user-profile-avatar"
        alt="avatar"
        src={this.props.avatarImg || UserPlaceholder}
        shape="square"
        size={97}
        icon="user"
      />
    );

    if (this.state.imageBlobUrl) {
      image = (
        <img
          className="upload-img user-profile-avatar"
          alt="avatar"
          src={this.state.imageBlobUrl}
        />
      );
    }

    return (
      <div
        className="form-holder edit-profile-container col-md-6 offset-md-3 col-xl-4 offset-xl-4"
        style={{ marginTop: '0px' }}
      >

        <div>
          <input
            ref={e => (this.input = e)}
            className="d-none"
            type="file"
            accept="image/x-png,image/jpeg,image/gif"
            onChange={e => {
              this.setState({
                imageFile: e.target.files[0],
                imageBlobUrl: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />

          <div className="upload-img-holder" onClick={() => this.input.click()}>
            {image}
            <AddIcon className="add-img" />
          </div>

          <p className="picture-text mt-4 mb-3">Profile picture</p>

          <form
            onSubmit={e => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            {this._renderField('first_name', firstName)}
            {this._renderField('last_name', lastName)}
            {this._renderField('job_title', jobTitle)}
            {this._renderField('phone_number', phoneNumber)}
            {this._renderSelect({
              key: 'department_id',
              label: 'Department',
              options: this.props.departments.map(value => ({
                value: value.id,
                displayName: value.department,
              })),
            })}
            {this._renderUserTags()}
            <p className="form-text phone-warning">
              If you enter your phone number it will be visible when a colleague visits your profile
            </p>
            {this.state.showValidateBlock ? (
              <>
                <div className="validate-block">
                  <p>Looks like you made some mistakes, check the following fields:</p>
                  <ul>
                    {this.state.validationBlockInputs.map(input => (
                      <li key={input}>{input}</li>
                    ))}
                  </ul>
                </div>
              </>
            ) : null}
          </form>

          <div className="save-button-container">
            <div className='save-button-inner-container'>
              <Button.Primary onClick={this.onSubmit} type="submit">
                SAVE
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUserProfile;
