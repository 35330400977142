import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import globalPreloaders from './globalPreloaderReducer';
import globalAlerts from './globalAlertsReducer';
import categories from './categoriesReducer';
import challenge from './challengeReducer';
import currentUser from './currentUserReducer';
import searchResults from './searchResultsReducer';
import feed from './feedReducer';
import events from './eventsReducer';
import group from './groupReducer';
import notification from './notificationReducer';
import users from './usersReducer';
import departments from './departmentsReducer';
import knowledgebase from './knowledgebaseReducer';
import account from './accountReducer';
import featureFlags from './featureFlagsReducer';
import employeeOnboard from './employeeOnboardReducer';
import customApp from './customAppReducer';

import routerHistory from './routerHistory';
import { RESET_STORE_TO_INIT_STATE } from '../constants/actionTypes';
import userTags from './userTags';

const appReducers = history =>
  combineReducers({
    router: connectRouter(history),
    globalPreloaders,
    globalAlerts,
    categories,
    currentUser,
    challenge,
    routerHistory,
    searchResults,
    feed,
    group,
    events,
    notification,
    users,
    departments,
    userTags,
    knowledgebase,
    account,
    featureFlags,
    employeeOnboard,
    customApp
  });

const rootReducer = history => (state, action) => {
  const defaultState = appReducers(history)({}, {});

  return appReducers(history)(
    action.type === RESET_STORE_TO_INIT_STATE
      ? {
          ...defaultState,
        }
      : state,
    action,
  );
};

export default rootReducer;
