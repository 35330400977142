import {
  GET_MORE_USERS_FAIL,
  GET_MORE_USERS_REQUEST,
  GET_MORE_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
} from '../constants/actionTypes';

export const getUsersRequest = ({ type, id }) => ({
  type: GET_USERS_REQUEST,
  payload: { type, id },
});

export const getUsersSuccess = ({ users, pagination }) => ({
  type: GET_USERS_SUCCESS,
  payload: { users, pagination },
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
});

export const getMoreUsersRequest = ({ type, id }) => ({
  type: GET_MORE_USERS_REQUEST,
  payload: { type, id },
});

export const getMoreUsersSuccess = ({ users, pagination }) => ({
  type: GET_MORE_USERS_SUCCESS,
  payload: { users, pagination },
});

export const getMoreUsersFail = error => ({
  type: GET_MORE_USERS_FAIL,
  payload: error,
});
