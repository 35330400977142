import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment'

import GroupPlaceholder from '../../../assets/images/group_placeholder.svg';
import ImageApi from '../../../api/image';

class SidebarEventBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.imageUrl) {
      ImageApi.getImage(this.props.imageUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  renderTime(startTime) {
    const startDate = moment(startTime);
    const now = moment()

    const date = startDate.isSame(now, 'day') ? 'Today' : startDate.format('Do MMM')
    const time = startDate.format('H:mma')

    return `${date} - ${time}`;
  }

  render() {
    const { title, startTime, key, id } = this.props;
    const { imageUrl } = this.state;
    const rootClassName = 'sidebar-group-block';

    return (
      <Link
        className={rootClassName}
        to={`/feed/${id}?type=event`}
        onClick={() => {
          setTimeout(() => this.props.history.go(0), 30)
        }}>
        <div className={`${rootClassName}-image-holder`}>
          {imageUrl ? (
            <img className={`${rootClassName}-image`} src={imageUrl} alt="group" />
          ) : (
            <img
              className={`${rootClassName}-image ${rootClassName}-image__default`}
              src={GroupPlaceholder}
              alt=""
            />
          )}
        </div>

        <div className={`${rootClassName}-title-block`}>
          <p className={`${rootClassName}-title ${rootClassName}-event-title`}>{title}</p>
          <p className={`${rootClassName}-subtitle`}>{this.renderTime(startTime)}</p>
        </div>
      </Link>
    );
  }
}

export default withRouter(SidebarEventBlock);
