import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SearchApi } from '../../api';

import { Mention, Form, Button } from 'antd';

const { toString, getMentions, toContentState } = Mention;
const FormItem = Form.Item;

const defaultState = {
  loading: false,
  suggestions: [],
  rawSuggestions: [],
  mentions: [],
  value: '',
  showError: false,
  isValid: true,
  inputActive: false,
};

class InputWithMentions extends Component {
  state = defaultState;

  handleSubmit = (e, keepFocus) => {
    e.preventDefault();

    if (!this.state.value.trim().length) return;
    if (!this.state.isValid) return this.setState({ showError: true });

    this.props.onSubmit(this.encodeValue(this.state.value.trim()));
    this.props.form.resetFields();

    if (keepFocus) this.setState({ ...defaultState, inputActive: true });
    else this.setState(defaultState);
  };

  encodeValue = value => {
    const { getFieldValue } = this.props.form;
    const mentions = getMentions(getFieldValue('mention'));

    const selectedMentions = this.state.mentions.filter(mention => mentions.includes(mention.name));

    let result = value;
    selectedMentions.forEach(mention => {
      const data = `@[${mention.name.replace('@', '')}](id:${mention.id})`;
      result = result.replace(mention.name, data);
    });

    return result;
  };

  getPersonNameDisplay = person => {
    return `${person.first_name || ''} ${person.last_name || ''}`.split(' ').join('.');
  };

  filterPeopleSuggestions = people => {
    return people
      .filter(person => person.first_name || person.last_name)
      .map(person => this.getPersonNameDisplay(person));
  };

  onSearchChange = async value => {
    this.setState({ loading: true });

    // Create search string depending on keyword with punctuation
    const searchValue =
      value.split('.').length > 1
        ? value.split('.')[1]
          ? value.split('.')[1]
          : value.split('.')[0]
        : value;
    const selectedGroup = this.props.selectedGroup || {};
    const peopleData = await SearchApi.getPeople({ value: searchValue, groupId: selectedGroup.id });
    let groupsData = {
      groups: [],
    };
    if (this.props.isPostCreation) {
      if (this.props.isAdmin) {
        groupsData = await SearchApi.getGroups({ value: searchValue, groupId: selectedGroup.id });
      } else if (selectedGroup.is_moderator) {
        groupsData = await SearchApi.getGroups({ value: selectedGroup.name });
      }
    }
    const peopleSuggestions = this.filterPeopleSuggestions(peopleData.users);
    const rawPeople = peopleData.users.filter(user => user.first_name || user.last_name);
    const groupSuggestions = groupsData.groups.map(group => group.name.split(' ').join('.'));
    if (groupSuggestions.length) groupSuggestions.splice(0, 0, 'Groups');
    if (peopleSuggestions.length && this.props.isPostCreation)
      peopleSuggestions.splice(0, 0, 'People');
    this.setState({ loading: false });
    this.setState({
      suggestions: [...groupSuggestions, ...peopleSuggestions],
      rawSuggestions: [...groupsData.groups, ...rawPeople],
    });
  };

  onChange = contentState => {
    const newState = {
      value: toString(contentState),
    };

    newState.isValid =
      !this.props.maxLength || newState.value.trim().length <= this.props.maxLength;
    if (!newState.isValid.isValid && this.props.isPostCreation) newState.showError = true;
    this.setState(newState);
    if (this.props.isPostCreation) {
      this.props.onChange(this.encodeValue(newState.value.trim()));
    }
  };

  onSelect = value => {
    const slctdSuggestion = this.state.rawSuggestions.find(suggestion => {
      if (suggestion.name) {
        return suggestion.name.split(' ').join('.') === value;
      } else {
        return this.getPersonNameDisplay(suggestion) === value;
      }
    });
    if (slctdSuggestion) {
      const mention = {
        name: `@${value}`,
        id: slctdSuggestion.id.trim(),
      };
      this.setState(prevState => ({ mentions: [...prevState.mentions, mention] }));
    }
  };

  render () {
    const { getFieldDecorator } = this.props.form;

    const classNames = ['input-with-mentions'];
    if (this.state.value) classNames.push('active');
    if (this.state.showError && !this.state.isValid) classNames.push('has-error');
    const suggestionElements = this.state.suggestions.map(item => {
      return (
        <div value={item === 'Groups' || item === 'People' ? '' : item}>
          <p
            onClick={this.onSelect}
            style={
              item === 'Groups' || item === 'People'
                ? { fontWeight: 'bold', margin: 0, marginLeft: 0 }
                : { margin: 0 }
            }
          >
            {item}
          </p>
        </div>
      );
    });
    return (
      <div className={classNames.join(' ')}>
        <Form layout='horizontal' onSubmit={this.handleSubmit}>
          <span
            className={`input-label ${this.state.inputActive || this.state.value ? 'active' : ''}`}
            onClick={() => this.mentionRef.focus()}
          >
            {this.props.label ? this.props.label : 'Comment'}
          </span>

          <FormItem wrapperCol={{ span: 24 }}>
            {getFieldDecorator('mention', {
              initialValue: toContentState(''),
            })(
              <Mention
                multiLines={this.props.isPostCreation ? true : false}
                ref={ref => (this.mentionRef = ref)}
                suggestions={suggestionElements}
                onSelect={this.onSelect}
                onChange={this.onChange}
                loading={this.state.loading}
                onSearchChange={this.onSearchChange}
                onKeyDown={event => {
                  if (!this.props.isPostCreation) {
                    if (event.nativeEvent.which === 13) {
                      return this.handleSubmit(event, true);
                    } else {
                      return null;
                    }
                  }
                }}
                onFocus={() => this.setState({ inputActive: true })}
                onBlur={() => this.setState({ inputActive: false })}
              />,
            )}
          </FormItem>

          {this.state.showError && !this.state.isValid && (
            <div className='input-general-error mention-error'>
              <p className='field-container__error m-0'>{`Too many characters entered - maximum is ${this.props.maxLength}`}</p>
            </div>
          )}

          {!this.props.isPostCreation && this.state.value.trim().length > 0 && (
            <Button htmlType='submit'>Submit</Button>
          )}
        </Form>
      </div>
    );
  }
}

InputWithMentions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const FormInputWithMentions = Form.create()(InputWithMentions);

export default FormInputWithMentions;
