import React, { Component } from 'react';
import validate from '../../../utils/validate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      error: null,
      isFocused: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    const { fk, value, updateFieldData, validateObj } = this.props;
    let { error } = this.props;

    if (!error) {
      error = this.getErrorMessage(validateObj);
    }

    updateFieldData(fk, value, error);
    this.setState({ error, value });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  componentDidUpdate(prevProps) {
    // if new value then validate via validate.js
    if (prevProps.value !== this.props.value) {
      this.onChange(this.props.value);
    }

    // if server return error then update state only
    if (prevProps.value === this.props.value && prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
        value: this.props.value,
      });
    }
  }

  getErrorMessage(obj = {}) {
    const errors = validate(obj);

    return errors ? errors[0] : null;
  }

  onChange(value) {
    const {
      validateObj = {},
      validateRule,
      updateFieldData,
      fk,
      triggerOnChange,
      inputType,
    } = this.props;

    if (inputType === 'number') {
      value = value.replace(/\D/, '');
    }

    validateObj[validateRule] = value;
    const errorText = this.getErrorMessage(validateObj);
    this.setState({ error: errorText, value });

    if (triggerOnChange) updateFieldData(fk, value, errorText);
  }

  onFocus() {
    this.setState({ isFocused: true });
    if (typeof this.props.onFocus === 'function') this.props.onFocus();
  }

  onBlur() {
    const { updateFieldData, fk, ignoreBlur, onBlur } = this.props;
    const { value, error } = this.state;

    if (!ignoreBlur) updateFieldData(fk, value, error);
    this.setState({ isFocused: false });

    if (typeof onBlur === 'function') onBlur();
  }

  _renderRightComponent() {
    const { rightComponent } = this.props;

    if (typeof rightComponent !== 'function') return null;

    return <div className="right-component">{rightComponent()}</div>;
  }

  render() {
    const {
      validateObj,
      validateRule,
      updateFieldData,
      updateFieldType,
      fk,
      error,
      type,
      label,
      value,
      fieldTag = 'input',
      helperText,
      showError,
      placeholder,
      floatingLabelFixed = false,
      rightComponent,
      triggerOnChange,
      inputType,
      ignoreBlur,
      customError,
      onChange,
      ...props
    } = this.props;

    const FieldTag = fieldTag;
    const isInputActive = this.state.isFocused || value || floatingLabelFixed;
    const errorText = showError && this.state.error;
    const placeholderText = isInputActive ? placeholder : '';

    return (
      <div
        className={`
          field-container
          ${isInputActive ? 'active' : ''}
          ${errorText ? 'has-error' : ''}
          ${this.state.isFocused ? 'focused' : ''}
        `}
      >
        {this._renderRightComponent()}
        {/*  if field label is Password or Repeat Password render right icon component for revealing password text*/}
        {label === 'Password' || 'Repeat Password' ?
          <div className='d-flex'>
            <FieldTag
              width={'20'}
              type={type}
              value={this.state.value}
              placeholder={placeholderText}
              onChange={e => this.onChange(e.target.value)}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            >
            </FieldTag>
            {label === 'Password' ?
              <div className='right-component' onClick={type === 'password' ? () => updateFieldType('password', 'text') : () => updateFieldType('password', 'password')}>
                <FontAwesomeIcon icon="eye" color={type === 'password' ? "#505A62" : "--var(--primary-color)"} />
              </div>
              : 
              label == 'Repeat Password' ?
              <div className='right-component' onClick={type === 'password' ? () => updateFieldType('confirmpassword', 'text') : () => updateFieldType('confirmpassword', 'password')}>
                <FontAwesomeIcon icon="eye" color={type === 'password' ? "#505A62" : "--var(--primary-color)"} />
              </div>
              : null 
            }
          </div>
          :
          <FieldTag
            {...props}
            type={type}
            value={this.state.value}
            placeholder={placeholderText}
            onChange={e => this.onChange(e.target.value)}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        }
        {label && (
          <label className="field-label">
            {label}
            {/*{ validateRule &&*/}
            {/*<span className="ml-1 field-required">*</span>*/}
            {/*}*/}
          </label>
        )}
        {errorText && (
          <div className="field-container__error-wrapper">
            <p className="field-container__error m-0">{errorText}</p>
          </div>
        )}
        {helperText && !this.state.value && !errorText && (
          <p
            className="field-container__helper-text"
            dangerouslySetInnerHTML={{ __html: helperText }}
          ></p>
        )}
      </div>
    );
  }
}

export default Input;
