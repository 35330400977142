import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ThumbnailImage} from '../index';

import './styles.scss'

function PeopleSearchResults(props) {
  const { data } = props;

  return (
    <div className="container mt-3">
      <div className="row">
        <h5 className="font-weight-semibold">People</h5>
      </div>
      <div className="row d-flex justify-content-between">
        {data
          .filter(item => item.first_name || item.last_name)
          .map(item => (
            <Link
              to={`users/${item.id}`}
              className="mb-3 remove-highlight-link people-cont"
              key={item.id}
            >
              <div className="d-flex flex-row align-items-center overflow-hidden">
                <ThumbnailImage sourceUrl={item.image.thumbnailUrl} type="person" />

                <div className="truncate-1-lines card-block pl-2 pt-1">
                  <h6 className="card-title mb-0 single-line-text font-weight-semibold">{`${item.first_name ||
                    ''} ${item.last_name || ''}`}</h6>
                  <p className="card-text">{item.job_title}</p>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

PeopleSearchResults.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PeopleSearchResults;
