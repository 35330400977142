import React from 'react';
import UserApi from '../../api/userProfile';
import ImageApi from '../../api/image';
import { Spinner } from 'react-bootstrap';
import './styles.scss';

class Participant extends React.Component {
  state = {
    userAvatar: '',
  };

  componentDidMount() {
    UserApi.fetchCollegueProfile({ id: this.props.participant })
      .then(user => {
        this.setState({ ...user.user });
      })
      .then(() => {
        ImageApi.getImage(this.state.image.url).then(img => {
          this.setState({
            userAvatar: img,
          });
        });
      });
  }

  render() {
    const { first_name, last_name, job_title } = this.state;

    return (
      <React.Fragment>
        <div className="col-lg-6 mb-3 px-1 remove-highlight-link">
          {this.state.userAvatar ? (
            <div className="card flex-row align-items-center img-container align-content-center p-2 overflow-hidden">
              {this.state.image_id !== null ? (
                <img className="user-avatar" src={this.state.userAvatar} alt="" />
              ) : (
                <div className="d-flex justify-content-center user-avatar align-items-center">
                  <span className="text-uppercase name-placeholder">
                    {first_name &&
                      `
                      ${first_name.toString().slice(0, 1)} 
                      ${last_name.toString().slice(0, 1)}`}
                  </span>
                </div>
              )}
              <div className="card-block pl-2 pt-1">
                <h6 className="card-title mb-1 single-line-text font-weight-bolder">
                  {first_name} {last_name}
                </h6>
                <p className="card-text subtitle">{job_title}</p>
              </div>
            </div>
          ) : (
            <div className="mb-4 text-center d-flex w-100 h-100 align-items-center justify-content-center">
              <Spinner animation="border" variant="dark" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Participant;
