import * as React from "react"

function Secret(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <rect
          x={0}
          y={0}
          width={40}
          height={40}
          rx={8}
          fill="#C3879B"
          transform="translate(-16 -594) translate(16 396) translate(0 198)"
        />
        <path
          d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
          transform="translate(-16 -594) translate(16 396) translate(0 198) translate(8 9)"
        />
        <path
          d="M12 6a9.77 9.77 0 018.82 5.5 9.647 9.647 0 01-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 01-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 00-.36 1.78 4.507 4.507 0 006.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 01-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(-16 -594) translate(16 396) translate(0 198) translate(8 9)"
        />
      </g>
    </svg>
  )
}

export default Secret