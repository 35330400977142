import React, { useEffect, useState } from 'react';
import ImageApi from '../../../../../api/image';
import { Portal } from '../../../../../components';
import MicroLearningModal from '../../../../../components/MicroLearningDetailsModal';

const LearningItem = ({ learningItem }) => {
  const [learningAvatar, setLearningAvatar] = useState('');
  const [isLearningModal, handleLearningModal] = useState(false);

  useEffect(() => {
    ImageApi.getImage(learningItem.image.url).then(img => {
      setLearningAvatar(img);
    });
  }, []);

  const props = {
    learningAvatar,
    learningItem,
  };

  const handleActive = ['learning-item--title'];

  if (learningItem.completed) handleActive.push('completed');
  if (!learningItem.image_id) handleActive.push('width-without-img');

  return (
    <React.Fragment>
      <div className="learning-item">
        {learningItem.image_id && (
          <img className="learning-item--img" src={learningAvatar} alt="" />
        )}
        <div className={learningItem.image_id ? 'learning-text-img' : 'learning-text'}>
          <div>
            <h3
              onClick={
                !learningItem.completed
                  ? () => {
                      handleLearningModal(true);
                    }
                  : null
              }
              className={handleActive.join(' ')}
            >
              {learningItem.name}
            </h3>
            {learningItem.completed ? (
              <p className="learning-item--subtitle">
                <strong>Completed</strong>
              </p>
            ) : (
              <p className="learning-item--subtitle">
                Time to complete: <strong>{`${learningItem.time_to_complete} ${learningItem.time_to_complete > 1 ? 'mins' : 'min'}`}</strong>
              </p>
            )}
          </div>
          <div className="learning-item--points">
            {learningItem.completed ? learningItem.points : learningItem.points}
          </div>
        </div>
      </div>
      <Portal
        hide={() => {
          handleLearningModal(!isLearningModal);
        }}
        isOpen={isLearningModal}
      >
        <MicroLearningModal {...props} hide={() => handleLearningModal(false)} />
      </Portal>
    </React.Fragment>
  );
};

export default LearningItem;
