import React, { useEffect, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThumbnailImage } from '../index';
import { toggleJoinGroupRequest } from '../../actionCreators/groupActions';
import lodash from 'lodash';

class GroupItem extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      user_count: props.group ? props.group.user_count : 0,
    };
  }
  _onToggleJoinClick = event => {
    event.preventDefault();
    if (this.props.group.group_privacy_type_id === 'PUBLIC') {
      this.setState({
        user_count: this.props.is_join ? this.state.user_count - 1 : this.state.user_count + 1,
      });
    }
    this.props.toggleJoinGroup({ groupId: this.props.group.id, join: !this.props.is_join });
  };
  render () {
    const { id, name, image, is_org_level, description, is_mandatory } = this.props.group || {};
    const user_count = this.state.user_count;

    return (
      <div className='group-item-cont'>
        {image ? <ThumbnailImage sourceUrl={image.thumbnailUrl} type='person' /> : null}
        <div className='group-item-details flex-fill'>
          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='truncate-1-lines font-weight-semibold group-name-container'>
                <Link to={`/group-feed/${id}`} className='group-item-name'>
                  {name}
                </Link>
              </div>
              {user_count ? (
                <Link
                  className='group-item-members'
                  to={{ search: `?listType=group&showUsers=true&id=${id}` }}
                >
                  {user_count} member{user_count > 1 ? 's' : ''}
                </Link>
              ) : null}
            </div>
            {!is_org_level && (
              <div className=''>
                {this.props.is_requested ? (
                  <div className='group-item-button group-item-button-request-sent'>Requested</div>
                ) : (
                  <div
                    className={`group-item-button ${
                      this.props.is_join ? 'group-item-button-leave' : ''
                    } ${is_mandatory && this.props.is_join ? 'disabled-b' : ''}`}
                    onClick={!is_mandatory ? this._onToggleJoinClick : null}
                  >
                    {this.props.is_join ? 'Leave' : 'Join'}
                  </div>
                )}
              </div>
            )}
          </div>
          {description ? <div className='flex-start truncate-3-lines'>{description}</div> : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    is_join: lodash.find(state.group.myGroups, { id: props.group ? props.group.id : '' })
      ? true
      : false,
    is_requested: lodash.find(state.group.groupsDetailed, {
      id: props.group ? props.group.id : '',
      is_requested: 1,
    })
      ? true
      : false,
  };
};
const mapDispatchToProps = dispatch => ({
  toggleJoinGroup: payload => dispatch(toggleJoinGroupRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupItem);
