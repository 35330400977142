import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class CategoryApi {
  static getCategories() {
    const request = new Request(`${apiUrl}/content/categories`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}

export default CategoryApi;
