import * as React from "react"

function Private(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <rect
          x={0}
          y={0}
          width={40}
          height={40}
          rx={8}
          fill="#E9C874"
          transform="translate(-16 -498) translate(16 396) translate(0 102)"
        />
        <path
          d="M2.25 9.75h13.5a1.5 1.5 0 011.5 1.5v10.5a1.5 1.5 0 01-1.5 1.5H2.25a1.5 1.5 0 01-1.5-1.5v-10.5a1.5 1.5 0 011.5-1.5zm1.5 0V6a5.25 5.25 0 1110.5 0v3.75M9 15v3"
          transform="translate(-16 -498) translate(16 396) translate(0 102) translate(11 8)"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
  )
}

export default Private