import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LearningTestItem from '../LearningTestItem';
import './styles.scss';
import { getUserMicroLearningAnswers } from '../../../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../../../firebase/analytics';

import ProgressBar from '../../../../components/global/ProgressBar';

const LearningResults = ({
  progressBarLength,
  results,
  id,
  handleResults,
  score,
  questions,
  history,
  taskId,
  firstTimeLogin
}) => {
  useEffect(() => {
    handleResults({ answers: Object.values(results), id: id });
  }, []);

  const handleLength = () => {
    const answersLength = questions.filter(question => question.answers !== null) || 0;
    return answersLength;
  };

  return (
    <React.Fragment>
      <div className='progressbar'>
        <ProgressBar className="m-0" width={progressBarLength} />
      </div>
      <div className='results-container-scroll'>
        <div>
          <div className="results-container">
            <h2 className="title">Your results:</h2>
            {questions && handleLength().length < 1 ? (
              <p className="subtitle"> Congratulations! You completed this microlearning.</p>
            ) : (
              <p className="subtitle">
                You got
                <strong>
                  {' '}
                  {score} out <span className="mr-1">of</span>
                  {questions && handleLength().length}
                </strong>
                <span className="ml-1">questions</span> correct. Your selections are in
                <strong> bold.</strong>
              </p>
            )}
            {questions && questions.length > 1 && (
              <LearningTestItem length={handleLength} questions={questions} />
            )}
          </div>
          <div className="results-bottom-container text-right">
            <button
              onClick={() => {
                history.push(taskId ? `/employee_onboarding?firstTimeLogin=${firstTimeLogin}`: '/engage?tab=learning');
                { taskId ?
                  logFirebaseEvent({
                    event:'employee_onboarding',
                    action: 'task_completed'
                  }) 
                  &&
                  logFirebaseEvent({
                    event:'learning',
                    action:'completed'
                  })
                  :
                  logFirebaseEvent({
                    event:'learning',
                    action:'completed'
                  })
                }
              }}
              className="text-uppercase finish-btn"
            >
              finish
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  state => ({
    score: state.currentUser.learningResults.score,
    questions: state.currentUser.learningResults.questions,
  }),
  dispatch => ({
    handleResults: payload => dispatch(getUserMicroLearningAnswers(payload)),
  })
)(LearningResults);
