import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

function Portal(props) {
  const portalRoot = document.getElementById('portal');
  const { children, hide, isOpen, className, minWidth, topPosition } = props;

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="portal" onClick={hide}>
      <div
        className={className}
        onClick={e => e.stopPropagation()}
        style={{
          minWidth: minWidth ? minWidth : '500px',
          marginBottom: topPosition && 'auto',
        }}
      >
        {children}
      </div>
    </div>,
    portalRoot
  );
}

Portal.propTypes = {
  children: PropTypes.element,
  hide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default Portal;
