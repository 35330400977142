import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actionCreators/auth';
import { addAlertToAlertsList } from '../../actionCreators/globalAlerts';

import PrivacyAndTermsComponent from '../../components/PrivacyAndTerms';

const PrivacyAndTerms = props => <PrivacyAndTermsComponent {...props} />;

const mapStateToProps = state => ({
  account: state.account,
});

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout()),
  addAlertToAlertsList: payload => dispatch(addAlertToAlertsList(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyAndTerms);
