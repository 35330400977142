import React, { useState } from 'react';

import { connect } from 'react-redux';

import SearchInput from '../SearchInput/SearchInput';
import CreateGroupSelectedRow from '../CreateGroupModal/CreateGroupSelectedRow';
import CreateGroupSearchRow from '../CreateGroupModal/CreateGroupSearchRow';
import ManageMembers from '../ManageMembers';
import { logFirebaseEvent } from '../../firebase/analytics';
import { useRef } from 'react';

const SearchPeople = ({ searchResults, searchPeople, addMembers, updateGroup, group, token }) => {
  const [selectedPeople, setSelectedPeople] = useState([]);

  const searchField = useRef(null)

  const handleSearchPeople = React.useCallback(
    value => {
      if (searchPeople) {
        searchPeople({ value });
      }
    },
    [searchPeople],
  );

  const handlePersonSelected = React.useCallback(
    person => {
      setSelectedPeople([...selectedPeople, person]);
    },
    [selectedPeople],
  );

  const handleRemovePerson = React.useCallback(
    person => {
      setSelectedPeople(selectedPeople.filter(item => person.id !== item.id));
    },
    [selectedPeople],
  );

  const handleSubmitForm = () => {
    const payload = {
      groupId: group ? group.id : null,
      userIds: selectedPeople.map(person => person.id),
      token: token.key,
    };

    setSelectedPeople([]);
    if (searchField.current) {
      searchField.current.clear();
    }
    if (group) {
      addMembers(payload);
      logFirebaseEvent({
        event: 'group',
        action: 'update',
      });
    } else {
      console.log('updating failed as group could not be found');
    }
  };

  return (
    <div className="modal-content overflow-scroll create-group" style={{ maxWidth: '500px' }}>
      <div className="modal-body p-4 overflow-auto d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="manage-group-header-title pb-2">
            <h5 className="modal-title">Members</h5>
          </div>
          <div
            className={`manage-people__button manage-people__invite ${selectedPeople.length < 1 ? 'disabled' : 'active'
              } ml-auto`}
            onClick={selectedPeople.length > 0 ? handleSubmitForm : null}
          >
            Invite
          </div>
        </div>
        <div className="mt-3 p-2 mb-3 px-4 create-group__invite-colleagues rounded-lg">
          <SearchInput ref={ref => { searchField.current = ref; }} submit={handleSearchPeople} placeholder="Invite colleagues" />
          <div className="create-group__people-select">
            <div className="overflow-auto max-vh-10">
              {searchResults.users.length > 0 &&
                searchResults.users.map(item => {
                  if (
                    (!item.first_name && !item.last_name) ||
                    selectedPeople.some(person => person.id === item.id)
                  )
                    return null;

                  return (
                    <CreateGroupSearchRow
                      key={item.id}
                      item={item}
                      handlePersonSelected={handlePersonSelected}
                    />
                  );
                })}
            </div>
          </div>

          <div className="mt-2">
            {selectedPeople.length > 0 && (
              <div>
                <h6 className="create-group__selected-title">
                  {selectedPeople.length} colleague{selectedPeople.length > 1 ? 's' : ''} selected
                </h6>

                <ul className="list-group list-group-horizontal-lg flex-wrap">
                  {selectedPeople.map(item => (
                    <CreateGroupSelectedRow
                      key={`selected${item.id}`}
                      item={item}
                      handleRemovePerson={handleRemovePerson}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="manage-divider" />
      <div className="manage-group-header-title pb-2">
        <ManageMembers groupId={group.id} />
      </div>
    </div>
  );
};

export default connect(
  state => ({
    ...state.group,
    categories: state.categories,
    currentUser: state.currentUser,
    groupPrivacyTypes: state.group.groupPrivacyTypes,
  }),
  dispatch => ({}),
)(SearchPeople);
