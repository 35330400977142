import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const ScrollView = ({ onEndReached, offset, className, ...otherProps }) => {
  const [endReached, setEndReached] = useState(false);
  const scrollViewRef = useRef();

  const handleScroll = () => {
    const height = scrollViewRef.current.scrollHeight;
    const scroll = scrollViewRef.current.scrollTop;
    const contentHeight = scrollViewRef.current.offsetHeight;

    if (contentHeight + scroll >= height - offset) {
      if (!endReached) {
        onEndReached && onEndReached();
        setEndReached(true);
      }
    } else {
      if (endReached) {
        setEndReached(false);
      }
    }
  };

  return (
    <div
      {...otherProps}
      onScroll={handleScroll}
      ref={scrollViewRef}
      className={`overflow-auto  ${className}`}
    />
  );
};

ScrollView.defaultProps = {
  offset: 300,
};

ScrollView.propTypes = {
  onEndReached: PropTypes.func,
  offset: PropTypes.number,
};

export default ScrollView;
