import React from 'react';
import { connect } from 'react-redux';

import { signInRequest, signInRequestWithPassword } from '../../../actionCreators/auth';
import {
  getSecurityQuestionsRequest,
  validateSecurityRequest,
} from '../../../actionCreators/account';
import SignInComponent from '../../../components/Auth/SignIn';
import { addAlertToAlertsList } from '../../../actionCreators/globalAlerts';

const SignIn = props => <SignInComponent {...props} />;

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  account: state.account,
  customAppId: state.customApp.id,
  organisationId: state.customApp.organisation_id
});

const mapDispatchToProps = dispatch => ({
  signIn: payload => dispatch(signInRequest(payload)),
  signInWithPassword: payload => dispatch(signInRequestWithPassword(payload)),
  addAlertToAlerts: payload => dispatch(addAlertToAlertsList(payload)),
  getSecurityQuestions: () => dispatch(getSecurityQuestionsRequest()),
  validateSecurity: payload => dispatch(validateSecurityRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
