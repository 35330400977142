import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChallengeListItem } from '../index';
import { logFirebaseEvent } from '../../firebase/analytics';

const challengesSections = [
  {
    key: 'joined',
    title: 'Tracked Challenges',
  },
  {
    key: null,
    title: 'More Challenges',
  },
  {
    key: 'complete',
    title: 'Completed Challenges',
  },
];

function EngageChallenges ({ challenges }) {
  useEffect(() => {
    logFirebaseEvent({
      event: 'challenges',
      action: 'load',
    });
  }, []);
  function filterChallenges (key) {
    switch (key) {
      case 'joined':
        return challenges.filter(challenge => challenge.joined && !challenge.complete);
      case 'complete':
        return challenges.filter(challenge => challenge.complete);
      default:
        return challenges.filter(challenge => !challenge.joined);
    }
  }

  return (
    <div className="engage__content">
      <div className="challenges">
        {challenges.length === 0 ? (
          <div className="mt-5 placeholder-text">
            <p>There are currently no challenges running.</p>
            <p>Check back later.</p>
          </div>
        ) : (
          challengesSections.map(
            (section, index) =>
              filterChallenges(section.key).length > 0 && (
                <div key={`${section.key}-${index}`}>
                  <h5>{section.title}</h5>

                  {filterChallenges(section.key).map(challenge => (
                    <ChallengeListItem challenge={challenge} key={challenge.id} />
                  ))}
                </div>
              ),
          )
        )}
      </div>
    </div>
  );
}

EngageChallenges.propTypes = {
  challenges: PropTypes.array.isRequired,
};

export default EngageChallenges;
