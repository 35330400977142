import { put, takeLatest, call } from 'redux-saga/effects';

import {
  getChallengeRequest,
  getChallengesSuccess,
  getChallengeSuccess,
  updateChallengeSuccess,
} from '../actionCreators/challenge';
import { CHALLENGE } from '../actionCreators/challenge';
import { ChallengeApi } from '../api';
import errorHandler from '../utils/requestErrorHandler';

function* getChallenges(action) {
  try {
    const data = yield call(ChallengeApi.getChallenges, action.payload);

    yield put(getChallengesSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getChallenge(action) {
  try {
    const data = yield call(ChallengeApi.getChallenge, action.payload);

    yield put(getChallengeSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* updateChallenge(action) {
  try {
    const data = yield call(ChallengeApi.updateChallenge, action.payload);

    yield put(updateChallengeSuccess(data));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* updateChallengeJoined(action) {
  try {
    yield call(ChallengeApi.updateChallengeJoined, action.payload);

    yield put(getChallengeRequest({ id: action.payload.id }));
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchChallenge() {
  yield takeLatest(CHALLENGE.GET_CHALLENGES_REQUEST, getChallenges);
  yield takeLatest(CHALLENGE.GET_CHALLENGE_REQUEST, getChallenge);
  yield takeLatest(CHALLENGE.UPDATE_CHALLENGE_REQUEST, updateChallenge);
  yield takeLatest(CHALLENGE.UPDATE_CHALLENGE_JOINED_REQUEST, updateChallengeJoined);
}
