import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllEvents, getMyEvents } from '../../actionCreators/events';
import { EngageChallenges, EngageEvents } from '../../components';
import './styles.scss';
import { getChallengesRequest } from '../../actionCreators/challenge';
import EngageLearning from './components/Learning';
import EngageHeader from './components/EngageHeader';
import GridContainer from '../../components/global/GridContainer/GridContainer';

const Engage = ({
  handleAllEvents,
  handleMyEvents,
  myEvents,
  allEvents,
  getChallenges,
  token,
  challenges,
  location,
  history,
}) => {
  const checkTabActive = tab => {
    const params = new URLSearchParams(location.search);

    return tab === (params.get('tab') || 'event');
  };

  useEffect(() => {
    handleAllEvents();
    handleMyEvents();
    getChallenges({ token });
  }, []);

  const props = {
    view: new URLSearchParams(location.search).get('tab') || 'event',
  };

  return (
    <div className='engage-container'>
      <GridContainer>
        <div className='engage-container--upper'>
          <EngageHeader {...props} />
          {checkTabActive('event') === true && (
            <EngageEvents myEvents={myEvents} allEvents={allEvents} />
          )}
          {checkTabActive('challenge') === true && <EngageChallenges challenges={challenges} />}
          {checkTabActive('learning') === true && <EngageLearning />}
        </div>
      </GridContainer>
    </div>
  );
};

export default connect(
  state => ({
    myEvents: state.events.myEvents,
    allEvents: state.events.allEvents,
    challenges: state.challenge.challengesList,
    token: state.currentUser.signInData.key,
  }),
  dispatch => ({
    handleAllEvents: () => dispatch(getAllEvents()),
    handleMyEvents: () => dispatch(getMyEvents()),
    getChallenges: payload => dispatch(getChallengesRequest(payload)),
  }),
)(Engage);
