import React from 'react';
import { Skeleton } from 'antd';

export default function FeedLoading({ isLoading, amount }) {
  if (!isLoading) return null;

  const skeletonElements = [];
  for (let i = 0; i < amount; i++) {
    skeletonElements.push(<Skeleton key={`skeleton${i}`} avatar />);
  }

  return skeletonElements;
}
