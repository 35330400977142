import { put } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { addAlertToAlertsList } from '../actionCreators/globalAlerts';

export default function * (error) {
  switch (error.status) {
    case 402:
      yield put(replace('/onboarding'));
      break;

    default: {
      yield put(
        addAlertToAlertsList({
          title: 'Error',
          message: error.message,
          error: true,
          isTokenExpired: error.code === 'TOKEN_EXPIRED' ? true : false,
        }),
      );
    }
  }
}
