import React, { Component } from 'react';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from 'antd';
import { connect } from 'react-redux';

import TextField from '../../global/fields/TextField';

import FeedApi from '../../../api/feed';
import CreateFeedItemSearchColleague from './CreateFeedItemSearchColleague';
import CreateFeedItemSelectedColleague from './CreateFeedItemSelectedColleague';
import ImageApi from '../../../api/image';
import highfiveBg from '../../../assets/images/desktop_high-five.png';
import HighFive from '../../../assets/images/highFive.svg';
import UserPlaceholder from '../../../assets/images/user_placeholder.svg';
import Button from '../../global/Button';

class CreateFeedItemHighFive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colleagueText: '',
      message: {
        value: '',
        error: null,
      },
      reason: undefined,
      typeOptions: [],
      availableColleagues: [],
      isColleagueSelected: !!props.selectedColleague,
      selectedColleague: props.selectedColleague || {},
      selectedColleagueImageUrl: '',
      selectActive: false,
      focusSearchColleague: false,
    };
  }

  componentDidMount() {
    FeedApi.getHighFiveOptions().then(options => {
      this.setState({ typeOptions: options.types });
    });

    if (
      this.props.selectedColleague &&
      this.props.selectedColleague.image &&
      this.props.selectedColleague.image.thumbnailUrl
    ) {
      ImageApi.getImage(this.props.selectedColleague.image.thumbnailUrl).then(imageUrl => {
        this.setState({ selectedColleagueImageUrl: imageUrl });
      });
    }
  }

  _onReasonChange = newValue => {
    this.setState({ reason: newValue });
    this.select.blur();
  };

  onSelect = selectedColleague => {
    this.setState({ selectedColleague, isColleagueSelected: true });

    if (selectedColleague && selectedColleague.image && selectedColleague.image.thumbnailUrl) {
      ImageApi.getImage(selectedColleague.image.thumbnailUrl).then(imageUrl => {
        this.setState({ selectedColleagueImageUrl: imageUrl });
      });
    }
  };

  cancelColleague = () => {
    this.setState({
      selectedColleague: {},
      isColleagueSelected: false,
      selectedColleagueImageUrl: '',
    });
  };

  _submit = () => {
    if (typeof this.props.onSubmit !== 'function') return;

    const body = {
      recipient_id: this.state.selectedColleague.id,
      highfive_type_id: this.state.reason,
    };
    if (this.state.message.value) body.reason = this.state.message;

    this.props.onSubmit('High Five', body);
  };

  _shouldShowButton = () => {
    if (typeof this.props.checkFields !== 'function') return;

    const body = {
      recipient_id: this.state.selectedColleague.id,
      highfive_type_id: this.state.reason,
    };
    if (this.state.message.value) body.reason = this.state.message;

    return this.props.checkFields('High Five', body);
  };

  render() {
    const { userImageUrl, selectedGroupId, showErrors } = this.props;
    const {
      selectedColleagueImageUrl,
      isColleagueSelected,
      selectedColleague,
      reason,
      message,
    } = this.state;
    const showButton = this._shouldShowButton();
    return (
      <div className="create-feed-extra-block">
        <div className="feed-item-highfive-header create-feed-highfive-block">
          <img className="feed-item-highfive-bg-image" src={highfiveBg} alt="" />

          <Avatar
            className={`feed-item-highfive-header-image left-image`}
            src={userImageUrl || UserPlaceholder}
            icon="user"
            shape="square"
            size={92}
          />
          <Avatar
            className={`feed-item-highfive-header-image right-image ${
              isColleagueSelected ? '' : 'not-selected'
            }`}
            shape="square"
            src={selectedColleagueImageUrl || UserPlaceholder}
            size={92}
            onClick={() => {
              if (isColleagueSelected) return;

              this.setState({ focusSearchColleague: true });
            }}
          />

          <img className="feed-item-highfive-hands-image create-item" src={HighFive} alt="" />

          <div
            className={`create-feed-highfive-colleague-row ${
              isColleagueSelected ? 'selected' : ''
            }`}
          >
            {!isColleagueSelected ? (
              <CreateFeedItemSearchColleague
                onSelect={this.onSelect}
                selectedGroupId={selectedGroupId}
                showError={showErrors}
                focusSearchColleague={this.state.focusSearchColleague}
                resetFocusSearchColleague={() => this.setState({ focusSearchColleague: false })}
              />
            ) : (
              <CreateFeedItemSelectedColleague
                colleague={selectedColleague}
                onCancel={this.cancelColleague}
                disallowEditColleague={this.props.disallowEditColleague}
              />
            )}
          </div>
        </div>

        <div className="create-feed-select-holder">
          <span
            className={`create-feed-select-label ${
              this.state.selectActive || this.state.reason ? 'active' : ''
            }`}
            onClick={() => this.select.focus()}
          >
            Select reason
          </span>

          <Select
            ref={ref => (this.select = ref)}
            onChange={this._onReasonChange}
            value={reason}
            className="create-feed-select"
            showArrow={false}
            dropdownClassName="create-feed-select-dropdown"
            open={this.state.selectActive}
            onFocus={() => this.setState({ selectActive: true })}
            onBlur={() => this.setState({ selectActive: false })}
          >
            {this.renderOptions()}
          </Select>

          <FontAwesomeIcon className="create-feed-select-icon" icon={['fas', 'chevron-down']} />

          {showErrors && !reason && (
            <div className="create-feed-select-error">This field is required</div>
          )}
        </div>

        <TextField
          fk="message"
          value={message.value}
          updateFieldData={(fk, value, error) => this.setState({ message: { value, error } })}
          fieldTag="textarea"
          rows="3"
          label="Message (optional)"
          triggerOnChange
          validateRule="max256"
          validateObj={{ max256: message.value }}
          showError={showErrors}
        />

        <div className="create-feed-post-button">
          <Button.Primary onClick={this._submit} type="submit" disabled={!showButton}>
            POST
          </Button.Primary>
        </div>
        <div className="create-feed-empty-space" />
      </div>
    );
  }

  renderOptions() {
    return this.state.typeOptions.map(option => {
      return (
        <Select.Option key={`optionType:${option.id}`} value={option.id}>
          <div className="create-feed-select-dropdown-option">{option.highfive_type_text}</div>
        </Select.Option>
      );
    });
  }
}

export default connect(state => ({ userImageUrl: state.currentUser.signInData.imageUrl }))(
  CreateFeedItemHighFive
);
