import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import lodash from 'lodash';

import {
  SET_GROUP_LOADING,
  GET_GROUPS_REQUEST,
  GET_GROUPS_REQUEST_SUCCESS,
  GET_MY_GROUPS_REQUEST,
  GET_MY_GROUPS_REQUEST_SUCCESS,
  CREATE_GROUP_REQUEST,
  VERIFY_GROUP_REQUEST,
  VERIFY_GROUP_SUCCESS,
  GET_GROUP_REQUEST,
  GET_GROUP_REQUEST_SUCCESS,
  TOGGLE_JOIN_GROUP_REQUEST,
  SHOW_CREATE_GROUP_MODAL,
  GET_GROUP_PRIVACY_TYPES_REQUEST,
  GET_GROUP_PRIVACY_TYPES_SUCCESS,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  POST_NEW_MEMBER_REQUEST,
  POST_NEW_MEMBER_SUCCESS,
  ADD_GROUP_MEMBER_SUCCESS,
  ADD_GROUP_MEMBER_REQUEST,
  GET_USERS_REQUEST,
} from '../constants/actionTypes';

import GroupApi from '../api/group';
import errorHandler from '../utils/requestErrorHandler';

function* createGroup(action) {
  try {
    const result = yield call(GroupApi.createGroup, action.payload);
    yield put({
      type: VERIFY_GROUP_SUCCESS,
      payload: {
        createGroupRequest: {},
        recommendedGroups: [],
        errorMessage: '',
      },
    });
    yield put({ type: SHOW_CREATE_GROUP_MODAL, payload: { flag: false } });
    yield put(push(`/group-feed/${result.group.id}`));
  } catch (error) {
    yield errorHandler(error);
  }
}

function* updateGroup(action) {
  try {
    const data = yield call(GroupApi.verifyGroup, action.payload);
    if (data && data.groups) {
      const exists = data.groups.find((group) => group.name.toLowerCase() === action.payload.name.toLowerCase() && action.payload.groupId !== group.id);
      const verifyGroupPayload = {
        createGroupRequest: action.payload,
        recommendedGroups: [],
        errorMessage: exists ? 'Please update the group name to continue' : '',
      };
      if (exists) {
        yield put({ type: VERIFY_GROUP_SUCCESS, payload: verifyGroupPayload });
      } else {
        yield put({
          type: VERIFY_GROUP_SUCCESS,
          payload: {
            createGroupRequest: {},
            recommendedGroups: [],
            errorMessage: ''
          },
        });
        const result = yield call(GroupApi.updateGroup, action.payload);
        yield put({
          type: UPDATE_GROUP_SUCCESS,
          payload: {
            updateGroupRequest: {},
          },
        });
        yield put({ type: SHOW_CREATE_GROUP_MODAL, payload: { flag: false } });
        yield put(push(`/group-feed/${result.group.id}`));
        yield put({ type: GET_GROUP_REQUEST, payload: action.payload.groupId });
      }
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function* addGroupMembers(action) {
  try {
    const data = yield call(GroupApi.addGroupMembers, action.payload);
    yield put({ type: ADD_GROUP_MEMBER_SUCCESS, payload: data })
    yield put({
      type: GET_USERS_REQUEST, payload: {
        type: 'group',
        id: action.payload.groupId
      }
    })
  } catch (error) {
    yield errorHandler(error)
  }
}

function* verifyGroup(action) {
  try {
    const data = yield call(GroupApi.verifyGroup, action.payload);
    if (data && data.groups.length > 0) {
      const exists = data.groups.find((group) => group.name.toLowerCase() === action.payload.name.toLowerCase());
      const verifyGroupPayload = {
        createGroupRequest: action.payload,
        recommendedGroups: [...data.groups],
        errorMessage: exists ? 'Please update the group name to continue' : '',
      };
      yield put({ type: VERIFY_GROUP_SUCCESS, payload: verifyGroupPayload });
      // yield put({ type: SHOW_CREATE_GROUP_MODAL, payload: false });
    } else {
      yield put({ type: CREATE_GROUP_REQUEST, payload: action.payload });
      yield put({
        type: VERIFY_GROUP_SUCCESS,
        payload: {
          createGroupRequest: {},
          recommendedGroups: [],
          errorMessage: ''
        },
      });
      yield put({ type: SHOW_CREATE_GROUP_MODAL, payload: { flag: false } });
    }
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getGroupsRequest() {
  yield put({ type: SET_GROUP_LOADING, payload: true });

  try {
    const data = yield call(GroupApi.getGroups);

    yield put({ type: GET_GROUPS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_GROUP_LOADING, payload: false });
  }
}

function* getMyGroupsRequest() {
  yield put({ type: SET_GROUP_LOADING, payload: true });

  try {
    const data = yield call(GroupApi.getMyGroups);

    const previousActivity = JSON.parse(localStorage.getItem('myGroupsActivity'));
    previousActivity && previousActivity.length && data.groups.map((group) => {
      const previousGroupActivity = lodash.find(previousActivity, { id: group.id }) || { post_count: 0 };

      group.newPostActivity = group.post_count && previousGroupActivity.post_count ?
        group.post_count - previousGroupActivity.post_count
        : 0;

      return group;
    });

    localStorage.setItem('myGroupsActivity', JSON.stringify(data.groups));
    yield put({ type: GET_MY_GROUPS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_GROUP_LOADING, payload: false });
  }
}

function* getGroupRequest(action) {
  try {
    const data = yield call(GroupApi.getGroup, action.payload);

    let previousActivity = JSON.parse(localStorage.getItem('myGroupsActivity'));
    const currentIndex = lodash.findIndex(previousActivity, { id: data.group.id });
    previousActivity.splice(currentIndex, 1, { ...previousActivity[currentIndex], newPostActivity: 0 });
    localStorage.setItem('myGroupsActivity', JSON.stringify(previousActivity));

    yield put({ type: GET_GROUP_REQUEST_SUCCESS, payload: data.group });
  } catch (error) {
    //yield errorHandler(error); //don't show message on unable to get group info
  }
}

function* toggleJoinGroupRequest(action) {
  try {
    yield call(GroupApi.toggleJoinGroup, action.payload);

    yield put({ type: GET_GROUP_REQUEST, payload: action.payload.groupId });
    yield put({ type: GET_MY_GROUPS_REQUEST, payload: action.payload.groupId });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* acceptNewUser(action) {
  try {
    yield call(GroupApi.acceptNewUser, action.payload);
    // yield put({ type: GET_GROUP_REQUEST, payload: action.payload.groupId });
    // yield put({ type: GET_MY_GROUPS_REQUEST, payload: action.payload.groupId });
  } catch (error) {
    yield errorHandler(error);
  }
}

function* getGroupPrivacyTypesRequest() {
  try {
    const data = yield call(GroupApi.getGroupPrivacyTypes);

    yield put({ type: GET_GROUP_PRIVACY_TYPES_SUCCESS, payload: data });
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function* watchGroup() {
  yield takeLatest(CREATE_GROUP_REQUEST, createGroup);
  yield takeLatest(VERIFY_GROUP_REQUEST, verifyGroup);
  yield takeLatest(GET_GROUPS_REQUEST, getGroupsRequest);
  yield takeLatest(GET_MY_GROUPS_REQUEST, getMyGroupsRequest);
  yield takeLatest(GET_GROUP_REQUEST, getGroupRequest);
  yield takeLatest(TOGGLE_JOIN_GROUP_REQUEST, toggleJoinGroupRequest);
  yield takeLatest(GET_GROUP_PRIVACY_TYPES_REQUEST, getGroupPrivacyTypesRequest);
  yield takeLatest(UPDATE_GROUP_REQUEST, updateGroup);
  yield takeLatest(ADD_GROUP_MEMBER_REQUEST, addGroupMembers);
  yield takeLatest(POST_NEW_MEMBER_REQUEST, acceptNewUser);
}
