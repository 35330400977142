import { call, put, takeLatest, all } from 'redux-saga/effects';

import errorHandler from '../utils/requestErrorHandler';
import { TagApi } from '../api';
import { GET_USER_TAGS_START } from '../constants/actionTypes';
import { getUserTagsFail, getUserTagsSuccess } from '../actionCreators/userTags';

function* getUserTagsWorker() {
  try {
    const result = yield call(TagApi.getUserTags);
    yield put(getUserTagsSuccess(result.tags));
  } catch (error) {
    yield put(getUserTagsFail(error));
    yield errorHandler(error);
  }
}

export default function* watchDepartmentsRoot() {
  yield all([takeLatest(GET_USER_TAGS_START, getUserTagsWorker)]);
}
