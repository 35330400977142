import React from 'react';

export default function CrossWithBgIcon({ fill = 'none', stroke = '#A7ACB0', className = '' }) {
  return (
    <svg
      className={className}
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="✏️-10-Creating-Posts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="10-Create-Notice-Filled"
          transform="translate(-1022.000000, -365.000000)"
          fill="#505A62"
        >
          <g id="Image-Holder" transform="translate(506.000000, 358.000000)">
            <g id="Icons-/-Cross" transform="translate(515.000000, 6.000000)">
              <path
                d="M13.5909903,12.4545455 L17.4238304,8.62170529 C17.8631703,8.18236546 17.8631703,7.47005486 17.4238304,7.03071503 C16.9844906,6.5913752 16.27218,6.5913752 15.8328402,7.03071503 L12,10.8635552 L8.16715983,7.03071503 C7.72782001,6.5913752 7.0155094,6.5913752 6.57616958,7.03071503 C6.13682975,7.47005486 6.13682975,8.18236546 6.57616958,8.62170529 L10.4090097,12.4545455 L6.57616958,16.2873856 C6.13682975,16.7267254 6.13682975,17.4390361 6.57616958,17.8783759 C7.0155094,18.3177157 7.72782001,18.3177157 8.16715983,17.8783759 L12,14.0455357 L15.8328402,17.8783759 C16.27218,18.3177157 16.9844906,18.3177157 17.4238304,17.8783759 C17.8631703,17.4390361 17.8631703,16.7267254 17.4238304,16.2873856 L13.5909903,12.4545455 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z"
                id="Oval-2"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
