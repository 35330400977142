import {
  SET_NOTIFICATION_LOADING,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  SET_NOTIFICATIONS_ITEMS,
} from '../constants/actionTypes';

const initialState = {
  items: [],
  unreadCount: 0,
  currentPage: 0,
  totalPages: Infinity,
  loading: false,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_NOTIFICATIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: [...state.items, ...action.payload.notifications],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.pages,
        unreadCount: action.payload.unreadCount,
      };
    }

    case SET_NOTIFICATIONS_ITEMS: {
      return {
        ...state,
        items: [...action.payload.notifications],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.pages,
        unreadCount: action.payload.unreadCount,
      };
    }

    default:
      return state;
  }
};

export default notification;
