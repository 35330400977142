import lodash from 'lodash';

import {
  GET_KNOWLEDGEBASE_ITEMS_SUCCESS,
  SET_KNOWLEDGEBASE_FILTERS,
  GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  items: [],
  filters: [],
  currentPage: 0,
  totalPages: Infinity,
  loading: false,
};

const knowledgebase = (state = initialState, action) => {
  switch (action.type) {
    case GET_KNOWLEDGEBASE_ITEMS_SUCCESS:
      return {
        ...state,
        items: [...action.payload.items],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS:
      const prevItems = state.items ? state.items : [];
      return {
        ...state,
        items: [...prevItems, ...action.payload.items],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };

    case SET_KNOWLEDGEBASE_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };

    default:
      return state;
  }
};

export default knowledgebase;
