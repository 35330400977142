import UserPlaceholder from '../../assets/images/user_placeholder.svg';
import { Avatar } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const Participant = ({ id, image }) => {
  return (
    <Avatar
      className="event-bottom-cover"
      shape="square"
      src={image.thumbnailUrl || UserPlaceholder}
      size={36}
      icon="user"
    />
  );
};

Participant.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default Participant;
