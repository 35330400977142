import update from 'immutability-helper';
import {
  USER_LOCKED,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_SUCCEEDED_WITH_PASSWORD,
  SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK,
  USER_PROFILE_CREATE_SUCCEEDED,
  SET_COLLEGUE_PROFILE,
  GET_COLLEGUE_PROFILE_SUCCESS,
  GET_COLLEGUE_AVATAR_SUCCESS,
  GET_USER_AVATAR_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  GET_USER_BADGES_REQUEST_SUCCESS,
  GET_USER_ACTIVITY_POINTS_SUCCESS,
  GET_USER_REQUEST_SUCCESS,
  GET_MICRO_LEARNING_REQUEST_SUCCESS,
  GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS,
  GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS,
  SET_PULSE_REPLIED,
  LOGOUT,
  GET_COLLEGUE_PROFILE_REQUEST,
  GET_ORG_THEME_SUCCESS,
} from '../constants/actionTypes';
import { getCookieValForKey } from '../api/helpers';
import { APP_PRIMARY_COLOR } from '../theme/constants';

const data = getCookieValForKey('token');
const initialState = {
  signInData: data,
  isAuthenticated: !!data.key,
  collegueProfile: {},
  userData: {
    user: {},
  },
  activityPoints: {},
  badges: {},
  learning: [],
  learningQuestions: {},
  learningResults: {},
  updateProfileModal: false,
  lockInfo: {},
  theme: {
    primaryColor: APP_PRIMARY_COLOR,
  },
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCEEDED:
      return update(state, {
        signInData: { $merge: action.data },
      });
    case USER_LOCKED:
      return update(state, {
        lockInfo: {
          $merge: { isLocked: action.data.locked, lockedUntil: action.data.lockedUntil },
        },
      });
    case SIGN_IN_SUCCEEDED_WITH_PASSWORD:
      return update(state, {
        signInData: { $merge: action.data },
        isAuthenticated: { $set: true },
      });
    case SIGN_IN_SUCCEEDED_WITH_MAGIC_LINK:
      return update(state, {
        signInData: { $merge: action.data },
        isAuthenticated: { $set: true },
      });
    case GET_USER_AVATAR_SUCCESS:
      return {
        ...state,
        signInData: {
          ...state.signInData,
          imageUrl: action.payload,
        },
      };
    case USER_PROFILE_CREATE_SUCCEEDED:
      return update(state, {
        userData: { $merge: action.data },
      });

    case SET_COLLEGUE_PROFILE:
      return {
        ...state,
        collegueProfile: action.payload,
      };
    case GET_COLLEGUE_PROFILE_REQUEST:
      return {
        ...state,
        collegueProfile: {},
      };
    case GET_COLLEGUE_PROFILE_SUCCESS:
      return {
        ...state,
        collegueProfile: action.payload.user,
      };
    case GET_USER_BADGES_REQUEST_SUCCESS:
      return {
        ...state,
        badges: action.payload,
      };
    case GET_USER_ACTIVITY_POINTS_SUCCESS:
      return {
        ...state,
        activityPoints: action.payload,
      };
    case GET_USER_REQUEST_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_COLLEGUE_AVATAR_SUCCESS:
      return {
        ...state,
        collegueProfile: {
          ...state.collegueProfile,
          avatarUrl: action.payload,
        },
      };
    case GET_MICRO_LEARNING_REQUEST_SUCCESS:
      return {
        ...state,
        learning: [...action.learning],
      };
    case GET_MICRO_LEARNING_QUESTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        learningQuestions: { ...action.payload.learning },
      };
    case GET_MICRO_LEARNING_ANSWERS_REQUEST_SUCCESS:
      return {
        ...state,
        learningResults: { ...action.payload },
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userData: {
          ...action.payload,
        },
      };
    case SET_PULSE_REPLIED:
      return {
        ...state,
        userData: {
          ...state.userData,
          pulse_replied: true,
        },
      };
    case LOGOUT:
      return {
        ...initialState,
        signInData: {},
        globalAlerts: {},
      };
    case GET_ORG_THEME_SUCCESS:
      return {
        ...state,
        theme: {
          primaryColor: action.payload,
        },
      };
    default:
      return state;
  }
};

export default currentUser;
