import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '../../hooks';

import TextField from '../global/fields/TextField';

function SearchInput(props, ref) {
  const { placeholder, submit } = props;

  useImperativeHandle(ref, () => ({
    clear: () => {
      setValue('')
    }
  }))

  // Search input value
  const [value, setValue] = useState('');
  // Submit only when value is changed
  const [isValueChanged, setValueChanged] = useState(false);
  // Search input debounced value
  const debouncedSearchValue = useDebounce(value, 500);

  // Debounce search value to perform search after delay
  useEffect(() => {
    if (isValueChanged) {
      submit(debouncedSearchValue);
    }

    setValueChanged(true);
  }, [debouncedSearchValue]);

  return (
    <TextField
      fk="name"
      type="text"
      value={value}
      label={placeholder}
      updateFieldData={(fk, value) => setValue(value)}
      triggerOnChange
    />
  );
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  submit: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  placeholder: 'Enter keyword',
};

export default forwardRef(SearchInput);
