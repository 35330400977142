import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders, setCookieValForKey } from './helpers';

export default class AuthApi {
  static getToken (data) {
    const request = new Request(`${apiUrl}/authentication/request_authentication`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return fetchRequest(request);
  }
  static authenticationWithPassword (data) {
    const request = new Request(`${apiUrl}/authentication/password_authentication`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return fetchRequest(request).then(data => {
      if (data) setCookieValForKey('token', data);

      return data;
    });
  }

  static authenticationWithMagicLink (data) {
    const request = new Request(`${apiUrl}/authentication/token_authentication`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data),
    });

    return fetchRequest(request).then(data => {
      if (data) setCookieValForKey('token', data);
      return data;
    });
  }

  static logAppSession () {
    const request = new Request(`${apiUrl}/content/appsession`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}
