import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class EmployeeOnboardingApi {
  static getTasks () {
    const request = new Request(`${apiUrl}/content/onboarding_tasks`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static updateTasks (data) {
    const request = new Request(`${apiUrl}/content/onboarding_tasks/${data.taskId}`, {
      method: 'PATCH',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }
}

export default EmployeeOnboardingApi;
