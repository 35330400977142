import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import lodash from 'lodash';

import FeedLoading from './FeedLoading';
import CreateFeedItem from './CreateFeedItem/CreateFeedItem';
import FeedNoticeItem from './FeedItem/FeedNoticeItem';
import FeedPollItem from './FeedItem/FeedPollItem';
import FeedHighFiveItem from './FeedItem/FeedHighFiveItem';
import FeedEventItem from './FeedItem/FeedEventItem';
import FeedLearningItem from './FeedItem/FeedLearningItem';
import FeedGroupManagementItem from './FeedItem/FeedGroupManagementItem';
import FeedOnboardingItem from './FeedItem/FeedOnboardingItem';
import Event from '../../containers/Engage/components/EventItem';
import { fetchFeatureFlagsRequest } from '../../actionCreators/featureFlags';
import { logFirebaseUserProperty } from '../../firebase/analytics';
import { getOrgThemeRequest } from '../../actionCreators/orgTheme';

import {
  getFeedItemDetailsRequest,
  getFeedItemRequest,
  getFeedRequest,
  resetFeedPage,
  resetSingleFeedPage,
  postCommentRequest,
  voteOnPostRequest,
  toggleLikeRequest,
  toggleCommentLikeRequest,
  populatePostReportTypesRequest,
  resetPostRequestSuccess,
} from '../../actionCreators/feedActions';
import { sendUserPulseRequest } from '../../actionCreators/userProfile';
import { getPasswordMigrationStatus } from '../../actionCreators/account';
import { getGroupRequest, toggleJoinGroupRequest } from '../../actionCreators/groupActions';
import { logFirebaseEvent } from '../../firebase/analytics';

import FeedItemTag from './FeedItem/components/FeedItemTag';
import FeedItemHeader from './FeedItem/components/FeedItemHeader';
import FeedItemFooter from './FeedItem/components/FeedItemFooter';
import FeedItemFooterControl from './FeedItem/components/FeedItemFooterControl';
import FeedGroupPrivacy from './FeedItem/components/FeedGroupPrivacy';
import DailyPulse from '../DailyPulse/DailyPulse';
import FeedGroupHeader from './FeedGroupHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import GroupItem from '../GroupItem/GroupItem';
import IconPinned from '../../assets/images/iconPinned.svg';
import { getTasksRequest } from '../../actionCreators/employeeOnboarding';
import GridContainer from '../global/GridContainer/GridContainer';
import KnowledgebaseItem from '../Knowledgebase/KnowledgebaseItem';
import { ChallengePostCard } from '../../components';

const TYPE_MAP = {
  notices: 'notice',
  polls: 'poll',
  events: 'event',
  highfives: 'highfive',
  groups: 'group',
  learning: 'learning',
  group_management: 'group_management',
  knowledgebase_items: 'knowledgebase_items',
  challenges: 'challenges',
};

const BACK_TEXT = {
  '/': 'Back to Feed',
  '/engage': 'Back to Events',
};

class Feed extends PureComponent {
  constructor (props) {
    super(props);

    this.scrollBlock = React.createRef();
    this.contentBlock = React.createRef();

    this.state = {
      scrollEnabled: true,
      showDailyPulse: false,
    };
    this.hasGroupFeedId = this.props.match.params.groupId;
    this.hasPostSingleId = this.props.match.params.postId;
    this.hasVerifyGroup = this.props.match.params.verifyGroup;
    this.userId = null;
  }

  componentDidMount () {
    logFirebaseUserProperty();
    this.props.getEmployeeOnboardingTasks();
    this.props.fetchOrgTheme();
    this.props.fetchFeatureFlags();
    // Get feed single post
    if (this.hasPostSingleId) {
      this.props.getFeedItem();
      logFirebaseEvent({
        event: 'events',
        action: 'detail',
      });
    } else {
      // Get group
      if (this.hasGroupFeedId) this.props.getGroup(this.hasGroupFeedId);

      // Get all feed
      this.props.getFeed();
      logFirebaseEvent({
        event: 'feed',
        action: 'load',
      });
    }
    this.props.populatePostReportTypes();
    this._manageDailyPulse(this.props);
    this.props.getPasswordMigrationStatus();
  }

  componentWillReceiveProps (nextProps) {
    this._manageDailyPulse(nextProps);
  }

  componentWillUnmount () {
    if (this.hasPostSingleId) return this.props.resetSingleFeed();
    else this.props.resetFeed();
  }

  _manageDailyPulse (props) {
    if (!props.userData.id || props.userData.pulse_replied || this.state.showDailyPulse) return;
    this.userId = props.userData.id;

    const dailyPulseFromStorage = localStorage.getItem(`dailyPulse-${this.userId}`);
    const dailyPulseData = JSON.parse(dailyPulseFromStorage || '{}');
    const day = parseInt(moment().format('DDD'));
    const year = parseInt(moment().format('YYYY'));

    if (
      dailyPulseData &&
      dailyPulseData.hideUntil &&
      dailyPulseData.hideUntil.year >= year &&
      dailyPulseData.hideUntil.day >= day
    )
      return;

    this.setState({ showDailyPulse: true });

    if (!dailyPulseFromStorage) {
      const newDailyPulseItem = { lastSeen: null, notNowClicked: 0 };
      localStorage.setItem(`dailyPulse-${this.userId}`, JSON.stringify(newDailyPulseItem));
    }
  }

  _onScroll = () => {
    let currentGroup = null;
    if (this.hasGroupFeedId) currentGroup = this.props.groupsDetailed[this.hasGroupFeedId];
    const isOnNotJoinedGroup = !!(this.hasGroupFeedId && currentGroup && !currentGroup.is_join);

    if (isOnNotJoinedGroup) return;

    const height = this.scrollBlock.current.offsetHeight;
    const scroll = this.scrollBlock.current.scrollTop;
    const contentHeight = this.contentBlock.current.offsetHeight;

    if (!this.props.loading && height + scroll >= contentHeight - 60) {
      this.props.getFeed();
    }
  };

  getDetails (postableId, type) {
    this.props.getFeedItemDetails({ type, id: postableId });
  }

  postCommentEvent (postId, postableId, postType, commentText) {
    logFirebaseEvent({
      event: postType,
      action: 'add_comment',
    });
    this.props.postComment({ postType, comment: commentText, postId, postableId });
  }

  voteOnPostEvent (postableId, postType, pollOptionId) {
    this.props.voteOnPost({ postType, pollOptionId, postableId });
  }

  toggleLike (postId, postableId, postType, newLikeState) {
    logFirebaseEvent({
      event: postType,
      action: newLikeState ? 'like' : 'unlike',
    });
    this.props.toggleLike({ postId, postableId, postType, newLikeState });
  }

  toggleCommentLike (postId, postableId, postType, commentId, newLikeState) {
    logFirebaseEvent({
      event: 'comments',
      action: 'like',
    });
    this.props.toggleCommentLike({ postId, postableId, postType, newLikeState, commentId });
  }

  toggleHideDailyPulse = () => {
    this.setState({ showDailyPulse: false });
  };

  onToggleCreateFeed = isActive => {
    if (isActive)
      this.scrollBlock.current.scroll({ top: this.hasGroupFeedId ? 190 : 0, behavior: 'smooth' });

    this.setState({ scrollEnabled: !isActive });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  // Render functions
  render () {
    const classNames = ['feed'];
    if (this.state.scrollEnabled) classNames.push('scroll');

    let groupHeader = null;
    if (this.hasGroupFeedId) {
      const currentGroup = this.props.groupsDetailed[this.hasGroupFeedId];
      groupHeader = (
        <FeedGroupHeader
          group={currentGroup}
          toggleJoinGroup={this.props.toggleJoinGroup}
          history={this.props.history}
        />
      );
    }

    return (
      <div className={classNames.join(' ')} ref={this.scrollBlock} onScroll={this._onScroll}>
        {this.state.showDailyPulse && (
          <DailyPulse
            hide={this.toggleHideDailyPulse}
            sendPulse={this.props.sendUserPulse}
            userId={this.userId}
          />
        )}

        <Modal isOpen={this.props.reportPostRequestSuccessful} centered>
          <ModalHeader toggle={this.props.resetPostRequestSuccess}>
            <div>Report received</div>
          </ModalHeader>
          <ModalBody>
            <div>
              Thank you for your report. It will be reviewed by an administrator who will take
              appropriate action
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              className={'w-100'}
              onClick={() => this.props.resetPostRequestSuccess()}
              outline
            >
              OKAY
            </Button>
          </ModalFooter>
        </Modal>

        <GridContainer>
          <div ref={this.contentBlock}>
            {groupHeader}

            <div
              className={`feed-content ${this.hasPostSingleId ? 'feed-content__lower-margin' : ''}`}
            >
              {this.hasPostSingleId && (
                <div
                  className='feed-content-back-button'
                  onClick={() => {
                    this.handleBack();
                  }}
                >
                  <FontAwesomeIcon className='mr-2' icon={['fas', 'chevron-left']} />
                  {BACK_TEXT[this.props.prevRoute] || 'Go back'}
                </div>
              )}

              {this.renderContent()}
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }

  renderContent () {
    // Render single Post
    if (this.hasPostSingleId) {
      if (!this.props.singleItem) return <FeedLoading isLoading amount={1} />;

      if (this.props.singleItem.postable_type === 'events') {
        const author = this.props.authors[this.props.singleItem.author_id] || {};
        const group = this.props.groups[this.props.singleItem.group_id] || {};

        return (
          <Event
            history={this.props.history}
            eventItem={this.props.singleItem}
            postableId={this.props.singleItem.id}
            postId={this.props.singleItem.postable_id}
            author={author}
            group={group}
          />
        );
      }

      return this.renderFeedItem(this.props.singleItem, true);
    }

    // Decide if we need to show create post block
    let currentGroup = null;
    if (this.hasGroupFeedId) currentGroup = this.props.groupsDetailed[this.hasGroupFeedId];
    const fullFunctionality = !!(
      !this.hasGroupFeedId ||
      (currentGroup && currentGroup.is_join && !currentGroup.is_requested)
    );

    // Limit amount of posts to render
    let postsToRender = [];
    if (!fullFunctionality && this.props.items.length) postsToRender.push(this.props.items[0]);
    else postsToRender = this.props.items;
    // Block/Pin headers
    let blockHeader,
      pinnedHeader = <div className='my-5' />;
    if (!this.props.loading && postsToRender.length) {
      blockHeader = <p className='feed-block-title'>Latest</p>;
      pinnedHeader = (
        <p className='feed-block-title'>
          <img className='feed-pinnned-icon' src={IconPinned} alt='' /> Pinned
        </p>
      );
    } else if (!this.props.loading) {
      blockHeader = (
        <p className='feed-block-empty'>
          Nothing has been posted on {this.hasGroupFeedId ? 'this group' : 'your organisation'} feed
          yet
        </p>
      );
    }

    const pinnedPosts = this.hasGroupFeedId
      ? [...postsToRender].filter(post => post.is_pinned)
      : [...postsToRender].filter(post => post.isOrgLevelPinned);

    const latestPosts = this.hasGroupFeedId
      ? [...postsToRender].filter(post => !post.is_pinned)
      : [...postsToRender].filter(post => !post.isOrgLevelPinned);
    const pinnedPostsMap = pinnedPosts.map(post => this.renderFeedItem(post, fullFunctionality));
    const latestPostsMap = latestPosts.map(post => this.renderFeedItem(post, fullFunctionality));
    const { taskCount, inProgressTaskCount, featureFlags } = this.props;
    // Render all Posts
    return (
      <>
        {fullFunctionality && this.props.hasAuthToPost && (
          <CreateFeedItem
            selectedGroupId={this.hasGroupFeedId}
            onToggleActive={this.onToggleCreateFeed}
          />
        )}
        {featureFlags.EMPLOYEE_ONBOARDING && taskCount && inProgressTaskCount ? (
          <FeedOnboardingItem taskCount={taskCount} inProgressTaskCount={inProgressTaskCount} />
        ) : null}
        {pinnedHeader}
        {pinnedPostsMap}
        {blockHeader}
        {latestPostsMap}

        <FeedLoading isLoading={this.props.loading} amount={postsToRender.length ? 1 : 3} />
      </>
    );
  }

  renderFeedItem (item, fullFunctionality = true) {
    // Broken posts
    if (!item.post) return null;
    if (item.postable_type === 'highfives' && !item.post.recipient_id) return null;
    const author = this.props.authors[item.author_id] || {};
    const itemType = TYPE_MAP[item.postable_type];
    const isNewGroupItem = itemType === 'group' ? true : false;
    const isNewMemberItem = itemType === 'group_management' ? true : false;
    const isKnowledgebaseItem = itemType === 'knowledgebase_items' ? true : false;
    const isChallengeItem = itemType === 'challenges' ? true : false;
    const link = fullFunctionality ? `/feed/${item.postable_id}?type=${itemType}` : '#';
    const props = { item, author, link };
    const campaignContentId = item.campaign_content_id;
    const group = this.props.groups[item.group_id];
    const feedItemGroup = item.post;
    const shouldRenderHeader =
      itemType !== 'highfive' &&
      itemType !== 'learning' &&
      itemType !== 'group_management' &&
      !isNewGroupItem &&
      !campaignContentId;
    const shouldRenderFooter =
      itemType !== 'event' &&
      itemType !== 'learning' &&
      itemType !== 'group_management' &&
      !isNewGroupItem &&
      !isKnowledgebaseItem &&
      !isChallengeItem;
    const shouldRenderFooterControl =
      itemType !== 'event' &&
      itemType !== 'learning' &&
      itemType !== 'group_management' &&
      !isNewGroupItem &&
      !isKnowledgebaseItem &&
      !isChallengeItem;
    const myGroups = this.props.myGroups.map(item => item.id);

    let FeedItemComponent = null;
    if (itemType === 'notice') FeedItemComponent = FeedNoticeItem;
    if (itemType === 'poll') {
      FeedItemComponent = FeedPollItem;
      props.disableVote = !fullFunctionality;
      props.voteOnPost = this.voteOnPostEvent.bind(this, item.postable_id, itemType);
    }
    if (itemType === 'event') FeedItemComponent = FeedEventItem;

    if (itemType === 'highfive') {
      const recipient = this.props.authors[item.post.recipient_id];
      if (!recipient) return null;

      FeedItemComponent = FeedHighFiveItem;
      props.recipient = recipient;
    }

    if (itemType === 'learning') {
      FeedItemComponent = FeedLearningItem;
    }

    if (itemType === 'group_management' && this.hasGroupFeedId) {
      FeedItemComponent = FeedGroupManagementItem;
    }

    if (isNewGroupItem) {
      FeedItemComponent = GroupItem;
    }
    if (isKnowledgebaseItem) {
      FeedItemComponent = KnowledgebaseItem;
    }
    if (isChallengeItem) {
      FeedItemComponent = ChallengePostCard;
    }

    if (!FeedItemComponent) return null;

    return (
      <div>
        {campaignContentId ? (
          <div className='flex flex-column'>
            <p className='m-0 p-0 feed-item-header-text'>{item.campaign_title}</p>
            <p className='p-0 mt-2'>{item.campaign_content_title}</p>
          </div>
        ) : null}
        {isNewGroupItem ? (
          <FeedItemHeader
            createdAt={item.created_at}
            author={author}
            link={link}
            group={feedItemGroup}
            hasGroupFeedId={this.hasGroupFeedId}
            postId={item.id}
            groupId={feedItemGroup.id}
            isNewGroupItem={isNewGroupItem}
            isPinned={item.is_pinned}
          />
        ) : null}
        {isNewMemberItem && (
          <FeedItemComponent {...props} myGroups={myGroups} users={this.props.authors} />
        )}
        <div
          className={`${!isKnowledgebaseItem && !isChallengeItem ? 'feed-item' : 'mb-3'} ${
            item.post && item.post.is_global ? 'feed-item--global' : ''
          }`}
          key={item.postable_id}
        >
          {!this.hasGroupFeedId && group ? (
            <FeedGroupPrivacy groupPrivacyTypeId={group.group_privacy_type_id} />
          ) : null}

          {shouldRenderHeader && (
            <FeedItemHeader
              history={this.props.history}
              createdAt={item.created_at}
              author={author}
              link={link}
              group={group}
              hasGroupFeedId={this.hasGroupFeedId}
              postId={item.id}
              groupId={item.group_id}
              hasPostSingleId={this.hasPostSingleId}
              isPinned={item.is_pinned}
            />
          )}

          {item.post.is_global ? (
            <div className='feed-item-tag-container'>
              <FeedItemTag title={'Wellbeing'} />
            </div>
          ) : null}

          {isNewGroupItem ? (
            <GroupItem group={feedItemGroup} />
          ) : isKnowledgebaseItem ? (
            <KnowledgebaseItem item={item.post} />
          ) : isChallengeItem ? (
            <ChallengePostCard challengeItem={item.post} from={'feed'} />
          ) : !isNewMemberItem ? (
            <FeedItemComponent {...props} myGroups={myGroups} />
          ) : null}

          {shouldRenderFooter && (
            <FeedItemFooter
              isPostSingle={this.hasPostSingleId}
              itemType={itemType}
              postContainerId={item.id}
              likes={item.likes_counter}
              comments={item.comments_counter}
              link={link}
            />
          )}

          {shouldRenderFooterControl && (
            <FeedItemFooterControl
              comments={item.comments}
              onComment={this.getDetails.bind(this, item.postable_id, itemType)}
              isPostSingle={this.hasPostSingleId}
              link={link}
              postComment={this.postCommentEvent.bind(this, item.id, item.postable_id, itemType)}
              userImageUrl={this.props.userImageUrl}
              isLiked={!!item.post.is_liked_by_me}
              onLike={this.toggleLike.bind(
                this,
                item.id,
                item.postable_id,
                itemType,
                !item.post.is_liked_by_me,
              )}
              onCommentLike={this.toggleCommentLike.bind(this, item.id, item.postable_id, itemType)}
              disableButtons={!fullFunctionality}
              isMandatoryGroup={group ? group.is_mandatory : false}
            />
          )}
        </div>
      </div>
    );
  }
}

const selectHasAuthToPost = (state, { groupId }) => {
  const groups = state.group.groupsDetailed || {};
  const featureFlags = state.featureFlags || {};
  const isAdmin = state.currentUser.userData && state.currentUser.userData.is_admin ? true : false;

  const group = groups[groupId] || {};
  if (group.is_org_level) return group.is_admin;
  // if (group.is_mandatory) return isAdmin || group.is_moderator
    return isAdmin ? true : featureFlags.CREATE_POST_NORMAL_USER;
};

const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;
  return {
    ...state.feed,
    featureFlags: state.featureFlags,
    taskCount: state.employeeOnboard.tasks.length,
    inProgressTaskCount: state.employeeOnboard.tasks.filter(item => !item.is_completed).length,
    userImageUrl: state.currentUser.signInData.imageUrl,
    groups: state.group.groups,
    groupsDetailed: state.group.groupsDetailed,
    userData: state.currentUser.userData,
    prevRoute: state.routerHistory.prevPathname,
    myGroups: state.group.myGroups,
    hasAuthToPost: selectHasAuthToPost(state, { groupId }),
    isAdmin: state.currentUser.userData && state.currentUser.userData.is_admin ? true : false,
  };
};

const mapDispatchToProps = dispatch => ({
  getFeed: () => dispatch(getFeedRequest()),
  getFeedItemDetails: payload => dispatch(getFeedItemDetailsRequest(payload)),
  getFeedItem: () => dispatch(getFeedItemRequest()),
  resetFeed: () => dispatch(resetFeedPage()),
  resetSingleFeed: () => dispatch(resetSingleFeedPage()),
  postComment: payload => dispatch(postCommentRequest(payload)),
  voteOnPost: payload => dispatch(voteOnPostRequest(payload)),
  toggleLike: payload => dispatch(toggleLikeRequest(payload)),
  toggleCommentLike: payload => dispatch(toggleCommentLikeRequest(payload)),
  sendUserPulse: payload => dispatch(sendUserPulseRequest(payload)),
  getGroup: payload => dispatch(getGroupRequest(payload)),
  toggleJoinGroup: payload => dispatch(toggleJoinGroupRequest(payload)),
  populatePostReportTypes: () => dispatch(populatePostReportTypesRequest()),
  resetPostRequestSuccess: () => dispatch(resetPostRequestSuccess()),
  getPasswordMigrationStatus: () => dispatch(getPasswordMigrationStatus()),
  getEmployeeOnboardingTasks: () => dispatch(getTasksRequest()),
  fetchFeatureFlags: () => dispatch(fetchFeatureFlagsRequest()),
  fetchOrgTheme: () => dispatch(getOrgThemeRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
