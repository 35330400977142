import update from 'immutability-helper';
import { SEARCH } from '../actionCreators/search';

const initialState = {
  users: [],
  groups: [],
};

const searchResults = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH.SEARCH_PEOPLE_SUCCESS:
      return update(state, {
        users: { $set: action.data.users },
      });
    case SEARCH.SEARCH_GROUPS_SUCCESS:
      return update(state, {
        groups: { $set: action.data.groups },
      });
    default:
      return state;
  }
};

export default searchResults;
