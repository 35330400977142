import React, { Component, useEffect, useState, PureComponent } from 'react';
import styles from './styles';
import renderHTML from 'react-render-html';
import ImageApi from '../../api/image';
import { mdiFileOutline, mdiStarThreePointsOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { getYoutubeIDByURL } from '../../utils/helpers';
import knowledgebaseApi from '../../api/knowledgebase';
import KnowledgebaseInfoImage from './KnowledgebaseInfoImage';
import './knowledgebase.scss';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { Stream } from '@cloudflare/stream-react';
import jwtDecode from 'jwt-decode';
import FeedApi from '../../api/feed';

export default class KnowledgebaseItem extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      videoToken: this.props.item.video ? this.props.item.video.token : '',
      newVideoToken: '',
    };
  }

  componentDidMount () {
    const { video_uri, type, video_id } = this.props.item;
    const { taskId, updateTask } = this.props;
    if (taskId && ((type === 'INFO' && !video_id) || type === 'DETAILS')) updateTask(taskId);
  }

  renderTitle = (type, title) => {
    return (
      <div>
        {type === 'DETAILS' && <div className='post-detail-title'>{title}</div>}
        {(type === 'INFO' || type === 'FILE') && <div className='post-title'>{title}</div>}
      </div>
    );
  };

  onClickPlayVideo = async () => {
    try {
      const { taskId, updateTask } = this.props;
      taskId && updateTask(taskId)
      const videoId = this.props.item.video_id;
      const currentTime = Date.now() / 1000;
      const videoJWTToken = jwtDecode(this.state.videoToken);
      if (videoJWTToken.exp - currentTime < 0) {
        const streamUrlResp = (await FeedApi.getVideoStreamURL(videoId)) || {};
        const newToken = streamUrlResp.video ? streamUrlResp.video.token : '';
        if (newToken)
          this.setState({
            newVideoToken: newToken,
          });
      }
    } catch (err) {}
  };

  renderFile = file => {
    const onClickFileItem = async () => {
      const { type } = this.props.item;
      const { taskId, updateTask } = this.props;
      let amazonS3fileUriResp = await knowledgebaseApi.getAmazonS3SignedFileUrl(file.fileUri);
      if (amazonS3fileUriResp && amazonS3fileUriResp.file_uri) {
        window.open(amazonS3fileUriResp.file_uri, '_blank');
      }
      if (type === 'FILE' && taskId) {
        updateTask(taskId);
      }
    };
    const getFileTypeAndSize = () => {
      try {
        if (file.size > 0) {
          const k = 1024;
          const dm = 0;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
          const i = Math.floor(Math.log(file.size) / Math.log(k));
          const size = parseFloat((file.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
          return `${file.type.toUpperCase()} - ${size}`;
        }
        return `${file.type.toUpperCase()}`;
      } catch (err) {
        console.log(err);
        return 'PDF';
      }
    };
    return (
      <div style={styles.fileContainer} onClick={onClickFileItem}>
        <div style={styles.fileIcon}>
          <Icon path={mdiFileOutline} size={1.2} color='var(--primary-color)' />
        </div>
        <div>
          <div style={styles.fileName}>{file.title}</div>
          <div style={styles.fileTypeSize}>{getFileTypeAndSize()}</div>
        </div>
      </div>
    );
  };

  renderDetails = detailsList => {
    return (
      <div>
        {detailsList.map(detailItem => (
          <div className='post-detail-container'>
            {detailItem.type === 'WEB_ADDRESS' && (
              <a href={detailItem.value} className='detail-label' target='_blank'>
                {detailItem.value}
              </a>
            )}
            {detailItem.type === 'EMAIL_ADDRESS' && (
              <a href={`mailto:${detailItem.value}`} className='detail-label' target='_blank'>
                {detailItem.value}
              </a>
            )}
            {detailItem.type === 'PHONE_NUMBER' && detailItem.value}
          </div>
        ))}
      </div>
    );
  };

  renderVideo = videoUrl => {
    const { taskId, updateTask } = this.props;
    return this.state.videoToken || this.state.newVideoToken ? (
      <Stream
        src={this.state.newVideoToken ? this.state.newVideoToken : this.state.videoToken}
        onPlay={() => {
          this.onClickPlayVideo();
          taskId && updateTask(taskId);
        }}
        controls
        autoplay={this.state.newVideoToken ? true : false}
        preload
        height='300px'
        width='100%'
      ></Stream>
    ) : null;
  };
  renderItemHeader = (categories, type, withImage) => {
    const categoryLabel =
      categories && categories.length > 2 ? categories.splice(0, 2).join() : categories.join();
    const typeLabel = type === 'INFO' ? 'Info' : type === 'DETAILS' ? 'Details' : 'File';
    return (
      <div
        className={`post-headerbar-container ${
          withImage ? 'post-headerbar-container-with-image' : ''
        }`}
      >
        <div className='post-headerbar-typelabel'>{typeLabel}</div>
        <div style={{ display: 'flex', flex: 1 }}></div>
        <div
          className={`post-headerbar-categorylabel ${withImage ? 'categorylabel-with-image' : ''}`}
        >
          {categoryLabel}
        </div>
      </div>
    );
  };
  render () {
    const {
      title,
      description,
      categories,
      type,
      files,
      details,
      image,
      video_uri,
      id,
    } = this.props.item;
    const { viewOnly } = this.props;
    const isImagePresent = (image && image.url) ? true : false;
    return (
      <div className={`post-container ${viewOnly ? 'post-container-knowledgebase' : ''}`}>
        {viewOnly ? (
          <div className='post-header-container'>
            {image && image.url ? (
              <div className='knowledgebase-item-content-image-holder'>
                <KnowledgebaseInfoImage image={image} />
              </div>
            ) : null}
            {this.renderItemHeader(categories, type, isImagePresent)}
          </div>
        ) : (
          <Link
            to={{
              pathname: `/knowledgebase-item/${id}`,
              state: { item: this.props.item },
            }}
          >
            <div className='post-header-container'>
              {image && image.url ? (
                <div className='knowledgebase-item-content-image-holder'>
                  <KnowledgebaseInfoImage image={image} />
                </div>
              ) : null}
              {this.renderItemHeader(categories, type, isImagePresent)}
            </div>
          </Link>
        )}
        <div style={{ padding: 15 }}>
          {this.renderTitle(type, title)}
          {this.renderDetails(details)}
          {description && <div className='post-information-content'>{renderHTML(description)}</div>}
          {this.renderVideo()}
          <div style={{ marginTop: 20 }}>{files.map(file => this.renderFile(file))}</div>
        </div>
      </div>
    );
  }
}
