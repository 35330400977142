import React, { PureComponent } from 'react';
import Onboarding from '../../../assets/images/employee-onboard.svg';
import '../../../assets/styles/feed.scss';
import { Link } from 'react-router-dom';

class FeedOnboardingItem extends PureComponent {
  render () {
    const { taskCount, inProgressTaskCount } = this.props;
    return (
      <React.Fragment>
        <div className='feed-employee-onboarding'>
          <p className='feed-employee-onboarding-header'>Onboarding</p>
          <div className='feed-item'>
            <div className='feed-employee-onboarding-content feed-employee-onboarding-img-bg'>
              <img className='feed-employee-onboarding-img' src={Onboarding} alt='' />
            </div>
            <div className='feed-employee-onboarding-content' style={{ flexDirection: 'column' }}>
              <h4 className='feed-employee-onboarding-title'>Let's get you up and running</h4>
              <p className='feed-employee-onboarding-task-info'>
                You've still got {inProgressTaskCount} out of {taskCount} items outstanding for your
                onboarding tasks
              </p>
              <Link className='feed-employee-onboarding-go-button' to={'/employee_onboarding'}>LET'S GO</Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FeedOnboardingItem;
