import { getHeaders } from './helpers';

const ImageApi = {
  cache: {},
  keys: [],

  async getImage(imageUrl) {
    if (imageUrl in this.cache) return this.cache[imageUrl];

    const request = new Request(imageUrl, {
      method: 'GET',
      headers: getHeaders(),
    });

    const stream = await fetch(request);
    const blob = await stream.blob();
    const image = URL.createObjectURL(blob);

    this.addToCache(imageUrl, image);

    return image;
  },

  addToCache(imageUrl, image) {
    this.cache[imageUrl] = image;
    this.keys.push(imageUrl);

    // Keep only latest 50 images
    if (this.keys.length <= 50) return;

    const keyToDelete = this.keys.shift();
    delete this.cache[keyToDelete];
  },
};

export default ImageApi;
