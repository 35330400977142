import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class GroupApi {
  static createGroup(data) {
    const formData = new FormData();

    if (data.imageFile) formData.append('image', data.imageFile);
    formData.append('group_name', data.name);
    formData.append('description', data.description);
    formData.append('user_ids', data.userIds);
    formData.append('category_id', data.categoryId);
    formData.append('group_privacy_type_id', data.groupPrivacyTypId);

    const request = new Request(`${apiUrl}/content/group`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        Authorization: `Bearer ${data.token}`,
      },
      body: formData,
    });

    return fetchRequest(request);
  }

  static updateGroup(data) {
    const formData = new FormData();

    if (data.imageFile) formData.append('image', data.imageFile);
    if (data.name) formData.append('group_name', data.name);
    if (data.description) formData.append('description', data.description);
    if (data.userIds) formData.append('user_ids', data.userIds);
    if (data.categoryId) formData.append('category_id', data.categoryId);
    if (data.groupPrivacyTypId) formData.append('group_privacy_type_id', data.groupPrivacyTypId);

    const request = new Request(`${apiUrl}/content/group/${data.groupId}`, {
      method: 'PATCH',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        Authorization: `Bearer ${data.token}`,
      },
      body: formData,
    });

    return fetchRequest(request);
  }

  static addGroupMembers(data) {
    const request = new Request(`${apiUrl}/content/group/${data.groupId}/add-members`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify({ user_ids: data.userIds }),
    });

    return fetchRequest(request);
  }

  static verifyGroup(data) {
    const formData = new FormData();

    formData.append('group_name', data.name);
    formData.append('description', data.description);
    formData.append('category_id', data.categoryId);
    formData.append('group_privacy_type_id', data.groupPrivacyTypId);

    const request = new Request(`${apiUrl}/content/groupverify`, {
      method: 'POST',
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        Authorization: `Bearer ${data.token}`,
      },
      body: formData,
    });

    return fetchRequest(request);
  }

  static getGroups() {
    const request = new Request(`${apiUrl}/content/group`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getGroup(id) {
    const request = new Request(`${apiUrl}/content/group/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getMyGroups() {
    const request = new Request(`${apiUrl}/content/group/myGroups`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static acceptNewUser ({ groupId, user_id, value, token }) {
    const formData = new FormData();

    formData.append('user_id', user_id);
    formData.append('value', value);

    const request = new Request(`${apiUrl}/content/group/${groupId}/action-join`, {
      method: 'POST',
      // headers: getHeaders(),
      headers: {
        'X-Application-Platform': 'web',
        'X-Application-Version': '1.0.0',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    return fetchRequest(request);
  }

  static toggleJoinGroup({ groupId, join }) {
    const pathPart = join ? 'join' : 'leave';
    const request = new Request(`${apiUrl}/content/group/${groupId}/${pathPart}`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getMyAndColleagueGroups(myId, colleagueId) {
    const request = new Request(
      `${apiUrl}/content/group?has_users=${myId}&has_users=${colleagueId}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }

  static getGroupMembers(groupId, limit = 15, page = 1) {
    const request = new Request(
      `${apiUrl}/content/group/${groupId}/members?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }

  static getGroupPrivacyTypes() {
    const request = new Request(`${apiUrl}/content/group/privacy/types`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static removeGroupMember(userId, groupId) {
    const request = new Request(`${apiUrl}/content/group/${groupId}/member/${userId}`, {
      method: 'DELETE',
      headers: getHeaders(),
    })
    return fetchRequest(request);
  }

  static toggleGroupMemberPermission(userId, groupId) {
    const request = new Request(`${apiUrl}/content/group/${groupId}/toggle_permission/${userId}`, {
      method: 'PATCH',
      headers: getHeaders(),
    })
    return fetchRequest(request);
  }
}
