import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GroupPlaceholder from '../../../assets/images/group_placeholder.svg';
import ImageApi from '../../../api/image';

class SidebarGroupBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.imageUrl) {
      ImageApi.getImage(this.props.imageUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    const { title, id, newPostActivity } = this.props;
    const { imageUrl } = this.state;
    let notificationEl = null;
    const rootClassName = 'sidebar-group-block';

    if (newPostActivity > 0)
      notificationEl = <p className={`${rootClassName}-notification`}>{newPostActivity}</p>

    return (
      <Link className={rootClassName} to={`/group-feed/${id}`}>
        <div className={`${rootClassName}-image-holder`}>
          {imageUrl ? (
            <img className={`${rootClassName}-image`} src={imageUrl} alt="group" />
          ) : (
            <img
              className={`${rootClassName}-image ${rootClassName}-image__default`}
              src={GroupPlaceholder}
              alt=""
            />
          )}
        </div>

        <p className={`${rootClassName}-title`}>{title}</p>

        {notificationEl}
      </Link>
    );
  }
}

export default SidebarGroupBlock;
