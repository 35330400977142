import {
  GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS,
  UPDATE_EMPLOYEE_ONBOARDING_TASKS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    tasks: [],
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_ONBOARDING_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    default:
      return state;
  }
};

export default tasks;
