import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar } from 'antd';

import FeedIcon from '../../Icons/FeedIcon';

import EngageActiveIcon from '../../Icons/EngageActiveIcon';
import EngageIcon from '../../Icons/EngageIcon';
import SearchIcon from '../../Icons/SearchIcon';
import ExploreActiveIcon from '../../Icons/ExploreActiveIcon';
import ExploreIcon from '../../Icons/ExploreIcon';
import FourthIcon from '../../../assets/images/fourth.svg';

import { getUserAvatarRequest, getUserRequest } from '../../../actionCreators/userProfile';
import { getNotificationsRequest } from '../../../actionCreators/notificationActions';
import UserPlaceholder from '../../../assets/images/user_placeholder.svg';

import ExploreMenu from './ExploreMenu';

import {
  NAVIGATE_TO_CHANGE_SECURITY,
  NAVIGATE_TO_CHANGE_PASSWORD,
  NAVIGATE_TO_REVOKE_ORG_TERMS,
  NAVIGATE_TO_REVOKE_PRIVACY,
  NAVIGATE_TO_EMPLOYEE_ONBOARDING,
} from '../../../constants/actionTypes';

function HeaderLinks (props) {
  const {
    pathname,
    activeProfile,
    avatarImg,
    stateUser,
    unreadNotifications,
    getAvatar,
    getUser,
    getNotifications,
    featureFlags,
  } = props;
  const userProfile = stateUser.userData;
  const [isExploreMenuOpen, setIsExploreMenuOpen] = useState(false);

  const links = [
    { path: '/', label: 'Feeds', icon: FeedIcon },
    { path: '/engage', label: 'Engage', icon: EngageIcon, activeIcon: EngageActiveIcon },
    { path: '/explore', label: 'Explore', icon: ExploreIcon, activeIcon: ExploreActiveIcon },
    { path: '/search', label: 'Search', icon: SearchIcon },
  ];

  if (featureFlags.FOURTH) {
    links.splice(3, 0, { path: '/fourth', label: 'Fourth', icon: FourthIcon });
  }
  useEffect(() => {
    getUser();
    getNotifications();
  }, [getNotifications, getUser]);

  useEffect(() => {
    if (!stateUser.userData.image_id) return;
    if (!stateUser.userData.image || !stateUser.userData.image.url) return;

    getAvatar(stateUser.userData.image.url);
  }, [getAvatar, stateUser.userData.image, stateUser.userData.image_id]);

  return (
    <div className='d-flex align-items-center w-100 h-100'>
      <div className='header-links-holder'>
        {links.map(link => {
          const { path, label, icon: Icon, activeIcon: ActiveIcon } = link;

          let fillIconColor = 'none';
          let strokeIconColor = '#505A62';
          if (path === pathname) {
            strokeIconColor = 'var(--primary-color)';
            fillIconColor = 'var(--primary-color)';
          }

          let IconEl = Icon;
          if (
            (ActiveIcon &&
              ((path === pathname && isExploreMenuOpen === false) ||
                (pathname.toString().includes(path.toString()) && isExploreMenuOpen === false) ||
                (path === '/explore' && pathname === '/knowledgebase') ||
                (path === '/explore' && pathname === '/activity'))) ||
            (path === '/explore' && isExploreMenuOpen)
          ) {
            IconEl = ActiveIcon;
            strokeIconColor = 'var(--primary-color)';
            fillIconColor = 'var(--primary-color)';
          }
          if (label === 'Fourth') {
            return (
              // <NavLink to="/fourth" className='nav-link header-link' activeClassName='selected'>
              <div className='nav-link header-link' activeClassName='selected' onClick={() => window.open('https://secure.fourth.com/', '_blank')}>
                <div className='position-relative'>
                  <img className='header-link-icon' width='25' height='25' src={FourthIcon} />
                  <a className='fourth-link' target='_blank' href='https://secure.fourth.com/'>
                    {label}
                  </a>
                </div>
              </div>
              // </NavLink>
            );
          }
          return (
            <NavLink
              onClick={e => {
                if (link.label === 'Explore') {
                  e.persist();
                  setIsExploreMenuOpen(!isExploreMenuOpen);
                }
              }}
              exact={path === '/'}
              key={path}
              isActive={(match, location, history) => {
                if (location.pathname === '/knowledgebase' && path === '/explore') {
                  return true;
                }
                if (location.pathname === '/activity' && path === '/explore') {
                  return true;
                }
                if (location.pathname === '/explore' && path === '/explore') {
                  return true;
                }

                return match;
              }}
              to={path}
              className='nav-link header-link'
              activeClassName='selected'
            >
              <div className='position-relative'>
                <IconEl
                  className='header-link-icon'
                  fill={fillIconColor}
                  stroke={strokeIconColor}
                />
                {label}

                {path === '/notifications' && unreadNotifications !== 0 && (
                  <span className='header-link-badge'>{unreadNotifications}</span>
                )}
              </div>

              {isExploreMenuOpen && link.label === 'Explore' ? (
                <ExploreMenu
                  featureFlags={featureFlags}
                  onClickAway={() => {
                    setIsExploreMenuOpen(false);
                  }}
                  {...props}
                />
              ) : null}
            </NavLink>
          );
        })}
        <div className={`nav-link header-link`}></div>
      </div>

      <div className='d-flex justify-content-end flex-1 header-right-section'>
        <NavLink to='/edit' className='nav-link' activeClassName='selected'>
          <div className='d-flex '>
            <Avatar shape='square' src={avatarImg || UserPlaceholder} size={47} icon='user' />
            <div className='ml-2 d-flex flex-column'>
              <p className='header-edit--title'>
                {userProfile.first_name || ''} {userProfile.last_name || ''}
              </p>
              <p className={`header-edit--subtitle ${activeProfile}`}>My Account</p>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  avatarImg: state.currentUser.signInData.imageUrl,
  stateUser: state.currentUser,
  unreadNotifications: state.notification.unreadCount,
  featureFlags: state.featureFlags,
  taskCount: state.employeeOnboard.tasks.length,
  inProgressTaskCount: state.employeeOnboard.tasks.filter(item => !item.is_completed).length,
});

const mapDispatchToProps = dispatch => ({
  getAvatar: payload => dispatch(getUserAvatarRequest(payload)),
  getUser: () => dispatch(getUserRequest()),
  getNotifications: payload => dispatch(getNotificationsRequest(payload)),
  onChooseChangeSecurity: () =>
    dispatch({
      type: NAVIGATE_TO_CHANGE_SECURITY,
    }),
  onChooseChangePassword: () =>
    dispatch({
      type: NAVIGATE_TO_CHANGE_PASSWORD,
    }),
  onChooseRevokePrivacy: () =>
    dispatch({
      type: NAVIGATE_TO_REVOKE_PRIVACY,
    }),
  onChooseRevokeTerms: () =>
    dispatch({
      type: NAVIGATE_TO_REVOKE_ORG_TERMS,
    }),
  onChooseOnboarding: () => {
    dispatch({
      type: NAVIGATE_TO_EMPLOYEE_ONBOARDING,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
