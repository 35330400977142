import update from 'immutability-helper';
import {
  GET_SECURITY_QUESTIONS_SUCCESS,
  GET_USER_SECURITY_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  NAVIGATE_TO_CHANGE_SECURITY,
  NAVIGATE_TO_CHANGE_PASSWORD,
  NAVIGATE_TO_REVOKE_PRIVACY,
  NAVIGATE_TO_REVOKE_ORG_TERMS,
} from '../constants/actionTypes';

const initialState = {
  securityQuestions: [],
  setPassword: false,
  userSecurity: {},
  isChangeSecurity: false,
  isChangePassword: false,
  isPrivacy: false,
  isOrgTerms: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITY_QUESTIONS_SUCCESS:
      return update(state, {
        securityQuestions: { $set: action.payload.security_questions || [] },
        setPassword: { $set: action.payload.setPassword || false },
      });
    case NAVIGATE_TO_CHANGE_SECURITY:
      return update(state, {
        isChangeSecurity: { $set: true },
        isChangePassword: { $set: false },
      });
    case NAVIGATE_TO_CHANGE_PASSWORD:
      return update(state, {
        isChangePassword: { $set: true },
        isChangeSecurity: { $set: false },
      });
    case NAVIGATE_TO_REVOKE_PRIVACY:
      return update(state, {
        isPrivacy: { $set: true },
        isOrgTerms: { $set: false },
      });
    case NAVIGATE_TO_REVOKE_ORG_TERMS:
      return update(state, {
        isPrivacy: { $set: false },
        isOrgTerms: { $set: true },
      });
    case RESET_PASSWORD_SUCCESS:
      return update(state, {
        setPassword: { $set: false },
      });
    case GET_USER_SECURITY_SUCCESS:
      return update(state, {
        userSecurity: { $set: action.payload.security || {} },
      });
    default:
      return state;
  }
};

export default accountReducer;
