import { GLOBAL_ALERT_ADD_TO_LIST, GLOBAL_ALERT_REMOVE_FROM_LIST } from '../constants/actionTypes';

const addAlertToAlertsList = data => ({
  type: GLOBAL_ALERT_ADD_TO_LIST,
  data: {
    id: Date.now(),
    ...data,
  },
});

const removeAlertFromAlertsList = id => ({
  type: GLOBAL_ALERT_REMOVE_FROM_LIST,
  id,
});

export { addAlertToAlertsList, removeAlertFromAlertsList };
