import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SearchInput } from '../index';
import GroupPlaceholder from '../../assets/images/group_placeholder.svg';
import TextField from '../global/fields/TextField';
import CrossIcon from '../Icons/CrossIcon';
import AddIcon from '../Icons/AddIcon';
import CreateGroupSelectedRow from './CreateGroupSelectedRow';
import CreateGroupSearchRow from './CreateGroupSearchRow';
import { logFirebaseEvent } from '../../firebase/analytics';
import Public from '../../components/Icons/Public';
import Private from '../../components/Icons/Private';
import Secret from '../../components/Icons/Secret';
import ImageApi from '../../api/image';

import {
  verifyGroupSuccess,
} from '../../actionCreators/groupActions';

function CreateGroupModal (props) {
  const { hide, categories, searchResults, searchPeople, token, verifyGroup, groupPrivacyTypes, updateGroup } = props;
  const group = useSelector(state => state.group.editedGroup);
  const errorMessage = useSelector(state => state.group.verifyGroup.errorMessage);
  const recommendedGroups = useSelector(state => state.group.verifyGroup.recommendedGroups);
  const dispatch = useDispatch();

  const [category, setCategory] = useState(group && group.category_id ? group.category_id : '');
  const [groupPrivacy, setGroupPrivacy] = useState(group && group.group_privacy_type_id ? group.group_privacy_type_id : '');
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [imageBlobUrl, setImageBlobUrl] = useState(group && group.image ? group.image.url : '');
  const [imageFile, setImageFile] = useState(null);
  const [name, setName] = useState({ value: group && group.name ? group.name : '', error: '' });
  const [description, setDescription] = useState({ value: group && group.description ? group.description : '', error: '' });
  const [selectActive, setSelectActive] = useState(false);
  const [selectGroupPrivacyActive, setSelectGroupPrivacyActive] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  let selectRef = null;
  let groupPrivacyRef = null;

  useEffect(() => {
    if (group && group.image && group.image.url) {
      getImage(group.image.url)
    }
  }, []);

  useEffect(() => {
    if (errorMessage && recommendedGroups) {
      const exists = recommendedGroups.find((group) => group.name === name);
      if (!exists) {
        removeErrorMessage(recommendedGroups)
      }
    }
  }, [name])

  const getImage = (imageUrl) => {
    ImageApi.getImage(imageUrl).then(imageUrl => {
      setImageBlobUrl(imageUrl)
    });
  }

  function handleSearchPeople (value) {
    searchPeople({ value });
  }

  function handlePersonSelected (person) {
    setSelectedPeople([...selectedPeople, person]);
  }

  function handleRemovePerson (person) {
    setSelectedPeople(selectedPeople.filter(item => person.id !== item.id));
  }

  const removeErrorMessage = (recommendedGroups) => {
    if (errorMessage) {
      dispatch(verifyGroupSuccess({
        recommendedGroups,
        createGroupRequest: {},
        errorMessage: ''
      }))
    }
  }

  function handleSubmitForm () {
    if (name.error || description.error || !category || !groupPrivacy) return setShowErrors(true);

    const payload = {
      groupId: group ? group.id : null,
      name: name.value,
      description: description.value,
      categoryId: category,
      imageFile,
      userIds: selectedPeople.map(person => person.id),
      token: token.key,
      groupPrivacyTypId: groupPrivacy
    };

    if (group) {
      updateGroup(payload);
      logFirebaseEvent({
        event: 'group',
        action: 'update',
      });
    } else {
      verifyGroup(payload);
      logFirebaseEvent({
        event: 'group',
        action: 'add',
      });
    }
  }

  return (
    <div className='modal-content create-group' style={{ maxWidth: '500px' }}>
      <div className='modal-header bg-white'>
        <h5 className='modal-title'>{group ? 'Manage Group' : 'New Group'}</h5>

        <div className='create-group__close' onClick={hide}>
          <CrossIcon stroke='#A7ACB0' />
        </div>
      </div>

      <div className='modal-body overflow-auto d-flex flex-column'>
        <input
          id='avatarImage'
          className='d-none'
          type='file'
          accept='image/x-png,image/jpeg,image/gif'
          onChange={e => {
            setImageBlobUrl(URL.createObjectURL(e.target.files[0]));
            setImageFile(e.target.files[0]);
          }}
        />

        <div
          className='create-group__image-holder'
          onClick={() => document.getElementById('avatarImage').click()}
        >
          {imageBlobUrl ? (
            <img className='create-group__image' src={imageBlobUrl} alt='avatar' />
          ) : (
            <img className='create-group__image_default' src={GroupPlaceholder} alt='avatar' />
          )}
        </div>

        <div
          className='create-group__add-button'
          onClick={() => document.getElementById('avatarImage').click()}
        >
          <AddIcon className='create-group__add-button-icon' />
          {imageBlobUrl ? 'REPLACE IMAGE' : 'ADD AN IMAGE'}
        </div>

        <form>
          <div className='form-group create-feed'>
            {errorMessage && <span className='create-feed-error-message'>{errorMessage}</span>}
            <TextField
              fk='name'
              type='text'
              label='Group name'
              value={name.value}
              validateObj={{ requiredMax128: name.value }}
              updateFieldData={(fk, value, error) => setName({ value, error })}
              showError={showErrors}
              triggerOnChange
            />
            <TextField
              fk='description'
              fieldTag='textarea'
              type='text'
              label='Description'
              value={description.value}
              validateObj={{ requiredMax255: description.value }}
              updateFieldData={(fk, value, error) => setDescription({ value, error })}
              showError={showErrors}
              triggerOnChange
            />

            <div className='create-feed-select-holder'>
              <span
                className={`create-feed-select-label ${selectActive || category ? 'active' : ''}`}
                onClick={() => selectRef.focus()}
              >
                Select category
              </span>

              <Select
                ref={ref => (selectRef = ref)}
                value={category}
                onChange={value => {
                  setCategory(value);
                  selectRef.blur();
                }}
                className='create-feed-select'
                showArrow={false}
                dropdownClassName='create-feed-select-dropdown'
                defaultActiveFirstOption={false}
                open={selectActive}
                onFocus={() => setSelectActive(true)}
                onBlur={() => setSelectActive(false)}
              >
                {categories.length > 0 &&
                  categories.map(category => (
                    <Select.Option
                      key={category.id + category['category_name']}
                      value={category.id}
                    >
                      <div className='create-feed-select-dropdown-option'>
                        {category['category_name']}
                      </div>
                    </Select.Option>
                  ))}
              </Select>

              <FontAwesomeIcon className='create-feed-select-icon' icon={['fas', 'chevron-down']} />

              {showErrors && !category && (
                <div className='create-feed-select-error'>This field is required</div>
              )}

            </div>

            <div className='create-feed-select-holder'>
              <span
                  className={`create-feed-select-label ${selectGroupPrivacyActive || groupPrivacy ? 'active' : ''}`}
                  onClick={() => groupPrivacyRef.focus()}
                >
                  Group privacy
                </span>
                <Select
                  ref={ref => (groupPrivacyRef = ref)}
                  value={groupPrivacy}
                  onChange={value => {
                    setGroupPrivacy(value);
                    groupPrivacyRef.blur();
                  }}
                  className='create-feed-select'
                  showArrow={false}
                  optionLabelProp="label"
                  defaultActiveFirstOption={false}
                  open={selectGroupPrivacyActive}
                  onFocus={() => setSelectGroupPrivacyActive(true)}
                  onBlur={() => setSelectGroupPrivacyActive(false)}
                >
                  {groupPrivacyTypes.length > 0 &&
                    groupPrivacyTypes.map(groupPrivacy => (
                      <Select.Option
                        key={groupPrivacy.id}
                        value={groupPrivacy.id}
                        label={groupPrivacy.name}
                      >
                        <div className="create-feed-select-group-container">
                          {groupPrivacy.id === 'PUBLIC' && <Public />}
                          {groupPrivacy.id === 'PRIVATE' && <Private />}
                          {groupPrivacy.id === 'SECRET' && <Secret />}

                          <div className="create-feed-select-group-innerCcontainer">
                            <div className='create-feed-select-group-dropdown-title'>
                              {groupPrivacy.name}
                            </div>
                            <div className='create-feed-select-group-dropdown-description'>
                              {groupPrivacy.description}
                            </div>
                          </div>
                        </div>
                      </Select.Option>
                    ))}
                </Select>

                {showErrors && !groupPrivacy && (
                  <div className='create-feed-select-error'>This field is required</div>
                )}

              <FontAwesomeIcon className='create-feed-select-icon' icon={['fas', 'chevron-down']} />
            </div>

            <div className='mt-3 p-2 px-4 create-group__invite-colleagues rounded-lg'>
              <SearchInput submit={handleSearchPeople} placeholder='Invite colleagues' />

              <div className='create-group__people-select'>
                <div className='overflow-auto max-vh-10'>
                  {searchResults.users.length > 0 &&
                    searchResults.users.map(item => {
                      if (
                        (!item.first_name && !item.last_name) ||
                        selectedPeople.some(person => person.id === item.id)
                      )
                        return null;

                      return (
                        <CreateGroupSearchRow
                          key={item.id}
                          item={item}
                          handlePersonSelected={handlePersonSelected}
                        />
                      );
                    })}
                </div>
              </div>

              <div className='mt-2'>
                {selectedPeople.length > 0 && (
                  <div>
                    <h6 className='create-group__selected-title'>
                      {selectedPeople.length} colleague{selectedPeople.length > 1 ? 's' : ''}{' '}
                      selected
                    </h6>

                    <ul className='list-group list-group-horizontal-lg flex-wrap'>
                      {selectedPeople.map(item => (
                        <CreateGroupSelectedRow
                          key={`selected${item.id}`}
                          item={item}
                          handleRemovePerson={handleRemovePerson}
                        />
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          {group ? (
            <div
              className='create-group__button create-group__create ml-auto'
              onClick={handleSubmitForm}
            >
              SAVE
            </div>
          ) : (
            <div
              className='create-group__button create-group__create ml-auto'
              onClick={handleSubmitForm}
            >
              CREATE
            </div>
          )}
          <div className='create-group__spacer' />
        </form>
      </div>
    </div>
  );
}

CreateGroupModal.propTypes = {
  hide: PropTypes.func.isRequired,
  categories: PropTypes.array,
  groupPrivacyTypes: PropTypes.array,
};

export default CreateGroupModal;
