import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getMoreUsersRequest, getUsersRequest } from '../../actionCreators/users';
import ScrollView from '../../components/ScrollView';
import GroupApi from '../../api/group';
import './styles.scss';

const Menu = ({ userId, isModerator, togglePermissionHandler, removeMemberHandler }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Dropdown isOpen={menuOpen} direction="left" toggle={toggleMenu}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={menuOpen}>
        <div className="d-flex mb-1">
          <FontAwesomeIcon
            className="feed-item-header-collapse"
            icon={['fas', 'ellipsis-h']}
            size="xs"
            color={'#505A62'}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => togglePermissionHandler(userId, isModerator)}>
          <FontAwesomeIcon
            icon={['fas', 'user-cog']}
            color={'#505A62'}
            className="create-feed-select-icon mr-2"
          />
          {isModerator ? 'Dismiss' : 'Assign'} as Moderator
        </DropdownItem>
        <DropdownItem onClick={() => removeMemberHandler(userId, isModerator)}>
          <FontAwesomeIcon
            icon={['fas', 'user-minus']}
            color={'#505A62'}
            className="create-feed-select-icon mr-2"
          />
          Remove Member
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const ManageMembers = ({ users, getUsers, getMoreUsers, lastPage, groupId }) => {
  const [numberOfModeratorsInGroup, setNumberOfModeratorsInGroup] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: '',
    isModerator: false,
    action: '',
  });
  const [] = useState();

  let type = 'group';
  let id = groupId;

  useEffect(() => {
    getUsers({ type, id });
  }, [getUsers, id, type]);

  useEffect(() => {
    // Count of how many moderators are in the group
    // Use permissions value to toal up (permission can equal 0 or 1)
    const count = users.reduce((acc, item) => {
      return acc + item.permission;
    }, 0);
    setNumberOfModeratorsInGroup(count);
  }, [users, numberOfModeratorsInGroup, setNumberOfModeratorsInGroup]);

  const loadMore = () => {
    if (!lastPage) {
      getMoreUsers({ type, id });
    }
  };

  const toggleModal = () => setConfirmationModalOpen(!confirmationModalOpen);

  const onToggleGroupMemberPermission = (userId, isModerator) => {
    setSelectedUser({ id: userId, isModerator: isModerator, action: 'toggle' });
    toggleModal();
  };

  const handleToggleMemberPermission = async () => {
    // Should not be able to remove the last moderator in a group
    // If last mod in last group just return out of function and close the modal
    if (selectedUser.isModerator && numberOfModeratorsInGroup === 1) {
      return toggleModal();
    }
    await GroupApi.toggleGroupMemberPermission(selectedUser.id, id);
    getUsers({ type, id });
    toggleModal();
  };

  const onRemoveMember = (userId, isModerator) => {
    setSelectedUser({ id: userId, isModerator: isModerator, action: 'remove' });
    toggleModal();
  };

  const handleRemoveMember = async () => {
    // Should not be able to remove the last moderator in a group
    // If last mod in last group just return out of function and close the modal
    if (selectedUser.isModerator && numberOfModeratorsInGroup === 1) {
      return toggleModal();
    }
    await GroupApi.removeGroupMember(selectedUser.id, id);
    getUsers({ type, id });
    toggleModal();
  };

  let title = 'Assign';
  let buttonText = 'Assign';
  let description = 'This user will now be a group moderator.';
  if (selectedUser.action === 'toggle') {
    if (selectedUser.isModerator && numberOfModeratorsInGroup === 1) {
      title = 'Dismiss';
      buttonText = 'Okay';
      description = 'You cannot remove the last moderator within a group.';
    } else if (selectedUser.isModerator) {
      title = 'Dismiss';
      buttonText = 'Dismiss';
      description = 'This user will now be removed as a group moderator.';
    }
  }
  if (selectedUser.action === 'remove') {
    if (selectedUser.isModerator && numberOfModeratorsInGroup === 1) {
      title = 'Remove Member';
      buttonText = 'Okay';
      description = 'You cannot remove the last moderator within a group.';
    } else {
      title = 'Remove Member';
      buttonText = 'Remove';
      description = 'Are you sure you want to remove this member from the group?';
    }
  }

  return (
    <>
      <Modal isOpen={confirmationModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Cancel
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            color="primary"
            onClick={
              selectedUser.action === 'toggle' ? handleToggleMemberPermission : handleRemoveMember
            }
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </Modal>

      <div
        style={{ backgroundColor: '#E1E3E4' }}
        className="row d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 mr-1 ml-1 mb-3 mt-3 rounded-top"
      >
        <h6>Name</h6>
        <h6>User Type</h6>
        <h6></h6>
      </div>

      <ScrollView onEndReached={loadMore} style={{ maxHeight: '65vh' }}>
        <div className="justify-content-between">
          {users
            .filter(item => item.first_name || item.last_name)
            .map(item => (
              <div
                key={item.id}
                className="d-flex flex-row justify-content-between align-items-center pl-2 pr-4 border-bottom mb-3"
              >
                <Link
                  to={`/users/${item.id}`}
                  className="d-flex pl-2 row-members remove-highlight-link"
                >
                  <h6 className="single-line-text">
                    {`${item.first_name || ''} ${item.last_name || ''}`}
                  </h6>
                </Link>
                <div className="d-flex row-members pl-1">
                  <h6>{item.permission ? 'Moderator' : 'Standard'}</h6>
                </div>
                <Menu
                  userId={item.id}
                  groupId={id}
                  isModerator={item.permission}
                  moderatorCount={numberOfModeratorsInGroup}
                  togglePermissionHandler={onToggleGroupMemberPermission}
                  removeMemberHandler={onRemoveMember}
                />
              </div>
            ))}
        </div>
      </ScrollView>
    </>
  );
};

const mapStateToProps = state => ({
  users: state.users.users,
  loadingMore: state.users.state === 'PENDING' && state.users.users.length > 0,
  lastPage: state.users.pagination.page >= state.users.pagination.pages,
});

const dispatchProps = {
  getUsers: getUsersRequest,
  getMoreUsers: getMoreUsersRequest,
};

ManageMembers.propTypes = {
  users: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, dispatchProps)(ManageMembers);
