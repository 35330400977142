import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { Skeleton } from 'antd';

import NotificationRow from './NotificationRow';
import {
  getNotificationsRequest,
  setNotificationsReadRequest,
} from '../../../actionCreators/notificationActions';
import { postNewMemberRequest } from '../../../actionCreators/groupActions'
import { getUserBadgesRequest } from '../../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../../firebase/analytics';
import Icon from '@mdi/react'
import { mdiDotsHorizontalCircle } from '@mdi/js'
import { ClickAwayListener } from '@material-ui/core'
import { Link } from 'react-router-dom';

const LIMIT = 15;

class NotificationsSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: LIMIT,
      showNotificationsMenu: false
    };
  }

  componentDidMount() {
    this.props.getUserBadges();
    this.props.getNotifications({ page: 1 });
    logFirebaseEvent({
      event: 'app_notifications',
      action: 'load',
    });
  }

  componentWillUnmount() {

  }

  toggleNotificationsMenu = () => this.setState({ showNotificationsMenu: !this.state.showNotificationsMenu });

  _markAllAsRead = skipFetch => {

    logFirebaseEvent({
      event: 'app_notifications',
      action: 'mark_all_read',
    });

    const ids = [];
    this.props.items.forEach(item => {
      if (!item.is_read) {
        item.meta && !item.meta.length && logFirebaseEvent({
          event: 'points_goal'
        });
        item.notification.indexOf('badge') !== -1 && logFirebaseEvent({
          event: 'badge'
        });
        ids.push(item.id);
      }
    });

    this.props.setNotificationsRead({ ids, skipFetch });
  };

  _prepNotifications(items) {
    let read = [];
    let notRead = [];

    for (let i = 0; i < this.state.showing; i++) {
      if (!items[i]) continue;
      const copy = { ...items[i] };

      // Find proper badge type
      for (const meta of items[i].meta || []) {
        if (meta.data.type !== 'badges') continue;

        const badge = lodash.find(this.props.badges, { id: +meta.data.id });
        if (badge) copy.badge = badge;
        break;
      }

      // Split items on read and unread
      if (items[i].is_read) read.push(copy);
      else notRead.push(copy);
    }

    // max number of total notifications are 10
    if (notRead.length >= 10) {
      read = [];
      notRead = notRead.slice(0, 10)
    } else {
      read = read.slice(0, (10 - notRead.length));
    }

    return [read, notRead];
  }

  _getMoreNotifications = () => {
    this.setState(state => {
      if (this.state.showing >= this.props.items.length) this.props.getNotifications();

      return {
        showing: state.showing + LIMIT,
      };
    });
  };

  render() {
    const [read, notRead] = this._prepNotifications(this.props.items);

    const showViewMore =
      !this.props.loading &&
      (this.state.showing < this.props.items.length ||
        this.props.currentPage < this.props.totalPages);

    return (
      <div className='notifications-sidebar'>
        <div className='notifications-sidebar-header'>
          <p className='notifications-sidebar-header-text'>Notifications</p>

          <div onClick={this.toggleNotificationsMenu}>
            <Icon path={mdiDotsHorizontalCircle}
              size={1.5}
              horizontal
              vertical
              color="#535b64"
            />
          </div>

          {
            this.state.showNotificationsMenu && (
              <ClickAwayListener onClickAway={this.toggleNotificationsMenu}>
                <div className={'notifications-sidebar-three-dots-menu'}>
                  <p
                    onClick={() => {
                      this._markAllAsRead(false)
                      this.toggleNotificationsMenu()
                    }}
                    className={'notifications-sidebar-three-dots-menu-button'}
                  >
                    Mark all as read
                  </p>
                  <Link to={'/notifications'}>
                    <p
                      className={'notifications-sidebar-three-dots-menu-button'}
                    >
                      View all
                  </p>
                  </Link>
                </div>
              </ClickAwayListener>
            )
          }
        </div>

        <div className='notifications-sidebar-content'>
          {this.renderLoading()}
          {this.renderNotRead(notRead)}
          {this.renderRead(read)}
        </div>
      </div>
    );
  }

  renderLoading() {
    if (!this.props.loading) return null;

    return (
      <div className='notifications-sidebar-holder'>
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
        <Skeleton paragraph={false} avatar />
      </div>
    );
  }

  renderNotRead(items) {
    if (!items.length) return null;

    return (
      <>
        <span className='notifications-sidebar-title'>
          Unread
          <span className='notifications-sidebar-title-badge'>{this.props.unreadCount}</span>
        </span>

        <div className='notifications-sidebar-holder'>
          {items.map(notification => {
            return (
              <NotificationRow token={this.props.token} acceptNewMember={this.props.acceptNewMember} key={`notread:${notification.id}`} notification={notification} />
            );
          })}
        </div>
      </>
    );
  }

  renderRead(items) {
    if (!items.length) return null;

    return (
      <>
        <span className='notifications-sidebar-title'>Older</span>

        <div className='notifications-sidebar-holder'>
          {items.map(notification => {
            return <NotificationRow token={this.props.token} acceptNewMember={this.props.acceptNewMember} key={`read:${notification.id}`} notification={notification} />;
          })}
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    ...state.notification,
    badges: state.currentUser.badges.userBadges || [],
    token: state.currentUser.signInData.key,
  }),
  dispatch => ({
    getNotifications: payload => dispatch(getNotificationsRequest(payload)),
    setNotificationsRead: payload => dispatch(setNotificationsReadRequest(payload)),
    getUserBadges: () => dispatch(getUserBadgesRequest()),
    acceptNewMember: payload => dispatch(postNewMemberRequest(payload)),
  }),
)(NotificationsSidebar);
