import React, { useState, useEffect } from 'react';
import ImageApi from '../../api/image';
import UserPlaceholder from '../../assets/images/placeholders/user_placeholder.svg';
import GroupPlaceholder from '../../assets/images/group_placeholder.svg';
import PropTypes from 'prop-types';

function ThumbnailImage({ sourceUrl, type = 'person' }) {
  const [url, setUrl] = useState('');

  // Set image source to placeholder if it is missing
  function handleMissingImage(event) {
    event.preventDefault();

    switch (type) {
      case 'person':
        event.target.src = UserPlaceholder;
        break;
      case 'group':
        event.target.src = GroupPlaceholder;
        event.target.style.backgroundColor = '#E1E3E4';
        event.target.style.objectFit = 'none';
        break;
      default:
        return;
    }
  }

  // Handle when image is loaded
  function handleOnLoad(event) {
    if (event.target.src === url) event.target.style.objectFit = 'cover';
  }

  useEffect(() => {
    ImageApi.getImage(sourceUrl).then(value => setUrl(value));
  }, []);

  return (
    <img
      src={url}
      onError={handleMissingImage}
      onLoad={handleOnLoad}
      className={`thumbnail-image__${type}`}
      alt=""
    />
  );
}

ThumbnailImage.propTypes = {
  sourceUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default ThumbnailImage;
