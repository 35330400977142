import React from 'react';
import { connect } from 'react-redux';

import EditUserProfileComponent from '../../components/OnBoarding/EditUserProfile';
import {
  createUserProfileRequest,
  postUserAvatarRequest,
  getUserAvatarRequest,
  updateUserProfileRequest,
  getUserRequest,
} from '../../actionCreators/userProfile';
import { getUserTagsStart } from '../../actionCreators/userTags';
import { getDepartmentsStart } from '../../actionCreators/departmentActions';

const EditUserProfile = props => <EditUserProfileComponent {...props} />;

const mapStateToProps = state => ({
  token: state.currentUser.signInData,
  userData: state.currentUser.userData,
  avatarImg: state.currentUser.signInData.imageUrl,
  userTags: state.userTags.data || [],
  departments: state.departments.data || [],
});

const mapDispatchToProps = dispatch => ({
  createUserProfile: payload => dispatch(createUserProfileRequest(payload)),
  postUserAvatar: payload => dispatch(postUserAvatarRequest(payload)),
  handleAvatar: payload => dispatch(getUserAvatarRequest(payload)),
  updateProfile: payload => dispatch(updateUserProfileRequest(payload)),
  getUserTags: () => dispatch(getUserTagsStart()),
  handleUser: payload => dispatch(getUserRequest(payload)),
  getDepartments: () => dispatch(getDepartmentsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfile);
