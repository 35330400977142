import * as React from "react"

function GroupTagPrivate(props) {
  return (
    <svg
      width={13}
      height={17}
      viewBox="0 0 13 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Group 14"}</title>
      <path
        d="M2.95 7.65h8.1a.9.9 0 01.9.9v6.3a.9.9 0 01-.9.9h-8.1a.9.9 0 01-.9-.9v-6.3a.9.9 0 01.9-.9zm.9 0V5.4a3.15 3.15 0 016.3 0v2.25M7 10.8v1.8"
        stroke="#505A62"
        strokeWidth={1.2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GroupTagPrivate