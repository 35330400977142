import React, { Component } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FeedItemFooterCommentRow from './FeedItemFooterCommentRow';
import { InputWithMentions } from '../../../../containers';

class FeedItemFooterControl extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showComments: props.isPostSingle,
      commentText: '',
    };
  }

  _onLike = () => {
    if (this.props.disableButtons) return;

    if (typeof this.props.onLike === 'function') this.props.onLike();
  };

  _onComment = () => {
    if (this.props.isPostSingle || this.props.disableButtons) return;

    this.setState(state => {
      if (typeof this.props.onComment === 'function' && !state.showComments) this.props.onComment();

      return { showComments: !state.showComments };
    });
  };

  onChange = (fk, value) => {
    this.setState({ commentText: value });
  };

  _submitComment = value => {
    const valueText = value.trim();
    if (!valueText) return;

    this.props.postComment(valueText);
  };

  // Render functions
  render () {
    const likeClassNames = ['feed-item-footer-control-button'];
    const commentClassNames = ['feed-item-footer-control-button'];

    if (this.props.isLiked) likeClassNames.push('liked');
    if (this.props.disableButtons) {
      likeClassNames.push('disabled-button');
      commentClassNames.push('disabled-button');
    }

    return (
      <div>
        <div className='feed-item-footer-container'>
          <div className='feed-item-footer-button-container'>
            <div className={likeClassNames.join(' ')} onClick={this._onLike}>
              <FontAwesomeIcon
                className='icon'
                icon={[this.props.isLiked ? 'fas' : 'far', 'heart']}
              />
              LIKE
            </div>

            {/* {!this.props.isMandatoryGroup ? ( */}
            <div className={commentClassNames.join(' ')} onClick={this._onComment}>
              <FontAwesomeIcon className='icon' icon={['far', 'comment']} />
              COMMENT
            </div>
            {/* ) : (
              <div className={commentClassNames.join(' ')} />
            )} */}
          </div>
        </div>
        <div>{this.renderComments()}</div>
      </div>
    );
  }

  renderComments () {
    if (!this.state.showComments || this.props.disableButtons) return null;

    const { comments = [], isPostSingle, userImageUrl, link, onCommentLike } = this.props;

    let commentsToRender = comments;
    if (!isPostSingle) commentsToRender = comments.slice(0, 3);

    return (
      <div className='feed-item-footer-comments-block'>
        <div className='d-flex'>
          <Avatar
            className='feed-item-footer-comments-avatar with-margin'
            src={userImageUrl}
            icon='user'
            shape='square'
            size={42}
          />

          <InputWithMentions onSubmit={this._submitComment} maxLength={1000} />
        </div>

        <div className='d-flex flex-column'>
          {!!comments.length && (
            <div className='feed-item-footer-comments-total'>{comments.length} comments</div>
          )}

          {commentsToRender.map(comment => {
            return (
              <FeedItemFooterCommentRow
                key={`comment:${comment.id}`}
                comment={comment}
                onCommentLike={onCommentLike}
              />
            );
          })}

          {comments.length > commentsToRender.length && (
            <Link to={link} className='feed-item-footer-comments-more'>
              View More Comments
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default FeedItemFooterControl;
