import {
  CREATE_NOTICE_REQUEST,
  GET_FEED_ITEM_DETAILS_REQUEST,
  GET_FEED_REQUEST,
  GET_SINGLE_FEED_REQUEST,
  POST_COMMENT_REQUEST,
  POST_VOTE_REQUEST,
  RESET_FEED_PAGE,
  RESET_SINGlE_FEED_PAGE,
  TOGGLE_LIKE_REQUEST,
  CREATE_POLL_REQUEST,
  CREATE_HIGHFIVE_REQUEST,
  CREATE_EVENT_REQUEST,
  TOGGLE_COMMENT_LIKE_REQUEST,
  DELETE_POST_REQUEST,
  PIN_POST_REQUEST,
  REPORT_POST_REQUEST,
  RESET_REPORT_POST_REQUEST_SUCCESS,
  POPULATE_POST_REPORT_TYPES_REQUEST,
  POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS,
  SET_PIN_RETRY
} from '../constants/actionTypes';

export const getFeedRequest = payload => ({
  type: GET_FEED_REQUEST,
  payload,
});

export const getFeedItemDetailsRequest = payload => ({
  type: GET_FEED_ITEM_DETAILS_REQUEST,
  payload,
});

export const getFeedItemRequest = () => ({
  type: GET_SINGLE_FEED_REQUEST,
});

export const resetFeedPage = () => ({
  type: RESET_FEED_PAGE,
});

export const resetSingleFeedPage = () => ({
  type: RESET_SINGlE_FEED_PAGE,
});

export const postCommentRequest = payload => ({
  type: POST_COMMENT_REQUEST,
  payload,
});

export const voteOnPostRequest = payload => ({
  type: POST_VOTE_REQUEST,
  payload,
});

export const toggleLikeRequest = payload => ({
  type: TOGGLE_LIKE_REQUEST,
  payload,
});

export const createNoticeRequest = payload => ({
  type: CREATE_NOTICE_REQUEST,
  payload,
});

export const createPollRequest = payload => ({
  type: CREATE_POLL_REQUEST,
  payload,
});

export const createHighFiveRequest = payload => ({
  type: CREATE_HIGHFIVE_REQUEST,
  payload,
});

export const createEventRequest = payload => ({
  type: CREATE_EVENT_REQUEST,
  payload,
});

export const toggleCommentLikeRequest = payload => ({
  type: TOGGLE_COMMENT_LIKE_REQUEST,
  payload,
});

export const deletePostRequest = payload => ({
  type: DELETE_POST_REQUEST,
  payload
})

export const pinPostRequest = payload => ({
  type: PIN_POST_REQUEST,
  payload
})

export const setPinRetry = payload => ({
  type: SET_PIN_RETRY,
  payload
})

export const reportPostRequest = payload => ({
  type: REPORT_POST_REQUEST,
  payload
})

export const resetPostRequestSuccess = () => ({
  type: RESET_REPORT_POST_REQUEST_SUCCESS
})

export const populatePostReportTypesRequest = () => ({
  type: POPULATE_POST_REPORT_TYPES_REQUEST
})

export const populatePostReportTypesRequestSuccess = payload => ({
  type: POPULATE_POST_REPORT_TYPES_REQUEST_SUCCESS,
  payload
})