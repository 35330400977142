import React, { Component } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ImageApi from '../../../api/image';
import { dateDifferenceShorted } from '../../../utils/helpers';
import Event from '../../../assets/images/badgeEvent.svg';
import Default from '../../../assets/images/badgeDefault.svg';
import Notice from '../../../assets/images/badgeNotice.svg';
import Poll from '../../../assets/images/badgePoll.svg';
import HighFive from '../../../assets/images/badgeHighFive.svg';
import PollIcon from '../../Icons/PollIcon';
import SocialIcon from '../../Icons/SocialIcon';
import EventIcon from '../../Icons/EventIcon';
import OnboardingIcon from '../../Icons/EmployeeOnoardingIcon';
import UserPlaceholder from '../../../assets/images/user_placeholder.svg';
import {
  setNotificationsReadRequest,
} from '../../../actionCreators/notificationActions';

const LINK_MAP = {
  users: 'users',
  polls: 'feed',
  events: 'feed',
  highfives: 'feed',
  notices: 'feed',
  badges: 'activity',
  groups: 'group-feed',
  challenges: 'engage',
  learning: 'learning',
  employee_onboarding: 'employee_onboarding',
};
const IGNORE_ID = ['badges'];
const TYPE_MAP = {
  polls: '?type=poll',
  events: '?type=event',
  highfives: '?type=highfive',
  notices: '?type=notice',
};
const badgeTypes = {
  1: <img src={Event} alt="" />,
  2: <img src={Default} alt="" />,
  3: <img src={Notice} alt="" />,
  4: <img src={Poll} alt="" />,
  5: <img src={Default} alt="" />,
  6: <img src={Notice} alt="" />,
  7: <img src={HighFive} alt="" />,
};

class NotificationRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
      newMemberAcceptMessage: '',
    };
  }

  componentDidMount() {
    if (
      this.props.notification &&
      this.props.notification.image &&
      this.props.notification.image.thumbnailUrl
    ) {
      ImageApi.getImage(this.props.notification.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  _renderNewMemberRequestDecision(groupId, user_id, userFullName) {
    const { acceptNewMember, token } = this.props;
    const { newMemberAcceptMessage } = this.state;

    if (newMemberAcceptMessage) {
      return (
        <div className='notification-sidebar-item-buttons-container'>
          <span>{newMemberAcceptMessage}</span>
        </div>
      )
    }
    return (
      <div className='notification-sidebar-item-buttons-container'>
        <div className='notification-sidebar-item-button'
          onClick={() => {
            acceptNewMember({ groupId, user_id, value: 1, token });
            this.setState({ newMemberAcceptMessage: `${userFullName} request accepted` });
          }}
        >
          Accept
        </div>
        <div className='notification-sidebar-item-button button-opposite'
          onClick={() => {
            acceptNewMember({ groupId, user_id, value: 0, token });
            this.setState({ newMemberAcceptMessage: `${userFullName} request declined` })
          }}
        >
          Decline
        </div>
      </div>
    )
  }

  _markAsRead = (skipFetch = false) => {
    const { notification } = this.props;
    // Return out of function is notification is already read
    // if (notification.is_read) return
    // Push notification ID into array - structure required by reducer
    let ids = []
    ids.push(notification.id)
    // Set this notification now as 'read'
    this.props.setNotificationsRead({ ids, skipFetch });
  }

  render() {
    const { notification } = this.props;

    const computed = [];
    let target = notification.notification;

    notification.meta.forEach(meta => {
      // Skip this meta, it was added for mobile app
      if (meta.data.type === 'badge') return;

      const [before, after] = target.split(meta.key);

      computed.push(before);

      let link = `/${LINK_MAP[meta.data.type]}`;
      if (!IGNORE_ID.includes(meta.data.type)) link += `/${meta.data.id}`;
      if (TYPE_MAP[meta.data.type]) link += TYPE_MAP[meta.data.type];
      if (!LINK_MAP[meta.data.type]) link = '#';
      if (LINK_MAP[meta.data.type] === 'employee_onboarding') {
        link = 'employee_onboarding';
      }
      computed.push(
          <Link onClick={() => this._markAsRead(false)} key={`${meta.start}:${link}`} to={link} className="notification-sidebar-link">
            {meta.key}
          </Link>
      );

      target = after;
    });
    computed.push(target);

    // "Congratulations, you have reached your points goal" notification
    if (notification.meta && !notification.meta.length) {
      return (
        <Link to="/activity" className="notifications-sidebar-row">
          {this.renderImage()}

          <div className="w-100 d-flex flex-column">
            <p className="notifications-sidebar-row-text">{computed}</p>
            <span className="notifications-sidebar-row-time">
              {dateDifferenceShorted(notification.created_at)}
            </span>
          </div>
        </Link>
      );
    }

    return (
      <div className="notifications-sidebar-row">
        {this.renderImage()}

        <div className="w-100 d-flex flex-column">
          <p className="notifications-sidebar-row-text">{computed}</p>
          {notification.is_new_member_type && this._renderNewMemberRequestDecision(notification.meta[1].data.id, notification.meta[0].data.id, notification.meta[0].key)}
          <span className="notifications-sidebar-row-time">
            {dateDifferenceShorted(notification.created_at)}
          </span>
        </div>
      </div>
    );
  }

  renderImage() {
    const { notification } = this.props;

    if (notification.meta && notification.meta.length) {
      const isEvent = notification.meta.some(
        meta => meta.data.type === 'events' && meta.key !== 'post'
      );
      const isPoll = notification.meta.some(
        meta => meta.data.type === 'polls' && meta.key !== 'post'
      );
      const isChallenge = notification.meta.some(
        meta => meta.data.type === 'challenges' && meta.key !== 'post'
      );
      const isOnboarding = notification.meta.some(meta => meta.data.type === 'employee_onboarding');

      let Icon = null;
      let bgColor = '';
      if (isEvent) {
        Icon = EventIcon;
        bgColor = 'rgba(175,54,95,0.5)';
      } else if (isPoll) {
        Icon = PollIcon;
        bgColor = 'rgba(251,184,17,0.5)';
      } else if (isChallenge) {
        Icon = SocialIcon;
        bgColor = 'rgba(74,183,147,0.5)';
      } else if (isOnboarding) {
        Icon = OnboardingIcon;
      }

      if (Icon) {
        return (
          <div className="notification-sidebar-icon" style={{ backgroundColor: bgColor }}>
            <Icon stroke="#0F2B41" />
          </div>
        );
      }
    }

    // You have reached your points goal
    if (notification.meta && !notification.meta.length) {
      return (
        <div className="notification-sidebar-badge">
          <img src={Default} alt="" />
        </div>
      );
    }

    if ('badge' in notification) {
      return (
        <div className="notification-sidebar-badge">{badgeTypes[notification.badge.badge_type_id]}</div>
      );
    }

    return (
      <Avatar
        icon="user"
        shape="square"
        size={40}
        className="notifications-sidebar-row-avatar"
        src={this.state.imageUrl || UserPlaceholder}
      />
    );
  }
}

export default connect(null, dispatch => ({
  setNotificationsRead: payload => dispatch(setNotificationsReadRequest(payload)),
}),)(NotificationRow);
