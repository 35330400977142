import React, { PureComponent } from 'react';
import Portal from '../../Portal';
import MicroLearningModal from '../../../components/MicroLearningDetailsModal'
import ImageApi from '../../../api/image';

class FeedLearningItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item.post.image.url) {
      ImageApi.getImage(this.props.item.post.image.url).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  handleLearningModal = (value) => {
    this.setState({
      isLearningModal: value
    })
  }

  render() {
    const { item } = this.props;
    const { name, image, points, time_to_complete, completed } = item.post;
    const { isLearningModal } = this.state;

    return (
      <React.Fragment>
      <div className="feed-item-content">
        <p className="feed-item-content-text pt-3">There is a new micro learning module available</p>
        <div className="d-flex feed-item-event-block">
          {image.url && (
            <div className="feed-item-event-image-holder">
              {this.state.imageUrl && (
                <img className="feed-item-event-image" src={this.state.imageUrl} />
              )}
            </div>
          )}

          <div className="feed-item-event-info-holder flex-column">
            <div className="d-flex flex-row flex-grow-1  justify-content-between pl-3">
              <div className="flex-column">
                <p className={"feed-item-event-info-title"}>{name}</p>
                {!completed ? (
                  <span className="feed-item-content-text">Time to complete:<span className="time">{` ${time_to_complete} ${time_to_complete > 1 ? 'mins' : 'min'}`}</span></span>
                ) : (
                  <p className="time">Completed</p>
                )}
              </div>
              <div className="justify-content-end">
                <div className="learning-item--points">
                  {points}
                </div>
              </div>
            </div>   
            {!completed ? (
              <div className="feed-item-event-info-button"onClick={
                !completed
                  ? () => {
                    this.handleLearningModal(true);
                    }
                  : null
                }>
                START LEARNING
              </div>)
            : null
            }
          </div>
        </div>
      </div>
      <Portal
        hide={() => {this.handleLearningModal(!isLearningModal);}}
        isOpen={isLearningModal}
      >
      <MicroLearningModal learningItem={item.post} learningAvatar={this.state.imageUrl} hide={() => this.handleLearningModal(false)} />
    </Portal>
    </React.Fragment>
    );
  }
}

export default FeedLearningItem;
