import React from 'react';

import 'three-dots/dist/three-dots.css';

export default props => {
  const titles = Object.values(props.globalPreloaders);

  if (!titles.length) return null;

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255, 0.75)',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        className="d-flex align-items-center flex-column"
        style={{
          padding: 15,
        }}
      >
        <div className="dot-bricks" />
        {titles.map(
          (title, i) =>
            title && (
              <span
                key={i}
                style={{
                  color: '#1C8FFA',
                  fontSize: '1.2rem',
                }}
              >
                {title}
              </span>
            )
        )}
      </div>
    </div>
  );
};
