import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_MORE_USERS_REQUEST,
  GET_MORE_USERS_SUCCESS,
  GET_MORE_USERS_FAIL,
  REMOVE_GROUP_MEMBER_REQUEST,
  REMOVE_GROUP_MEMBER_SUCCESS,
  REMOVE_GROUP_MEMBER_FAIL,
  TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST,
  TOGGLE_GROUP_MEMBER_PERMISSON_SUCCESS,
  TOGGLE_GROUP_MEMBER_PERMISSON_FAIL
} from '../constants/actionTypes';

const initialState = {
  state: 'NONE',
  users: [],
  pagination: {
    total: 0,
    pages: 0,
    perPages: 0,
    page: 0,
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { ...state, state: 'PENDING', users: [] };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        state: 'SUCCESS',
        users: action.payload.users,
        pagination: action.payload.pagination,
      };
    case GET_USERS_FAIL:
      return { ...state, state: 'FAIL', users: [] };

    case GET_MORE_USERS_REQUEST:
      return { ...state, state: 'PENDING' };
    case GET_MORE_USERS_SUCCESS:
      return {
        ...state,
        state: 'SUCCESS',
        users: [...state.users, ...action.payload.users],
        pagination: action.payload.pagination,
      };

    case GET_MORE_USERS_FAIL:
      return { ...state, state: 'FAIL', ...state };
    
    case REMOVE_GROUP_MEMBER_REQUEST:
      return { ...state, state: 'PENDING' };
    
    case REMOVE_GROUP_MEMBER_SUCCESS:
      return { ...state, state: 'SUCCESS' };
    
    case REMOVE_GROUP_MEMBER_FAIL: 
      return { ...state, state: 'FAIL' };
    
    case TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST:
      return { ...state, state: 'PENDING' };
    
    case TOGGLE_GROUP_MEMBER_PERMISSON_SUCCESS:
      return { ...state, state: 'SUCCESS' };
    
    case TOGGLE_GROUP_MEMBER_PERMISSON_FAIL: 
      return { ...state, state: 'FAIL' };

    default:
      return state;
  }
};

export default usersReducer;
