import {
  GET_USER_TAGS_START,
  GET_USER_TAGS_SUCCESS,
  GET_USER_TAGS_FAIL,
} from '../constants/actionTypes';

export const getUserTagsStart = () => ({
  type: GET_USER_TAGS_START,
});

export const getUserTagsSuccess = data => ({
  type: GET_USER_TAGS_SUCCESS,
  payload: data,
});

export const getUserTagsFail = payload => ({
  type: GET_USER_TAGS_FAIL,
  payload,
});
