import React, { Component } from 'react';
import { Avatar } from 'antd';

import CrossWithBgIcon from '../Icons/CrossWitBgIcon';
import ImageApi from '../../api/image';

class CreateGroupSelectedRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item && this.props.item.image && this.props.item.image.thumbnailUrl) {
      ImageApi.getImage(this.props.item.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    const { item, handleRemovePerson } = this.props;

    return (
      <li className="create-group__selected-people">
        <Avatar
          className="create-group__selected-avatar"
          src={this.state.imageUrl}
          shape="square"
          size={38}
          icon="user"
        />
        <span>
          {item.first_name || ''} {(item.last_name || '').charAt(0)}.
        </span>
        <div className="create-group__remove-people" onClick={() => handleRemovePerson(item)}>
          <CrossWithBgIcon />
        </div>
      </li>
    );
  }
}

export default CreateGroupSelectedRow;
