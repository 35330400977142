import {
  GET_SECURITY_QUESTIONS_REQUEST,
  GET_SECURITY_QUESTIONS_SUCCESS,
  SET_PASSWORD_REQUEST,
  VALIDATE_DOB_REQUEST,
  VALIDATE_SECURITY_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  GET_PASSWORD_MIGRATION_REQUEST,
  POST_PASSWORD_MIGRATION_REQUEST,
  NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_SECURITY_REQUEST,
  GET_USER_SECURITY_REQUEST,
  GET_USER_SECURITY_SUCCESS,
} from '../constants/actionTypes';

const getSecurityQuestionsRequest = () => ({
  type: GET_SECURITY_QUESTIONS_REQUEST,
});

const getSecurityQuestionsSuccess = payload => ({
  type: GET_SECURITY_QUESTIONS_SUCCESS,
  payload,
});

const setPasswordRequest = payload => ({
  type: SET_PASSWORD_REQUEST,
  payload,
});

const validateDOBRequest = payload => ({
  type: VALIDATE_DOB_REQUEST,
  payload,
});

const validateSecurityRequest = payload => ({
  type: VALIDATE_SECURITY_REQUEST,
  payload,
});

const resetPasswordRequest = payload => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

const getPasswordMigrationStatus = payload => ({
  type: GET_PASSWORD_MIGRATION_REQUEST,
  payload,
});

const migrateToPasswordRequest = payload => ({
  type: POST_PASSWORD_MIGRATION_REQUEST,
  payload,
});

const navigateToPasswordMigrationScren = payload => ({
  type: NAVIGATE_TO_PASSWORD_MIGRATION_SCREEN,
  payload,
});

const changePasswordRequest = payload => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

const changeSecurityRequest = payload => ({
  type: CHANGE_SECURITY_REQUEST,
  payload,
});

const getUserSecurityRequest = () => ({
  type: GET_USER_SECURITY_REQUEST,
});

const getUserSecuritySuccess = payload => ({
  type: GET_USER_SECURITY_SUCCESS,
  payload,
});

export {
  setPasswordRequest,
  validateDOBRequest,
  getSecurityQuestionsRequest,
  getSecurityQuestionsSuccess,
  validateSecurityRequest,
  resetPasswordRequest,
  resetPasswordSuccess,
  getPasswordMigrationStatus,
  migrateToPasswordRequest,
  navigateToPasswordMigrationScren,
  changePasswordRequest,
  changeSecurityRequest,
  getUserSecurityRequest,
  getUserSecuritySuccess,
};
