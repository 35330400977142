import React, { useEffect } from 'react';
import OnboardingTaskItem from './components/OnboardingTaskItem';
import ProgressBar from '../global/ProgressBar';
import GridContainer from '../global/GridContainer/GridContainer';
import { logFirebaseEvent } from '../../firebase/analytics';

const EmployeeOnboarding = props => {
  const { tasks, history, firstTimeLogin } = props;
  const taskCount = tasks.length;
  const completedTasks = tasks.filter(item => item.is_completed).length;
  const progressBarWidth = (completedTasks / taskCount) * 100;

  // log fireBase Event on page load, if user has completed all tasks then log this as well
  useEffect(() => {
    logFirebaseEvent({
      event: 'employee_onboarding',
      action: 'load',
    });
    if (completedTasks.length === tasks.length) {
      logFirebaseEvent({
        event: 'employee_onboarding',
        action: 'onboarding_completed',
      });
    }
  }, []);

  return (
    <div className='employee-onboarding'>
      <div className='onboarding-container'>
        <GridContainer>
          {!taskCount ? (
            <p className='m-4 align-self-center'>Onboarding not set up yet!</p>
          ) : (
            <div>
              <div className='d-flex justify-content-between'>
                <h3 className='oboarding-title'>{'Onboarding'}</h3>
                {firstTimeLogin === 'true' ? (
                  <div className='skip-link' onClick={() => history.push('/')}>
                    Done
                  </div>
                ) : null}
              </div>

              <div
                className='progress-bar-holder'
                style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
              >
                <div className='w-100 d-flex justify-content-center flex-column'>
                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{
                        width: `${progressBarWidth}%`,
                        background: 'var(--primary-color)',
                      }}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              {tasks.map((item, index) => (
                <OnboardingTaskItem
                  {...item}
                  firstTimeLogin={firstTimeLogin}
                  updateTask={props.updateOnboardingTask}
                  isFirst={index === 0}
                  isLast={index === taskCount - 1}
                />
              ))}
            </div>
          )}
        </GridContainer>
      </div>
    </div>
  );
};

export default EmployeeOnboarding;
