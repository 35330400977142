import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Progress } from 'antd';
import ReactTip from 'react-tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


import Event from '../../assets/images/badgeEvent.svg';
import Poll from '../../assets/images/badgePoll.svg';
import Default from '../../assets/images/badgeDefault.svg';
import HighFive from '../../assets/images/badgeHighFive.svg';
import Notice from '../../assets/images/badgeNotice.svg';

import { getUserBadgesRequest, getUserPointsRequest } from '../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../firebase/analytics';
import GridContainer from '../../components/global/GridContainer/GridContainer';

const badgeTypes = {
  1: <img src={Event} alt='' />,
  2: <img src={Default} alt='' />,
  3: <img src={Notice} alt='' />,
  4: <img src={Poll} alt='' />,
  5: <img src={Default} alt='' />,
  6: <img src={Notice} alt='' />,
  7: <img src={HighFive} alt='' />,
};

const Activity = ({
  handleBadges,
  handlePoints,
  activityPoints: { isMonthTop20, total_user_points, points, cap },
  badges: { userBadges, availableBadges },
  activityPoints,
}) => {
  useEffect(() => {
    handleBadges();
    handlePoints();
    logFirebaseEvent({
      event: 'activity',
      action: 'load',
    });
  }, []);

  const isComplete = (points * 100) / cap >= 100;

  const handleProgressBar = () => {
    if (activityPoints.length) {
      return (
        <div className='mb-4 text-center'>
          <Spinner animation='border' variant='light' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div className='chart-progress-container'>
        <Progress
          strokeWidth={14}
          className='circle'
          type='circle'
          strokeColor={isComplete ? '#FBB811' : 'var(--primary-color)'}
          percent={(100 * points) / cap}
          width={240}
          format={() => (
            <span className='inner-points'>
              {points || 0}
              <span>Points</span>
            </span>
          )}
        />
      </div>
    );
  };

  const handleUserBadges = () => {
    return (
      userBadges.length > 0 &&
      userBadges.map(badge => {
        return (
          <Col key={badge.description} className='mb-2' xl={0}>
            <div data-tip={badge.description} key={badge.id} className='badge'>
              {badgeTypes[badge.badge_type_id]}
              <ReactTip className='pop-up-desc' effect='solid' place='bottom' type='light' />
            </div>
          </Col>
        );
      })
    );
  };

  const handleAvailableBadges = () => {
    return (
      availableBadges &&
      availableBadges.map(badge => {
        return (
          <Col key={badge.description} className='mb-2' xl={0}>
            <div className='d-flex align-items-center' data-tip={badge.description}>
              <div className='badge'>{badgeTypes[badge.badge_type_id]}</div>
            </div>
          </Col>
        );
      })
    );
  };

  const handleSpinner = theme => {
    return (
      <div className='mb-4 text-center'>
        <Spinner animation='border' variant={theme} role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      </div>
    );
  };

  const handleTitle = () => {
    if (userBadges && isComplete) {
      return (
        <p className='subtitle'>
          Well done! Yov've met the monthly organisation goal of{' '}
          <strong className='blue-highlight'>{cap}</strong> points
        </p>
      );
    }

    if (userBadges && !isComplete) {
      return (
        <p className='subtitle'>
          You’re only
          <strong className='mr-1 ml-1 blue-highlight'>{cap - points} points </strong>
          away from the monthly goal of
          <strong className='mr-1 ml-1 blue-highlight'>{cap} points</strong>
        </p>
      );
    }

    return handleSpinner('light');
  };

  const handleContent = () => {
    return (
      <div className='badges-container'>
        <div className='badges-container--upper'>
          <div className='title'>Badges</div>
          <Row className='pb-5 ml-0 mr-0'>
            <div className='active-badges'>{handleUserBadges()}</div>
            <div className='inactive-badges'>{handleAvailableBadges()}</div>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div className='activity-container'>
      <GridContainer>
        <div className='activity-container--upper'>
          <h3 className='title m-0'>Activity</h3>
          {handleProgressBar()}
          {handleTitle()}
          <div className='activity-count-container'>
            <div className='activity-count-container__upper'>
              <h3 className='points'>
                <strong className='mr-1 blue-highlight'>{total_user_points}</strong>
                total points
              </h3>
              {userBadges && userBadges.length > 0 && (
                <h3 className='badges m-0'>
                  <strong className='mr-1 blue-highlight'>{userBadges && userBadges.length}</strong>
                  badges earned
                </h3>
              )}
            </div>
            {isMonthTop20 && (
              <div className='top-percentile-section'>
                <p className='rank'>
                  You rank in the <strong>top 20% </strong>of the company, keep it up!
                </p>
              </div>
            )}
          </div>
        </div>
        {userBadges ? handleContent() : handleSpinner('dark')}
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  activityPoints: state.currentUser.activityPoints,
  badges: state.currentUser.badges,
});

const mapDispatchToProps = dispatch => ({
  handleBadges: () => dispatch(getUserBadgesRequest()),
  handlePoints: () => dispatch(getUserPointsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
