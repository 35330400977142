import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  SET_NOTIFICATION_LOADING,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  SET_NOTIFICATIONS_READ_REQUEST,
  SET_NOTIFICATIONS_ITEMS,
} from '../constants/actionTypes';

import NotificationApi from '../api/notification';
import errorHandler from '../utils/requestErrorHandler';

function* getNotificationsRequest(action) {
  yield put({ type: SET_NOTIFICATION_LOADING, payload: true });

  try {
    const payload = action.payload || {};
    let page = payload.page;
    if (!page) {
      page = yield select(state => state.notification.currentPage);
      page++;
    }
    const SUCCESS_ACTION = page === 1 ? SET_NOTIFICATIONS_ITEMS : GET_NOTIFICATIONS_REQUEST_SUCCESS;

    const notifications = [];
    let result = {};

    // Keep getting notification till the first one read
    while (true) {
      const data = yield call(NotificationApi.getNotifications, page);
      notifications.push(...data.notifications);

      if (
        !data.notifications.length ||
        data.notifications[data.notifications.length - 1].is_read ||
        data.pages === page
      ) {
        result = { ...data, notifications: notifications, currentPage: page };
        break;
      }

      page++;
    }

    result.unreadCount = 0;
    result.notifications.forEach(notification => {
      if (!notification.is_read) result.unreadCount++;
    });

    yield put({ type: SUCCESS_ACTION, payload: result });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_NOTIFICATION_LOADING, payload: false });
  }
}

function* setNotificationsReadRequest(action) {
  try {
    const { ids, skipFetch } = action.payload;

    yield call(NotificationApi.setNotificationsRead, ids);

    if (!skipFetch) yield put({ type: GET_NOTIFICATIONS_REQUEST, payload: { page: 1 } });
  } catch (error) {
    //yield errorHandler(error);
  }
}

export default function* watchNotifications() {
  yield takeLatest(GET_NOTIFICATIONS_REQUEST, getNotificationsRequest);
  yield takeLatest(SET_NOTIFICATIONS_READ_REQUEST, setNotificationsReadRequest);
}
