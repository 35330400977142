import React, { Component } from 'react';
import { Avatar } from 'antd';

import ImageApi from '../../../api/image';

class CreateFeedItemSelectedGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.group && this.props.group.image && this.props.group.image.thumbnailUrl) {
      ImageApi.getImage(this.props.group.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    return (
      <div className="create-feed-group-row create-feed-group-selected">
        Posting in
        <div className="create-feed-selected-group">
          <Avatar
            className="create-feed-selected-group-image"
            src={this.state.imageUrl}
            shape="square"
            size={36}
          />
          {this.props.group.name}
        </div>
        <span className="create-feed-change-button" onClick={this.props.onCancel}>
          CHANGE
        </span>
      </div>
    );
  }
}

export default CreateFeedItemSelectedGroup;
