const materialIconsImport =
  "<link href='https://fonts.googleapis.com/icon?family=Material+Icons' rel='stylesheet'>";
const checkboxStyle = '<style>ul { list-style-type: none; cursor: pointer; }</style>';
const styledCheckboxLi = "<li style='display: flex; flex-direction: row;'>";
const checkboxIcon = "<span class='material-icons' style='color: #4AB793; padding-right: 10px;'>";

export const screenData = [
  {
    header: ['Check-In Pulse', 'Surveys'],
    body: [
      'How are you feeling today? We want to make sure everyone is having the best experience here. Let us know anonymously through the app.',
      'Make sure your voice is heard! We want you to shape our direction to become the best we can be. Take part in short surveys and see live results instantly.',
    ],
  },
  {
    header: ['Feed', 'Groups', 'Events'],
    body: [
      'Share news, read about the latest company updates, or start conversations about anything your colleagues need to know.',
      'Join (or set up) specific groups - for your team, department, location or even for shared interests.',
      'Set up or attend company events. From team nights out to the Christmas Party to weekend sporting meets, find all the details here.',
    ],
  },
  {
    header: ['Challenges', 'Microlearning', 'High-Fives'],
    body: [
      'Working as a team or on your own, set and complete Challenges - raise money for charity, hit your fitness goals or commit to learning something new - have fun and the support of your workmates.',
      'Brush up your knowledge on a range of topics, from cybersecurity through to health & wellbeing with short tailored tutorials.',
      'Show your appreciation to your workmates for their great work, dedication and attitude!',
    ],
  },
];

export const policyData = [
  {
    header: 'What we do with your data',
    body: `${checkboxStyle}${materialIconsImport}Here's the key points about the information you input to <b>engage4:</b><br><br><ul>${styledCheckboxLi}${checkboxIcon}check_circle</span><p>Notices you post and your comments, likes and High-5s are <b>only visible</b> to colleagues who are also using the app</p></li><br/>${styledCheckboxLi}${checkboxIcon}check_circle</span><p>Your individual poll, check-in pulse and micro-learning <b>are not visible</b> to anyone but you</p></li><br/>${styledCheckboxLi}${checkboxIcon}check_circle</span><p>Check-in pulse and general usage statistics are available to your organisation only in <b>aggregated and anonymised</b> form</p></li>`,
    ctaButtonTitle: 'VIEW PRIVACY POLICY',
  },
  {
    header: 'You’re all caught up!',
    body:
      "Now that you're up to speed, take some time getting used to the app! Join some Groups, give a colleague a well deserved High Five or sign up for a Community Challenge!\nIf you have any problems using the app, check out the Video Tutorials section or contact the Admin Team.",
  },
];
