import {
  GET_KNOWLEDGEBASE_CATEGORIES_REQUEST,
  GET_KNOWLEDGEBASE_ITEMS_REQUEST,
  SET_KNOWLEDGEBASE_FILTERS,
  GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST,
  UPDATE_KNOWLEDEBASE_VIEWS_REQUEST,
} from '../constants/actionTypes';

export const getKnowledgebasebaseCategoriesRequest = payload => ({
  type: GET_KNOWLEDGEBASE_CATEGORIES_REQUEST,
  payload,
});

export const getKnowledgebaseItemsRequest = payload => ({
  type: GET_KNOWLEDGEBASE_ITEMS_REQUEST,
  payload,
});

export const getMoreKnowledgebaseItemsRequest = payload => ({
  type: GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST,
  payload,
});

export const setKnowledgebaseFilters = payload => ({
  type: SET_KNOWLEDGEBASE_FILTERS,
  payload,
});

export const updateKnowledgebaseViewsRequest = payload => ({
  type: UPDATE_KNOWLEDEBASE_VIEWS_REQUEST,
  payload,
});
