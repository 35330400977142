import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { fetchFeatureFlagsRequest } from '../../../../actionCreators/featureFlags'

const Header = ({ view }) => {
  const featureFlags = useSelector(state => state.featureFlags);
  const dispatch = useDispatch()
  const eventStyles = ['switcher-container--events'];
  const challengeStyles = ['switcher-container--challenges'];
  const learningStyles = ['switcher-container--learning'];

  if (view === 'event') eventStyles.push('active-header');
  if (view === 'challenge') challengeStyles.push('active-header');
  if (view === 'learning') learningStyles.push('active-header');
  if (!featureFlags.LEARNING && !featureFlags.CHALLENGES) eventStyles.push('only-header');

  useEffect(() => {
    dispatch(fetchFeatureFlagsRequest())
  }, [])

  return (
    <React.Fragment>
      <div className="switcher-container">
        <Link to="/engage?tab=event" className={eventStyles.join(' ')}>
          <p className="engageSubtitle active">Events</p>
          {view === 'event' && <div className={'switcher-container-active-underline'} />}
        </Link>
        {featureFlags.CHALLENGES && (
          <Link to="/engage?tab=challenge" className={challengeStyles.join(' ')}>
            <p className="engageSubtitle">Challenges</p>
            {view === 'challenge' && <div className={'switcher-container-active-underline'} />}
          </Link>
        )}
        {featureFlags.LEARNING && (
          <Link to="/engage?tab=learning" className={learningStyles.join(' ')}>
            <p className="engageSubtitle">Learning</p>
            {view === 'learning' && <div className={'switcher-container-active-underline'} />}
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
