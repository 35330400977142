export const CUSTOM_THEME = {
  engage4: {
    logo: require('../assets/images/company_logo.svg'),
    welcome: require('../assets/images/welcome.png'),
  },
  vidatec: {
    logo: require(`../themes/vidatec/assets/company_logo.svg`),
    welcome: require(`../themes/vidatec/assets/welcome.png`),
    firebaseConfig: {
      dev: require(`../themes/vidatec/firebase/dev/firebaseConfig`),
      uat: require(`../themes/vidatec/firebase/uat/firebaseConfig`),
      live: require(`../themes/vidatec/firebase/live/firebaseConfig`),
    },
  }, //no firebase project for united capital and uses engage4 firebase project
  uc: {
    logo: require(`../themes/uc/assets/company_logo.svg`),
    welcome: require(`../themes/uc/assets/welcome.png`),
    firebaseConfig: {
      dev: require(`../firebase/firebaseConfig`),
      uat: require(`../firebase/firebaseConfig`),
      live: require(`../firebase/firebaseConfig`),
    },
  },
  bluestonewales: {
    logo: require(`../themes/bluestonewales/assets/company_logo.svg`),
    welcome: require(`../themes/bluestonewales/assets/welcome.jpg`),
    firebaseConfig: {
      dev: require(`../firebase/firebaseConfig`),
      uat: require(`../firebase/firebaseConfig`),
      live: require(`../firebase/firebaseConfig`),
    },
  },
};
