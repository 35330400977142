import React, { Component } from 'react';
import DatepickerField from '../../global/fields/DatepickerField';
import EventIcon from '../../Icons/EventIcon';

class ConfirmDOB extends Component {
  render () {
    return (
      <div>
        <p className='field-container__account-helper-text'>
          Please confirm your date of birth:
        </p>
        <DatepickerField
          style={{ padding: 0, margin: 0 }}
          value={this.props.dateOfBirth}
          updateFieldData={(fk, value, error) =>
            this.props.onChangeFieldValue('dateOfBirth', value)
          }
          placeholder='dd/MM/yyyy'
          label='Date of birth'
          icon={EventIcon}
          optionalParams={{ dateFormat: 'dd/MM/yyyy', maxDate: new Date() }}
        />
      </div>
    );
  }
}

export default ConfirmDOB;
