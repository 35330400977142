import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import lodash, { isError } from 'lodash';

import Tabs from '../../global/Tabs/Tabs';

import PollIcon from '../../Icons/PollIcon';
import EventIcon from '../../Icons/EventIcon';
import NoticeIcon from '../../Icons/NoticeIcon';
import HighFiveIcon from '../../Icons/HighFiveIcon';

import CreateFeedItemSearchGroup from './CreateFeedItemSearchGroup';
import CreateFeedItemSelectedGroup from './CreateFeedItemSelectedGroup';
import CreateFeedItemNotice from './CreateFeedItemNotice';
import CreateFeedItemPoll from './CreateFeedItemPoll';
import CreateFeedItemHighFive from './CreateFeedItemHighFive';
import CreateFeedItemEvent from './CreateFeedItemEvent';
import { logFirebaseEvent } from '../../../firebase/analytics';
import { getMyGroupsRequest } from '../../../actionCreators/groupActions';

import {
  createNoticeRequest,
  createPollRequest,
  createHighFiveRequest,
  createEventRequest,
} from '../../../actionCreators/feedActions';
import TextField from '../../global/fields/TextField';
import { addAlertToAlertsList } from '../../../actionCreators/globalAlerts';

class CreateFeedItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTab: 'Notice',
      isFocused: this.props.isFocused || false,
      selectedGroupId: props.selectedGroupId || '',
      searchGroupValue: '',
      showErrors: false,
    };

    this.tabs = [
      { label: 'Notice', icon: NoticeIcon },
      { label: 'Poll', icon: PollIcon },
      { label: 'High Five', icon: HighFiveIcon },
      { label: 'Event', icon: EventIcon },
    ]

    if (props.action === 'share') {
      this.tabs = [
        { label: props.title },
      ]
    }
  }

  componentDidMount () {
    if (this.props.myGroups) {
      this.props.getMyGroups();
    }
  }

  _activateTab = target => {
    if (this.state.activeTab === target) return;

    this.setState({ activeTab: target, showErrors: false });

    this.onInputFocus();
  };

  onInputFocus = () => {
    if (this.state.isFocused) return;

    this.setState({ isFocused: true });
    if (typeof this.props.onToggleActive === 'function') this.props.onToggleActive(true);
  };

  _close = () => {
    this.setState({
      isFocused: false,
      selectedGroupId: this.props.selectedGroupId || '',
      searchGroupValue: '',
      showErrors: false,
    });
    if (typeof this.props.onToggleActive === 'function') this.props.onToggleActive(false);
  };

  onSelect = selectedGroupId => {
    this.setState({ selectedGroupId: selectedGroupId });
  };

  onSubmit = async (type, data) => {
    if (!this.state.selectedGroupId) return this.setState({ showErrors: true });
    // Create Notice
    if (type === 'Notice') {
      if (data.notice.error) return this.setState({ showErrors: true });
      data.notice = data.notice.value;
      data.group_id = this.state.selectedGroupId;
      if (data.video) {
        const abortController = new AbortController();
        const onClickCancelVideo = () => {
          abortController.abort();
        };
        const signal = abortController.signal;
        const onFailToCreateNotice = errMsg =>
          this.props.addAlertToAlerts({
            title: 'Message',
            message: errMsg,
            confirmBtnText: 'Okay',
        });
        this.props.addAlertToAlerts({
          title: 'Message',
          message: 'Uploading Video',
          confirmBtnText: 'Cancel',
          isVideoAlert: true,
          noticeParams: {
            notice: data,
            createNotice: this.props.createNotice,
            closeNotice: this._close,
            signal,
            onFailToCreateNotice,
          },
          successCb: onClickCancelVideo,
        });
      } else {
        this.props.createNotice(data);
        this._close();
      }

      let event = 'notice';
      let action = 'create'

      if (data.challenge_id) {
        event = 'challenge';
        action = 'share';
      }

      if (data.knowledgebase_item_id) {
        event = 'knowledgebase';
        action = 'share';
      }

      logFirebaseEvent({
        event: event,
        action: action,
        attachment: data.video ? 'video' : data.image ? 'image' : ''
      });
    }

    // Create Poll
    if (type === 'Poll') {
      if (data.question.error || data.options.some(option => option.error) || !data.duration) {
        return this.setState({ showErrors: true });
      }

      this.props.createPoll({
        group_id: this.state.selectedGroupId,
        question: data.question.value,
        options: data.options.map(option => option.value),
        duration: data.duration,
      });
      logFirebaseEvent({
        event: 'poll',
        action: 'create',
      });
      return this._close();
    }

    // Create High Five
    if (type === 'High Five') {
      if (!data.recipient_id || !data.highfive_type_id || (data.reason && data.reason.error)) {
        return this.setState({ showErrors: true });
      }

      if (data.reason) data.reason = data.reason.value;
      data.group_id = this.state.selectedGroupId;
      this.props.createHighFive(data);
      logFirebaseEvent({
        event: 'highfive',
        action: 'create',
      });
      return this._close();
    }

    if (type === 'Event') {
      logFirebaseEvent({
        event: 'events',
        action: 'create',
      });
      if (
        data.title.error ||
        data.description.error ||
        data.location.error ||
        data.start_time.error ||
        (data.end_time && data.end_time.error)
      ) {
        return this.setState({ showErrors: true });
      }

      data.title = data.title.value;
      data.description = data.description.value;
      data.location = data.location.value;
      data.start_time = data.start_time.value;
      if (data.end_time) data.end_time = data.end_time.value;

      data.group_id = this.state.selectedGroupId;
      this.props.createEvent(data);

      return this._close();
    }
  };

  checkFields = (type, data) => {
    if (!this.state.selectedGroupId) return false;
    // Create Notice
    if (type === 'Notice') {
      if (!data.notice.value || data.notice.error || this.state.loading) return false;
    }

    // Create Poll
    if (type === 'Poll') {
      if (!data.question.value || data.question.error || data.options.some(option => option.error) || !data.duration) {
        return false;
      }
    }

    // Create High Five
    if (type === 'High Five') {
      if (!data.recipient_id || !data.highfive_type_id) {
        return false;
      }
    }

    if (type === 'Event') {
      if (
        data.title.error ||
        data.description.error ||
        data.location.error ||
        data.start_time.error ||
        (data.end_time && data.end_time.error)
      ) {
        return false;
      }
    }

    return true;
  };

  render () {
    const { isFocused } = this.state;

    return (
      <div className='create-feed'>
        {isFocused && <div className='create-feed-overlay' onClick={this._close} />}

        <div
          className={`create-feed-holder ${isFocused ? 'active' : ''}`}
          onClick={e => e.stopPropagation()}
        >
          <Tabs
            tabs={this.tabs}
            onChange={this._activateTab}
            active={this.state.activeTab}
          />

          <div className={`create-feed-content ${this.state.isFocused ? '' : 'collapsed'}`}>
            {this.renderGroupRow()}
            {this.renderFocusedContent()}
          </div>
        </div>
      </div>
    );
  }

  renderGroupRow () {
    if (!this.state.isFocused && this.props.selectedGroupId) {
      return (
        <div className='create-feed-content-input-wrapper'>
          <TextField
            fk='useless'
            value=''
            label='New post'
            updateFieldData={() => {}}
            onFocus={this.onInputFocus}
          />
        </div>
      );
    }


    if (this.state.selectedGroupId && this.state.activeTab!='Notice' && !this.props.isAdmin) {
      const selectedGroup = lodash.find(this.props.myGroups, { id: this.state.selectedGroupId });
      if (!selectedGroup.is_admin) {
        this.setState({ selectedGroupId: null });
      }
    }

    if (!this.state.selectedGroupId) {
      return (
        <CreateFeedItemSearchGroup
          onFocus={this.onInputFocus}
          onSelect={this.onSelect}
          groups={this.props.myGroups}
          featureFlags={this.props.featureFlags}
          activeTab={this.state.activeTab}
          isActive={this.state.isFocused}
          showError={this.state.showErrors}
        />
      );
    }

    const selectedGroup = lodash.find(this.props.myGroups, { id: this.state.selectedGroupId });
    if (!selectedGroup) return null;

    return (
      <CreateFeedItemSelectedGroup
        group={selectedGroup}
        onCancel={() => this.setState({ selectedGroupId: '' })}
      />
    );
  }

  renderFocusedContent () {
    if (!this.state.isFocused) return null;
    const selectedGroup = lodash.find(this.props.myGroups, { id: this.state.selectedGroupId });
    const {challengeItem = false, shouldShowAttachedMedia = true, isAdmin, from, knowledgebaseItem = false} = this.props;
    if (this.state.activeTab === 'Notice') {
      return (
        <CreateFeedItemNotice
          onSubmit={this.onSubmit}
          showErrors={this.state.showErrors}
          checkFields={this.checkFields}
          isAdmin={isAdmin}
          selectedGroup={selectedGroup}
          shouldShowAttachedMedia={shouldShowAttachedMedia}
          challengeItem={challengeItem}
          knowledgebaseItem={knowledgebaseItem}
        />
      );
    }
    if (this.state.activeTab === 'Poll') {
      return (
        <CreateFeedItemPoll
          onSubmit={this.onSubmit}
          showErrors={this.state.showErrors}
          checkFields={this.checkFields}
          isAdmin={this.props.isAdmin}
          selectedGroup={selectedGroup}
        />
      );
    }
    if (this.state.activeTab === 'High Five') {
      return (
        <CreateFeedItemHighFive
          onSubmit={this.onSubmit}
          selectedGroupId={this.state.selectedGroupId}
          showErrors={this.state.showErrors}
          checkFields={this.checkFields}
        />
      );
    }
    if (this.state.activeTab === 'Event') {
      return (
        <CreateFeedItemEvent
          onSubmit={this.onSubmit}
          showErrors={this.state.showErrors}
          checkFields={this.checkFields}
        />
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    myGroups: state.group.myGroups,
    isAdmin: state.currentUser.userData && state.currentUser.userData.is_admin ? true : false,
    featureFlags: state.featureFlags,
  }),
  dispatch => ({
    addAlertToAlerts: payload => dispatch(addAlertToAlertsList(payload)),
    createNotice: payload => dispatch(createNoticeRequest(payload)),
    createPoll: payload => dispatch(createPollRequest(payload)),
    createHighFive: payload => dispatch(createHighFiveRequest(payload)),
    createEvent: payload => dispatch(createEventRequest(payload)),
    getMyGroups: () => dispatch(getMyGroupsRequest()),
  }),
)(CreateFeedItem);
