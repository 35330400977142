import React from 'react';

export default function SearchIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="30px"
      height="30px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M9.5,1.875 C13.7111668,1.875 17.125,5.28895111 17.125,9.5 C17.125,11.1996839 16.5689175,12.7694517 15.6287549,14.0373011 L21.090417,19.4994267 C21.5297568,19.9387666 21.5297568,20.6510772 21.090417,21.090417 C20.6510772,21.5297568 19.9387666,21.5297568 19.4994267,21.090417 L19.4994267,21.090417 L14.0373011,15.6287549 C12.7694517,16.5689175 11.1996839,17.125 9.5,17.125 C5.28872966,17.125 1.875,13.7112703 1.875,9.5 C1.875,5.28895111 5.2888332,1.875 9.5,1.875 Z M9.5,4.125 C6.53149128,4.125 4.125,6.5315744 4.125,9.5 C4.125,12.4686297 6.53137034,14.875 9.5,14.875 C12.4686297,14.875 14.875,12.4686297 14.875,9.5 C14.875,6.5315744 12.4685087,4.125 9.5,4.125 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="📰-2-Feed" stroke="none" strokeWidth="0.5" fill="none" fillRule="evenodd">
        <g id="2-Feed-Placeholders" transform="translate(-232.000000, -59.000000)">
          <g id="search_white-search" transform="translate(231.000000, 58.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Stroke-3" fill={stroke} fillRule="nonzero" xlinkHref="#path-1"></use>
            <g id="Colours-/-blue-Colours-/-White" mask="url(#mask-2)" fill={stroke}>
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
