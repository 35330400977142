import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class CustomAppApi {
  static getCustomAppDetails(data) {
    const request = new Request(`${apiUrl}/content/custom_app/${data.web_url}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }
}

export default CustomAppApi;
