import { FETCH_FEATURE_FLAGS_SUCCESS } from '../constants/actionTypes';

const initialState = {};

const featureFlagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default featureFlagsReducer;
