import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class FeedApi {
  static getFeed({ page = 1, groupId }) {
    let url = `${apiUrl}/content/feed?limit=10&page=${page}`;
    if (groupId) url += `&group_id=${groupId}`;

    const request = new Request(url, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getSingleFeed({ type, id }) {
    const request = new Request(`${apiUrl}/content/${type}/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getAuthor({ id }) {
    const request = new Request(`${apiUrl}/content/user/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static postComment({ body, postId }) {
    const request = new Request(`${apiUrl}/content/post/${postId}/comment`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    return fetchRequest(request);
  }

  static voteOnPoll(pollOptionId) {
    const request = new Request(`${apiUrl}/content/vote/${pollOptionId}/`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static toggleLike({ postId, newLikeState }) {
    const pathPart = newLikeState ? 'like' : 'unlike';
    const request = new Request(`${apiUrl}/content/post/${postId}/${pathPart}`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getHighFiveOptions() {
    const request = new Request(`${apiUrl}/content/highfivetypes`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getGroupUsers(groupId, search) {
    const request = new Request(
      `${apiUrl}/content/user?in_group_id=${groupId}&filter=${search}&limit=100&page=1`,
      {
        method: 'GET',
        headers: getHeaders(),
      }
    );

    return fetchRequest(request);
  }

  static deletePost({ postId }) {
    const request = new Request(`${apiUrl}/content/post/${postId}`, {
      method: 'DELETE',
      headers: getHeaders()
    })

    return fetchRequest(request)
  }

  static pinPost({ postId, pinned_post, groupId, replacePost }) {
    const request = new Request(`${apiUrl}/content/post/${postId}`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({ pinned_post, postId, group_id: groupId, unpinOldest: replacePost }),
    })

    return fetchRequest(request)
  }

  static reportPost({ postId, reason_type, reason_text }) {

    const formData = new FormData()
    formData.append('reason_type', reason_type)

    if (reason_text.length > 0)
      formData.append('reason_text', reason_text)

    const request = new Request(`${apiUrl}/content/post/${postId}/report`, {
      method: 'POST',
      headers: getHeaders({}, true),
      body: formData
    })

    return fetchRequest(request)
  }

  static populatePostReportTypes() {
    const request = new Request(`${apiUrl}/content/report_reasons`, {
      method: 'GET',
      headers: getHeaders()
    })

    return fetchRequest(request)
  }

  static getNoticeVideoUploadURL () {
    const request = new Request(`${apiUrl}/content/video`, {
      method: 'POST',
      headers: getHeaders({}, true),
    });
    return fetchRequest(request);
  }

  static async uploadNoticeVideoToCloudFlare (data, signal) {
    try {
      const { file, uploadURL } = data;
      const formData = new FormData();
      formData.append('file', file);
      const uploadResp = await fetch(uploadURL, {
        method: 'POST',
        body: formData,
        signal,
      });
      return uploadResp;
    } catch (err) {
      console.log(err);
    }
  }
  static createNotice(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const request = new Request(`${apiUrl}/content/notice`, {
      method: 'POST',
      headers: getHeaders({}, true),
      body: formData,
    });

    return fetchRequest(request);
  }

  static createPoll(data) {
    const request = new Request(`${apiUrl}/content/poll`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });

    return fetchRequest(request);
  }

  static createHighFive(data) {
    const request = new Request(`${apiUrl}/content/highfive`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });

    return fetchRequest(request);
  }

  static createEvent(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const request = new Request(`${apiUrl}/content/event`, {
      method: 'POST',
      headers: getHeaders({}, true),
      body: formData,
    });

    return fetchRequest(request);
  }

  static toggleCommentLike({ commentId, newLikeState }) {
    const pathPart = newLikeState ? 'like' : 'unlike';
    const request = new Request(`${apiUrl}/content/comment/${commentId}/${pathPart}`, {
      method: 'POST',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }

  static getUsersWhoLikedPost(postContainerId, limit = 15, page = 1) {
    const request = new Request(
      `${apiUrl}/content/post/${postContainerId}/like?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }

  static getVideoStreamURL(videoId) {
    const request = new Request(
      `${apiUrl}/content/video/${videoId}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );

    return fetchRequest(request);
  }
}


