import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import configureStore from './configureStore';
import GlobalAlerts from './containers/global/GlobalAlerts';
import GlobalPreloaders from './containers/global/GlobalPreloaders';

import routes from './routes';

import { logAppSession } from './actionCreators/auth';
import { fetchFeatureFlagsRequest } from './actionCreators/featureFlags';
import { getOrgThemeRequest } from './actionCreators/orgTheme';
import { getCustomAppRequest } from './actionCreators/customApp';
import { APP_PRIMARY_COLOR, APP_PRIMARY_FONT } from './theme/constants';

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
const host = window.location.host;

store.dispatch(logAppSession());
store.dispatch(fetchFeatureFlagsRequest());
store.dispatch(getOrgThemeRequest());
if (
  host !== 'app-dev.engage4.com' &&
  host !== 'app-uat.engage4.com' &&
  host !== 'app.engage4.com'
) {
  store.dispatch(
    getCustomAppRequest({
      web_url: window.location.host,
    }),
  );
}
document.body.style.setProperty(
  '--primary-color',
  localStorage.getItem('primary-color') || APP_PRIMARY_COLOR,
);
document.body.style.setProperty(
  '--primary-font',
  localStorage.getItem('primary-font') || APP_PRIMARY_FONT,
);

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className={`wrapper`}>{routes}</div>
      <GlobalAlerts />
      <GlobalPreloaders />
    </ConnectedRouter>
  </Provider>
);
