import {
  REMOVE_GROUP_MEMBER_REQUEST,
  REMOVE_GROUP_MEMBER_SUCCESS,
  REMOVE_GROUP_MEMBER_FAIL,
  TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST,
  TOGGLE_GROUP_MEMBER_PERMISSON_SUCCESS,
  TOGGLE_GROUP_MEMBER_PERMISSON_FAIL
} from '../constants/actionTypes';

export const removeGroupMemberRequest = ({ userId, groupId }) => ({
  type: REMOVE_GROUP_MEMBER_REQUEST,
  payload: { userId, groupId },
});

export const removeGroupMemberSuccess = () => ({
  type: REMOVE_GROUP_MEMBER_SUCCESS,
  payload: { success: true },
});

export const removeGroupMemberFail = error => ({
  type: REMOVE_GROUP_MEMBER_FAIL,
  payload: error,
});

export const toggleGroupMemberPermissonRequest = ({ userId, groupId }) => ({
  type: TOGGLE_GROUP_MEMBER_PERMISSON_REQUEST,
  payload: { userId, groupId },
})

export const toggleGroupMemberPermissonSuccess = ({ }) => ({
  type: TOGGLE_GROUP_MEMBER_PERMISSON_SUCCESS,
  payload: { success: true }
})

export const toggleGroupMemberPermissonFail = ({ }) => ({
  type: TOGGLE_GROUP_MEMBER_PERMISSON_FAIL,
  payload: { success: false }
})