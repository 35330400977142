import React from 'react';
import { Radio } from 'antd';

const LearningRadio = ({ value, questions, step, handleChange, handleRadio }) => {
  return (
    <Radio.Group value={value}>
      {questions && questions[step].answers !== null
        ? questions[step].answers.map((question, index) => {
            return (
              <li
                onClick={() => {
                  handleRadio(index);
                }}
                key={index}
                className="list-item cursor-pointer"
              >
                <Radio.Button
                  onChange={() => {
                    handleRadio(index);
                  }}
                  value={index}
                >
                  <div className="radio-inner" />
                </Radio.Button>
                <p className="mb-0">{question.title}</p>
              </li>
            );
          })
        : null}
    </Radio.Group>
  );
};

export default LearningRadio;
