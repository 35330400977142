import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import { logFirebaseEvent } from '../../firebase/analytics';

import './styles.scss';

function UpdateChallenge({ value, updateValue, hide, id, total, handleTotal, isTotalChoose }) {
  const [sliderValue, updateSliderValue] = useState(value ? value : 0);
  const [sliderValueChanged, updateSliderValueChanged] = useState(false);

  function formMarks() {
    let marks = {};
    for (let x = 0; x <= total; x++) {
      marks[x] = x;
    }
    return marks;
  }

  function handleSubmit() {
    if (sliderValue === total) {
      handleTotal(!isTotalChoose);
      hide();
      return;
    }
    updateValue({ id, count: sliderValue });
    logFirebaseEvent({
      event:'challenges',
      action:'update_score'
    });
    hide();
  }

  function handleOnChange(newValue) {
    updateSliderValue(newValue);
    if (!sliderValueChanged) updateSliderValueChanged(true);
  }

  return (
    <div className="modal-content update-challenge">
      <div className="modal-header bg-white">
        <h5 className="modal-title">Challenge update</h5>
        <button type="button" className="close" onClick={hide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>How many times you have completed the task in this challenge:</p>
        <Slider
          step={1}
          min={0}
          max={total}
          marks={formMarks()}
          tooltipVisible={sliderValue}
          defaultValue={sliderValue}
          onChange={handleOnChange}
        />
      </div>
      <div className="modal-footer">
        <button
          type="button"
          disabled={!sliderValueChanged}
          className={`btn update-challenge__button ${!sliderValueChanged ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          UPDATE
        </button>
      </div>
    </div>
  );
}

UpdateChallenge.propTypes = {
  value: PropTypes.number.isRequired,
  updateValue: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

export default UpdateChallenge;
