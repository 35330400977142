import { put, takeLatest, call, } from 'redux-saga/effects';
import { getTasksRequest, getTasksSuccess, updateTaskSuccess } from '../actionCreators/employeeOnboarding';
import { EmployeeOnboardingApi } from '../api';
import {
  GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
  UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST,
} from '../constants/actionTypes';
import errorHandler from '../utils/requestErrorHandler';

function * getOnboardingTasks () {
  try {
    const result = yield call(EmployeeOnboardingApi.getTasks);
    yield put(getTasksSuccess(result.onboarding_tasks));
  } catch (error) {
    yield errorHandler(error);
  }
}

function * updateOnboardingTasks (action) {
  try {
    const data = yield call(EmployeeOnboardingApi.updateTasks, action.payload);
    yield put(updateTaskSuccess(data));
    yield put(getTasksRequest())
  } catch (error) {
    yield errorHandler(error);
  }
}

export default function * watchEmployeeOnboardingRoot () {
  yield takeLatest(GET_EMPLOYEE_ONBOARDING_TASKS_REQUEST, getOnboardingTasks);
  yield takeLatest(UPDATE_EMPLOYEE_ONBOARDING_TASKS_REQUEST, updateOnboardingTasks);
}
