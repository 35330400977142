export const CHALLENGE = {
  GET_CHALLENGES_REQUEST: 'GET_CHALLENGES_REQUEST',
  GET_CHALLENGES_SUCCESS: 'GET_CHALLENGES_SUCCESS',
  GET_CHALLENGE_REQUEST: 'GET_CHALLENGE_REQUEST',
  GET_CHALLENGE_SUCCESS: 'GET_CHALLENGE_SUCCESS',
  UPDATE_CHALLENGE_REQUEST: 'UPDATE_CHALLENGE_REQUEST',
  UPDATE_CHALLENGE_SUCCESS: 'UPDATE_CHALLENGE_SUCCESS',
  UPDATE_CHALLENGE_JOINED_REQUEST: 'UPDATE_CHALLENGE_JOINED_REQUEST',
  UPDATE_CHALLENGE_JOINED_SUCCESS: 'UPDATE_CHALLENGE_JOINED_SUCCESS',
  CLEAR_SINGLE_CHALLENGE: 'CLEAR_SINGLE_CHALLENGE',
};

const getChallengesRequest = payload => ({
  type: CHALLENGE.GET_CHALLENGES_REQUEST,
  payload,
});

const getChallengesSuccess = payload => ({
  type: CHALLENGE.GET_CHALLENGES_SUCCESS,
  payload,
});

const getChallengeRequest = payload => ({
  type: CHALLENGE.GET_CHALLENGE_REQUEST,
  payload,
});

const getChallengeSuccess = payload => ({
  type: CHALLENGE.GET_CHALLENGE_SUCCESS,
  payload,
});

const updateChallengeRequest = payload => ({
  type: CHALLENGE.UPDATE_CHALLENGE_REQUEST,
  payload,
});

const updateChallengeSuccess = payload => ({
  type: CHALLENGE.UPDATE_CHALLENGE_SUCCESS,
  payload,
});

const updateChallengeJoinedRequest = payload => ({
  type: CHALLENGE.UPDATE_CHALLENGE_JOINED_REQUEST,
  payload,
});

const clearSingleChallenge = () => ({
  type: CHALLENGE.CLEAR_SINGLE_CHALLENGE,
});

export {
  clearSingleChallenge,
  getChallengesRequest,
  getChallengesSuccess,
  getChallengeRequest,
  getChallengeSuccess,
  updateChallengeRequest,
  updateChallengeSuccess,
  updateChallengeJoinedRequest,
};
