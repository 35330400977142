import React from 'react';

const TYPES = {
  PRIMARY: 'primary',
  PRIMARY_VIOLET: 'primary-violet',
  PRIMARY_OUTLINE: 'primary-outline',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINE: 'secondary-outline',
  VIOLET_OUTLINE: 'violet-outline',
  WHITE: 'white',
  WHITE_OUTLINE: 'white-outline',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const BaseButton = props => {
  const { children, onClick, buttonType, buttonSize, className = '', disabled, ...params } = props;

  const classNames = [
    'button',
    buttonType,
    !buttonSize ? SIZES.medium : SIZES[buttonSize],
    disabled ? 'disabled' : '',
    className,
  ];

  return (
    <button
      {...params}
      onClick={e => {
        e.preventDefault();
        if (!(typeof onClick === 'function')) return false;

        if (!disabled) {
          onClick();
        }
      }}
      className={classNames.join(' ')}
    >
      {children}
    </button>
  );
};

const Primary = props => {
  return <BaseButton {...props} buttonType={TYPES.PRIMARY} />;
};

const PrimaryViolet = props => {
  return <BaseButton {...props} buttonType={TYPES.PRIMARY_VIOLET} />;
};

const PrimaryOutline = props => <BaseButton {...props} buttonType={TYPES.PRIMARY_OUTLINE} />;

const Secondary = props => <BaseButton {...props} buttonType={TYPES.SECONDARY} />;

const SecondaryOutline = props => <BaseButton {...props} buttonType={TYPES.SECONDARY_OUTLINE} />;

const VioletOutline = props => <BaseButton {...props} buttonType={TYPES.VIOLET_OUTLINE} />;

const White = props => <BaseButton {...props} buttonType={TYPES.WHITE} />;

const WhiteOutline = props => <BaseButton {...props} buttonType={TYPES.WHITE_OUTLINE} />;

export default {
  Primary,
  PrimaryOutline,
  Secondary,
  PrimaryViolet,
  SecondaryOutline,
  VioletOutline,
  White,
  WhiteOutline,
};
