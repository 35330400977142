import React, { Component } from 'react';
import { Avatar, AutoComplete } from 'antd';

import TextField from '../../global/fields/TextField';
import ImageApi from '../../../api/image';

class SearchGroup extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.isActive !== nextProps.isActive && !nextProps.isActive) {
      this.props.onChange({ target: { value: '' } });
    }
  }

  render() {
    // If modal is active change 'Write a new post' to 'Posting In' for more clarity
    const label = this.props.isActive ? 'Posting in' : 'Write a new post' 
    return (
      <TextField
        fk="group"
        value={this.props.value}
        onFocus={this.props.onFocus}
        label={label}
        updateFieldData={(fk, value) => this.props.onChange({ target: { value } })}
        triggerOnChange
        ignoreBlur
        showError={this.props.showError}
        validateRule="alwaysRequired"
        validateObj={{ alwaysRequired: this.props.value }}
      />
    );
  }
}

class AutoCompleteOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  componentDidMount() {
    if (this.props.item.image && this.props.item.image.thumbnailUrl) {
      ImageApi.getImage(this.props.item.image.thumbnailUrl).then(imageUrl => {
        this.setState({ imageUrl });
      });
    }
  }

  render() {
    return (
      <div className="create-feed-group-option">
        <Avatar
          className="create-feed-group-option-image"
          src={this.state.imageUrl}
          size={36}
          shape="square"
        />
        {this.props.item.name}
      </div>
    );
  }
}

class CreateFeedItemSearchGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      dataSource: [],
    };
  }

  _onChange = newValue => {
    this.setState({ value: newValue });
  };

  _onSearch = searchValue => {
    if (!searchValue) return this.setState({ dataSource: [] });

    const dataSource = [];
    this.props.groups.forEach(group => {
      if (group.name.toLowerCase().includes(searchValue.toLowerCase()) && (group.is_admin || (this.props.activeTab==='Notice' && this.props.featureFlags.POST))) dataSource.push(group);
    });

    this.setState({ dataSource: dataSource });
  };

  render() {
    return (
      <div>
        <AutoComplete
          className="create-feed-group-row"
          value={this.state.value}
          onChange={this._onChange}
          dataSource={this.state.dataSource.map(this.renderOption)}
          onSelect={this.props.onSelect}
          onSearch={this._onSearch}
          onFocus={this.props.onFocus}
          dropdownClassName="create-feed-group-row-dropdown"
          getPopupContainer={() => document.querySelector('.autocomplete-target')}
        >
          <SearchGroup isActive={this.props.isActive} showError={this.props.showError} />
        </AutoComplete>
        <div className="autocomplete-target" />
      </div>
    );
  }

  renderOption(item) {
    return (
      <AutoComplete.Option key={item.id} text={item.name}>
        <AutoCompleteOption item={item} />
      </AutoComplete.Option>
    );
  }
}

export default CreateFeedItemSearchGroup;
