import update from 'immutability-helper';
import { CATEGORY } from '../actionCreators/category';

const initialState = [];

const categories = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY.GET_CATEGORIES_SUCCESS:
      return update(state, { $set: action.data.categories });
    default:
      return state;
  }
};

export default categories;
