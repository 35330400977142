import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckIcon from '../Icons/CheckIcon';
import './style.scss';

function Controlled({ checked, clsNames, onChange }) {
  const { container, checkbox, checkmark } = clsNames;
  const containerCls = classNames({ 'engage-checkbox-container': true, checked }, container);

  return (
    <span className={containerCls}>
      <input
        className={`engage-checkbox ${checkbox}`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {checked && <CheckIcon className={`engage-checkmark ${checkmark}`} />}
    </span>
  );
}

function Stateful({ defaultChecked, onChange, ...otherProps }) {
  const [checked, setChecked] = useState(defaultChecked);

  const handleOnChange = () => {
    setChecked(state => !state);
    onChange && onChange(!checked);
  };

  return <Controlled {...otherProps} checked={checked} onChange={handleOnChange} />;
}

export default class Checkbox extends PureComponent {
  static Controlled = Controlled;
  render() {
    return <Stateful {...this.props} />;
  }
}

const defaultProps = {
  clsNames: {},
  checked: false,
};

const propTypes = {
  clsNames: PropTypes.shape({
    container: PropTypes.string,
    checkbox: PropTypes.string,
    checkmark: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Controlled.defaultProps = defaultProps;
Controlled.propTypes = propTypes;

Checkbox.defaultProps = defaultProps;
Checkbox.propTypes = {
  ...propTypes,
  onChange: PropTypes.func,
};
