import React from 'react';

export default function EmployeeOnoardingIcon ({
  fill = 'none',
  stroke = '#A7ACB0',
  className = '',
}) {
  return (
    <svg
      width='142px'
      height='142px'
      viewBox='0 0 142 142'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>deal</title>
      <defs>
        <linearGradient x1='0%' y1='50%' x2='100%' y2='50%' id='linearGradient-1'>
          <stop stop-color='#3E88C5' offset='0%'></stop>
          <stop stop-color='#68D1C5' offset='100%'></stop>
        </linearGradient>
      </defs>
      <g id='Screens' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='deal' fill-rule='nonzero'>
          <circle id='Oval' fill='url(#linearGradient-1)' cx='71' cy='71' r='71'></circle>
          <path
            d='M23.1864695,46.0008308 L46.9083988,57.7450238 C46.9083988,57.7450238 64.4108947,52.1027929 67.1351116,52.9848675 C67.3998038,53.070444 67.846889,53.2471358 68.4332713,53.4925104 L68.4354956,53.4938952 C73.8725299,55.7748265 91.4767877,64.1499654 91.4767877,64.1499654 L86.4590348,63.830923 C88.5003902,66.4425285 91.3433294,69.860879 92.9584525,70.8576094 C95.6779428,72.5347974 89.2016022,77.3082472 83.7634558,73.8242603 C78.3241972,70.3411042 76.8992522,66.0841597 76.8992522,66.0841597 L74.209512,66.3608292 L93.0735603,83.6157036 L96.0702545,87.1935753 C98.6629594,90.2890063 95.4435567,94.7600304 91.2093151,93.3971767 C91.2768783,93.4481349 91.0372095,93.3783444 91.1006022,93.5497743 C91.3427733,94.1992138 91.8321203,95.7586995 91.3908739,96.5194715 C89.8405337,99.1964363 87.5058481,101.256364 82.5603851,97.5045702 C83.7587292,98.4135086 84.4930277,100.552643 83.6616939,101.79641 C82.530635,103.489107 81.2836343,104.252648 79.9195796,104.666683 C78.4821229,105.103151 76.3145381,103.734759 75.1178623,102.826651 C76.3153723,103.734759 76.8859064,105.889125 76.0876591,107.15754 C75.1053506,108.718688 71.9846514,111.012912 70.0061326,109.511032 L63.7897576,104.795187 C63.7897576,104.795187 48.6578143,90.2003834 43.9823262,86.3162088 C39.305448,82.4303726 32.949498,74.524658 32.949498,74.524658 L5,59.0765826 L23.1878597,46 L23.1864695,46.0008308 Z'
            id='Path'
            fill='#FED298'
          ></path>
          <path
            d='M0.000554927958,65.3147539 L20.4194066,79 L42,50.5866421 L16.0252096,26 C7.06145827,36.8638328 1.25164001,50.4377805 0,65.3147539 L0.000554927958,65.3147539 Z'
            id='Path'
            fill='#0F7986'
          ></path>
          <polygon
            id='Path'
            fill='#FFFFFF'
            points='41.4610973 51 23 75.2970073 26.5389027 78 45 53.7021457'
          ></polygon>
          <path
            d='M117.846221,46.0022216 L94.1691038,57.7785578 C92.2764663,58.7196983 85.476292,54.2611529 79.8816334,53.58161 C75.8307786,53.0897954 70.9368399,54.6618804 71.2282284,54.8132294 L83.9113971,60.6800142 C83.9113971,60.6800142 90.0941055,69.2463643 92.8087359,70.9267541 C95.5230889,72.607977 89.0589826,77.3950468 83.6311092,73.9015245 C78.2021257,70.4088352 76.7798725,66.1402397 76.7798725,66.1402397 L65.5583638,67.2977119 L43.9240187,86.4290512 C40.9293778,89.0775189 41.8451701,90.4596541 43.8590807,93.0428611 C44.7798683,94.2258822 48.1641372,93.8273763 49.5827828,93.2847417 C46.3064663,95.7788057 51.0305672,102.20211 55.6622563,98.6691539 C52.8613193,100.804979 56.878595,108.335214 62.4879618,104.058009 C59.6898,107.832569 65.3929661,110.577955 67.7909545,109.895635 L69.5279073,109.401599 L69.0844418,109.063633 C68.9409676,109.063633 64.3980826,104.401807 64.1230674,103.836401 C62.5689956,100.644466 66.6642524,96.3219956 70.3357471,99.4125688 L73.4957857,101.840539 L71.6650335,99.5175411 C69.3297631,96.4552938 71.1272137,93.2933506 73.3639671,92.3347147 C75.0032354,91.6332332 76.6999488,92.412472 78.0830727,93.7776672 L81.5733516,96.5760952 L78.5998016,93.0992351 C78.5998016,93.0992351 75.7478023,89.8753638 78.3655808,86.9964015 C80.9833593,84.1182723 84.5308058,87.5690281 84.5308058,87.5690281 L88.7062638,91.0233942 L85.1124727,86.8581047 C85.1124727,86.8581047 81.9998888,83.5836911 84.0948331,81.3928805 C87.0048326,78.3495171 90.6052841,81.3467816 93.447848,84.3443237 C94.2645682,85.2060407 95.1059871,86.5295803 95.7445441,87.1405301 C98.8818267,83.6070183 100.992867,79.7769181 100.992867,79.7769181 L136,59.1123724 L117.847053,46 L117.846221,46.0022216 Z'
            id='Path'
            fill='#F0BA7D'
          ></path>
          <path
            d='M141.999168,65.313991 L121.579484,79 L100,50.5867913 L125.97479,26 C134.939097,36.862941 140.747528,50.4370958 142,65.313991 L141.999168,65.313991 Z'
            id='Path'
            fill='#FAD24D'
          ></path>
          <polygon
            id='Path'
            fill='#FFFFFF'
            points='100.537495 51 119 75.2970073 115.460252 78 97 53.7021457'
          ></polygon>
          <path
            d='M18.7938931,71.4119933 C19.4596972,71.4119933 20,71.9916713 20,72.7059967 C20,73.420322 19.4604735,74 18.7938931,74 C18.128089,74 17.5877863,73.4211549 17.5877863,72.7059967 C17.5877863,71.9916713 18.1273127,71.4119933 18.7938931,71.4119933 Z M15.2061069,69 C15.871911,69 16.4122137,69.5788451 16.4122137,70.2940033 C16.4122137,71.0083287 15.871911,71.5880067 15.2061069,71.5880067 C14.5403028,71.5880067 14,71.0083287 14,70.2940033 C14,69.579678 14.5395265,69 15.2061069,69 Z'
            id='Shape'
            fill='#ECF0F1'
          ></path>
          <path
            d='M124.206107,71.4118496 C123.540303,71.4118496 123,71.9915598 123,72.7059248 C123,73.4202899 123.539526,74 124.206107,74 C124.871911,74 125.412214,73.4211228 125.412214,72.7059248 C125.412214,71.9915598 124.872687,71.4118496 124.206107,71.4118496 Z M127.793893,69 C127.128089,69 126.587786,69.5788772 126.587786,70.2940752 C126.587786,71.0084402 127.128089,71.5881504 127.793893,71.5881504 C128.459697,71.5881504 129,71.0084402 129,70.2940752 C129,69.5797101 128.460474,69 127.793893,69 Z'
            id='Shape'
            fill='#FFFFFF'
          ></path>
        </g>
      </g>
    </svg>
  );
}
