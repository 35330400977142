import {
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
} from '../constants/actionTypes';

export const getDepartmentsStart = () => ({
  type: GET_DEPARTMENTS_START,
});

export const getDepartmentsSuccess = data => ({
  type: GET_DEPARTMENTS_SUCCESS,
  payload: data,
});

export const getDepartmentsFail = payload => ({
  type: GET_DEPARTMENTS_FAIL,
  payload,
});
