import React from 'react';

export default function FeedIcon({ fill = 'none', stroke = '#505A62', className = '' }) {
  return (
    <svg
      className={className}
      width="30px"
      height="30px"
      viewBox="0 0 25 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="-🎯-7-Activity"
        stroke="none"
        strokeWidth="1"
        fill={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="7-Activity"
          transform="translate(-231.000000, -36.000000)"
          stroke={stroke}
          strokeWidth="2"
        >
          <g id="Header">
            <g id="Nav" transform="translate(231.000000, 28.000000)">
              <g id="Group-2" transform="translate(0.000000, 5.000000)">
                <g id="Icons-/-Feeds" transform="translate(0.000000, 4.000000)">
                  <g id="feed" transform="translate(1.785714, -0.000000)">
                    <path
                      d="M9.22167471,7.15270951 C9.22167471,7.86798046 8.5828964,8.45320214 7.80295553,8.45320214 L1.41871919,8.45320214 C0.638778314,8.45320214 -9.51619735e-15,7.86798046 -9.51619735e-15,7.15270951 L-9.51619735e-15,1.30049264 C-9.51619735e-15,0.585221687 0.638778314,1.58603287e-15 1.41871919,1.58603287e-15 L7.80295553,1.58603287e-15 C8.5828964,1.58603287e-15 9.22167471,0.585221687 9.22167471,1.30049264 L9.22167471,7.15270951 Z"
                      id="Stroke-1"
                    ></path>
                    <path
                      d="M21.4285714,7.15270951 C21.4285714,7.86798046 20.7897931,8.45320214 20.0098522,8.45320214 L13.6256159,8.45320214 C12.845675,8.45320214 12.2068967,7.86798046 12.2068967,7.15270951 L12.2068967,1.30049264 C12.2068967,0.585221687 12.845675,1.58603287e-15 13.6256159,1.58603287e-15 L20.0098522,1.58603287e-15 C20.7897931,1.58603287e-15 21.4285714,0.585221687 21.4285714,1.30049264 L21.4285714,7.15270951 Z"
                      id="Stroke-5"
                    ></path>
                    <path
                      d="M9.22167471,18.7887928 C9.22167471,19.5040638 8.5828964,20.0892855 7.80295553,20.0892855 L1.41871919,20.0892855 C0.638778314,20.0892855 -9.51619735e-15,19.5040638 -9.51619735e-15,18.7887928 L-9.51619735e-15,12.936576 C-9.51619735e-15,12.221305 0.638778314,11.6360833 1.41871919,11.6360833 L7.80295553,11.6360833 C8.5828964,11.6360833 9.22167471,12.221305 9.22167471,12.936576 L9.22167471,18.7887928 Z"
                      id="Stroke-9"
                    ></path>
                    <path
                      d="M21.4285714,18.7887928 C21.4285714,19.5040638 20.7897931,20.0892855 20.0098522,20.0892855 L13.6256159,20.0892855 C12.845675,20.0892855 12.2068967,19.5040638 12.2068967,18.7887928 L12.2068967,12.936576 C12.2068967,12.221305 12.845675,11.6360833 13.6256159,11.6360833 L20.0098522,11.6360833 C20.7897931,11.6360833 21.4285714,12.221305 21.4285714,12.936576 L21.4285714,18.7887928 Z"
                      id="Stroke-13"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
