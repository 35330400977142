import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ManageComponent from '../../components/ManageGroups/ManageGroup'
import { getCategoriesRequest } from '../../actionCreators/category';
import { getGroupPrivacyTypesRequest } from '../../actionCreators/groupActions';
import { searchPeopleRequest, searchGroupsRequest } from '../../actionCreators/search';
import { createGroupRequest, addGroupMemberRequest } from '../../actionCreators/groupActions';
import { getUserSuccess, sendUserPulseRequest } from '../../actionCreators/userProfile';
import { getGroupRequest } from '../../actionCreators/groupActions';
import { getUsersRequest } from '../../actionCreators/users';

const ManageGroup = props => {
    useEffect(() => {
        const groupId = props.match.params.groupId || '';
        props.getGroup(groupId);
        props.getCategoriesRequest()
        props.getGroupPrivacyTypesRequest()
    }, [])

    return (<ManageComponent {...props} />)
};

const mapStateToProps = (state, ownProps) => {
    const groupId = ownProps.match.params.groupId || '';
    const group = state.group.groupsDetailed[groupId] || {};
    const editedGroup = state.group.editedGroup
    const categories = state.categories
    const groupPrivacyTypes = state.group.groupPrivacyTypes

    return {
        currentUser: state.currentUser,
        group,
        editedGroup,
        categories,
        groupPrivacyTypes,
        groupsDetailedUpdatedAt: state.group.groupsDetailedUpdatedAt,
        groupId: ownProps.match.params.groupId,
        searchResults: state.searchResults,

    }
};

const mapDispatchToProps = dispatch => ({
    getCategoriesRequest: payload => dispatch(getCategoriesRequest(payload)),
    searchPeopleRequest: payload => dispatch(searchPeopleRequest(payload)),
    searchGroupsRequest: (value, token) => dispatch(searchGroupsRequest(value, token)),
    createGroupRequest: payload => dispatch(createGroupRequest(payload)),
    sendUserPulse: payload => dispatch(sendUserPulseRequest(payload)),
    getGroupPrivacyTypesRequest: payload => dispatch(getGroupPrivacyTypesRequest(payload)),
    getGroup: payload => dispatch(getGroupRequest(payload)),
    addMembersToGroup: payload => dispatch(addGroupMemberRequest(payload)),
    getGroupUsers: payload => dispatch(getUsersRequest(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageGroup);
