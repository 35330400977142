import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

export default class KnowledgebaseApi {
  static getknowledgebaseCategories () {
    let url = `${apiUrl}/content/knowledgebase_categories`;
    const request = new Request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }
  static getknowledgebaseItems ({ page = 1, limit = 10, type = '', category = '', search = '' }) {
    let url = `${apiUrl}/content/knowledgebase_items?limit=${limit}&page=${page}&type=${type}&category=${category}&search=${search}`;
    const request = new Request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }
  static getAmazonS3SignedFileUrl (fileUri) {
    let url = fileUri;
    const request = new Request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
    return fetchRequest(request);
  }
  static updateKnowledgebaseViews(action){
    let url = `${apiUrl}/content/knowledgebase_item_views/${action.payload}`;
    const request = new Request(url, {
       method: "POST",
       headers: getHeaders()
    });
    return fetchRequest(request)
  }
}
