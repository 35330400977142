import React from 'react';
import { connect } from 'react-redux';

import UserProfileComponent from '../../components/OnBoarding/UserProfile';
import { createUserProfileRequest, postUserAvatarRequest } from '../../actionCreators/userProfile';
import { getDepartmentsStart } from '../../actionCreators/departmentActions';
import { getUserTagsStart } from '../../actionCreators/userTags';
import { getTasksRequest } from '../../actionCreators/employeeOnboarding';
import { fetchFeatureFlagsRequest } from '../../actionCreators/featureFlags';

const UserProfile = props => <UserProfileComponent {...props} />;

const mapStateToProps = state => {
  const featureFlags = state.featureFlags;
  const taskCount = state.employeeOnboard.tasks.length;
  const inProgressTaskCount = state.employeeOnboard.tasks.filter(item => !item.is_completed).length;
  const showEmployeeOnboard = featureFlags.EMPLOYEE_ONBOARDING && taskCount && inProgressTaskCount;
  return {
    token: state.currentUser.signInData,
    userData: state.currentUser.userData,
    userTags: state.userTags.data || [],
    departments: state.departments.data || [],
    showEmployeeOnboard,
    customAppId: state.customApp.id
  };
};

const mapDispatchToProps = dispatch => ({
  createUserProfile: payload => dispatch(createUserProfileRequest(payload)),
  postUserAvatar: payload => dispatch(postUserAvatarRequest(payload)),
  getDepartments: () => dispatch(getDepartmentsStart()),
  getUserTags: () => dispatch(getUserTagsStart()),
  getEmployeeOnboardingTasks: () => dispatch(getTasksRequest()),
  fetchFeatureFlags: () => dispatch(fetchFeatureFlagsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
