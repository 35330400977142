import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  GET_KNOWLEDGEBASE_CATEGORIES_REQUEST,
  GET_KNOWLEDGEBASE_CATEGORIES_SUCCESS,
  GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST,
  GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS,
  GET_KNOWLEDGEBASE_ITEMS_SUCCESS,
  SET_KNOWLEDGEBASE_LOADING,
  SET_KNOWLEDGEBASE_FILTERS,
  UPDATE_KNOWLEDEBASE_VIEWS_REQUEST,
  UPDATE_KNOWLEDEBASE_VIEWS_SUCCESS,
} from '../constants/actionTypes';
import KnowledgebaseApi from '../api/knowledgebase';
import { getKnowledgebasePagination } from '../utils/selectors';
import errorHandler from '../utils/requestErrorHandler';

function * getKnowledgebaseCategoriesRequest (action) {
  try {
    const data = yield call(KnowledgebaseApi.getknowledgebaseCategories);
    yield put({
      type: GET_KNOWLEDGEBASE_CATEGORIES_SUCCESS,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    yield errorHandler(error);
  }
}

function * setKnowledgebaseCategories (action) {
  const getKnowledgebaseTypes = () => {
    return {
      id: 'types',
      label: 'Types',
      filters: [
        {
          id: 'FILE',
          name: 'File',
          isChecked: false,
        },
        {
          id: 'DETAILS',
          name: 'Details',
          isChecked: false,
        },
        {
          id: 'INFO',
          name: 'Info',
          isChecked: false,
        },
      ],
    };
  };
  const knowledgebaseTypes = getKnowledgebaseTypes();
  let filterGroups = [];
  filterGroups.push(knowledgebaseTypes);
  filterGroups.push({
    id: 'categories',
    label: 'Categories',
    filters: action.payload.categories.map(category => ({ ...category, isChecked: false })),
  });
  yield put({ type: SET_KNOWLEDGEBASE_FILTERS, payload: { filters: filterGroups } });
}

const getKowledgebaseFilterIds = knowledgebaseFilters => {
  const typeFilters = knowledgebaseFilters[0]
    ? knowledgebaseFilters[0].filters.filter(item => item.isChecked)
    : [];
  const categoryFilters = knowledgebaseFilters[1]
    ? knowledgebaseFilters[1].filters.filter(item => item.isChecked)
    : [];
  const typeFilterIds = typeFilters.map(item => item.id);
  const categoryFilterIds = categoryFilters.map(item => item.id);
  return {
    type: typeFilterIds && typeFilterIds.length > 0 ? typeFilterIds.join(',') : '',
    category: categoryFilterIds && categoryFilterIds.length > 0 ? categoryFilterIds.join(',') : '',
  };
};

function * getKnowledgebaseItems (action) {
  try {
    yield put({ type: SET_KNOWLEDGEBASE_LOADING, payload: true });
    const { type, category } = getKowledgebaseFilterIds(action.payload.filters);
    const data = yield call(KnowledgebaseApi.getknowledgebaseItems, {
      page: 1,
      limit: 10,
      type,
      category,
      search: action.payload.search,
    });
    yield put({
      type: GET_KNOWLEDGEBASE_ITEMS_SUCCESS,
      payload: {
        items: data.knowledgebase_items,
        currentPage: 1,
        totalPages: data.pages,
      },
    });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_KNOWLEDGEBASE_LOADING, payload: false });
  }
}

function * getMoreKnowledgebaseItems (action) {
  try {
    yield put({ type: SET_KNOWLEDGEBASE_LOADING, payload: true });
    const { currentPage, totalPages } = yield select(getKnowledgebasePagination);
    // last page
    if (currentPage >= totalPages) return;
    const { type, category } = getKowledgebaseFilterIds(action.payload.filters);
    const data = yield call(KnowledgebaseApi.getknowledgebaseItems, {
      page: currentPage + 1,
      limit: 10,
      type,
      category,
      search: action.payload.search,
    });
    yield put({
      type: GET_MORE_KNOWLEDGEBASE_ITEMS_SUCCESS,
      payload: {
        items: data.knowledgebase_items,
        currentPage: currentPage + 1,
        totalPages: data.pages,
      },
    });
  } catch (error) {
    yield errorHandler(error);
  } finally {
    yield put({ type: SET_KNOWLEDGEBASE_LOADING, payload: false });
  }
}

function * updateKnowledgebaseViews (action) {
  try {
    const data = yield call(KnowledgebaseApi.updateKnowledgebaseViews, action);
    if (data && data.success) {
      yield put({ type: UPDATE_KNOWLEDEBASE_VIEWS_SUCCESS });
    }
  } catch (error) {
    yield errorHandler(error);
  }
}
export default function * watchFeed () {
  yield takeLatest(GET_KNOWLEDGEBASE_CATEGORIES_REQUEST, getKnowledgebaseCategoriesRequest);
  yield takeLatest(GET_KNOWLEDGEBASE_CATEGORIES_SUCCESS, setKnowledgebaseCategories);
  yield takeLatest(SET_KNOWLEDGEBASE_FILTERS, getKnowledgebaseItems);
  yield takeLatest(GET_MORE_KNOWLEDGEBASE_ITEMS_REQUEST, getMoreKnowledgebaseItems);
  yield takeLatest(UPDATE_KNOWLEDEBASE_VIEWS_REQUEST, updateKnowledgebaseViews);
}
