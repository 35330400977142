import React, { useState } from 'react';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from 'antd';
import { mdiChevronDown, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import { setKnowledgebaseFilters } from '../../actionCreators/knowledgebase';
import { Dropdown, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
const KnowledgebaseFilters = ({ filterGroups, setKnowledgebaseFilters, searchTxt }) => {
  const [filtersOn, setFiltersOn] = useState(false);
  const toggleFilters = () => {
    setFiltersOn(!filtersOn);
  };
  const setFilter = (groupId, filterId) => {
    const tmpFilterGroups = [...filterGroups];
    const filterGroupIndex = tmpFilterGroups.findIndex(filterGroup => filterGroup.id === groupId);
    const filterIdIndex = tmpFilterGroups[filterGroupIndex].filters.findIndex(
      filter => filter.id === filterId,
    );
    const isChecked = tmpFilterGroups[filterGroupIndex].filters[filterIdIndex].isChecked;
    tmpFilterGroups[filterGroupIndex].filters[filterIdIndex].isChecked = !isChecked;
    setKnowledgebaseFilters(tmpFilterGroups, searchTxt);
  };
  const activeFilters = filterGroups.map(filterGroup => {
    let output = [];
    filterGroup.filters.map(filter => {
      if (filter.isChecked) {
        output.push(
          <div
            style={styles.activeFilterContainer}
            onClick={() => setFilter(filterGroup.id, filter.id)}
          >
            <div style={styles.activeFilter}>{`${filterGroup.label}: ${filter.name}`}</div>
            <div style={{ cursor: 'pointer' }}>
              <Icon size={1} path={mdiCloseCircle} />
            </div>
          </div>,
        );
      }
    });
    return output;
  });

  const renderFilterSection = () => {
    let filterCount = 0;
    for (const filterGroup of filterGroups) {
      const filters = filterGroup.filters.filter(item => item.isChecked);
      if (filters.length > 0) {
        filterCount = filterCount + filters.length;
      }
    }
    return (
      <div>
        <Dropdown isOpen={filtersOn} toggle={toggleFilters} direction='right'>
          <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={filtersOn}>
            <div style={styles.filtersButtonContainer} onClick={toggleFilters}>
              <div style={styles.filtersButtonLabel}>Filters</div>
              {filterCount > 0 && <div style={styles.filterCount}>{filterCount}</div>}
              <Icon path={mdiChevronDown} size={1} vertical={filtersOn} color='var(--primary-color)' />
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <div style={styles.filterGroupsContainer}>
              {filterGroups.map(filterGroup => (
                <div style={styles.filterGroupContainer}>
                  <div style={styles.filterGroupLabel}>{filterGroup.label}</div>
                  {filterGroup.filters.map(filter => (
                    <div style={styles.singleFilterContainer}>
                      <div>
                        {filter.name}
                        <Checkbox
                          style={{ right: 30 }}
                          checked={filter.isChecked}
                          onChange={() => setFilter(filterGroup.id, filter.id)}
                        ></Checkbox>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  };
  let isActiveFilter = false;
  for (const filterGroup of filterGroups) {
    const filters = filterGroup.filters.filter(item => item.isChecked);
    if (filters.length > 0) {
      isActiveFilter = true;
      break;
    }
  }
  return (
    <div>
      <div style={styles.filtersSectionContainer}> {renderFilterSection()}</div>
      {isActiveFilter && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={styles.activeFiltersSectionLabel}>Active Filters</div>
          <div style={styles.activeFiltersSectionContainer}>{activeFilters}</div>
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    filterGroups: state.knowledgebase.filters,
  }),
  dispatch => ({
    setKnowledgebaseFilters: (filters, search) =>
      dispatch(setKnowledgebaseFilters({ filters, search })),
  }),
)(KnowledgebaseFilters);
