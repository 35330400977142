import React from 'react';
import { Link } from 'react-router-dom';

export default function FeedItemFooter({
  isPostSingle,
  itemType,
  likes,
  comments,
  link,
  postContainerId,
}) {
  const to = isPostSingle && likes > 0
    ? { search: `?type=${itemType}&listType=likes&showUsers=true&id=${postContainerId}` }
    : link;
  const likesText = likes > 1 ? 'Likes' : 'Like';
  const commentsText = comments > 1 ? 'Comments' : 'Comment'   
   
  return (
    <Link to={to} className="feed-item-footer">
      {likes} {` ${likesText}`}
      <span className="feed-item-footer-splitter" />
      {comments} {` ${commentsText}`}
    </Link>
  );
}
