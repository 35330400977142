import lodash from 'lodash';

import {
  SET_GROUP_LOADING,
  VERIFY_GROUP_SUCCESS,
  GET_GROUPS_REQUEST_SUCCESS,
  GET_MY_GROUPS_REQUEST_SUCCESS,
  SET_GROUPS_FROM_FEED_REQUEST,
  GET_GROUP_REQUEST_SUCCESS,
  SHOW_CREATE_GROUP_MODAL,
  GET_GROUP_PRIVACY_TYPES_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  allGroups: [],
  myGroups: [],
  groupsByKey: {},
  groups: {},
  groupsDetailed: {},
  groupsDetailedUpdatedAt: 0,
  loading: false,
  verifyGroup: {
    recommendedGroups: [],
    createGroupRequest: {},
    errorMessage: '',
  },
  showCreateGroupModal: false,
  editedGroup: {},
  groupPrivacyTypes: []
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_GROUPS_FROM_FEED_REQUEST:
      return {
        ...state,
        groupsByKey: { ...state.groupsByKey, ...lodash.keyBy(action.payload, 'id') },
        groups: { ...state.groups, ...lodash.keyBy(action.payload, 'id') },
      };

    case GET_GROUPS_REQUEST_SUCCESS: {
      return {
        ...state,
        allGroups: action.payload.groups,
      };
    }

    case GET_MY_GROUPS_REQUEST_SUCCESS: {
      return {
        ...state,
        myGroups: action.payload.groups,
      };
    }

    case GET_GROUP_REQUEST_SUCCESS: {
      return {
        ...state,
        groupsDetailed: { ...state.groupsDetailed, ...state.editedGroup, [action.payload.id]: action.payload },
        groupsDetailedUpdatedAt: Date.now()
      };
    }

    case VERIFY_GROUP_SUCCESS: {
      return {
        ...state,
        verifyGroup: action.payload,
      };
    }

    case SHOW_CREATE_GROUP_MODAL: {
      return {
        ...state,
        showCreateGroupModal: action.payload.flag,
        editedGroup: action.payload.group
      };
    }

    case GET_GROUP_PRIVACY_TYPES_SUCCESS:
      return {
        ...state,
        groupPrivacyTypes: action.payload.groupPrivacyTypes
      };

    default:
      return state;
  }
};

export default group;
