import React, { Component } from 'react';
import TextField from '../../global/fields/TextField';
import SelectField from '../../global/fields/SelectField';
import {
  getSecurityQuestionsRequest,
  getUserSecurityRequest,
} from '../../../actionCreators/account';
import { connect } from 'react-redux';

class ChangeSecurity extends Component {
  componentDidMount () {
    this.props.getSecurityQuestions();
    this.props.getUserSecurity();
  }
  render () {
    const {
      account,
      onChangeFieldValue,
      newSecurityAnswer,
      newSecurityQuestionId,
      securityAnswer,
    } = this.props;
    const securityQuestions = account.securityQuestions.map(item => ({
      value: item.id,
      displayName: item.long_text,
    }));
    return (
      <div>
        <p className='field-container__account-helper-text'>Your current question and answer:</p>
        <p
          className='field-container__account-helper-text'
          style={{ fontWeight: 'bold', marginTop: 16 }}
        >
          {account.userSecurity.long_text}
        </p>
        <TextField
          fk='securityAnswer'
          key='securityAnswer'
          label={'Old Answer'}
          type='password'
          value={securityAnswer}
          updateFieldData={onChangeFieldValue}
          triggerOnChange={true}
        />
        <p
          className='field-container__account-helper-text'
          style={{ marginTop: 16, marginBottom: 5 }}
        >
          Your new question and answer:
        </p>
        <SelectField
          fk='newSecurityQuestionId'
          key='newSecurityQuestionId'
          placeholder={'Choose security question'}
          options={securityQuestions}
          value={newSecurityQuestionId}
          updateFieldData={onChangeFieldValue}
        />
        <TextField
          fk='newSecurityAnswer'
          key='newSecurityAnswer'
          type='password'
          label={'Answer'}
          value={newSecurityAnswer}
          updateFieldData={onChangeFieldValue}
          triggerOnChange={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
});

const mapDispatchToProps = dispatch => ({
  getSecurityQuestions: () => dispatch(getSecurityQuestionsRequest()),
  getUserSecurity: () => dispatch(getUserSecurityRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSecurity);
