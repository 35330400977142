import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LearningItem from './LearningItem';
import { Spinner } from 'react-bootstrap';
import { getUserMicroLearning } from '../../../../actionCreators/userProfile';
import { logFirebaseEvent } from '../../../../firebase/analytics';

import './styles.scss';

const Learning = ({ handleLearning, learningList }) => {
  useEffect(() => {
    handleLearning();
    logFirebaseEvent({
      event: 'learning',
      action: 'load',
    });
  }, []);

  return (
    <React.Fragment>
      <div className="learning-header">
        <h2 className="learning-header--title">Micro-learning</h2>
        <p className="learning-header--subtitle">
          Complete short learning courses and earn points!
        </p>
      </div>
      {learningList ? (
        learningList
          .sort((a, b) => a.completed - b.completed)
          .map(learningItem => {
            return <LearningItem key={learningItem.id} learningItem={learningItem} />;
          })
      ) : (
        <div className="mb-4 text-center d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner animation="border" variant="dark" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(
  state => ({
    learningList: state.currentUser.learning,
  }),
  dispatch => ({
    handleLearning: () => dispatch(getUserMicroLearning()),
  })
)(Learning);
