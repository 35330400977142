import React from 'react';
import UserApi from '../../../../api/userProfile';
import ImageApi from '../../../../api/image';
import './styles.scss';
import { Avatar } from 'antd';
import UserPlaceholder from '../../../../assets/images/user_placeholder.svg';

class Participant extends React.Component {
  state = {
    userAvatar: '',
    image: '',
  };

  componentDidMount() {
    UserApi.fetchCollegueProfile({ id: this.props.participant })
      .then(user => {
        this.setState({ ...user.user });
      })
      .then(() => {
        ImageApi.getImage(this.state.image.url).then(img => {
          this.setState({
            userAvatar: img,
          });
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.userAvatar && (
          <Avatar
            className="event-bottom-cover"
            shape="square"
            src={this.state.userAvatar || UserPlaceholder}
            size={36}
            icon="user"
          />
        )}
      </React.Fragment>
    );
  }
}

export default Participant;
