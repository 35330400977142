import React, { Component } from 'react';

import update from 'immutability-helper';

import Button from '../global/Button';
import Header from '../global/Header/Header';

import DateofBirthForm from './components/ConfirmDOB';
import SetPasswordForm from './components/SetPassword';
import ChangePasswordForm from './components/ChangePassword';
import ChangeSecurityForm from './components/ChangeSecurity';
import SplashImage from '../../assets/images/welcome.png';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';

import { CUSTOM_THEME } from '../../constants/themeConstants'


class Account extends Component {
  queryParams = new URLSearchParams(this.props.location.search);
  username = this.queryParams.get('username');
  hideSecurity = this.queryParams.get('question_id') || this.queryParams.get('answer');
  passwordMigration = this.queryParams.get('password-migration') || false;
  constructor(props) {
    super(props);
    this.state = {
      dateOfBirth: '',
      password: '',
      oldPassword: '',
      confirmPassword: '',
      securityQuestions: [],
      securityAnswer: this.queryParams.get('answer') || '',
      isPasswordValid: false,
      passwordNotMatch: false,
      securityQuestionId: this.queryParams.get('question_id') || null,
      newSecurityAnswer: '',
      newSecurityQuestionId: '',
    };
  }

  updatePasswordValidity = isPasswordValid => {
    this.setState({
      isPasswordValid,
    });
  };

  updateFieldType(fk, type) {
    this.setState(state => {
      return update(state, {
        form: {
          fields: {
            [fk]: {
              type: { $set: type }
            }
          }
        }
      })
    })
  }

  onChangeFieldValue = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onSubmit = () => {
    const { setPassword: isPasswordForm } = this.props.account;
    let { isChangePassword, isChangeSecurity } = this.props.account;
    if (!isChangeSecurity && !this.queryParams.get('change-security')) {
      isChangePassword = this.queryParams.get('change-password');
    }
    if (!isChangePassword && !this.queryParams.get('change-password')) {
      isChangeSecurity = this.queryParams.get('change-security');
    }
    const reqPayload = { username: this.username, ...this.state };
    const {
      validateDOB,
      migrateToPassword,
      resetPassword,
      changePassword,
      changeSecurity,
      setPassword,
    } = this.props;
    let submitAction = validateDOB;
    if (this.passwordMigration) {
      submitAction = migrateToPassword;
    } else if (this.hideSecurity) {
      submitAction = resetPassword;
    } else if (isChangePassword) {
      submitAction = changePassword;
    } else if (isChangeSecurity) {
      reqPayload.securityQuestionId = this.props.account.userSecurity.id;
      reqPayload.username = this.props.currentUser.userData
        ? this.props.currentUser.userData.username
        : '';
      submitAction = changeSecurity;
    } else if (isPasswordForm) {
      submitAction = setPassword;
    }
    if (isPasswordForm || this.passwordMigration || isChangePassword) {
      if (this.state.password === this.state.confirmPassword) submitAction(reqPayload);
      else
        this.setState({
          passwordNotMatch: true,
        });
    } else {
      submitAction(reqPayload);
    }
  };

  render() {
    const {
      securityAnswer,
      securityQuestionId,
      isPasswordValid,
      dateOfBirth,
      oldPassword,
      newSecurityQuestionId,
      newSecurityAnswer,
    } = this.state;

    let { isChangePassword, isChangeSecurity } = this.props.account;
    if (!isChangeSecurity && !this.queryParams.get('change-security')) {
      isChangePassword = this.queryParams.get('change-password');
    }
    if (!isChangePassword && !this.queryParams.get('change-password')) {
      isChangeSecurity = this.queryParams.get('change-security');
    }
    const { setPassword: isPasswordForm } = this.props.account;
    const passwordFormProps = {
      updateFieldType: this.updateFieldType,
      onChangeFieldValue: this.onChangeFieldValue,
      updatePasswordValidity: this.updatePasswordValidity,
      hideSecurity: this.hideSecurity,
      passwordMigration: this.passwordMigration,
      ...this.state,
      ...this.props,
    };
    const dobForm = <DateofBirthForm {...passwordFormProps} />;
    const passwordForm = <SetPasswordForm {...passwordFormProps} />;
    const changePwdForm = <ChangePasswordForm {...passwordFormProps} />;
    const changeSecurityForm = <ChangeSecurityForm {...passwordFormProps} />;

    let formComp = dobForm;
    let formTitle = 'Confirm date of birth';
    let isSubmitDisabled = !dateOfBirth;
    if (this.passwordMigration) {
      formTitle = 'Create login info';
      isSubmitDisabled = !isPasswordValid || !securityQuestionId || !securityAnswer || !dateOfBirth;
      formComp = (
        <div>
          {dobForm}
          <br />
          {passwordForm}
        </div>
      );
    } else if (isChangePassword) {
      isSubmitDisabled = !isPasswordValid || !oldPassword;
      formTitle = 'Change password';
      formComp = changePwdForm;
    } else if (isChangeSecurity) {
      isSubmitDisabled = !securityAnswer || !newSecurityQuestionId || !newSecurityAnswer;
      formTitle = 'Change security details';
      formComp = changeSecurityForm;
    } else if (isPasswordForm) {
      isSubmitDisabled = !isPasswordValid || !securityQuestionId || !securityAnswer;
      formTitle = 'Create password';
      formComp = passwordForm;
    }

    return (
      <>
        

        <div className="first-time-outer">
        <Container fluid style={{ maxWidth: '1440px' }}>
          <Row style={{height: '100%'}}>
          <Col className="no-padding" style={{ backgroundColor: '#F4F4F4'}}>
              <div className="first-time-innerleft">
            <div className="first-time-content">
              <div>
                <h3 className='title m-0 mb-2'>{formTitle}</h3>
                {formComp}
                <div className="d-flex justify-content-end pt-3">
                  <div className="first-time-button-login" >
                    <Button.Primary onClick={this.onSubmit} type='submit' disabled={isSubmitDisabled}>
                      {this.hideSecurity || isChangePassword || isChangeSecurity
                        ? 'SAVE'
                        : this.passwordMigration
                          ? 'FINISH'
                          : 'NEXT'}
                    </Button.Primary>
                  </div>
                </div>
              </div>
            </div>
          </div>
              </Col>
              <Col className="no-padding" style={{ backgroundColor: '#F4F4F4'}}>
              <div className="first-time-innerright">
            <img src={CUSTOM_THEME[this.props.customAppId].welcome} alt="Welcome" className="first-time-imageright" />
          </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Account;
