import {
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
} from '../constants/actionTypes';

const initialState = {
  data: null,
  state: 'NONE',
};

const departmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS_START:
      return { state: 'PENDING', data: null };
    case GET_DEPARTMENTS_SUCCESS:
      return { state: 'SUCCESS', data: action.payload };
    case GET_DEPARTMENTS_FAIL:
      return { state: 'FAIL', data: null };
    default:
      return state;
  }
};

export default departmentsReducer;
