import { apiUrl } from '../constants/apiConsts';
import fetchRequest from '../utils/request';
import { getHeaders } from './helpers';

class TagApi {
  static getUserTags() {
    const request = new Request(`${apiUrl}/content/tag`, {
      method: 'GET',
      headers: getHeaders(),
    });

    return fetchRequest(request);
  }
}

export default TagApi;
