import { LOCATION_CHANGE } from 'connected-react-router';

let initialState = {
  currentPathname: '',
  prevPathname: '',
};

const routerHistory = (state = initialState, action) => {
  if (action.type === LOCATION_CHANGE) {
    let prevPathname = !state.prevPathname ? action.payload.location.pathname : state.prevPathname;

    if (action.payload.location.pathname !== state.currentPathname) {
      prevPathname = state.currentPathname;
    }

    return {
      currentPathname: action.payload.location.pathname,
      prevPathname: prevPathname,
    };
  }

  return state;
};

export default routerHistory;
